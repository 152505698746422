import {createMuiTheme} from "@material-ui/core/styles";

export const myTheme = createMuiTheme({
  direction: "rtl",
  palette: {
    common: {
      black: "#000",
      white: "#fff",
    },
    background: {
      paper: "#fff",
      default: "#fafafa",
    },
    primary: {
      light: "rgba(96, 173, 94, 1)",
      main: "rgba(46, 125, 50, 1)",
      dark: "rgba(0, 80, 5, 1)",
      contrastText: "rgba(244, 244, 244, 1)",
    },
    secondary: {
      light: "rgba(255, 255, 255, 1)",
      main: "rgba(46, 125, 50, 1)",
      dark: "rgba(182, 194, 183, 1)",
      contrastText: "rgba(244, 244, 244, 1)",
    },
    error: {
      light: "#e57373",
      main: "rgba(217, 20, 6, 1)",
      dark: "#d32f2f",
      contrastText: "#fff",
    },
    text: {
      primary: "rgba(0, 0, 0, 0.95)",
      secondary: "rgba(0, 0, 0, 0.73)",
      disabled: "rgba(0, 0, 0, 0.42)",
      hint: "rgba(63, 165, 108, 0.47)",
    },
  },
  typography: {
    fontFamily: "IRANSansWeb",
    fontSize: 16,
  },
  overrides: {
    MuiTableRow: {
      head: {
        backgroundColor: "lightgray",
        "& > th ": {
          color: "black",
          fontFamily: "IRANSansWeb",
          fontWeight: "bold",
          textAlign: "center",
        },
      },
    },
    MuiTableCell: {
      sizeSmall: {
        textAlign: "center",
      },
    },
  },
});
