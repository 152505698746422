import React from "react";
import {
    BooleanField,
    BooleanInput,
    Datagrid,
    DateField,
    Filter,
    List,
    RichTextField,
    SelectInput,
    SimpleShowLayout,
    TextField,
    TextInput,
    required
} from "react-admin";
import CreateView from "../../Components/CreateView";
import EditView from "../../Components/EditView";
import ShowView from "../../Components/ShowView";
import {
  ADVICE_FILTER_CHOICES as filterChoices,
  ADVICE_CHOICES as adviceChoices,
} from "../../Constants/choices";

const AdviceFilter = (props) => (
  <Filter {...props}>
    <SelectInput
      label="نوع"
      choices={filterChoices}
      optionText={"name"}
      optionValue={"value"}
      source={"type"}
      alwaysOn
      emptyText={"----"}
    />
  </Filter>
);

export const AdviceList = (props) => (
  <List {...props} perPage={50} filters={<AdviceFilter />}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <BooleanField source="isDeleted" />
      <BooleanField source="isActionable" />
      <DateField locales="fa-IR" source="updatedAt" />
      <DateField locales="fa-IR" source="createdAt" />
      <RichTextField source="content" />
      <TextField source="type" />
    </Datagrid>
  </List>
);

export const AdviceCreate = (props) => (
  <CreateView {...props}>
    <BooleanInput defaultValue={false} source="isDeleted" />
    <BooleanInput defaultValue={false} source="isActionable" />
    <TextInput multiline source="content"  validate={required()}/>
    <SelectInput
      source="type"
      validate={required()}
      choices={adviceChoices}
    />
  </CreateView>
);

export const AdviceEdit = (props) => (
  <EditView {...props}>
    <BooleanInput source="isDeleted" />
    <BooleanInput source="isActionable" />
    <TextInput multiline source="content" validate={required()} />
    <SelectInput
      source="type"
      validate={required()}
      choices={adviceChoices}
    />
  </EditView>
);

export const AdviceShow = (props) => (
  <ShowView {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <BooleanField source="isDeleted" />
      <BooleanField source="isActionable" />
      <DateField locales="fa-IR" source="updatedAt" />
      <DateField locales="fa-IR" source="createdAt" />
      <RichTextField source="content" />
      <TextField source="type" />
    </SimpleShowLayout>
  </ShowView>
);
