import {SERVER, SERVER_DOMAIN} from "../Constants";
import {httpClient} from "../utils/request";
import { dataProvider } from "./dataProvider";

const apiUrl = SERVER;
const serverUrl = SERVER_DOMAIN;
export function deleteOne(resource, params){
  switch (resource) {
    case "bulkDiscountCode":
    case "pageDataType":
    case "user": {
      return httpClient(`${apiUrl}/${resource}/${params.id}`, {
        method: "DELETE",
      }).then(({json}) => ({data: json}));
    }
    case "bulkDiscountCodes":{
      return httpClient(`${apiUrl}/shop/v2/bulkDiscountCode/${params.id}`, {
        method: "DELETE",
      }).then(({json}) => ({data: json}));
    }
    case "plan": 
    case "shop-package": 
    case "promotion": {
      return httpClient(`${apiUrl}/shop/v2/${resource}/${params.id}`, {
          method: "DELETE",
      }).then(({json}) => ({data: json}));
    }
    case "regularDiscountCodes": {
      return httpClient(`${apiUrl}/shop/v2/regularDiscountCode/${params.id}`, {
          method: "DELETE",
      }).then(({json}) => ({data: json}));
    }
    case "promotion": {
      return httpClient(`${apiUrl}/shop/v2/${resource}/${params.id}`, {
          method: "DELETE",
      }).then(({json}) => ({data: json}));
    }
    case "challenge": {
      let data = {
        ...params.previousData,
        endDate: new Date(params.previousData.endDate).getTime() / 1000,
        startDate: new Date(params.previousData.startDate).getTime() / 1000,
      };
      return httpClient(`${apiUrl}/${resource}/${params.id}`, {
          method: "PUT",
          body: JSON.stringify({
              ...data,
              isDeleted: !data.isDeleted,
          }),
      }).then(({json}) => ({data: json}));
    }
    case "packagePricing": {
      return httpClient(
          `${apiUrl}/${resource}/${params.previousData.packageId}/${params.id}`,
          {
              method: "DELETE",
          }
      ).then(({ json }) => ({
          data: json,
      }));
    }
    case "faqCategory":
    case "faqSubcategory":
    case "faqQuestion":
    case "regularDiscountCode":
    case "packagePricingTag":
    case "notification":
    case "adminUsers":
    case "getToKnow":
    case "campaignCard":
    case "pageDataType":
    case "popUpData": {
      return httpClient(`${apiUrl}/${resource}/${params.id}`, {
        method: "DELETE",
        body: JSON.stringify({ id: params.id }),
      }).then(({ json }) => ({
        data: json,
      }));
    }
    case "food": {
      let similarNames = [];
      if (params.previousData.similarNames.length > 0) {
          params.previousData.similarNames.forEach(({ name }) => {
              similarNames.push(name);
          });
          let { previousData } = params;
          previousData = { ...params.previousData, similarNames };
          return httpClient(`${apiUrl}/${resource}/${params.id}`, {
              method: "PUT",
              body: JSON.stringify({
                  ...previousData,
                  isDeleted: !params.previousData.isDeleted,
              }),
          }).then(({ json }) => ({ data: json }));
      }
      return dataProvider.deleteOne(resource, params);
    }
      case "tracker":
          return httpClient(`${serverUrl}/api/${resource}/${params.id}`, {
              method: "DELETE",
          }).then(({json}) => ({data: {...json.data,id:json.data._id}}));
    default:
      return httpClient(`${apiUrl}/${resource}/${params.id}`, {
        method: "PUT",
        body: JSON.stringify({
          ...params.previousData,
          isDeleted: !params.previousData.isDeleted,
        }),
      }).then(({json}) => ({data: json})
    );
  } 
};
