import * as React from "react";
import PropTypes from "prop-types";

const ArrayLengthField = ({ source, record = {} }) => {
  return <span>{record[source].length}</span>;
};
ArrayLengthField.defaultProps = {
  addLabel: true,
};
ArrayLengthField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default ArrayLengthField;
