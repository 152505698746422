import React from "react";
import {
    BooleanField,
    BooleanInput,
    Datagrid,
    DatagridBody,
    DateField,
    ImageField,
    ImageInput,
    List,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    SimpleShowLayout,
    TextField,
    TextInput,
    required, SelectArrayInput
} from "react-admin";
import EditView from "../../Components/EditView";
import CreateView from "../../Components/CreateView";
import ShowView from "../../Components/ShowView";
import {ArrayTextField} from "../../Components/ArrayTextField";
import {MARKETS_CHOICES} from "../../Constants/choices";

const BodyFc = (props) => {
  return <DatagridBody {...props} />;
};
export const BannerList = (props) => (
  <List {...props}>
    <Datagrid rowClick="show" body={<BodyFc />}>
      <TextField source="name" />
      <ReferenceField source="challengeId" reference="challenge">
        <TextField source="name" />
      </ReferenceField>
      <ImageField source="imageId" title="title" />
      <TextField source="description" />
      <DateField locales="fa-IR" source="updatedAt" />
      <ArrayTextField source="markets" />
      <TextField source="id" />
      <BooleanField source="isDeleted" />
    </Datagrid>
  </List>
);

export const BannerEdit = (props) => (
  <EditView {...props}>
    <BooleanInput source="isDeleted" />
    <DateField locales="fa-IR" source="updatedAt" />
    <TextInput source="name"  validate={required()}/>
    <ReferenceInput source="challengeId" reference="challenge">
      <SelectInput optionText="name"  validate={required()}/>
    </ReferenceInput>
    <ImageInput source="pictures" label="تصویر مربوطه" accept="image/*">
      <ImageField source="src" title="title" />
    </ImageInput>
    <TextInput source="description" />
    <SelectArrayInput  source="markets" choices={MARKETS_CHOICES}/>
  </EditView>
);

export const BannerCreate = (props) => (
  <CreateView {...props}>
    <BooleanInput defaultValue={false} source="isDeleted" />
    <TextInput source="name"  validate={required()}/>
    <ReferenceInput source="challengeId" reference="challenge">
      <SelectInput optionText="name" validate={required()} />
    </ReferenceInput>
    <ImageInput source="pictures" label="تصویر مربوطه" accept="image/*">
      <ImageField source="src" title="title" />
    </ImageInput>
    <TextInput source="description" />
    <SelectArrayInput  source="markets" choices={MARKETS_CHOICES}/>
  </CreateView>
);

export const BannerShow = (props) => (
  <ShowView {...props}>
    <SimpleShowLayout>
      <BooleanField source="isDeleted" />
      <DateField locales="fa-IR" source="updatedAt" />
      <TextField source="name" />
      <ReferenceField source="challengeId" reference="challenge">
        <TextField source="name" />
      </ReferenceField>
      <ImageField source="imageId" />
      <TextField source="description" />
      <ArrayTextField source="markets" />
      <TextField source="id" />
    </SimpleShowLayout>
  </ShowView>
);
