import SportsIcon from "@material-ui/icons/Sports";
import {
  ConfigCreate,
  ConfigEdit,
  ConfigList,
  ConfigShow,
} from "./ConfigPanel";

export const config = {
  name: "config",
  list: ConfigList,
  edit: ConfigEdit,
  show: ConfigShow,
  create: ConfigCreate,
  icon: SportsIcon,
  options: { cat: "misc" },
};
