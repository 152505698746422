import React from "react";
import {
    Datagrid,
    List,
    SelectInput,
    SimpleShowLayout,
    TextField,
    TextInput,
    required,
} from "react-admin";
import EditView from "../../Components/EditView";
import ShowView from "../../Components/ShowView";
import CreateView from "../../Components/CreateView";
import {POPUP_DATA_CHOICES} from "../../Constants/choices";

export const ErrorMessageList = (props) => (
    <List {...props} >
        <Datagrid rowClick="show" >
            <TextField source="errorType " />
            <TextField source="errorMessage  " />
        </Datagrid>
    </List>
);

export const ErrorMessageEdit = (props) => (
    <EditView {...props}>
        <SelectInput source="errorType " choices={POPUP_DATA_CHOICES} validate={required()}  />
        <TextInput source="errorMessage" validate={required()} />
    </EditView>
);

export const ErrorMessageShow = (props) => (
    <ShowView {...props}>
        <SimpleShowLayout>
            <TextField source="errorType " />
            <TextField source="errorMessage  " />
        </SimpleShowLayout>
    </ShowView>
);

export const ErrorMessageCreate = (props) => (
    <CreateView {...props}>
        <SelectInput source="errorType " choices={POPUP_DATA_CHOICES} validate={required()}  />
        <TextInput source="errorMessage" validate={required()} />
    </CreateView>
);
