import axios from "axios";
const { SERVER } = require("../Constants");

export const authProvider = {
  login: ({ username, password }) => {
    const req = new Request(`${SERVER}/login`, {
      method: "POST",
      body: JSON.stringify({ phoneNumber: username, code: password }),
      headers: new Headers({ "Content-Type": "application/json" }),
    });
    return fetch(req)
      .then((res) => {
        if (res.status < 200 || res.status >= 300) {
          throw new Error(res.statusText);
        }
        return res.json();
      })
      .then((res) => {
        const { Authorization, role, refreshToken } = res.result;
        localStorage.setItem("Authorization", Authorization);
        localStorage.setItem("refreshToken", refreshToken);
        localStorage.setItem("role", role);
      });
  },
  logout: () => {
    localStorage.removeItem("Authorization");
    return Promise.resolve();
  },
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      const refreshToken =    localStorage.getItem("refreshToken")
      axios
      .get(`${SERVER}/refresh_token`, {
        headers: {
          'Authorization': refreshToken
        }
      })
      .then((res) => {
        const { Authorization, role, refreshToken } = res.data.result;
        localStorage.setItem("Authorization", Authorization);
        localStorage.setItem("refreshToken", refreshToken);
        localStorage.setItem("role", role);
      })
      .catch(() => {
        return Promise.reject();
      });
      if(!refreshToken) {
        return Promise.reject();
      }
    }
    return Promise.resolve();
    },
  checkAuth: () => 

    localStorage.getItem("Authorization")
      ? Promise.resolve()
      : Promise.reject(),
  getPermissions: () => {
    const role = localStorage.getItem("role");
    return role ? Promise.resolve(role) : Promise.reject();
  },
};
