import React from "react";
import {
  ArrayField,
  ChipField,
  Datagrid, Filter,
  List, SelectInput, SingleFieldList,
  TextField,
  ArrayInput,
  SimpleFormIterator,
} from "react-admin";
import {
  DATA_PAGE_NAMES_CHOICES as pageChoices,
  PAGE_DATA_TYPES_CHOICES as typeChoices
} from "../../Constants/choices";

const PackagePricingFilter = (props) => (
    <Filter {...props}>
      <ArrayInput source="pagesDataTypes">
        <SimpleFormIterator>
          <SelectInput source="page" choices={pageChoices}  />
          <SelectInput source="type" choices={typeChoices}  />
        </SimpleFormIterator>
      </ArrayInput>
    </Filter>
);

export const PagesDataList = (props) => (
  <List {...props}  filters={<PackagePricingFilter/>}>
    <Datagrid rowClick="show">
      <ArrayField source="pagesDataTypes">
        <SingleFieldList>
          <ChipField source="type" />
        </SingleFieldList>
        </ArrayField>
      <ArrayField source="pagesDataTypes">
        <SingleFieldList>
          <ChipField source="page" />
        </SingleFieldList>
      </ArrayField>
      <TextField source="id" />
    </Datagrid>
  </List>
);


