import React from "react";
import CategoryIcon from "@material-ui/icons/Bookmark";
import {
    ArrayField,
    ArrayInput,
    BooleanField,
    ChipField,
    Datagrid,
    DateField,
    Filter,
    List,
    NumberField,
    ReferenceArrayField,
    ReferenceArrayInput,
    ReferenceField,
    ReferenceManyField,
    SelectArrayInput,
    SimpleFormIterator,
    SingleFieldList,
    Tab,
    TabbedShowLayout,
    TextField,
    TextInput,
    required, BooleanInput, 
} from "react-admin";

import EditView from "../../Components/EditView";
import ShowView from "../../Components/ShowView";
import CreateView from "../../Components/CreateView";

const FoodFilter = (props) => (
  <Filter {...props}>
    <TextInput label="جست‌وجو" source="name" alwaysOn />
    <ReferenceArrayInput label="دسته‌بندی غذا" source="foodCategories" reference="foodCategory" allowEmpty>
      <SelectArrayInput optionText="name" />
    </ReferenceArrayInput>
      <BooleanInput source="hasCategory" label="دارای دسته بندی" alwaysOn  />
  </Filter>
);
const FoodTitle = ({ record }) => {
  return <span> غذای {record ? `"${record.name}"` : ""}</span>;
};

export const FoodList = (props) => {
  return (
    <List {...props}  filters={<FoodFilter />}  sort={{ field: "updatedAt", order: "DESC" }}>
      <Datagrid rowClick="show">
        <TextField source="name" />
        <ReferenceArrayField source="foodCategories" reference="foodCategory" linkType="show">
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <ArrayField source="similarNames" label={"نام‌های مشابه"}>
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ArrayField>
        <DateField locales="fa-IR" source="updatedAt" />
        <NumberField source="id" />
        <BooleanField source="isDeleted" />
      </Datagrid>
    </List>
  );
};


export const FoodEdit = (props) => (
  <EditView {...props} title={<FoodTitle />}>
    <TextInput source="name" validate={required()} />
    <ReferenceArrayInput source="foodCategories" reference="foodCategory" label="Food Category">
      <SelectArrayInput>
        <ChipField source="name" label="Food categories" />
      </SelectArrayInput>
    </ReferenceArrayInput>

    <ArrayInput source="similarNames" label={"نام‌های مشابه"}>
      <SimpleFormIterator>
        <TextInput source="name" />
      </SimpleFormIterator>
    </ArrayInput>
    <TextField source="id" />
  </EditView>
);

export const FoodShow = (props) => (
  <ShowView {...props} title={<FoodTitle />}>
    <TabbedShowLayout>
      <Tab label="توضیحات">
        <BooleanField source="isDeleted" />
        <DateField locales="fa-IR" source="updatedAt" />
        <TextField source="name" />
        <ReferenceArrayField source="foodCategories" reference="foodCategory">
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <ArrayField source="similarNames" label={"نام‌های مشابه"}>
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ArrayField>
        <TextField source="id" />
        <ReferenceManyField target="foodId" reference="foodFact" label="">
          <Datagrid rowClick="show">
            <ReferenceField source="foodUnitId" reference="foodUnit">
              <TextField source="name" />
            </ReferenceField>
            <NumberField source="calorie" />
            <NumberField source="protein" />
            <NumberField source="carbohydrate" />
            <NumberField source="cholesterol" />
            <NumberField source="fat" />
            <NumberField source="sugar" />
            <NumberField source="sodium" />
            <NumberField source="calcium" />
            <NumberField source="iron" />
            <NumberField source="transFat" />
            <NumberField source="fiber" />
            <NumberField source="potassium" />
            <NumberField source="phosphorus" />
            <NumberField source="monounsaturatedFat" />
            <NumberField source="polyunsaturatedFat" />
            <NumberField source="saturatedFat" />
            <NumberField source="magnesium" />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </ShowView>
);

export const FoodCreate = (props) => (
  <CreateView {...props}>
    <TextInput source="name" validate={required()} />
    <ReferenceArrayInput source="foodCategories" reference="foodCategory" label="دسته‌ی غذا">
      <SelectArrayInput>
        <ChipField source="name" label="دسته‌ی غذا" />
      </SelectArrayInput>
    </ReferenceArrayInput>
    <ArrayInput source="similarNames" label={"نام‌های مشابه"}>
      <SimpleFormIterator>
        <TextInput source="name" />
      </SimpleFormIterator>
    </ArrayInput>
  </CreateView>
);

export default {
  list: FoodList,
  edit: FoodEdit,
  show: FoodShow,
  create: FoodCreate,
  icon: CategoryIcon,
};
