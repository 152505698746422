import BorderVerticalIcon from "@material-ui/icons/BorderVertical";
import {
  PageDataTypeCreate,
  PageDataTypeEdit,
  PageDataTypeList,
  PageDataTypeShow,
} from "./PageDataType";

import {
  ABTestEdit,
  ABTestList,
  ABTestShow,
} from "./ABTest";

import {ABTestCreate,} from "./ABTestCreate";

import {
  RemoteConfigCreate,
  RemoteConfigEdit,
  RemoteConfigList,
  RemoteConfigShow,
} from "./RemoteConfig";

import {PagesDataList} from "./PagesData";

export const pageDataType = {
  name: "pageDataType",
  list: PageDataTypeList,
  edit: PageDataTypeEdit,
  show: PageDataTypeShow,
  create: PageDataTypeCreate,
  icon: BorderVerticalIcon,

};

export const ABTests = {
  name:  "abtest",
  list:   ABTestList,
  edit:   ABTestEdit,
  show:   ABTestShow,
  create: ABTestCreate,
  icon: BorderVerticalIcon,
  options: { cat: "a/b" },
};

export const pagesData = {
  name: "pagesData",
  list: PagesDataList,
  edit: null,
  show: null,
  create: null,
  icon: BorderVerticalIcon,

};

export const RemoteConfigs = {
  name: "remoteConfig",
  list: RemoteConfigList,
  edit: RemoteConfigEdit,
  show: RemoteConfigShow,
  create: RemoteConfigCreate,
  icon: BorderVerticalIcon,
  options: { cat: "a/b" },
};
