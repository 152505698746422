import React from "react";
import {
  AutocompleteInput,
  BooleanField,
  BooleanInput,
  Datagrid,
  DateField,
  Filter,
  List,
  ReferenceField,
  ReferenceInput,
  required,
  SimpleShowLayout,
  TextField,
  TextInput,
} from "react-admin";

import EditView from "../../Components/EditView";
import CreateView from "../../Components/CreateView";
import ShowView from "../../Components/ShowView";

const CategoryFilter = (props) => (
  <Filter {...props}>
    <TextInput label="جست‌وجو" source="barcode" alwaysOn />
  </Filter>
);

export const FoodBarcodeList = (props) => (
  <List {...props} filters={<CategoryFilter />} perPage={15}>
    <Datagrid rowClick="show">
      <TextField source="barcode" />
      <ReferenceField source="foodId" reference="food">
        <TextField source="name" />
      </ReferenceField>
      <DateField locales="fa-IR" source="updatedAt" />
      <TextField source="id" />
      <BooleanField source="isDeleted" />
    </Datagrid>
  </List>
);

export const FoodBarcodeEdit = (props) => (
  <EditView {...props}>
    <TextInput source="barcode" validate={required()} />
    <ReferenceInput source="foodId" reference="food" filterToQuery={(searchText) => ({ name: searchText })}>
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <DateField locales="fa-IR" source="createdAt" />
    <DateField locales="fa-IR" source="updatedAt" />
    <BooleanInput source={"isDeleted"} />
  </EditView>
);

export const FoodBarcodeCreate = (props) => (
  <CreateView {...props}>
    <TextInput source="barcode" validate={required()} />
    <ReferenceInput source="foodId" reference="food" filterToQuery={(searchText) => ({ name: searchText })}>
      <AutocompleteInput optionText="name" validate={required()} />
    </ReferenceInput>
  </CreateView>
);

export const FoodBarcodeShow = (props) => (
  <ShowView {...props}>
    <SimpleShowLayout>
      <TextField source="barcode" />
      <ReferenceField source="foodId" reference="food">
        <TextField source="name" />
      </ReferenceField>
      <DateField locales="fa-IR" source="createdAt" />
      <DateField locales="fa-IR" source="updatedAt" />
      <BooleanField source="isDeleted" />
    </SimpleShowLayout>
  </ShowView>
);
