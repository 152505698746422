import SpeakerNotesIcon from "@material-ui/icons/SpeakerNotes";
import {
  RemoveDuplicateClient
} from "./RemoveDuplicateClient"
import {
  reportOldUser
} from "./ReportOldUser"
import {
  OldNonRegister
} from "./OldNonRegister"
import {
  UserLog
} from "./UserLog"
import {
  NonPaidUser
} from "./NonPaidUser"
import {
  BarcodeLog
} from "./BarcodeLog"
import {
  SalesReport
} from "./SalesReport"
import {
  SignUpReport
} from "./SignUpReport"
import {
  FoodUnitUsage
} from "./FoodUnitUsages"
import {
  RandomFood
} from "./RandomFood"
import {
  addGroupSubscription
} from "./AddGroupSubscription"

import {
  UsersByExpirationDate
} from "./UsersByExpirationDate"

import {
  GetUsersTransaction
} from "./GetUsersTransaction"
import {
  GetMigratedUsers
} from "./GetMigratedUsers"
import {
  ScoreboardReport
} from "./ScoreboardReport"
import {
  ScoreboardVoteReport
} from "./ScoreboardVoteReport"

export const RemoveDuplicateData = {
  name: "fix_duplicate_client_data",
  list:   RemoveDuplicateClient,
  edit:   null,
  show:   null,
  create: null,
  icon: SpeakerNotesIcon,
  options: { cat: "API" },
};

export const ReportOldUser = {
  name: "report/users/old/",
  list:   reportOldUser,
  edit:   null,
  show:   null,
  create: null,
  icon: SpeakerNotesIcon,
  options: { cat: "API" },
};

export const OldNonRegisterUser = {
  name: "old_non_registered_karafs_users",
  list:   OldNonRegister,
  edit:   null,
  show:   null,
  create: null,
  icon: SpeakerNotesIcon,
  options: { cat: "API" },
};
export const UserLogCount = {
  name: "KarafsUserLog",
  list:   UserLog,
  edit:   null,
  show:   null,
  create: null,
  icon: SpeakerNotesIcon,
  options: { cat: "API" },
};
export const NonPaidUsers = {
  name: "NonPaidUser",
  list:   NonPaidUser,
  edit:   null,
  show:   null,
  create: null,
  icon: SpeakerNotesIcon,
  options: { cat: "API" },
};

export const BarcodeLogCount = {
  name: "BarcodeLog",
  list:   BarcodeLog,
  edit:   null,
  show:   null,
  create: null,
  icon: SpeakerNotesIcon,
  options: { cat: "API" },
};

export const SaleReport = {
  name: "SalesReport",
  list:   SalesReport,
  edit:   null,
  show:   null,
  create: null,
  icon: SpeakerNotesIcon,
  options: { cat: "API" },
};
export const SignUpsReport = {
  name: "SignUpReport",
  list:   SignUpReport,
  edit:   null,
  show:   null,
  create: null,
  icon: SpeakerNotesIcon,
  options: { cat: "API" },
};
export const FoodUnitsUsage = {
  name: "FoodUnitUsage",
  list:   FoodUnitUsage,
  edit:   null,
  show:   null,
  create: null,
  icon: SpeakerNotesIcon,
  options: { cat: "API" },
};

export const RandomFoods = {
  name: "RandomFood",
  list:   RandomFood,
  edit:   null,
  show:   null,
  create: null,
  icon: SpeakerNotesIcon,
  options: { cat: "API" },
};
export const addGroupSubscriptions = {
  name: "addGroupSubscription",
  list:   addGroupSubscription,
  edit:   null,
  show:   null,
  create: null,
  icon: SpeakerNotesIcon,
  options: { cat: "API" },
};
export const GetUsersTransactions = {
  name: "getUsersTransaction",
  list:   GetUsersTransaction,
  edit:   null,
  show:   null,
  create: null,
  icon: SpeakerNotesIcon,
  options: { cat: "API" },
};


export const UsersByExpirationDates = {
  name: "UsersByExpirationDate",
  list:   UsersByExpirationDate,
  edit:   null,
  show:   null,
  create: null,
  icon: SpeakerNotesIcon,
  options: { cat: "API" },
};
export const GetMigratedUser = {
  name: "GetMigratedUsers",
  list:   GetMigratedUsers,
  edit:   null,
  show:   null,
  create: null,
  icon: SpeakerNotesIcon,
  options: { cat: "API" },
};
export const ScoreboardReports = {
  name: "scoreboardReport",
  list:   ScoreboardReport,
  edit:   null,
  show:   null,
  create: null,
  icon: SpeakerNotesIcon,
  options: { cat: "API" },
};
export const ScoreboardVoteReports = {
  name: "scoreboardVoteReport",
  list:   ScoreboardVoteReport,
  edit:   null,
  show:   null,
  create: null,
  icon: SpeakerNotesIcon,
  options: { cat: "API" },
};