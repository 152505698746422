import React, {useEffect, useState} from 'react';
import {useGetList, useNotify} from 'react-admin'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from "@material-ui/core";
import {CLINIC_USER_ACTIVITY_LEVEL_CHOICES, USER_ACTIVITY_LEVEL_CHOICES, CLINIC_USER_Difficulty_CHOICES} from "../../Constants/choices";
import {PersianDatePicker} from "../../Components/PersianDatePickers";
import {SERVER_DOMAIN} from "../../Constants";
import {httpClient} from "../../utils/request";

const DietGenerate = ({user,session,visit}) => {
    const [isOpen, setOpen] = useState(false);
    const notify = useNotify();
    const [values, setValues] = useState({
        startWeight: user.weight,
        diseases: user.diseases,
        hatedFoods: user.hatedFoods,
        activity: user.activityLevel,
        pregnancyDate: null,
        breastFeedingDate: null,
        difficulty:null
    });
    const [calculation, setCalculation] = useState(null);
    const {data: diseases} = useGetList('disease');
    const {data: hatedFoods} = useGetList('hatedFood');
    const {data: specialTypes} = useGetList('specialType', undefined, undefined, {completed: true, deleted: false});
    useEffect(() => {
        if (values.startWeight && values.goalWeight && values.activity)
            httpClient(`${SERVER_DOMAIN}/api/diet/calculateV2`, {
                method: 'post',
                body: JSON.stringify({
                    weight: values.startWeight,
                    height: user.height,
                    sex: user.sex,
                    age: new Date().getFullYear() - new Date(user.birthDate).getFullYear(),
                    activity: values.activity,
                    goalWeight: values.goalWeight
                })
            })
                .then(res => setCalculation(res.json.data)).then(() => setValues(values => ({...values,difficulty:null})))
                // .then(() => console.log("values", values));
                // .then(() => console.log("calculation", calculation));
                // .then(() => setCalculation({goal: 'weightGain', calories: []}));
                // .then(() => setCalculation({goal: 'weightFix', dietWeightFixCalorie: null}));
    }, [values.startWeight, values.goalWeight, values.activity]);

    useEffect(() => {
        if (calculation && ((['weightLoss', 'weightGain'].includes(calculation.goal) && !calculation.calories?.length) || (['weightFix','pregnancy','breastfeeding'].includes(calculation?.goal) && !calculation.dietWeightFixCalorie)))
            notify("با توجه به وزن و میزان فعالیت برای شما برنامه غذایی نداریم.", "error")
    }, [calculation]);

    const onOpen = () => {
        // console.log("user", user)
        // console.log("values", values)
        // console.log("calculation", calculation)
        
        setOpen(true);
    }

    const onClose = () => {
        setOpen(false);
    }


    const onGenerateDiet = (values) => {
        // console.log("test", !(values.difficulty || (['weightFix','pregnancy','breastfeeding'].includes(calculation?.goal) && calculation?.dietWeightFixCalorie)))
        if(!values.startWeight || !values.goalWeight || !values.activity || !(values.difficulty || (['weightFix','pregnancy','breastfeeding'].includes(calculation?.goal) && calculation?.dietWeightFixCalorie)) || !values.specialTypes){
            return notify("فیلد ها را کامل کنید.","error")
        }
        // console.log("HHH")
        // console.log(['weightFix','pregnancy','breastfeeding'].includes(calculation?.goal) ? calculation.dietWeightFixCalorie : calculation.calories.filter(calorie => calorie.difficulty===values.difficulty)[0].diet)
        // console.log(Number(['weightFix','pregnancy','breastfeeding'].includes(calculation?.goal) ? calculation.dietWeightFixCalorie : calculation.calories.filter(calorie => calorie.difficulty===values.difficulty)[0].diet) == undefined)
        if(Number(['weightFix','pregnancy','breastfeeding'].includes(calculation?.goal) ? calculation.dietWeightFixCalorie : calculation.calories.filter(calorie => calorie.difficulty===values.difficulty)[0].diet) == undefined){
            return notify("با توجه به وزن و میزان فعالیت برای شما برنامه غذایی نداریم.","error")
        }
        const body = {
            ...values,
            user:user._id,
            visitId:visit._id,
            sessionId:session.sessionId,
            goal:calculation.goal,
            specialTypes:[values.specialTypes],
            selectedCalorie:['weightFix','pregnancy','breastfeeding'].includes(calculation?.goal) ? calculation.dietWeightFixCalorie : calculation.calories.filter(calorie => calorie.difficulty===values.difficulty)[0].diet,
            startWeight:Number(values.startWeight),
            goalWeight:Number(values.goalWeight),
        };
        if (!body.difficulty) {
            delete body.difficulty
        }
        httpClient(`${SERVER_DOMAIN}/api/clinic/diet`,{
            method:'post',
            body:JSON.stringify(body)
        }).then(() => notify("رژیم ایجاد شد.", "success"))
        setTimeout(function(){
            window.location.reload(1);
         }, 5000);

        // console.log("GENERATE", JSON.stringify(body))
    }
    const onChange = (e) => {
        e.persist && e.persist();
        // console.log("calculation", calculation)
        setValues(values => ({
            ...values,
            [e.target.name]: e.target.value
        }));
    }
    return (
        <>
            <Button onClick={onOpen}>دریافت رژیم</Button>
            <Dialog open={isOpen} onClose={onClose}>
                <DialogTitle>دریافت رژیم</DialogTitle>
                <DialogContent>
                    <TextField required onChange={onChange} value={values["startWeight"]} fullWidth name={"startWeight"}
                               type={"number"} label={"وزن شروع"}/>
                    <TextField required onChange={onChange} value={values["goalWeight"]} fullWidth name={"goalWeight"}
                               type={"number"} label={"وزن هدف"}/>
                    <FormControl fullWidth>
                        <InputLabel required>سطح فعالیت</InputLabel>
                        <Select  name={"activity"} onChange={onChange} value={values["activity"]}>
                            {CLINIC_USER_ACTIVITY_LEVEL_CHOICES.map(choice => <MenuItem
                                value={choice.id}>{choice.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                    {!(['weightFix','pregnancy','breastfeeding'].includes(calculation?.goal) && calculation?.dietWeightFixCalorie) && <FormControl fullWidth>
                        <InputLabel required>درجه سختی</InputLabel>
                        
                        <Select name={"difficulty"} onChange={onChange} value={values["difficulty"]}>
                        {CLINIC_USER_Difficulty_CHOICES.map(choice => <MenuItem
                                value={choice.id}>{choice.name}</MenuItem>)}
                        </Select>
                    </FormControl>}
                    <FormControl fullWidth>
                        <InputLabel>بیماری ها</InputLabel>
                        <Select name={"diseases"} onChange={onChange} value={values["diseases"]} multiple>
                            {diseases ? Object.keys(diseases).map(diseaseId => <MenuItem
                                value={diseases[diseaseId]._id}>{diseases[diseaseId].name}</MenuItem>) : null}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel>حساسیت های غذایی</InputLabel>
                        <Select name={"hatedFoods"} onChange={onChange} value={values["hatedFoods"]} multiple>
                            {hatedFoods ? Object.keys(hatedFoods).map(hatedFoodId => <MenuItem
                                value={hatedFoods[hatedFoodId]._id}>{hatedFoods[hatedFoodId].name}</MenuItem>) : null}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel required>نوع رژیم</InputLabel>
                        <Select name={"specialTypes"} onChange={onChange} value={values["specialTypes"]}>
                            {specialTypes ? Object.keys(specialTypes).map(specialTypeId => <MenuItem
                                value={specialTypes[specialTypeId]._id}>{specialTypes[specialTypeId].name}</MenuItem>) : null}
                        </Select>
                    </FormControl>
                    {user.sex === "FEMALE" && <>
                        <PersianDatePicker minDate={Date.now() - 9 * 30 * 24 * 3600 * 1000} label={"تاریخ شروع بارداری"}
                                           disabled={values["breastFeedingDate"]} clearable
                                           value={values["pregnancyDate"]} onChange={(value) => onChange({
                            target: {
                                name: 'pregnancyDate',
                                value: value
                            }
                        })}/>
                        <PersianDatePicker minDate={Date.now() - 12 * 30 * 24 * 3600 * 1000} label={"تاریخ شروع شیردهی"}
                                           disabled={values["pregnancyDate"]} clearable
                                           value={values["breastFeedingDate"]} onChange={(value) => onChange({
                            target: {
                                name: 'breastFeedingDate',
                                value: value
                            }
                        })}/>
                    </>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => onGenerateDiet(values)}>دریافت رژیم</Button>
                    <Button onClick={onClose} autoFocus>
                        انصراف
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default DietGenerate;