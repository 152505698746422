import React from "react";
import {
    BooleanField,
    BooleanInput,
    Datagrid,
    DateField,
    Filter,
    ImageField,
    ImageInput,
    List,
    SimpleShowLayout,
    TextField,
    TextInput,
    required,
    SelectInput,
    SelectField,
} from "react-admin";
import { withStyles } from "@material-ui/styles";
import EditView from "../../Components/EditView";
import ShowView from "../../Components/ShowView";
import CreateView from "../../Components/CreateView";
import { EXERCISE_TYPE_ENUM as typeChoices } from "../../Constants/choices";

export const ExerciseInstructionCategoryFilter = (props) => (
  <Filter {...props}>
    <TextInput label="جست‌وجو" source="title" alwaysOn />
  </Filter>
);

const styles = {
  inlineBlock: {
    display: "inline-flex",
    marginRight: "1rem",
    right: "0 !important",
  },
};

export const ExerciseInstructionCategoryList = (props) => (
  <List {...props} perPage={15} filters={<ExerciseInstructionCategoryFilter />}>
    <Datagrid rowClick="show">
      <TextField source="title" />
      <SelectField source="type"  choices={typeChoices} />
      <BooleanField source="isPremium" />
      <BooleanField source="needsEquipment" />
      <ImageField source={"image"} />
      <DateField locales="fa-IR" source="updatedAt" />
      <BooleanField source="isDeleted" />
    </Datagrid>
  </List>
);
export const ExerciseInstructionCategoryEdit = (props) => (
  <EditView {...props}>
    <TextInput source="title" validate={required()}/>
    <BooleanInput source="needsEquipment" />
    <BooleanInput source="isPremium" />
    <SelectInput source="type" choices={typeChoices}  />
    <ImageField source="image" />
    <ImageInput source="pictures" label="تصویر مربوطه" accept="image/*">
      <ImageField source="src" title="title" />
    </ImageInput>
  </EditView>
);

export const ExerciseInstructionCategoryShow = (props) => (
  <ShowView {...props}>
    <SimpleShowLayout className="showShit">
      <TextField source="title" />
      <SelectField source="type"  choices={typeChoices} />
      <ImageField source="image" />
      <BooleanField source="needsEquipment" />
      <BooleanField source="isPremium" />
      <BooleanField source="isDeleted" />
      <DateField locales="fa-IR" source="updatedAt" />
      <TextField source="id" />
    </SimpleShowLayout>
  </ShowView>
);

export const ExerciseInstructionCategoryCreate = withStyles(styles)(
  ({ classes, ...props }) => (
    <CreateView {...props}>
      <TextInput source="title"  validate={required()}/>
      <SelectInput source="type" choices={typeChoices}  />
      <BooleanInput defaultValue={false} source="isPremium" />
      <BooleanInput defaultValue={false} source="needsEquipment" />
      <ImageInput source="pictures" label="تصویر مربوطه" accept="image/*">
        <ImageField source="src" title="title" />
      </ImageInput>
    </CreateView>
  )
);
