import {AdviceCreate, AdviceEdit, AdviceList, AdviceShow} from "./Advice";
import SpeakerNotesIcon from "@material-ui/icons/SpeakerNotes";
export const advice = {
  name: "advice",
  list: AdviceList,
  edit: AdviceEdit,
  show: AdviceShow,
  create: AdviceCreate,
  icon: SpeakerNotesIcon,
  options: { cat: "marketing" },
};
