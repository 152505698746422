import React from "react";
import {
    Datagrid,
    List, 
    required,
    TextField,
    BooleanField,
    BooleanInput,
    SimpleShowLayout, 
    TextInput,
    ImageField,
    ImageInput,
} from "react-admin";
import TextFields from '@material-ui/core/TextField';
import EditView from "../../Components/EditView";
import CreateView from "../../Components/CreateView";
import ShowView from "../../Components/ShowView";
import {Typography} from "@material-ui/core";

export const RemoteConfigList = (props) => (
    <List {...props}  >
      <Datagrid rowClick="show">
        <TextField source="type" />
        <TextField source="page"  />
        <BooleanField source="isDefault" />
        <TextField source="id" />
      </Datagrid>
    </List>
);

export const RemoteConfigEdit = (props) =>(
    <EditView {...props}>
        <BooleanInput source="isDefault" />
        <TextInput source="type"   fullWidth multiline  />
        <TextInput source="page" validate={required()}  />
        <ImageInput source="picture" label="تصویر مربوطه" accept="image/*">
          <ImageField source="src" title="title" />
        </ImageInput>
        <ImageField source="type" label="عکس فعلی" title="title" />
    </EditView>
);

export const RemoteConfigCreate = (props) => (
    <CreateView {...props}>
        <BooleanInput source="isDefault" />
        <TextInput source="type"   fullWidth multiline />
        <TextInput source="page"  />
        <ImageInput source="picture" label="تصویر مربوطه" accept="image/*">
            <ImageField source="src" title="title" />
         </ImageInput>
         <Typography variant="body1" gutterTop style={{  color: "red"}} fullWidth>
              در صورت اپلود عکس ، لینک عکس در فیلد نوع (Type) قرار خواهد گرفت
        </Typography>
    </CreateView>
);

const Text = (props) => (<TextFields label="نوع" fullWidth multiline defaultValue={props.record.type} InputProps={{readOnly: true,}}/>)

export const RemoteConfigShow = (props) => (
    <ShowView {...props}>
      <SimpleShowLayout>
        <TextField source="id" />
        <BooleanField source="isDefault"/>
        <TextField source="page"  />
        <Text/>
        <ImageField source="type" label="تصویر"/>
      </SimpleShowLayout>
    </ShowView>
);



