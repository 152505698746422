import {ErrorMessageCreate,
    ErrorMessageList,
    ErrorMessageEdit,
    ErrorMessageShow} from "./ErrorMessage";
import PanoramaIcon from "@material-ui/icons/Panorama";

export const errorMessages = {
    name: "errorMessage",
    list: ErrorMessageList,
    edit: ErrorMessageEdit,
    show: ErrorMessageShow,
    create: ErrorMessageCreate,
    icon: PanoramaIcon,
    options: { cat: "marketing" },
};
