import React, { useState } from "react";
import {
  Confirm,
  Datagrid,
  DateField,
  DeleteButton,
  EditButton,
  Labeled,
  List,
  NumberField,
  ReferenceField,
  ReferenceInput,
  required,
  SelectArrayInput,
  SelectField,
  SelectInput,
  Show,
  SimpleShowLayout,
  TextField,
  TextInput,
  TopToolbar,
  useDataProvider,
  useNotify,
  useRedirect,
  useRefresh,
  useTranslate,
  useLoading,
  ImageInput,
  ImageField,
  FileInput,
  FileField,
  NumberInput,
} from "react-admin";
import { Box, Button, Grid, Tooltip, Typography } from "@material-ui/core";
import { SERVER } from "../../Constants";
import { axiosFetcher } from "../../utils/axiosFetcher";
import BackToListIcon from "@material-ui/icons/LowPriority";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import CreateView from "../../Components/CreateView";
import EditView from "../../Components/EditView";
import { MARKETS_CHOICES_NOTIFICATION,Notification_Data_Sex_Enums,PAGE_NAMES_ENUM,Notification_Data_Goals_Enums,Notification_Data_Subscription_Enums } from "../../Constants/choices";
import { ArrayTextField } from "../../Components/ArrayTextField";
import { withStyles } from "@material-ui/styles";
import EmojiBody from "./Components/EmojiNotifBody";
import EmojiTitle from "./Components/EmojiNotifTitle";


function ShowActions({ basePath, data, resource, noDel = false }) {
  const redirect = useRedirect();
  const translate = useTranslate();
  const refresh = useRefresh();
  const notify = useNotify();
  const _handleSubmit = () => {
    axiosFetcher
      .post(`${SERVER}/notification/send/${data.id}`)
      .then(() => {
        notify("اعلان ارسال شد.", "info");
        refresh();
      })
      .catch((e) => {
        notify(`ارسال اعلان با خطا مواجه شد. ${e.message}`, "error");
      });
  };
  return (
    <TopToolbar>
      <SendNotification handleSubmit={_handleSubmit} />
      <Button
        color="primary"
        onClick={() => redirect(`${basePath}`, basePath)}
        startIcon={<BackToListIcon />}
      >
        {translate(`ra.action.back_to_list`)}
      </Button>
      <EditButton basePath={basePath} record={data} />
      {data?.isDeleted ? (
        <DeleteButton
          label={"ra.action.restore"}
          basePath={basePath}
          record={data}
          resource={resource}
          style={{ color: "orange" }}
          mutationMode="pessimistic"
        />
      ) : noDel ? null : (
        <DeleteButton basePath={basePath} record={data} resource={resource}   mutationMode="pessimistic" />
      )}
    </TopToolbar>
  );
}
const SendNotification = (props) => {
  const { handleSubmit, loading } = props;
  const [open, setOpen] = useState(false);
  const handleConfirm = () => {
    setOpen(false);
    handleSubmit();
  };
  return (
    <>
      <Button style={{ color: "#FF8000" }} onClick={() => setOpen(true)} disabled={loading} startIcon={<NotificationsNoneIcon />}>ارسال اعلان</Button>
      <Confirm
        isOpen={open}
        loading={loading}
        title="ارسال اعلان"
        content="آیا از ارسال این اعلان مطمئن هستید؟"
        onConfirm={handleConfirm}
        onClose={() => setOpen(false)}
        onCancel={() => setOpen(false)}
      />
    </>
  );
};
const NotificationDataView = (props) => {
  const translate = useTranslate();
  const { record, resource } = props;
  return (
    <Box style={{ backgroundColor: "#eee" }} p={3}>
      <Typography align={"center"} gutterBottom variant={"h6"}>جزییات</Typography>
      <Grid container spacing={4}>
        <Grid item>
          <Labeled
            label={translate(`resources.${resource}.fields.data.campaign`)}
          >
            <NumberField source="data.campaign" record={record} />
          </Labeled>
        </Grid>
        <Grid item>
          <Labeled
            label={translate(`resources.${resource}.fields.data.minAge`)}
          >
            <NumberField source="data.minAge" record={record} />
          </Labeled>
        </Grid>
            <Grid item>
          <Labeled
            label={translate(`resources.${resource}.fields.data.maxAge`)}
          >
            <NumberField source="data.maxAge" record={record} />
          </Labeled>
        </Grid>
        <Grid item>
          <Labeled label={translate(`resources.${resource}.fields.data.sex`)}>
            <SelectField
              source="data.sex"
              record={record}
              choices={Notification_Data_Sex_Enums}
            />
          </Labeled>
        </Grid>
        <Grid item>
          <Labeled label={translate(`resources.${resource}.fields.data.goal`)}>
            <SelectField
              source="data.goal"
              record={record}
              choices={Notification_Data_Goals_Enums}
            />
          </Labeled>
        </Grid>
        <Grid item>
          <Labeled
            label={translate(
              `resources.${resource}.fields.data.lastAppOpenedDatePerHour`
            )}
          >
            <TextField source="data.lastAppOpenedDatePerHour" record={record} />
          </Labeled>
        </Grid>
        <Grid item>
          <Labeled
            label={translate(
              `resources.${resource}.fields.data.maxAppOpenedCount`
            )}
          >
            <TextField source="data.maxAppOpenedCount" record={record} />
          </Labeled>
        </Grid>
        <Grid item>
          <Labeled
            label={translate(`resources.${resource}.fields.data.subscription`)}
          >
            <SelectField
              source="data.subscription"
              record={record}
              choices={Notification_Data_Subscription_Enums}
            />
          </Labeled>
        </Grid>
        <Grid item>
          <Labeled label={"فیلتر کاربران روی چالش خاص "}>
            <ReferenceField source="data.challengeId" reference="challenge">
            <TextField source="name" />
            </ReferenceField>
          </Labeled>
        </Grid>
        <Grid item>
          <Labeled
            label={translate(`resources.${resource}.fields.data.markets`)}
          >
            <ArrayTextField
              resource={props.resource}
              source="data.markets"
              record={record}
              label={translate(`resources.${resource}.fields.data.markets`)}
              validate={required()}
            />
          </Labeled>
        </Grid>
      </Grid>
    </Box>
  );
};
const NotificationDataEdit = (props) => {
  const translate = useTranslate();
  const { record, resource} = props;
  return (
    <Box style={{ backgroundColor: "#eee"  }} p={2}>
      <Typography align={"center"} gutterBottom variant={"h6"}>
        جزییات
      </Typography>
      <Grid container spacing={3}>
        <Grid item>
          <TextInput
            source="data.campaign"
            record={record}
            label={translate(`resources.${resource}.fields.data.campaign`)}
          />
        </Grid>
        <Grid item>
          <NumberInput
            source="data.minAge"
            record={record}
            label={translate(`resources.${resource}.fields.data.minAge`)}
            defaultValue={0}
          />
        </Grid>
        <Grid item>
          <NumberInput
            source="data.maxAge"
            record={record}
            label={translate(`resources.${resource}.fields.data.maxAge`)}
            defaultValue={0}
          />
        </Grid>
        <Grid item>
          <SelectInput
            source="data.sex"
            record={record}
            choices={Notification_Data_Sex_Enums}
            label={translate(`resources.${resource}.fields.data.sex`)}
            defaultValue={"ALL"}
          />
        </Grid>
        <Grid item>
          <SelectInput
            source="data.goal"
            record={record}
            choices={Notification_Data_Goals_Enums}
            label={translate(`resources.${resource}.fields.data.goal`)}
          />
        </Grid>
        <Grid item>
          <TextInput
            source="data.lastAppOpenedDatePerHour"
            record={record}
            label={translate(
              `resources.${resource}.fields.data.lastAppOpenedDatePerHour`
            )}
          />
        </Grid>
        <Grid item>
          <TextInput
            source="data.maxAppOpenedCount"
            record={record}
            label={translate(
              `resources.${resource}.fields.data.maxAppOpenedCount`
            )}
          />
        </Grid>
        <Grid item>
          <SelectInput
            source="data.subscription"
            record={record}
            choices={Notification_Data_Subscription_Enums}
            label={translate(`resources.${resource}.fields.data.subscription`)}
          />
        </Grid>
        <Grid item>
          <ReferenceInput
            label="فیلتر کاربران روی چالش خاص"
            source="data.challengeId"
            reference="challenge"
          >
            <SelectInput />
          </ReferenceInput>
        </Grid>
        <Grid item>
          <SelectArrayInput
            resource={props.resource}
            source="data.markets"
            record={record}
            choices={MARKETS_CHOICES_NOTIFICATION}
            label={translate(`resources.${resource}.fields.data.markets`)}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
const NotificationDataCreate = (props) => {
  const translate = useTranslate();
  const { record, resource } = props;
  return (
    <Box style={{ backgroundColor: "#eee"}} id="data"  p={2}>
      <Typography align={"center"} gutterBottom variant={"h6"}>
        جزییات
      </Typography>
      <Grid container spacing={3}>
        <Grid item>
          <TextInput
            source="data.campaign"
            record={record}
            label={translate(`resources.${resource}.fields.data.campaign`)}
            // validate={required()}
          />
        </Grid>
        <Grid item>
          <NumberInput
            source="data.minAge"
            record={record}
            label={translate(`resources.${resource}.fields.data.minAge`)}
            defaultValue={0}
         
          />
        </Grid>
        <Grid item>
          <NumberInput
            source="data.maxAge"
            record={record}
            label={translate(`resources.${resource}.fields.data.maxAge`)}
            defaultValue={0}
         
          />
        </Grid>
        <Grid item>
          <SelectInput
            source="data.sex"
            record={record}
            choices={Notification_Data_Sex_Enums}
            label={translate(`resources.${resource}.fields.data.sex`)}
            defaultValue={"ALL"}
            
       
          />
        </Grid>
        <Grid item>
          <SelectInput
            source="data.goal"
            record={record}
            choices={Notification_Data_Goals_Enums}
            label={translate(`resources.${resource}.fields.data.goal`)}
            defaultValue={"ALL"}
      
          />
        </Grid>
        <Grid item>
          <Tooltip title="بر حسب ساعت" aria-label="add">
            <TextInput defaultValue={0}
              source="data.lastAppOpenedDatePerHour"
              record={record}
              label={translate(
                `resources.${resource}.fields.data.lastAppOpenedDatePerHour`
              )}
   
            />
          </Tooltip>
        </Grid>
        <Grid item>
          <TextInput
            source="data.maxAppOpenedCount"
            record={record}
            defaultValue={0}
            label={translate(
              `resources.${resource}.fields.data.maxAppOpenedCount`
            )}
      
          />
        </Grid>
        <Grid item>
          <SelectInput
            source="data.subscription"
            record={record}
            choices={Notification_Data_Subscription_Enums}
            label={translate(`resources.${resource}.fields.data.subscription`)}
            defaultValue={"ALL"}
       
          />
        </Grid>
        <Grid item>
          <SelectArrayInput
            resource={props.resource}
            source="data.markets"
            record={record}
            choices={MARKETS_CHOICES_NOTIFICATION}
            optionText={"name"}
            optionValue={"id"}
            // label={translate(`resources.${resource}.fields.data.markets`)}
          />
        </Grid>
        <Grid item>
          <ReferenceInput
            label="فیلتر کاربران روی چالش خاص"
            source="data.challengeId"
            reference="challenge"
          >
            <SelectInput />
          </ReferenceInput>
        </Grid>
      </Grid>
    </Box>
  );
};
const styles = {
  image: {
    width: "auto",
  }
};
export const NotificationList = withStyles(styles)(({ classes, ...props }) => (
  <List {...props} sort={{ field: "id", order: "DESC" }}>
    <Datagrid rowClick="show">
      <TextField source="title" />
      <TextField source="body" />
      <TextField source="url" />
      <NumberField source="minAndroidVersionCode" />
      <TextField source="successSentCount" />
      <TextField source="failedSentCount" />
      <TextField source="clickRateCount" />
      <SelectField source="page" choices={PAGE_NAMES_ENUM} />
      <ImageField source="imageUrl"   className={classes.image} />
      <TextField source="id" />
    </Datagrid>
  </List>
));

export const NotificationEdit = (props) => {
  const[byNumber,setBynumber]=useState(false)
  axiosFetcher
      .get(`${SERVER}/notification/${props.id}`)
      .then((res) => {
        if( res.data.phoneNumbersCount > 0) {
          setBynumber(true)
        }
      })
      .catch(() => {});
  return (
    <EditView {...props}>
      {byNumber===true ?<><Typography style={{ width: "100%" }} variant="body1" color="error">ارسال اعلان با شماره تلفن</Typography> <br/></> : null}
      <EmojiTitle/>
      <EmojiBody/>
      <br/>
      {byNumber===false ? <Typography style={{ width: "100%" }} variant="body1" color="error">اگر از نشانی اینترنتی استفاده می‌کنید، حداقل نسخه را روی 5141 قرار دهید</Typography> : null}
      {byNumber===false ? <TextInput source="url" /> :null}
      {byNumber===false ?  <SelectInput source="page" choices={PAGE_NAMES_ENUM} /> :null}

      {byNumber===false ?
        <ReferenceInput label=" ورود به چالش خاص" source="navigateToChallengeId" reference="challenge">
          <SelectInput />
        </ReferenceInput>: null
      }
      {byNumber===false  ?
          <NumberInput source="minAndroidVersionCode"  />
          :
          <FileInput source="file" label="فایل شماره تلفن" placeholder="فایل csv  را برای آپلود دراپ کنید، یا برای انتخاب آن کلیک کنید" accept=".csv">
            <FileField source="src" title="title"/>
          </FileInput>
      }
      <ImageInput source="imageUrl" label="تصویر مربوطه" accept="image/*">
        <ImageField source="src" />
      </ImageInput>
      <ImageField source="imageUrl" />
      <TextInput source="testPhoneNumber" />
      {byNumber===false ? <NotificationDataEdit {...props} source="data" />: null }
    </EditView>
  );
};
export const NotificationCreate = (props) =>{
  const [notifNumber,setNotifNumber]=useState(false)
  const [notifNumberText,setNotifNumberText]=useState("ارسال اعلان با شماره تلفن")
  console.log(notifNumber)
  const NotifByNumber = () => {
    function changeToNumber() {
      if(notifNumber=== false){
        setNotifNumber(true)
        setNotifNumberText("ارسال اعلان با فیلتر")
      }
      else {
        setNotifNumber(false)
        setNotifNumberText("ارسال اعلان با شماره تلفن")
      }
    }
    return (
        <Button style={{ marginBottom:"15px" }} variant="contained" color="primary" onClick={() => changeToNumber()}>{notifNumberText}</Button>
    );
  };
  return(
      <CreateView {...props}>
        <NotifByNumber   />
        <EmojiTitle/>
        <EmojiBody/>
        {notifNumber===false ?  <Typography style={{ width: "100%" }} variant="body1" color="error">اگر از نشانی اینترنتی استفاده می‌کنید، حداقل نسخه را روی 5141 قرار دهید</Typography> : null}
        {notifNumber===false ? <TextInput source="url" /> : null}
        <SelectInput source="page" choices={PAGE_NAMES_ENUM}/>
        {notifNumber===false ?
            <ReferenceInput label=" ورود به چالش خاص" source="navigateToChallengeId" reference="challenge">
              <SelectInput />
            </ReferenceInput>
            : null}
        {notifNumber===false ? <NumberInput source="minAndroidVersionCode" /> : null}
        {notifNumber===true ?
            <FileInput source="file" label="فایل شماره تلفن" placeholder="فایل csv  را برای آپلود دراپ کنید، یا برای انتخاب آن کلیک کنید" accept=".csv">
              <FileField source="src" title="title"/>
            </FileInput>
            : null}
        <ImageInput source="imageUrl" label="تصویر مربوطه"  accept="image/*">
          <ImageField source="src" />
        </ImageInput>
        <ImageField source="imageUrl" />
        <NumberInput source="testPhoneNumber" />
        {notifNumber===false ? <NotificationDataCreate {...props} source="data" /> : null}
      </CreateView>
  );
}

const RefreshBtn = (props) => {
  const { handleUserCount } = props;
  const loading = useLoading();
  return (
    <Button variant="contained" color="primary" onClick={() => handleUserCount(props.record)} disabled={loading}>
      بروزرسانی تعداد کاربران دریافت ‌کننده اعلان
    </Button>
  );
};

export const NotificationShow = (props) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const handleUserCount = (record) => {
    dataProvider
      .getOne("notification", { record, reqType: "refreshUserToken" })
      .then(({ data }) => {
        return notify(
          `تعداد کاربران بروز شد: ${data.currentUserCount}`,
          "info"
        );
      })
      .catch((er) => {
        return notify(er.message, "error");
      });
  };
  return (
    <Show {...props} actions={<ShowActions />}>
      <SimpleShowLayout>
        <TextField source="title" />
        <TextField source="body" />
        <NumberField source="minAndroidVersionCode" />
        <SelectField source="page" choices={PAGE_NAMES_ENUM} />
        <ReferenceField source="navigateToChallengeId" reference="challenge">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="url" />
        <TextField source="successSentCount" />
        <TextField source="failedSentCount" />
        <TextField source="clickRateCount" />
        <TextField source="clickedCount" />
        <TextField source="testPhoneNumber" />
        <NumberField source="currentUsersCount"> </NumberField>
        <RefreshBtn handleUserCount={handleUserCount} />
        <TextField source="lastSentUsersCount" />
        <DateField showTime locales="fa-IR" source="lastSentUsersDate" />
        <NumberField source="clickedCount" />
        <TextField source="id" />
        <ImageField source="imageUrl"/>
        <NotificationDataView {...props} source="data" />
      </SimpleShowLayout>
    </Show>
  );
};
