import {User} from "./User";
import {UserEdit} from "./UserEdit";
import PersonAddIcon from "@material-ui/icons/PersonAdd";

export const user = {
  name: "user",
  list: User,
  edit: UserEdit,
  icon: PersonAddIcon,
  options: { cat: "users" },
};

export const userNotes = {
  name: "note",
};
