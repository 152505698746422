import FastfoodIcon from "@material-ui/icons/Fastfood";
import InvalidFoodsList, {InvalidFoodsFactList} from "./InvalidFoods";

export const invalidFood = {
  name: "getInvalidFoodUnits",
  list: InvalidFoodsList,
  icon: FastfoodIcon,
  options: { cat: "food" },
};

export const invalidFoodFacts = {
  name: "getInvalidFoodFacts",
  list: InvalidFoodsFactList,
  icon: FastfoodIcon,
  options: { cat: "food" },
};
