import React from "react";
import {create} from "jss";
import {jssPreset, StylesProvider} from "@material-ui/styles";
import rtl from "jss-rtl";

// Configure JSS
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

export function RTL(props) {
  return <StylesProvider jss={jss}>{props.children}</StylesProvider>;
}
