import React from "react";
import {Grid} from "@material-ui/core";
import {Title} from "react-admin";
import Tile from "../../Components/Tile";

const tiles = [
  {
    title: "اطلاعات غذاها",
    desc: "",
    link: "foodFact",
    image: require("../../assets/food.jpg"),
  },
  {
    title: "دستورهای غذا",
    desc: "",
    link: "foodRecipe",
    image: require("../../assets/rep.jpg"),
  },
  {
    title: "اطلاعات تمرین‌ها",
    desc: "",
    link: "exerciseFact",
    image: require("../../assets/ex.jpg"),
  },
  {
    title: "چالش‌ها",
    desc: "",
    link: "challenge",
    image: require("../../assets/mar.jpg"),
  },
  {
    title: "اطلاعات کاربران",
    desc: "",
    link: "user",
    image: require("../../assets/users.jpg"),
  },
  {
    title: "خروج",
    desc: "",
    link: "exit",
    image: require("../../assets/exit.jpg"),
  },
];
export default () => (
  <div style={{ marginTop: "1em" }}>
    <Title title="ادمین پنل کرفس" />
    <Grid container spacing={3}>
      {tiles.map((e, i) => (
        <Grid key={i} item xs={12} sm={4} lg={3} xl={"auto"}>
          <Tile {...e} />
        </Grid>
      ))}
    </Grid>
  </div>
);
