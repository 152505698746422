import React, {useState} from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader, Typography,
} from "@material-ui/core";
import {useNotify, downloadCSV, Loading} from "react-admin";
import {SERVER_DOMAIN} from "../../Constants";
import jsonExport from "jsonexport/dist";
import { axiosFetcher } from "../../utils/axiosFetcher";
import TextField from '@material-ui/core/TextField';

export function RandomFood() {
  const notify = useNotify()
  const [loading, setLoading] = useState(false);
  const [number,setNumber]= useState()
  function ChangeNumber(e) {
    setNumber(e.target.value)
  }
  function submitClick(){
    setLoading(true);
    axiosFetcher
    .get(`${SERVER_DOMAIN}/admin/randomFoodCategory/${number}`)
      .then((res) => {
        setLoading(false);
      notify("انجام شد")
      jsonExport(res.data, (err, csv) => {
        downloadCSV(csv, `FixDuplicate${Date.now()}`);
      });
      })
      .catch(() => {
        setLoading(false);
        notify("به مشکلی برخوردیم")
      });
  }
  return (
    <>
      <Card style={{marginTop:"2rem"}}>
        <CardHeader title="انتخاب تصادفی غذا با دسته بندی مشخص"/>
        <Typography style={{marginRight:"1.2rem"}}>تعداد غذای تصادفی مورد نظر را در ورودی زیر وارد کنید </Typography>
        <CardContent>
          <TextField
              onChange={ChangeNumber}
              label="تعداد"
              type="number"
              variant="filled"
          />
        <form >
        <Button type="submit" variant="contained"  color="primary" onClick={submitClick}  style={{marginTop:"1rem"}}>اعمال </Button>
        </form>  
        </CardContent>
        {loading===true ?<Loading/> : null}
      </Card>   
    </>
  );
}

