import SportsIcon from "@material-ui/icons/Sports";
import {SibAppList} from "./SibApp";

export const SibApp = {
  name: "sibApp",
  list: SibAppList,
  edit: null,
  show: null,
  create: null,
  icon: SportsIcon,
  options: { cat: "misc" },
};
