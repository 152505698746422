import ContactSupportIcon from '@material-ui/icons/ContactSupport';

import {
  CountryCodeCreate,
  CountryCodeEdit,
  CountryCodeList,
  CountryCodeShow,
} from "./CountryCode";

export const CountryCodes = {
  name: "CountryCode",
  list:  CountryCodeList,
  edit:  CountryCodeEdit,
  create:CountryCodeCreate,
  show:  CountryCodeShow,
  icon:   ContactSupportIcon,
  options: { cat: "misc" },
};