import React from "react";
import { FileInput, FileField} from "react-admin";
import {
  Create,
  SimpleForm,
  downloadCSV,
} from "react-admin";
import jsonExport from "jsonexport/dist";
import { CardHeader, Paper, Typography} from "@material-ui/core";
import {MaterialDateInput} from "../../Components/RaMaterialDatePickers";

export function reportOldUser(props) {
  const onSuccess = ({data}) => {
    console.log(data);
    jsonExport(data.list, (err, csv) => {
      downloadCSV(csv, `OldUserReport${Date.now()}`);
    });
  };
  const transform = (data) => {
    let from = new Date(Date.parse(data.from)).setHours(0, 0, 0).toString();
    let to = new Date(Date.parse(data.to)).setHours(23, 59, 0).toString();
    return {...data, from, to};
  };
  return (
    <Paper>
      <Create {...props} transform={transform} onSuccess={onSuccess}>
        <SimpleForm {...props} >
          <CardHeader title="گزارش کاربران قدیمی"/>
          <Typography style={{width:"100%"}}>
            گزارش میزان فروش، ثبت نام در بازه زمانی. فایل ارسالی میبایست شامل شماره‌های تلفن همراه کاربران در هر خط باشد
          </Typography>
          <MaterialDateInput source={"from"}/>
          <MaterialDateInput source={"to"}/>
          <FileInput source="files" label="فایل مربوطه (.csv)" accept=".csv">
            <FileField source="src" title="title"/>
          </FileInput>
          <Typography>
            فایل خروجی csv به صورت خودکار دانلود خواهد شد.
          </Typography>
          <span style={{margin: "0 1em"}}/>
        </SimpleForm>
      </Create>
    </Paper>
  );
}


