import Button from "@material-ui/core/Button";
import {withStyles} from "@material-ui/core/styles";

export const WarningButton = withStyles({
  root: {
    background: "orangered",
  },
  label: {
    textTransform: "capitalize",
    color: "white",
    "&:hover": {
      color: "black",
    },
  },
})(Button);
export const FailureButton = withStyles({
  root: {
    background: "blue",
  },
  label: {
    textTransform: "capitalize",
    color: "white",
    "&:hover": {
      color: "black",
    },
  },
})(Button);
