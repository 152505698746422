import React from "react";
import {
  Datagrid,
  List,
  SimpleShowLayout,
  TextField,
  TextInput,
} from "react-admin";
import ShowView from "../../Components/ShowView";
import EditView from "../../Components/EditView";
import CreateView from "../../Components/CreateView";

export const FaqTagList = (props) => (
  <List {...props}>
    <Datagrid rowClick="show">
      <TextField source="name" />
      <TextField source="id" />
    </Datagrid>
  </List>
);

export const FaqTagShow = (props) => (
  <ShowView {...props}>
    <SimpleShowLayout>
    <TextField source="name" />
    <TextField source="id" />
    </SimpleShowLayout>
  </ShowView>
);

export const FaqTagEdit = (props) => (
  <EditView {...props}>
    <TextInput source="name" />   
  </EditView>
);

export const FaqTagCreate = (props) => (
  <CreateView {...props}>
    <TextInput source="name"  />
  </CreateView>
);
