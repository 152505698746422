import React from 'react';
import {Create, BooleanInput, TextInput, SimpleForm, required} from 'react-admin';
import CreateView from "../../Components/CreateView";

const TrackerCreate = (props) => {
    return (
        <CreateView {...props}>
            {/*<SimpleForm>*/}
                <TextInput source={"trackerLink"} validate={required()}/>
                <TextInput source={"baseURL"} validate={required()}/>
                <TextInput source={"identifier"} validate={required()}/>
                <TextInput source={"description"} multiline fullWidth validate={required()}/>
            {/*</SimpleForm>*/}
        </CreateView>
    );
};

export default TrackerCreate;