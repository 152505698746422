import React, {useEffect, useState} from 'react';
import {useNotify} from "react-admin";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@material-ui/core";
import {httpClient} from "../../utils/request";
import {SERVER_DOMAIN} from "../../Constants";
import get from "lodash/get";

const DietEdit = ({dietId}) => {

    const [isOpen, setOpen] = useState(false);

    const onOpen = () => {
        setOpen(true);
    }

    const onClose = () => {
        setOpen(false);
    }

    return (
        <>
            <Button onClick={onOpen}>ویرایش رژیم</Button>
            <Dialog open={isOpen} onClose={onClose}>
                <DietEditForm dietId={dietId} onClose={onClose}/>
            </Dialog>
        </>
    );
};

const DietEditForm = ({dietId, onClose}) => {

    const [diet, setDiet] = useState(null);
    const notify = useNotify();
    const onEditDiet = () => {
        const body = {
            days:diet.days,
        };
        // console.log("_________________", JSON.stringify(body))
        httpClient(`${SERVER_DOMAIN}/api/clinic/diet/${dietId}`,{
            method:'put',
            body:JSON.stringify(body)
        }).then(() => notify("رژیم ویرایش شد.", "success"))
    }

    useEffect(() => {
        httpClient(`${SERVER_DOMAIN}/api/clinic/diet/${dietId}`).then(res => {setDiet(res.json.data)});
    }, []);

    const onChange = (value,dayIndex,mealIndex,foodIndex) => {
        setDiet(diet => ({
            ...diet,
            days:diet.days.map((day,index) => index!==dayIndex ? day : {
                ...day,
                meals:day.meals.map((meal,index) => index!==mealIndex ? meal : {
                    ...meal,
                    foods:meal.foods.map((food,index) => index!==foodIndex ? food : {
                        ...food,
                        food:value
                    })
                })
            })
        }))
    }


    return (
        <>
            <DialogTitle>ویرایش رژیم</DialogTitle>
            <DialogContent>
                {diet?.days.map((day,dayIndex) => {
                    return (
                        <div>
                        <div><p style={{fontSize:"18px", fontFamily:"IRANSansWeb"}}>روز {day.day}</p></div>
                            {day.meals.map((meal,mealIndex) => {

                                return (
                                    <div><div><p style={{fontSize:"14px", fontFamily:"IRANSansWeb"}}>{meal.meal_text}</p></div>
                                        {meal.foods.map((food,foodIndex) => {
                                            const name = `days[${dayIndex}].meals[${mealIndex}].foods[${foodIndex}].food`
                                            return (
                                                <Input value={get(diet,name)} onChange={(e) => onChange(e.target.value,dayIndex,mealIndex,foodIndex)}/>
                                            )
                                        })}
                                    </div>
                                )
                            })}
                        </div>
                    )
                })}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onEditDiet()}>ویرایش رژیم</Button>
                <Button onClick={onClose} autoFocus>
                    انصراف
                </Button>
            </DialogActions>
        </>
    )
}

const Input = React.memo(({value, onChange}) => {
    return <TextField fullWidth value={value} onChange={onChange}/>
},(prevProps, nextProps) => prevProps.value === nextProps.value)

export default DietEdit;