// NOTE: delete btn works

import React from "react";
import {
    BooleanField,
    Datagrid,
    DateField,
    List,
    SimpleShowLayout,
    TextField,
    TextInput,
    required
} from "react-admin";
import EditView from "../../Components/EditView";
import CreateView from "../../Components/CreateView";
import ShowView from "../../Components/ShowView";

export const ExerciseCategoryList = (props) => (
  <List {...props} perPage={15}>
    <Datagrid rowClick="show">
      <TextField source="name" />
      <DateField locales="fa-IR" source="updatedAt" />
      <BooleanField source="isDeleted"   />
    </Datagrid>
  </List>
);

export const ExerciseCategoryEdit = (props) => (
  <EditView {...props}>
    <DateField locales="fa-IR" source="updatedAt" />
    <TextInput source="name" validate={required()} />
  </EditView>
);

export const ExerciseCategoryCreate = (props) => (
  <CreateView {...props}>
    <TextInput source="name" validate={required()} />
  </CreateView>
);

export const ExerciseCategoryShow = (props) => (
  <ShowView {...props}>
    <SimpleShowLayout>
      <BooleanField source="isDeleted" />
      <DateField locales="fa-IR" source="updatedAt" />
      <TextField source="name" />
      <TextField source="id" />
    </SimpleShowLayout>
  </ShowView>
);
