import React from 'react';
import {List, Datagrid, TextField, BooleanField,DateField} from 'react-admin';
import TrackerUrlField from "./TrackerUrlField";

const TrackerList = (props) => {
    return (
        <List {...props}>
            <Datagrid rowClick={"show"}>
                <TextField source={"trackerLink"}/>
                <TextField source={"baseURL"}/>
                <TextField source={"identifier"}/>
                <TrackerUrlField source={"url"}/>
                <TextField source={"description"} fullWidth multiline/>
                <DateField source={"createdAt"} locales={"fa-IR"}/>
                <DateField source={"updatedAt"} locales={"fa-IR"}/>
                <BooleanField source={"deleted"}/>
            </Datagrid>
        </List>
    );
};

export default TrackerList;