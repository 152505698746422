import React from "react";
import {
  Datagrid,
  DateField,
  List,
  NumberField,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  required,
  SelectInput,
  SimpleShowLayout,
  TextField,
  TextInput,

} from "react-admin";
import ShowView from "../../Components/ShowView";
import EditView from "../../Components/EditView";
import CreateView from "../../Components/CreateView";

export const FaqSubcategoryList = (props) => (
  <List {...props}>
    <Datagrid rowClick="show">
      <ReferenceField source="categoryId" reference="faqCategory" link="show">
        <TextField source="title" />
      </ReferenceField>
      <TextField source="title" />
      <NumberField source="order" />
      <DateField source="updatedAt" locales={"fa-IR"} />
      <TextField source="id" />
    </Datagrid>
  </List>
);

export const FaqSubcategoryShow = (props) => (
  <ShowView {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <ReferenceField source="categoryId" reference="faqCategory" link="show">
        <TextField source="title" />
      </ReferenceField>
      <TextField source="title" />
      <NumberField source="order" />
      <DateField source="updatedAt" />
    </SimpleShowLayout>
  </ShowView>
);

export const FaqSubcategoryEdit = (props) => (
  <EditView {...props}>
    <TextField source="id" />
    <TextInput source="title" validate={required()} />
    <ReferenceInput source="categoryId" reference="faqCategory" validate={required()}>
      <SelectInput optionText="title" />
    </ReferenceInput>
    <NumberInput source="order"  validate={required()}/>
  </EditView>
);

export const FaqSubcategoryCreate = (props) => (
  <CreateView {...props}>
    <TextInput source="title" validate={required()} />
    <ReferenceInput source="categoryId" reference="faqCategory" validate={required()}>
      <SelectInput optionText="title" />
    </ReferenceInput>
    <NumberInput source="order" validate={required()} />
  </CreateView>
);
