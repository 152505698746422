import {
  GetToKnowCreate,
  GetToKnowEdit,
  GetToKnowList,
  GetToKnowShow,
} from "./GetToKnow";
import FastfoodIcon from "@material-ui/icons/Fastfood";

export const getToKnow = {
  name: "getToKnow",
  list: GetToKnowList,
  edit:  GetToKnowEdit,
  show: GetToKnowShow,
  create: GetToKnowCreate,
  icon: FastfoodIcon,
  options: { cat: "marketing" },
};
