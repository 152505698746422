import React from "react";
import {
  ChipField,
  Datagrid,
  DeleteButton,
  List,
  ListButton,
  number,
  NumberField,
  NumberInput,
  ReferenceArrayField,
  required,
  SaveButton,
  ShowButton,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
  TextInput,
  Toolbar,
  TopToolbar,
} from "react-admin";
import EditView from "../../Components/EditView";
import CreateView from "../../Components/CreateView";
import ShowView from "../../Components/ShowView";

export const PackageList = (props) => (
  <List {...props}>
    <Datagrid rowClick="show">
      <TextField source="name" />
      <NumberField source="subscriptionAmount" />
      <ReferenceArrayField source="pricingOptions" reference="packagePricing">
        <SingleFieldList>
          <ChipField source="title" />
        </SingleFieldList>
      </ReferenceArrayField>
      <TextField source="id" />
    </Datagrid>
  </List>
);

const PostEditActions = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
    <ShowButton basePath={basePath} record={data} />
  </TopToolbar>
);

const PostCreateToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton
      label="post.action.save_and_show"
      redirect="show"
      submitOnEnter={true}
    />
    <SaveButton
      label="post.action.save_and_add"
      redirect={false}
      submitOnEnter={false}
      variant="text"
    />
    <DeleteButton />
  </Toolbar>
);
export const PackageEdit = (props) => (
  <EditView {...props} actions={<PostEditActions />}>
    <TextInput source="name" validate={required()} />
    <NumberInput source="subscriptionAmount"  validate={required()}/>
    <ReferenceArrayField source="pricingOptions" reference="packagePricing">
      <SingleFieldList>
        <ChipField source="title" />
      </SingleFieldList>
    </ReferenceArrayField>
  </EditView>
);

export const PackageCreate = (props) => (
  <CreateView {...props}>
    <TextInput source="name" validate={required()} />
    <NumberInput source="subscriptionAmount" validate={[required(), number()]}/>
  </CreateView>
);

export const PackageShow = (props) => (
  <ShowView {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
      <NumberField source="subscriptionAmount" />
      <ReferenceArrayField source="pricingOptions" reference="packagePricing">
        <SingleFieldList>
          <ChipField source="eachMonth" />
        </SingleFieldList>
      </ReferenceArrayField>
    </SimpleShowLayout>
  </ShowView>
);
