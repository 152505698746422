import {BannerCreate, BannerEdit, BannerList, BannerShow} from "./Banner";
import PanoramaIcon from "@material-ui/icons/Panorama";
export const banner = {
  name: "banner",
  list: BannerList,
  edit: BannerEdit,
  show: BannerShow,
  create: BannerCreate,
  icon: PanoramaIcon,
  options: { cat: "marketing" },
};
