import React from "react";
import {
    Datagrid,
    DateField,
    List,
    NumberField,
    NumberInput,
    SimpleShowLayout,
    TextField,
    TextInput,
    required,
    Filter
} from "react-admin";
import ShowView from "../../Components/ShowView";
import EditView from "../../Components/EditView";
import CreateView from "../../Components/CreateView";
import { MaterialDateInput } from "../../Components/RaMaterialDatePickers";

const FaqCategoryFilter = (props) => {
  return (
    <Filter {...props}>
      <MaterialDateInput source={"updateAt"} />
    </Filter>
  );
};

export const FaqCategoryList = (props) => (
  <List
    {...props}
    sort={{ field: "order", order: "ASC" }}
    filters={<FaqCategoryFilter />}
  >
    <Datagrid rowClick="show">
      <TextField source="title" />
      <NumberField source="order" />
      <DateField source="updatedAt" locales={"fa-IR"} />
      <TextField source="id" />
    </Datagrid>
  </List>
);

export const FaqCategoryShow = (props) => (
  <ShowView {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="title" />
      <NumberField source="order" />
      <DateField source="updatedAt" />
    </SimpleShowLayout>
  </ShowView>
);

export const FaqCategoryEdit = (props) => (
  <EditView {...props}>
    <TextField source="id" />
    <TextInput source="title"  validate={required()} />
    <NumberInput source="order"   validate={required()}/>
  </EditView>
);

export const FaqCategoryCreate = (props) => (
  <CreateView {...props}>
    <TextInput source="title"  validate={required()}/>
    <NumberInput source="order"  validate={required()}/>
  </CreateView>
);
