import { useDataProvider, useMutation, useNotify } from "react-admin";
import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField as MuTextField,
  Typography,
} from "@material-ui/core";
import TransactionsDialog from "./TransactionDialog";
import { FailureButton, WarningButton } from "./StyledButtons";
import AddSubscriptionBtn, { ReduceSubscriptionBtn } from "../../../Components/Buttons";
import UserLog from "./UserLog";

export default function (props) {
  const dataProvider = useDataProvider();
  const { record } = props;
  const [data, setData] = useState("");
  const [addSub, setAddSub] = useState("");
  const [subscriptionType , setSubscriptionType] = useState("regular")
  console.log(subscriptionType);
  const notify = useNotify();
  const handleSubChange = (e) => {
    setAddSub(e.target.value);
  };
  const subscriptionTypeChange = (e) => {
    setSubscriptionType(e.target.value);
  
  };
  function _getsubscriptionData() {
    dataProvider
      .getOne("subscription", { id: record.user.objectId })
      .then(({ data }) => {
        setData(data);
        notify("اطلاعات مالی دریافت شد");
      })
      .catch((error) => {
        notify(`خطا در دریافت اطلاعات مالی: ${error.message}`, "warning");
      });
  }
  function addAppStoreSubscription() {
    dataProvider
      .getOne("revenuecat", { id: record.user.objectId })
      .then(({ data }) => {
        setData(data);
        notify("اطلاعات مالی دریافت شد");
      })
      .catch((error) => {
        notify(`خطا در دریافت اطلاعات مالی: ${error.message}`, "warning");
      });
  }
  useEffect(() => {
    record && _getsubscriptionData();
  }, [dataProvider, notify, record]);

  const [mutate, { loading }] = useMutation();
  const mutateSubscription = (type) =>
    mutate(
      {
        type: "create",
        resource: "subscription",
        payload: {
          id: record?.user.objectId,
          data: { amount: type === "reduction" ? `-${addSub}` : addSub , subscriptionType: subscriptionType},
        },
      },
      {
        onSuccess: () => {
          _getsubscriptionData();
          notify("عملیات موفق بود.", "success");
        },
        onFailure: (error) =>
          notify(`افزودن اشتراک با خطا مواجه شد: ${error.message}`, "warning"),
      }
    );

  const addRevenueSubscription = (type) =>
  mutate(
      {
        type: "create",
        resource: "revenuecat",
        payload: {
          id: record?.user.objectId,
          data: { amount: type === "reduction" ? `-${addSub}` : addSub },
        },
      },
      {
        onSuccess: () => {
          addAppStoreSubscription();
          notify("عملیات موفق بود.", "success");
        },
        onFailure: (error) =>
          notify(`افزودن اشتراک با خطا مواجه شد: ${error.message}`, "warning"),
      }
    );

  return (
    <>
      <Typography variant="body1" gutterBottom style={{ margin: "0.5em" }}>
        اطلاعات مالی کاربر
      </Typography>
      <MuTextField
        id="filled-basic"
        label="تاریخ انقضا اشتراک"
        value={
          data.expirationTime > 0
            ? new Date(data.expirationTime).toLocaleString("fa-IR")
            : 0
        }
        fullWidth
        disabled
      />
      <Typography variant="body1" gutterBottom style={{ margin: "0.5em" }}>
        نوع اشتراک  : {data.subscriptionType}
      </Typography>
      <MuTextField
        id="filled-basic"
        label="تاریخ انقضا اشتراک دلاری (رونیوکت)"
        value={
          data.revenueCatExpirationTime > 0
            ? new Date(data.revenueCatExpirationTime).toLocaleString("fa-IR")
            : 0
        }
        fullWidth
        disabled
      />
      <Typography variant="body1" gutterBottom style={{ margin: "0.5em" }}>
        نوع اشتراک دلاری : {data.revenueCatType}
      </Typography>
      <div style={{ margin: "5px 0" }}>
        <TransactionsDialog
          refreshSubDate={_getsubscriptionData}
          transactions={data.completedPaidTransactions}
          title={"تراکنش های پرداختی بانکی موفق:"}
          record={record}
        >
          <Button variant="contained" color="primary">
            تراکنش های پرداختی بانکی موفق:{"  "}
            {data.completedPaidTransactions &&
              data.completedPaidTransactions.length.toString()}
          </Button>
        </TransactionsDialog>
      </div>
      <div style={{ margin: "5px 0" }}>
        <TransactionsDialog
          refreshSubDate={_getsubscriptionData}
          transactions={data.nonPaidTransactions}
          title={"تراکنش های غیرپرداختی (غیربانکی) موفق:"}
          record={record}
        >
          <FailureButton variant="contained">
            تراکنش های غیرپرداختی (غیربانکی) موفق:{"  "}
            {data.nonPaidTransactions &&
              data.nonPaidTransactions.length.toString()}
          </FailureButton>
        </TransactionsDialog>
      </div>
      <div style={{ margin: "5px 0" }}>
        <TransactionsDialog
          refreshSubDate={_getsubscriptionData}
          transactions={data.withdrawnTransactions}
          title={"تراکنش‌های پرداختی ناموفق:"}
          record={record}
        >
          <WarningButton variant="contained">
            تراکنش‌های پرداختی ناموفق:{"  "}
            {data.withdrawnTransactions &&
              data.withdrawnTransactions.length.toString()}
          </WarningButton>
        </TransactionsDialog>
      </div>
      <FormControl fullWidth={true}>
        <InputLabel>کاهش/افزایش اشتراک</InputLabel>
        <Select value={addSub} onChange={handleSubChange}>
          <MenuItem value={"0month"}>صفر ماهه</MenuItem>
          <MenuItem value={"1month"}>یک ماهه</MenuItem>
          <MenuItem value={"3month"}>سه ماهه</MenuItem>
          <MenuItem value={"6month"}>شش ماهه</MenuItem>
          <MenuItem value={"12month"}>یک ساله</MenuItem>
          <MenuItem value={"24month"}>دو ساله</MenuItem>
          <MenuItem value={"100month"}>صد ساله</MenuItem>
        </Select>
      </FormControl>
      <FormControl fullWidth={true}>
      <InputLabel>نوع اشتراک</InputLabel>
        <Select value={subscriptionType} onChange={subscriptionTypeChange}>
          <MenuItem value={"regular"}>معمولی </MenuItem>
          <MenuItem value={"pro"}> ویژه </MenuItem>
        </Select>
        {record && (
          <>
            <AddSubscriptionBtn
              addSubscription={mutateSubscription}
              loading={loading}
            />
            <ReduceSubscriptionBtn
              handleSubmit={mutateSubscription}
              loading={loading}
            />
          </>
        )}
      </FormControl>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <FormControl fullWidth={true}>
        <InputLabel>کاهش/افزایش اشتراک اپ استور دلاری</InputLabel>
        <Select value={addSub} onChange={handleSubChange}>
          <MenuItem value={"daily"}>یک روز</MenuItem>
          <MenuItem value={"three_day"}>سه روز</MenuItem>
          <MenuItem value={"weekly"}>یک هفته</MenuItem>
          <MenuItem value={"monthly"}>یک ماهه</MenuItem>
          <MenuItem value={"two_month"}>دو ماهه</MenuItem>
          <MenuItem value={"three_month"}>سه ماهه</MenuItem>
          <MenuItem value={"six_month"}>شش ماهه</MenuItem>
          <MenuItem value={"yearly"}>یک ساله</MenuItem>
          <MenuItem value={"lifetime"}>لایف تایم</MenuItem>
        </Select>

        <AddSubscriptionBtn
            addSubscription={addRevenueSubscription}
            loading={loading}
        />

      </FormControl>

      <UserLog logs={data.logs} />
    </>
  );
}
