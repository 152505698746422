import ContactSupportIcon from '@material-ui/icons/ContactSupport';

import {
  FaqCategoryCreate,
  FaqCategoryEdit,
  FaqCategoryList,
  FaqCategoryShow,
} from "./FAQCategory";

import {
  FaqSubcategoryCreate,
  FaqSubcategoryEdit,
  FaqSubcategoryList,
  FaqSubcategoryShow,
} from "./FAQSubcategory";

import {
  FaqQuestionCreate,
  FaqQuestionEdit,
  FaqQuestionList,
  FaqQuestionShow,
} from "./FAQQuestion";

import {
  FaqQuestionHistoryCreate,
  FaqQuestionHistoryEdit,
  FaqQuestionHistoryList,
  FaqQuestionHistoryShow,
} from "./FAQquestionHistory";

import {
  FaqUserQuestionCreate,
  FaqUserQuestionEdit,
  FaqUserQuestionList,
  FaqUserQuestionShow,
} from "./FAQUserQusetion";


import {
  FaqTagCreate,
  FaqTagEdit,
  FaqTagList,
  FaqTagShow,
} from "./FAQTag";

export const faqCategory = {
  name: "faqCategory",
  list: FaqCategoryList,
  edit: FaqCategoryEdit,
  show: FaqCategoryShow,
  create: FaqCategoryCreate,
  icon: ContactSupportIcon,
  options: { cat: "FAQ" },
};

export const faqSubcategory = {
  name: "faqSubcategory",
  list: FaqSubcategoryList,
  edit: FaqSubcategoryEdit,
  show: FaqSubcategoryShow,
  create: FaqSubcategoryCreate,
  icon: ContactSupportIcon,
  options: { cat: "FAQ" },
};

export const faqQuestion = {
  name: "faqQuestion",
  list: FaqQuestionList,
  edit: FaqQuestionEdit,
  create: FaqQuestionCreate,
  show: FaqQuestionShow,
  icon: ContactSupportIcon,
  options: { cat: "FAQ" },
};
export const faqquestionHistory = {
  name: "faqQuestionHistory",
  list: FaqQuestionHistoryList,
  edit: FaqQuestionHistoryEdit,
  create: FaqQuestionHistoryCreate,
  show: FaqQuestionHistoryShow,
  icon: ContactSupportIcon,
  options: { cat: "FAQ" },
};

export const faqUserQuestion = {
  name: "faqUserQuestion",
  list:   FaqUserQuestionList,
  edit:   FaqUserQuestionEdit,
  create: FaqUserQuestionCreate,
  show:   FaqUserQuestionShow,
  icon:   ContactSupportIcon,
  options: { cat: "FAQ" },
};

export const faqTaq = {
  name: "faqTag",
  list:   FaqTagList,
  edit:   FaqTagEdit,
  create: FaqTagCreate,
  show:   FaqTagShow,
  icon:   ContactSupportIcon,
  options: { cat: "FAQ" },
};