import React from "react";
import {
    Datagrid,
    DateField,
    List,
    SimpleShowLayout,
    TextField,
    TextInput,
    ImageField,
    ImageInput,
    SelectInput,
    SelectField,
    BooleanInput,
    required,
    BooleanField,
    SelectArrayInput
} from "react-admin";
import {MaterialDateInput} from "../../Components/RaMaterialDatePickers";
import {PAGE_NAMES_ENUM, ACTION_NAMES_ENUM, MARKETS_CHOICES} from "../../Constants/choices";
import CreateView from "../../Components/CreateView";
import ShowView from "../../Components/ShowView";
import EditView from "../../Components/EditView";
import {ArrayTextField} from "../../Components/ArrayTextField";

export const CampaignList = (props) => (
    <List {...props}>
        <Datagrid rowClick="show">
            <TextField source="title"/>
            <TextField source="subtitle"/>
            <ArrayTextField source="markets"/>
            <SelectField source="page" choices={PAGE_NAMES_ENUM}/>
            <SelectField locales={"fa-IR"} source="action" choices={ACTION_NAMES_ENUM}/>
            <DateField locales={"fa-IR"} source="startDate"/>
            <DateField locales={"fa-IR"} source="expirationDate"/>
            <DateField locales={"fa-IR"} source="updatedAt"/>
            <TextField locales={"fa-IR"} source="url"/>
            <ImageField source="image"/>
            <BooleanField source="isActive"/>
        </Datagrid>
    </List>
);

export const CampaignEdit = (props) => (
    <EditView
        {...props}
        transform={(data) => {
            let s = new Date(data.startDate).getTime();
            let e = new Date(data.expirationDate).getTime();
            return {...data, startDate: s, expirationDate: e};
        }}>
        <BooleanInput defaultValue={true} source="isActive"/>
        <TextInput source="title"/>
        <TextInput source="subtitle"/>
        <SelectArrayInput source="markets" choices={MARKETS_CHOICES} validate={required()}/>
        <SelectInput source="page" choices={PAGE_NAMES_ENUM} validate={required()}/>
        <SelectInput source="action" choices={ACTION_NAMES_ENUM} validate={required()}/>
        <TextInput source="url" validate={validateCreate}/>
        <MaterialDateInput source="startDate" options={{format: "DD/MM/YYYY"}}/>
        <MaterialDateInput source="expirationDate" options={{format: "DD/MM/YYYY"}}/>
        <ImageInput source="image" label="تصویر مربوطه" validate={required()} accept="image/*">
            <ImageField source="src"/>
        </ImageInput>
        <ImageField source="image"/>
    </EditView>
);
export const CampaignCreate = (props) => (
    <CreateView
        {...props}
        transform={(data) => {
            let s = new Date(data.startDate).getTime();
            let e = new Date(data.expirationDate).getTime();
            return {...data, startDate: s, expirationDate: e};
        }}>
        <BooleanInput defaultValue={true} source="isActive"/>
        <TextInput source="title"/>
        <TextInput source="subtitle"/>
        <SelectArrayInput source="markets" choices={MARKETS_CHOICES} validate={required()}/>
        <SelectInput source="page" choices={PAGE_NAMES_ENUM} validate={required()}/>
        <SelectInput source="action" choices={ACTION_NAMES_ENUM} validate={required()}/>
        <TextInput source="url" validate={validateCreate}/>
        <MaterialDateInput source="startDate" options={{format: "DD/MM/YYYY"}}/>
        <MaterialDateInput source="expirationDate" options={{format: "DD/MM/YYYY"}}/>
        <ImageInput source="image" label="تصویر مربوطه" validate={required()} accept="image/*">
            <ImageField source="src"/>
        </ImageInput>
        <ImageField source="image"/>
    </CreateView>
);

function validateCreate(url, allValues) {
    if (allValues.action === "URL") {
        if (!url) {
            return "در حالت URL باید مقدار لینک پر شود";
        }
    }
    if (allValues.action === "PAGE") {
        if (url) {
            return "در حالت PAGE نباید لینک وارد شود";
        }
    }
    return undefined;
}

export const CampaignShow = (props) => (
    <ShowView {...props} >
        <SimpleShowLayout>
            <BooleanField source="isActive"/>
            <TextField source="title"/>
            <TextField source="subtitle"/>
            <ArrayTextField source="markets"/>
            <SelectField source="action" choices={ACTION_NAMES_ENUM}/>
            <TextField source="url"/>
            <SelectField source="page" choices={PAGE_NAMES_ENUM}/>
            <DateField locales={"fa-IR"} source="startDate"/>
            <DateField locales={"fa-IR"} source="expirationDate"/>
            <DateField locales={"fa-IR"} source="updatedAt"/>
            <ImageField source="image"/>
            <TextField source="objectId"/>
        </SimpleShowLayout>
    </ShowView>
);
