import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import {useLogout, useRedirect} from "react-admin";

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
});

export default function Tile({ title, desc, link, image }) {
  const redirect = useRedirect();
  const logout = useLogout();
  const classes = useStyles();
  const _handleClick = () => {
    if (link === "exit") {
      return logout(null, "login");
    } else {
      return redirect(link);
    }
  };
  return (
    <Card className={classes.root}>
      <CardActionArea>
        <CardMedia
          component="img"
          alt="Contemplative Reptile"
          height="auto"
          image={image}
          title={title}
          onClick={_handleClick}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {title}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
