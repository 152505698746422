import React from "react";
import {
    Datagrid,
    DateField,
    List,
    NumberField,
    NumberInput,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    SimpleShowLayout,
    TextField,
    TextInput,
    required,
    Button,
    ReferenceArrayInput,
    SelectArrayInput, Filter, BooleanInput,
} from "react-admin";
import EditView from "../../Components/EditView";
import CreateView from "../../Components/CreateView";
import ShowView from "../../Components/ShowView";
import GenericDialog from "./Components/GenericDialog";

const FaqQuestionFilter = (props) => (
  <Filter {...props}>
    <TextInput label="جست‌وجو" source="subject" alwaysOn />
    <ReferenceArrayInput label= " زیر دسته بندی"  source="subcategoryId" reference="faqSubcategory" allowEmpty>
      <SelectInput optionText="title" />
    </ReferenceArrayInput>
    <BooleanInput source="likeCount" />
  </Filter>
);

export const FaqQuestionList = (props) => (
  <List {...props} filters={<FaqQuestionFilter/>} >
    <Datagrid rowClick="show">
      <TextField source="subject" />
      <TextField source="body" />
      <ReferenceField source="subcategoryId" reference="faqSubcategory">
        <TextField source="title" />
      </ReferenceField>
     <NumberField source="views"/>
     <NumberField source="likeCount"/>
     <NumberField source="dislikeCount"/>
     <NumberField source="order"/>
     <DateField source="updatedAt" locales={"fa-IR"} />
      <TextField source="_id" />
    </Datagrid>
  </List>
);
export const FaqQuestionShow = (props) => {
  return (
    <ShowView {...props}>
      <SimpleShowLayout>
          <TextField source="subject" />
          <TextField source="body" />
          {/*<ReferenceArrayField label="تگ‌ها" reference="faqTag" source="tagsId">*/}
          {/*  <SingleFieldList>*/}
          {/*    <ChipField source="name" />*/}
          {/*  </SingleFieldList>*/}
          {/*</ReferenceArrayField>*/}
          <ReferenceField source="subcategoryId" reference="faqSubcategory">
              <TextField source="title" />
          </ReferenceField>

          <NumberField source="views"/>
          <NumberField source="likeCount"/>
          <NumberField source="dislikeCount"/>
          <NumberField source="order"/>
          <DateField source="updatedAt" locales={"fa-IR"} />

          <TextField source="_id" />

        <GenericDialog>
          <Button label="مشاهده‌ی تاریخچه‌ی سوال" />
        </GenericDialog>
      </SimpleShowLayout>
    </ShowView>
  );
};

export const FaqQuestionEdit = (props) => (
  <EditView {...props}>
    <ReferenceInput source="subcategoryId" reference="faqSubcategory">
      <SelectInput optionText="title" />
    </ReferenceInput>
    <NumberInput source="order" />
    <TextInput source="subject" />
    <ReferenceArrayInput source="tagsId" reference="faqTag">
      <SelectArrayInput optionText="name" />
    </ReferenceArrayInput>
    <TextInput source="body" />
  </EditView>
);

export const FaqQuestionCreate = (props) => (
  <CreateView {...props}>
    <ReferenceInput
      source="subcategoryId"
      reference="faqSubcategory"
      validate={required()}
    >
      <SelectInput optionText="title" />
    </ReferenceInput>
    <NumberInput source="order" validate={required()} />
    <TextInput source="subject" validate={required()} />
    <TextInput source="body" validate={required()} />
    <ReferenceInput source="tagsId" reference="faqTag">
      <SelectInput source="name" />
    </ReferenceInput>
  </CreateView>
);
