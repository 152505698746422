import {SERVER, SERVER_DOMAIN} from "../Constants";
import {httpClient} from "../utils/request";
import { stringify } from "query-string";
const apiUrl = SERVER;
const serverUrl = SERVER_DOMAIN;
export function getMany(resource, params){
  switch (resource) {
    case "campaign":
    case "getToKnow":
    case "faqQuestion":
    case "popUpData":
      const queryPopUpData = {
        filter: JSON.stringify({ id: params.id }),
      };
      const urlqueryPopUpData = `${apiUrl}/${resource}?${stringify(queryPopUpData)}`;
      return httpClient(urlqueryPopUpData).then(({ json }) => ({
        data: json.map((resource) => ({
            ...resource,
            id: resource._id,
        })),
      }));
      case "plan":
        
        const querya = {
          filter: JSON.stringify({ id: params.id}),
        };
        const urla = `${apiUrl}/shop/v2/${resource}?${stringify(querya)}`;
        return  httpClient(urla).then(({ json }) => ({ data: json }));
    case "disease":{
      const { field, order } = {field:"_id",order:"DESC"};
      const query = {
        sort: JSON.stringify([field, order]),
        pagination: JSON.stringify({perPage:1000,page:1}),
        filter: JSON.stringify({id:params.ids}),
      };
      const url = `${serverUrl}/api/food/disease?${stringify(query)}`;
      return httpClient(url).then(({ headers, json }) => {
        return {
          data:json.data.map(r => ({...r,id:r._id})),
          total: parseInt(headers.get("X-Total-Count").split("/").pop(), 10),
        }
      });
    }
    case "hatedfoood":{
      const { field, order } = {field:"_id",order:"DESC"};
      const query = {
        sort: JSON.stringify([field, order]),
        pagination: JSON.stringify({perPage:1000,page:1}),
        filter: JSON.stringify({id:params.ids}),
      };
      const url = `${serverUrl}/api/food/hatedfood?${stringify(query)}`;
      return httpClient(url).then(({ headers, json }) => {
        return {
          data:json.data.map(r => ({...r,id:r._id})),
          total: parseInt(headers.get("X-Total-Count").split("/").pop(), 10),
        }
      });
    }
    default:
   
      const query = {
        filter: JSON.stringify({ id: params.ids}),
      };
      const url = `${apiUrl}/${resource}?${stringify(query)}`;
      return  httpClient(url).then(({ json }) => ({ data: json }));
  }
};