import CategoryIcon from "@material-ui/icons/Bookmark";
import SportsIcon from "@material-ui/icons/Sports";
import {
  ChallengeCreate,
  ChallengeEdit,
  ChallengeList,
  ChallengeShow,
} from "./Challange";
import {
  ChallengecategoryCreate,
  ChallengecategoryEdit,
  ChallengeCategoryList,
  ChallengecategoryShow,
} from "./ChallengeCategory";

export const challenge = {
  name: "challenge",
  list: ChallengeList,
  edit: ChallengeEdit,
  show: ChallengeShow,
  create: ChallengeCreate,
  icon: SportsIcon,
  options: { cat: "marketing" },
};
export const challengeCategory = {
  name: "challengeCategory",
  list: ChallengeCategoryList,
  edit: ChallengecategoryEdit,
  show: ChallengecategoryShow,
  create: ChallengecategoryCreate,
  icon: CategoryIcon,
  options: { cat: "marketing" },
};
