import CategoryIcon from "@material-ui/icons/Bookmark";
import {
  DownloadlinkCreate,
  DownloadlinkEdit,
  DownloadlinkList,
  DownloadlinkShow,
} from "./DownloadLinks";

export const downloadLinks = {
  name: "downloadLink",
  list: DownloadlinkList,
  edit: DownloadlinkEdit,
  show: DownloadlinkShow,
  create: DownloadlinkCreate,
  icon: CategoryIcon,
  options: { cat: "Download" },
};
