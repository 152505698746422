import React from "react";
import { FileInput, FileField} from "react-admin";
import {
  Create,
  SimpleForm,
  downloadCSV,
  NumberInput,
  SelectInput
} from "react-admin";
import jsonExport from "jsonexport/dist";
import { CardHeader, Paper} from "@material-ui/core";
import { PLAN_SUBSCRIPTION_TYPE } from "../../Constants/choices";

export function addGroupSubscription(props) {
  const onSuccess = ({data}) => {
    jsonExport(data.list, (err, csv) => {
      downloadCSV(csv, `AddGroupSubscription${Date.now()}`);
    });
  };
  const transform = (data) => {
    let from = new Date(Date.parse(data.from)).setHours(0, 0, 0).toString();
    let to = new Date(Date.parse(data.to)).setHours(23, 59, 0).toString();
    return {...data, from, to};
  };
  return (
    <Paper>
      <Create {...props} transform={transform} onSuccess={onSuccess}>
        <SimpleForm {...props} >
          <CardHeader title="افزایش اشتراک گروهی"/>
          <NumberInput source="day" label="روز"/>
          <SelectInput source="subscriptionType" choices={PLAN_SUBSCRIPTION_TYPE} label="نوع اشتراک"  style={{ width : "240px"}}   />

          <FileInput source="files" label="فایل مربوطه (.csv)" accept=".csv">
            <FileField source="src" title="title"/>
          </FileInput>
          <span style={{margin: "0 1em"}}/>
        </SimpleForm>
      </Create>
    </Paper>
  );
}
