import React from 'react';
import { ArrayField, Datagrid, DateField, TextField, Button, useNotify } from "react-admin";
import WithProps from "../../Components/WithProps";
import DietEdit from "./DietEdit";
import DietGenerate from "./DietGenerate";
import { httpClient } from "../../utils/request";
import { SERVER_DOMAIN } from "../../Constants";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";


const Visits = ({ record: user }) => {
    const notify = useNotify();
    const onSendDiet = (dietId) => {
        const body = {
            user: user._id,
            dietId: dietId,
        };
        // console.log("_________________", JSON.stringify(body))
        httpClient(`${SERVER_DOMAIN}/api/clinic/visit/sms`, {
            method: 'post',
            body: JSON.stringify(body)
        }).then(() => {
            notify("رژیم ارسال شد.", "success")
            setTimeout(function(){
                window.location.reload(1);
             }, 3000);
    
        })
    }
    return (
        <>
            <p style={{ fontSize: "36px", fontFamily: "IRANSansWeb", paddingRight: "16px" }}>دوره های کلینیک</p>
            <ArrayField source={"visits"}>

                <Datagrid>
                    <TextField source={"reserveCode"} />
                    <WithProps>
                        {({ record: visit }) => (
                            <ArrayField source={"sessions"}>
                                <WithProps>
                                    {({ data: session }) => {
                                        return (
                                            <Datagrid>
                                                <DateField label={"تاریخ"} source={"startDate"} locales={"fa-IR"} />
                                                <DateField label={"ساعت"} source={"startDate"} showTime={true}
                                                    options={{ hour: 'numeric', minute: 'numeric' }} locales={"fa-IR"} />
                                                <TextField source={"voice"} emptyText={"ندارد"} />
                                                <TextField source={"statusMessage"} />
                                                <WithProps>
                                                    {({ record: session }) => (
                                                        session.diet ?
                                                            <>
                                                                <DietEdit dietId={session.diet} />
                                                                <Button label={"ارسال رژیم"} onClick={() => onSendDiet(session.diet)} />
                                                                {session.smsSent ? <CheckIcon style={{ verticalAlign: "inherit" }} /> : <CloseIcon style={{ verticalAlign: "inherit" }} />}
                                                            </> :
                                                            <DietGenerate user={user} visit={visit} session={session} />
                                                    )}
                                                </WithProps>

                                            </Datagrid>
                                        )
                                    }}
                                </WithProps>
                            </ArrayField>
                        )}
                    </WithProps>

                </Datagrid>
            </ArrayField>
        </>
    );
};

export default Visits;