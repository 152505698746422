import {getList}  from "./GetList.js";
import {getOne}  from "./GetOne.js";
import {getMany}  from "./getMany.js";
import {getManyReference}  from "./getManyReference.js";
import {Update}  from "./update.js";
import {updateMany}  from "./updateMany.js";
import {Create}  from "./create.js";
import {deleteOne}  from "./delete.js";
import {deleteMany}  from "./deleteMany.js";

export const dataProvider = {
  getList: (resource, params)         => getList(resource, params),
  getOne: (resource, params)          => getOne(resource, params),
  getMany: (resource, params)         => getMany(resource, params),
  getManyReference: (resource, params)=> getManyReference(resource, params),
  update: (resource, params)          => Update.update(resource, params),
  updateMany: (resource, params)      => updateMany(resource, params),
  create: (resource, params)          => Create.create(resource, params),
  delete: (resource, params)          => deleteOne(resource, params),
  deleteMany: (resource, params)      => deleteMany(resource, params),
};

