import {Loading } from "react-admin";
import React, {  useState } from "react";
import {
  AppBar,
  Button,
  Dialog,
  IconButton,
  Paper,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { PersianDatePicker } from "../../../Components/PersianDatePickers";
import CloseIcon from "@material-ui/icons/Close";
import { axiosFetcher } from "../../../utils/axiosFetcher";

export default function (props) {
  const data = ""
  const { record } = props;
  return (
    <>
      <DiscountDialog
        transactions={data}
        title={`اطلاعات لاگ ها  `}
        record={record}
      >
        <Button variant="contained" color="primary" style={{marginTop:10}}>
 اطلاعات لاگ ها
        </Button>
      </DiscountDialog>
    </>
  );
}

const DiscountDialog = (props) => {
  const [open, setOpen] = useState(false);
  const[fromDate,setFromDate] = useState(new Date().getTime());
  const[loading,setLoading]= useState(false)
  const[showButton,setShowButton]=useState(false)
  const[userLogsData,setuserLogsData]= useState("")
  const[selectIndex,setSelectIndex]=useState(null)
  const[tableShow,setTableShow] = useState(false)
  var buttons = []
  for (const [key] of Object.entries(userLogsData)) {
    buttons.push(`${key}`);

  }


  const { transactions: discountCodes = [], title } = props;
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const renderChildren = () => {
    return React.Children.map(props.children, (child) => {
      return React.cloneElement(child, {
        onClick: handleClick,
        fullWidth: true,
      });
    });
  };
  function fetchData() {
    setLoading(true)
    axiosFetcher
        .get(`/allUserLogs/${props.record.id}?from=${fromDate}`)
        .then((res) => {
          setLoading(false)
          setuserLogsData(res.data.result)
          setShowButton(true)
        })
        .catch((e) => {
          setLoading(false)
        });
  }
  function fromChange(e) {
    setFromDate(e._d.getTime())
  }

  function renderSwitch() {
    switch(buttons[selectIndex]) {
      case "foodLogs":
        return (
          <>
            <TableHead>
            <TableRow>
              <TableCell align="center">createdAt</TableCell>
              <TableCell align="center">foodFactId</TableCell>
              <TableCell align="center">isDeleted</TableCell>
              <TableCell align="center">meal</TableCell>
              <TableCell align="center">objectId</TableCell>
              <TableCell align="center">relatedDate</TableCell>
              <TableCell align="center">size</TableCell>
              <TableCell align="center">updatedAt</TableCell>
              <TableCell align="center">userId</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
              {userLogsData.foodLogs.map((e) => (
                  <TableRow>
                    <TableCell align="center">{e?.createdAt}</TableCell>
                    <TableCell align="center">{e?.foodFactId}</TableCell>
                    <TableCell align="center">{e?.isDeleted.toString()}</TableCell>
                    <TableCell align="center">{e?.meal}</TableCell>
                    <TableCell align="center">{e?.objectId}</TableCell>
                    <TableCell align="center">{e?.relatedDate}</TableCell>
                    <TableCell align="center">{e?.size}</TableCell>
                    <TableCell align="center">{e?.updatedAt}</TableCell>
                    <TableCell align="center">{e?.userId}</TableCell>
                  </TableRow>
              ))}
            </TableBody>
         </>
        ) ;

      case "changeWeightGoals":
        return (
            <>
              <TableHead>
                <TableRow>
                  <TableCell align="center">createdAt</TableCell>
                  <TableCell align="center">difficulty</TableCell>
                  <TableCell align="center">state</TableCell>
                  <TableCell align="center">target</TableCell>
                  <TableCell align="center">objectId</TableCell>
                  <TableCell align="center">updatedAt</TableCell>
                  <TableCell align="center">userId</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userLogsData.changeWeightGoals.map((e) => (
                    <TableRow>
                      <TableCell align="center">{e?.createdAt}</TableCell>
                      <TableCell align="center">{e?.difficulty}</TableCell>
                      <TableCell align="center">{e?.state}</TableCell>
                      <TableCell align="center">{e?.target}</TableCell>
                      <TableCell align="center">{e?.objectId}</TableCell>
                      <TableCell align="center">{e?.updatedAt}</TableCell>
                      <TableCell align="center">{e?.userId}</TableCell>
                    </TableRow>
                ))}
              </TableBody>
            </>
        ) ;

      case "exerciseLogs":
        return (
            <>
              <TableHead>
                <TableRow>
                  <TableCell align="center">createdAt</TableCell>
                  <TableCell align="center">addDate</TableCell>
                  <TableCell align="center">isDeleted</TableCell>
                  <TableCell align="center">exerciseFactId</TableCell>
                  <TableCell align="center">objectId</TableCell>
                  <TableCell align="center">quantity</TableCell>
                  <TableCell align="center">relatedDate</TableCell>
                  <TableCell align="center">updatedAt</TableCell>
                  <TableCell align="center">userId</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userLogsData.exerciseLogs.map((e) => (
                    <TableRow>
                      <TableCell align="center">{e?.createdAt}</TableCell>
                      <TableCell align="center">{e?.addDate}</TableCell>
                      <TableCell align="center">{e?.isDeleted.toString()}</TableCell>
                      <TableCell align="center">{e?.exerciseFactId}</TableCell>
                      <TableCell align="center">{e?.objectId}</TableCell>
                      <TableCell align="center">{e?.quantity}</TableCell>
                      <TableCell align="center">{e?.relatedDate}</TableCell>
                      <TableCell align="center">{e?.updatedAt}</TableCell>
                      <TableCell align="center">{e?.userId}</TableCell>
                    </TableRow>
                ))}
              </TableBody>
            </>
        ) ;

      case "maintenanceWeightGoals":
        return (
            <>
              <TableHead>
                <TableRow>
                  <TableCell align="center">createdAt</TableCell>
                  <TableCell align="center">objectId</TableCell>
                  <TableCell align="center">state</TableCell>
                  <TableCell align="center">updatedAt</TableCell>
                  <TableCell align="center">userId</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userLogsData.maintenanceWeightGoals.map((e) => (
                    <TableRow>
                      <TableCell align="center">{e?.createdAt}</TableCell>
                      <TableCell align="center">{e?.objectId}</TableCell>
                      <TableCell align="center">{e?.state}</TableCell>
                      <TableCell align="center">{e?.updatedAt}</TableCell>
                      <TableCell align="center">{e?.userId}</TableCell>
                    </TableRow>
                ))}
              </TableBody>
            </>
        ) ;

      case "personalFoodLogs":
        return (
            <>
              <TableHead>
                <TableRow>
                    <TableCell align="center">amount</TableCell>
                    <TableCell align="center">createdAt</TableCell>
                    <TableCell align="center">day</TableCell>
                    <TableCell align="center">month</TableCell>
                    <TableCell align="center">year</TableCell>
                    <TableCell align="center">updatedAt</TableCell>
                    <TableCell align="center">userId</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userLogsData.personalFoodLogs.map((e) => (
                    <TableRow>
                      <TableCell align="center">{e?.amount}</TableCell>
                      <TableCell align="center">{e?.createdAt}</TableCell>
                      <TableCell align="center">{e?.day}</TableCell>
                      <TableCell align="center">{e?.month}</TableCell>
                      <TableCell align="center">{e?.year}</TableCell>
                      <TableCell align="center">{e?.updatedAt}</TableCell>
                      <TableCell align="center">{e?.userId}</TableCell>
                    </TableRow>
                ))}
              </TableBody>
            </>
        ) ;

      case "quickExerciseLogs":
        return (
            <>
              <TableHead>
                <TableRow>
                  <TableCell align="center">calorie</TableCell>
                  <TableCell align="center">createdAt</TableCell>
                  <TableCell align="center">isDeleted</TableCell>
                  <TableCell align="center">name</TableCell>
                  <TableCell align="center">objectId</TableCell>
                  <TableCell align="center">relatedDate</TableCell>
                  <TableCell align="center">updatedAt</TableCell>
                  <TableCell align="center">userId</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userLogsData.quickExerciseLogs.map((e) => (
                    <TableRow>
                      <TableCell align="center">{e?.calorie}</TableCell>
                      <TableCell align="center">{e?.createdAt}</TableCell>
                      <TableCell align="center">{e?.isDeleted.toString()}</TableCell>
                      <TableCell align="center">{e?.name}</TableCell>
                      <TableCell align="center">{e?.objectId}</TableCell>
                      <TableCell align="center">{e?.relatedDate}</TableCell>
                      <TableCell align="center">{e?.updatedAt}</TableCell>
                      <TableCell align="center">{e?.userId}</TableCell>
                    </TableRow>
                ))}
              </TableBody>
            </>
        ) ;

      case "quickFoodLogs":
        return (
            <>
              <TableHead>
                <TableRow>
                  <TableCell align="center">calorie</TableCell>
                  <TableCell align="center">createdAt</TableCell>
                  <TableCell align="center">isDeleted</TableCell>
                  <TableCell align="center">meal</TableCell>
                  <TableCell align="center">objectId</TableCell>
                  <TableCell align="center">relatedDate</TableCell>
                  <TableCell align="center">updatedAt</TableCell>
                  <TableCell align="center">userId</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userLogsData.quickFoodLogs.map((e) => (
                    <TableRow>
                      <TableCell align="center">{e?.calorie}</TableCell>
                      <TableCell align="center">{e?.createdAt}</TableCell>
                      <TableCell align="center">{e?.isDeleted.toString()}</TableCell>
                      <TableCell align="center">{e?.meal}</TableCell>
                      <TableCell align="center">{e?.objectId}</TableCell>
                      <TableCell align="center">{e?.relatedDate}</TableCell>
                      <TableCell align="center">{e?.updatedAt}</TableCell>
                      <TableCell align="center">{e?.userId}</TableCell>
                    </TableRow>
                ))}
              </TableBody>
            </>
        ) ;

      case "stepGoals":
        return (
            <>
              <TableHead>
                <TableRow>
                  <TableCell align="center">amount</TableCell>
                  <TableCell align="center">createdAt</TableCell>
                  <TableCell align="center">isDeleted</TableCell>
                  <TableCell align="center">objectId</TableCell>
                  <TableCell align="center">startDate</TableCell>
                  <TableCell align="center">state</TableCell>
                  <TableCell align="center">updatedAt</TableCell>
                  <TableCell align="center">userId</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userLogsData.stepGoals.map((e) => (
                    <TableRow>
                      <TableCell align="center">{e?.amount}</TableCell>
                      <TableCell align="center">{e?.createdAt}</TableCell>
                      <TableCell align="center">{e?.isDeleted.toString()}</TableCell>
                      <TableCell align="center">{e?.objectId}</TableCell>
                      <TableCell align="center">{e?.startDate}</TableCell>
                      <TableCell align="center">{e?.state}</TableCell>
                      <TableCell align="center">{e?.updatedAt}</TableCell>
                      <TableCell align="center">{e?.userId}</TableCell>
                    </TableRow>
                ))}
              </TableBody>
            </>
        ) ;

      case "stepLogs":
        return (
            <>
              <TableHead>
                <TableRow>
                  <TableCell align="center">amount</TableCell>
                  <TableCell align="center">createdAt</TableCell>
                  <TableCell align="center">id</TableCell>
                  <TableCell align="center">day</TableCell>
                  <TableCell align="center">month</TableCell>
                  <TableCell align="center">year</TableCell>
                  <TableCell align="center">updatedAt</TableCell>
                  <TableCell align="center">userId</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userLogsData.stepLogs.map((e) => (
                    <TableRow>
                      <TableCell align="center">{e?.amount}</TableCell>
                      <TableCell align="center">{e?.createdAt}</TableCell>
                      <TableCell align="center">{e?.id}</TableCell>
                      <TableCell align="center">{e?.day}</TableCell>
                      <TableCell align="center">{e?.month}</TableCell>
                      <TableCell align="center">{e?.year}</TableCell>
                      <TableCell align="center">{e?.updatedAt}</TableCell>
                      <TableCell align="center">{e?.userId}</TableCell>
                    </TableRow>
                ))}
              </TableBody>
            </>
        ) ;

      case "waterLogs":
        return (
            <>
              <TableHead>
                <TableRow>
                  <TableCell align="center">amount</TableCell>
                  <TableCell align="center">createdAt</TableCell>
                  <TableCell align="center">day</TableCell>
                  <TableCell align="center">month</TableCell>
                  <TableCell align="center">year</TableCell>
                  <TableCell align="center">updatedAt</TableCell>
                  <TableCell align="center">userId</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userLogsData.waterLogs.map((e) => (
                    <TableRow>
                      <TableCell align="center">{e?.amount}</TableCell>
                      <TableCell align="center">{e?.createdAt}</TableCell>
                      <TableCell align="center">{e?.day}</TableCell>
                      <TableCell align="center">{e?.month}</TableCell>
                      <TableCell align="center">{e?.year}</TableCell>
                      <TableCell align="center">{e?.updatedAt}</TableCell>
                      <TableCell align="center">{e?.userId}</TableCell>
                    </TableRow>
                ))}
              </TableBody>
            </>
        ) ;

      case "waterGoals":
        return (
            <>
              <TableHead>
                <TableRow>
                  <TableCell align="center">amount</TableCell>
                  <TableCell align="center">createdAt</TableCell>
                  <TableCell align="center">isDeleted</TableCell>
                  <TableCell align="center">objectId</TableCell>
                  <TableCell align="center">startDate</TableCell>
                  <TableCell align="center">state</TableCell>
                  <TableCell align="center">updatedAt</TableCell>
                  <TableCell align="center">userId</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userLogsData.waterGoals.map((e) => (
                    <TableRow>
                      <TableCell align="center">{e?.amount}</TableCell>
                      <TableCell align="center">{e?.createdAt}</TableCell>
                      <TableCell align="center">{e?.isDeleted.toString()}</TableCell>
                      <TableCell align="center">{e?.objectId}</TableCell>
                      <TableCell align="center">{e?.startDate}</TableCell>
                      <TableCell align="center">{e?.state}</TableCell>
                      <TableCell align="center">{e?.updatedAt}</TableCell>
                      <TableCell align="center">{e?.userId}</TableCell>
                    </TableRow>
                ))}
              </TableBody>
            </>
        ) ;

      case "weightGoals":
        return (
            <>
              <TableHead>
                <TableRow>
                  <TableCell align="center">createdAt</TableCell>
                  <TableCell align="center">isDeleted</TableCell>
                  <TableCell align="center">objectId</TableCell>
                  <TableCell align="center">referenceId</TableCell>
                  <TableCell align="center">startDate</TableCell>
                  <TableCell align="center">startWeight</TableCell>
                  <TableCell align="center">type</TableCell>
                  <TableCell align="center">updatedAt</TableCell>
                  <TableCell align="center">userId</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userLogsData.weightGoals.map((e) => (
                    <TableRow>
                      <TableCell align="center">{e?.createdAt}</TableCell>
                      <TableCell align="center">{e?.isDeleted.toString()}</TableCell>
                      <TableCell align="center">{e?.objectId}</TableCell>
                      <TableCell align="center">{e?.referenceId}</TableCell>
                      <TableCell align="center">{e?.startDate}</TableCell>
                      <TableCell align="center">{e?.startWeight}</TableCell>
                      <TableCell align="center">{e?.type}</TableCell>
                      <TableCell align="center">{e?.updatedAt}</TableCell>
                      <TableCell align="center">{e?.userId}</TableCell>
                    </TableRow>
                ))}
              </TableBody>
            </>
        ) ;

      case "weightLogs":
        return (
            <>
              <TableHead>
                <TableRow>
                  <TableCell align="center">createdAt</TableCell>
                  <TableCell align="center">day</TableCell>
                  <TableCell align="center">month</TableCell>
                  <TableCell align="center">year</TableCell>
                  <TableCell align="center">isDeleted</TableCell>
                  <TableCell align="center">imageId</TableCell>
                  <TableCell align="center">note</TableCell>
                  <TableCell align="center">weight</TableCell>
                  <TableCell align="center">updatedAt</TableCell>
                  <TableCell align="center">userId</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userLogsData.weightLogs.map((e) => (
                    <TableRow>
                      <TableCell align="center">{e?.createdAt}</TableCell>
                      <TableCell align="center">{e?.day}</TableCell>
                      <TableCell align="center">{e?.month}</TableCell>
                      <TableCell align="center">{e?.year}</TableCell>
                      <TableCell align="center">{e?.isDeleted.toString()}</TableCell>
                      <TableCell align="center">{e?.imageId}</TableCell>
                      <TableCell align="center">{e?.note}</TableCell>
                      <TableCell align="center">{e?.weight}</TableCell>
                      <TableCell align="center">{e?.updatedAt}</TableCell>
                      <TableCell align="center">{e?.userId}</TableCell>
                    </TableRow>
                ))}
              </TableBody>
            </>
        ) ;




      default:
        return (
            <TableRow>
              <TableCell align="right">خطایی رخ داده است </TableCell>
            </TableRow>

        )
    }
  }
  return (
    <>
      {renderChildren()}
      <Dialog
        open={open}
        onClose={handleDialogClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        fullScreen
        TransitionComponent={Transition}
      >
        <AppBar style={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleDialogClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="body1" gutterTop style={{ margin: "0.5em" }}>
              {title}
            </Typography>
          </Toolbar>
        </AppBar>
        <Paper>
            <>
              <div style={{ width:"90%",margin:"auto",marginTop:40,display:"flex", alignItems:"center"}}>
                <div  style={{marginLeft:20}}>
                  <PersianDatePicker name="from"  value={fromDate} onChange={fromChange}  label={"از"}/>
                </div>
                <Button color="primary" variant="contained" style={{padding:"14px 22px"}} onClick={fetchData}>جست و جو</Button>
              </div>
              {loading===true ?<Loading/> : null}
              {showButton ===true ?<div style={{width:"90%",margin:"auto",marginTop:"30px",marginBottom:"30px"}}>
                {buttons.map((e) => (
                    <Button style={{margin:"10px",padding:"13px 25px"}} color="primary" variant="contained" onClick={()=> {
                      setSelectIndex(buttons.indexOf(e))
                      setTableShow(true)
                      axiosFetcher
                          .get(`/allUserLogs/${props.record.id}?from=${fromDate}`)
                          .then((res) => {
                            console.log(res.data.result ,  `${res.data.result}`)
                          })
                          .catch((e) => {

                          });
                    }}>{e}</Button>
                ))}

              </div> : null }

              {tableShow===true ?<div style={{width:"90%",margin:"auto"}}>
                <Typography>جدول {buttons[selectIndex]}  </Typography>
                {/*<PersianDatePicker name="from" value={fromDate} onChange={fromChange} label={"از"}/>*/}
                <Table aria-label="a dense table">
                    {renderSwitch()}

                </Table>
              </div> : null}
            </>

        </Paper>
      </Dialog>
    </>
  );
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
