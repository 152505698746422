import { stringify } from "query-string";
import { SERVER, SERVER_DOMAIN } from "../Constants";
import { httpClient } from "../utils/request";

const apiUrl = SERVER;
const serverUrl = SERVER_DOMAIN;

export function getList(resource, params) {
  switch (resource) {
    case "food": {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query = {
        sort: JSON.stringify([field, order]),
        range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
        filter: JSON.stringify(params.filter),
      };
      const url = `${apiUrl}/${resource}?${stringify(query)}`;
      return httpClient(url).then(({ headers, json }) => {
        return {
          data: json.map((resource) => ({
            ...resource,
            similarNames:
              resource.similarNames &&
              resource.similarNames.map((name) => ({
                name,
              })),
          })),
          total: parseInt(headers.get("X-Total-Count").split("/").pop(), 10),
        };
      });
    }
    case "personalfood": {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query = {
        sort: JSON.stringify([field, order]),
        range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
        filter: JSON.stringify(params.filter),
      };
      const url = `${apiUrl}/${resource}/dashboard?${stringify(query)}`;
      return httpClient(url).then(({ headers, json }) => {
		console.log("jj",json);
        return {
          data: json.map((resource) => ({
            ...resource,
            barcode: resource.barcode,
			id: resource.barcode
          })),
          total: parseInt(headers.get("X-Total-Count").split("/").pop(), 10),
        };
      });
    }
    case "promotion":
    case "shop-package":
    case "plan": {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query = {
        sort: JSON.stringify([field, order]),
        range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
        filter: JSON.stringify(params.filter),
      };
      const url = `${apiUrl}/shop/v2/${resource}?${stringify(query)}`;
      return httpClient(url).then(({ headers, json }) => ({
        data: json.result.map((resource) => ({
          ...resource,
          id: resource.id,
        })),
        total: parseInt(headers.get("X-Total-Count").split("/").pop(), 10),
      }));
    }
    case "bulkDiscountCodes": {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query = {
        sort: JSON.stringify([field, order]),
        range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
        filter: JSON.stringify(params.filter),
      };
      const url = `${apiUrl}/shop/v2/bulkDiscountCode?${stringify(query)}`;
      return httpClient(url).then(({ headers, json }) => ({
        data: json.map((resource) => ({
          ...resource,
          id: resource._id,
        })),
        total: parseInt(headers.get("X-Total-Count").split("/").pop(), 10),
      }));
    }
    case "regularDiscountCodes": {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query = {
        sort: JSON.stringify([field, order]),
        range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
        filter: JSON.stringify(params.filter),
      };
      const url = `${apiUrl}/shop/v2/regularDiscountCode?${stringify(query)}`;
      return httpClient(url).then(({ headers, json }) => ({
        data: json.map((resource) => ({
          ...resource,
          id: resource.id,
        })),
        total: parseInt(headers.get("X-Total-Count").split("/").pop(), 10),
      }));
    }
    case "plansku": {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query = {
        sort: JSON.stringify([field, order]),
        range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
        filter: JSON.stringify(params.filter),
      };
      const url = `${apiUrl}/shop/v2/sku?${stringify(query)}`;
      return httpClient(url).then(({ headers, json }) => ({
        data: json.result.map((resource) => ({
          ...resource,
          id: resource.id,
        })),
        total: parseInt(headers.get("X-Total-Count").split("/").pop(), 10),
      }));
    }
    case "userDiscountCodes": {
      const query = {
        userId: params.id,
      };
      const url = `${apiUrl}/${resource}?${stringify(query)}`;
      return httpClient(url).then(({ json }) => ({
        data: json,
        total: 55,
      }));
    }
    case "getInvalidFoodFacts": {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query = {
        sort: JSON.stringify([field, order]),
        range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
        filter: JSON.stringify(params.filter),
      };
      const url = `${apiUrl}/${resource}?${stringify(query)}`;
      return httpClient(url).then(({ headers, json }) => {
        return {
          data: json.map((resource) => ({
            ...resource,
            id: resource._id,
            similarNames:
              resource.similarNames &&
              resource.similarNames.map((name) => ({
                name,
              })),
          })),
          total: parseInt(headers.get("X-Total-Count").split("/").pop(), 10),
        };
      });
    }
    case "adminUsers": {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query = {
        sort: JSON.stringify([field, order]),
        range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
        filter: JSON.stringify(params.filter),
      };
      const url = `${apiUrl}/${resource}?${stringify(query)}`;
      return httpClient(url).then(({ headers, json }) => {
        return {
          data: json.map((resource) => ({
            ...resource,
            id: resource.phoneNumber,
          })),
          total: parseInt(headers.get("X-Total-Count").split("/").pop(), 10),
        };
      });
    }
    case "getInvalidFoodUnits":
    case "pagesData":
    case "scenario":
    case "faqQuestion":
    case "bulkDiscountCode": {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query = {
        sort: JSON.stringify([field, order]),
        range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
        filter: JSON.stringify(params.filter),
      };
      const url = `${apiUrl}/${resource}?${stringify(query)}`;
      return httpClient(url).then(({ headers, json }) => ({
        data: json.map((resource) => ({
          ...resource,
          id: resource._id,
        })),
        total: parseInt(headers.get("X-Total-Count").split("/").pop(), 10),
      }));
    }
    case "campaignCard": {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query = {
        sort: JSON.stringify([field, order]),
        range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
        filter: JSON.stringify(params.filter),
      };
      const url = `${apiUrl}/${resource}?${stringify(query)}`;
      return httpClient(url).then(({ headers, json }) => ({
        data: json.map((resource) => ({
          ...resource,
          id: resource.objectId,
        })),
        total: parseInt(headers.get("X-Total-Count").split("/").pop(), 10),
      }));
    }
    case "tracker": {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query = {
        sort: JSON.stringify([field, order]),
        range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
        filter: JSON.stringify(params.filter),
      };
      const url = `${serverUrl}/api/${resource}?${stringify(query)}`;
      return httpClient(url).then(({ headers, json }) => {
        return {
          data: json.data.map((r) => ({ ...r, id: r._id })),
          total: parseInt(headers.get("X-Total-Count").split("/").pop(), 10),
        };
      });
    }
    default:
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query = {
        sort: JSON.stringify([field, order]),
        range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
        filter: JSON.stringify(params.filter),
      };
      const url = `${apiUrl}/${resource}?${stringify(query)}`;
      return httpClient(url).then(({ headers, json }) => ({
        data: json,
        total: parseInt(headers.get("X-Total-Count").split("/").pop(), 10),
      }));
  }
}
