import {Confirm} from "react-admin";
import React, {useState} from "react";
import {Button} from "@material-ui/core";
import get from "lodash/get";
import PropTypes from "prop-types";


const AddSubscriptionBtn = (props) => {
  const { addSubscription, loading } = props;
  const [open, setOpen] = useState(false);
  const handleConfirm = () => {
    setOpen(false);
    addSubscription();
  };
  return (
    <>
      <Button
        variant="contained"
        color="primary"
        style={{ marginTop: "0.5em" }}
        onClick={() => setOpen(true)}
        disabled={loading}
      >
        افزودن اشتراک
      </Button>
      <Confirm
        isOpen={open}
        loading={loading}
        title="افزودن اشتراک"
        content="آیا از افزودن اشتراک به این کاربر مطمئن هستید؟"
        onConfirm={handleConfirm}
        onClose={() => setOpen(false)}
        onCancel={() => setOpen(false)}
      />
    </>
  );
};

export default AddSubscriptionBtn;

export const ReduceSubscriptionBtn = (props) => {
  const { handleSubmit, loading } = props;
  const [open, setOpen] = useState(false);
  const handleConfirm = () => {
    setOpen(false);
    handleSubmit("reduction");
  };
  return (
    <>
      <Button
        variant="contained"
        style={{ marginTop: "0.5em" }}
        onClick={() => setOpen(true)}
        disabled={loading}
      >
        کاهش اشتراک
      </Button>
      <Confirm
        isOpen={open}
        loading={loading}
        title="کاهش اشتراک اشتراک"
        content="آیا از کم کردن اشتراک این کاربر مطمئن هستید؟"
        onConfirm={handleConfirm}
        onClose={() => setOpen(false)}
        onCancel={() => setOpen(false)}
      />
    </>
  );
};

export const SendPopUpBtn = (props) => {
  const { handleSubmit, loading } = props;
  const [open, setOpen] = useState(false);
  const handleConfirm = () => {
    setOpen(false);
    handleSubmit("reduction");
  };
  return (
    <>
      <Button
        variant="contained"
        color="primary"
        style={{ marginTop: "0.5em" }}
        onClick={() => setOpen(true)}
        disabled={loading}
      >
        ارسال پاپ‌اپ
      </Button>
      <Confirm
        isOpen={open}
        loading={loading}
        title="ارسال پاپ‌اپ"
        content="آیا از ارسال پاپ‌اپ به این کاربر مطمئن هستید؟"
        onConfirm={handleConfirm}
        onClose={() => setOpen(false)}
        onCancel={() => setOpen(false)}
      />
    </>
  );
};

export const DownloadBtnDatagrid = (props) => {
  const { source, record = {}, btnlabel = "دریافت" } = props;
  let value = get(record, source);

  value = value.join("\r\n");
  let data = new Blob([value], { type: "text/plain" });
  // console.log(value, data);
  let url = window.URL.createObjectURL(data);
  return (
    <Button
      onClick={(e) => e.stopPropagation()}
      component={"a"}
      href={url}
      download={`${Date.now().toString()}.txt`}
      color={"primary"}
      fullWidth
      variant={"contained"}
    >
      {btnlabel}
    </Button>
  );
};

DownloadBtnDatagrid.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string,
};
