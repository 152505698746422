import React from 'react';
import {
    DateField,
    Edit,
    ReferenceArrayInput,
    SelectArrayInput,
    SimpleForm,
    TextField, BooleanField, NumberField
} from 'react-admin';

import {CLINIC_USER_ACTIVITY_LEVEL_CHOICES, CLINIC_USER_GENDER_CHOICES, NEW_USER_BREAST_FEEDING_STATE_CHOICES} from "../../Constants/choices";
import WithProps from "../../Components/WithProps";
import Visits from "./Visits";
import UserTransactions from "./Components/UserTransactions";
import PersianTextField from "../../Components/PersianTextField";

const ClinicUserEdit = (props) => {

    return (
        <Edit {...props}>
            <WithProps>
                {props => (
                    <>
                        <SimpleForm {...props}>
                            <TextField source={"phoneNumber"}/>
                            <TextField source={"name"}/>
                            <PersianTextField source={"sex"} choices={CLINIC_USER_GENDER_CHOICES}/>
                            <DateField source={"birthDate"} locales="fa-IR"/>
                            <BooleanField source={"laboredPast"}/>
                            <PersianTextField source="cycle" choices={NEW_USER_BREAST_FEEDING_STATE_CHOICES}/>
                            <NumberField source={"weight"}/>
                            <NumberField source={"height"}/>
                            <NumberField source={"abdomenSize"}/>
                            <NumberField source={"waistSize"}/>
                            <NumberField source={"wristSize"}/>
                            <ReferenceArrayInput reference={"hatedFood"} source={"hatedFoods"}>
                                <SelectArrayInput/>
                            </ReferenceArrayInput>
                            <ReferenceArrayInput reference={"disease"} source={"diseases"}>
                                <SelectArrayInput/>
                            </ReferenceArrayInput>
                            <PersianTextField source={"activityLevel"} choices={CLINIC_USER_ACTIVITY_LEVEL_CHOICES}/>

                        </SimpleForm>
                        <UserTransactions {...props} />
                        <Visits {...props}/>
                    </>
                )}
            </WithProps>
        </Edit>
    );
};

export default ClinicUserEdit;