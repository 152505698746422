
import { SERVER } from "../Constants";
import {httpClient} from "../utils/request";
import { stringify } from "query-string";
const apiUrl = SERVER;

export function updateMany(resource, params){
  const query = {filter: JSON.stringify({ id: params.ids })};
  return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
    method: "PUT",
    body: JSON.stringify(params.data),
  }).then(({ json }) => ({ data: json }));
};