import React from 'react';
import {Edit, BooleanInput, TextInput, SimpleForm, required} from 'react-admin';
import EditView from "../../Components/EditView";
const TrackerEdit = (props) => {
    return (
        <EditView {...props}>
                <BooleanInput source={"deleted"}/>
                <TextInput source={"trackerLink"} validate={required()}/>
                <TextInput source={"baseURL"} validate={required()}/>
                <TextInput source={"identifier"} validate={required()}/>
                <TextInput source={"description"} multiline fullWidth validate={required()}/>
        </EditView>
    );
};

export default TrackerEdit;