import React from "react";
import {
    BooleanField,
    BooleanInput,
    DateField,
    List,
    SimpleShowLayout,
    TextField,
    TextInput,
    required,
    SelectArrayInput
} from "react-admin";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import CreateView from "../../Components/CreateView";
import EditView from "../../Components/EditView";
import ShowView from "../../Components/ShowView";
import SortableView, {
  DragHandle,
  SortableItem,
} from "./Components/SortableView";

import {MARKETS_CHOICES } from"../../Constants/choices"
import {ArrayTextField} from "../../Components/ArrayTextField";


const RenderedChildren = (props) => {
  return (
    <Table aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell align="center"> </TableCell>
          <TableCell align="center">نام</TableCell>
          <TableCell align="center">پرمیوم</TableCell>
          <TableCell align="center">بروزشده</TableCell>
          <TableCell align="center">مارکت</TableCell>
          <TableCell align="center">شناسه</TableCell>
          <TableCell align="center">حذف شده</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {props.items.map((id, index) => (
          <SortableItem
            key={`item-${id}`}
            index={index}
            id={id}
            resource={props.resource}
          >
            <TableCell align="center" component="th" scope="row">
              <DragHandle />
            </TableCell>

            <TableCell align="center" component="th" scope="row">
                <TextField record={props.data[id]} source="name" />
            </TableCell>
            <TableCell align="center" component="th" scope="row">
              <BooleanField record={props.data[id]} source="isPremium" />
            </TableCell>

            <TableCell align="center" component="th" scope="row">
              <DateField
                record={props.data[id]}
                locales="fa-IR"
                source="updatedAt"
              />
            </TableCell>
            <TableCell align="center" component="th" scope="row">
                <ArrayTextField  record={props.data[id]} source="markets" />
          </TableCell>
            <TableCell align="center" component="th" scope="row">
              <TextField record={props.data[id]} source="id" />
            </TableCell>
            <TableCell align="center" component="th" scope="row">
              <BooleanField record={props.data[id]} source="isDeleted" />
            </TableCell>
          </SortableItem>
        ))}
      </TableBody>
    </Table>
  );
};
export const ChallengeCategoryList = (props) => {
  return (
    <List {...props} sort={{ field: "order", order: "ASC" }}>
      <SortableView>
        <RenderedChildren />
      </SortableView>
    </List>
  );
};
export const ChallengecategoryEdit = (props) => (
  <EditView {...props}>
    <DateField locales={"fa-IR"} source="updatedAt" />
    <TextInput source="name" validate={required()}/>
    <BooleanInput source="isPremium" />
    <BooleanInput source="isDeleted" />
    <SelectArrayInput  source="markets" choices={MARKETS_CHOICES}/>
  </EditView>
);

export const ChallengecategoryCreate = (props) => (
  <CreateView {...props}>
    <TextInput source="name" validate={required()}/>
    <BooleanInput defaultValue={false} source="isPremium" />
    <SelectArrayInput  source="markets" choices={MARKETS_CHOICES}/>
  </CreateView>
);

export const ChallengecategoryShow = (props) => (
  <ShowView {...props}>
    <SimpleShowLayout>
      <BooleanField source="isDeleted" />
      <DateField locales={"fa-IR"} source="updatedAt" />
      <TextField source="name" />
      <BooleanField source="isPremium" />
      <ArrayTextField source="markets" />
      <TextField source="id" />
    </SimpleShowLayout>
  </ShowView>
);
