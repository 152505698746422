import {SERVER, SERVER_DOMAIN} from "../Constants";
import {httpClient} from "../utils/request";
import axios from "axios";
import {dataProvider} from "./dataProvider";


const apiUrl = SERVER;
const serverUrl = SERVER_DOMAIN;
export const Update = {
    
    defaultUpdate : function (resource, params) {
        return httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: "PUT",
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json,}))
    },
    update:async (resource, params) => {
        
        switch (resource) {
            
            case "bulkDiscountCode" : {
                return httpClient(`${apiUrl}/${resource}/${params.id}`, {
                    method: "PUT",
                    body: JSON.stringify(params.data),
                }).then(({ json }) => ({ data: {json ,id: json._id}}))
            }
            case "bulkDiscountCodes" : {
                return httpClient(`${apiUrl}/shop/v2/bulkDiscountCode/${params.id}`, {
                    method: "PUT",
                    body: JSON.stringify(params.data),
                }).then(({ json }) => ({ data: {json ,id: json._id}}))
            }
            case "food": {
                let similarNames = [];
                if (params.data.similarNames.length > 0) {
                    params.data.similarNames.forEach(({ name }) => {
                        similarNames.push(name);
                    });
                    let { data } = params;
                    data = { ...params.data, similarNames };
                    return httpClient(`${apiUrl}/${resource}/${params.id}`, {
                        method: "PUT",
                        body: JSON.stringify(data),
                    }).then(({ json }) => ({ data: json }));
                }
                return Update.defaultUpdate(resource, params);
            }
            case "user": {
                params.data.id = undefined;
                return httpClient(
                    `${apiUrl}/${resource}/${params.data.user.objectId}`,
                    {
                        method: "PUT",
                        body: JSON.stringify({ ...params.data.user }),
                    }
                ).then(({ json }) => ({
                    data: { ...json, id: json.objectId },
                }));
            }
            case "adminUsers":
                return httpClient(`${apiUrl}/${resource}/${params.id}`, {
                    method: "PUT",
                    body: JSON.stringify(params.data),
                }).then(({ json }) => ({
                    data: { ...json, id: json.phoneNumber },
                }));
            case "foodRecipeCategory": {
                if (params.data.pictures) {
                    let newData = { ...params.data };
                    const { rawFile } = params.data.pictures;
                    const formData = new FormData();
                    formData.append("image", rawFile);
                    const token = localStorage.getItem("Authorization");
                    const image = await axios.post(`${apiUrl}/teaching/image`, formData, {
                        headers: {
                            Authorization: `${token}`,
                        },
                    });
                    delete newData["pictures"];
                    newData.imageUrl = image.data.result.url;
                    params.data = newData;
                    return dataProvider.update(resource, {
                        ...params,
                        data: {
                            title: params.data.title,
                            imageUrl: params.data.imageUrl,
                            isPremium: params.data.isPremium,
                            isDeleted: params.data.isDeleted,
                        },
                    });
                } else {
                    return dataProvider.update(resource, {
                        ...params,
                        data: {
                            title: params.data.title,
                            imageUrl: params.data.imageUrl,
                            isPremium: params.data.isPremium,
                            isDeleted: params.data.isDeleted,
                        },
                    });
                }
            }
            case "foodRecipe":
                if (params.data.pictures) {
                    let newData = { ...params.data };
                    const { rawFile } = params.data.pictures;
                    const formData = new FormData();
                    formData.append("image", rawFile);
                    const token = localStorage.getItem("Authorization");
                    const image = await axios.post(
                        `${apiUrl}/challenge/image`,
                        formData,
                        {
                            headers: {
                                Authorization: `${token}`,
                            },
                        }
                    );
                    delete newData["pictures"];
                    newData.imageUrl = image.data.result.url;
                    params.data = newData;
                    return Update.defaultUpdate(resource, params);
                } else {
                    return Update.defaultUpdate(resource, params);
                }
            case "challenge":
              
                if (params.data.pictures) {
                    let newData = { ...params.data };
                    const { rawFile } = params.data.pictures;
                    const formData = new FormData();
                    formData.append("image", rawFile);
                    const token = localStorage.getItem("Authorization");
                    const image = await axios.post(
                        `${apiUrl}/challenge/image`,
                        formData,
                        {
                            headers: {
                                Authorization: `${token}`,
                            },
                        });
                    delete newData["pictures"];
                    if (params.data.description) {
                        newData.description=document.getElementById("textInput").value
                     
                    } 
                    newData.imageId = image.data.result.url;
                    params.data = newData;
                    return Update.defaultUpdate(resource, params);
                } else {
                    if (params.data.description) {
                        params.data.description=document.getElementById("textInput").value
                     
                    } 
                    return Update.defaultUpdate(resource, params);
                }
            case "exerciseInstructionCategory":
                if (params.data.pictures) {
                    let newData = { ...params.data };
                    const { rawFile } = params.data.pictures;
                    const formData = new FormData();
                    formData.append("image", rawFile);
                    formData.append("type", "teaching");
                    const token = localStorage.getItem("Authorization");
                    const image = await axios.post(`${apiUrl}/image`, formData, {
                        headers: {
                            Authorization: `${token}`,
                        },
                    });
                    delete newData["pictures"];
                    newData.image = image.data.result.url;
                    params.data = newData;
                    return Update.defaultUpdate(resource, params);
                } else {
                    return Update.defaultUpdate(resource, params);
                }
            case "packagePricing":
                if (params.data.pictures) {
                    let newData = { ...params.data };
                    const { rawFile } = params.data.pictures;
                    const formData = new FormData();
                    formData.append("image", rawFile);
                    const token = localStorage.getItem("Authorization");
                    const image = await axios.post(
                        `${apiUrl}/image`,
                        formData,
                        {
                            headers: {
                                Authorization: `${token}`,
                            },
                        }
                    );
                    delete newData["pricing"];
                    newData.imageId = image.data.result.url;
                    params.data = newData;
                    return Update.defaultUpdate(resource, params);
                } else {
                    return Update.defaultUpdate(resource, params);
                }
            case "campaignCard": {
                if (params.data.image) {
                    let newData = { ...params.data };
                    const { rawFile } = params.data.image;
                    const formData = new FormData();
                    formData.append("image", rawFile);
                    formData.append("type", "general");
                    const token = localStorage.getItem("Authorization");
                    const image = await axios.post(`${apiUrl}/image`, formData, {
                        headers: {
                            Authorization: `${token}`,
                        },
                    });
                    delete newData["image"];
                    newData.image = image.data.result.url;
                    params.data = newData;
                    return httpClient(`${apiUrl}/${resource}/${params.id}`, {
                        method: "PUT",
                        body: JSON.stringify(params.data),
                    }).then(({ json }) => ({data : {id: 1254, ...json}  }))
                } else {
                    return Update.defaultUpdate(resource, params);
                }
            }
            case "banner":
                if (params.data.pictures) {
                    let newData = { ...params.data };
                    const { rawFile } = params.data.pictures;
                    const formData = new FormData();
                    formData.append("image", rawFile);
                    const token = localStorage.getItem("Authorization");
                    const image = await axios.post(
                        `${apiUrl}/challenge/image`,
                        formData,
                        {
                            headers: {
                                Authorization: `${token}`,
                            },
                        });
                    delete newData["pictures"];
                    newData.imageId = image.data.result.url;
                    params.data = newData;
                    return Update.defaultUpdate(resource, params);
                } else {
                    return Update.defaultUpdate(resource, params);
                }
            case "campaign":
            case "getToKnow": case "popUpData": {
                let newData = { ...params.data };
                if (params.data.pictures) {
                    const { rawFile } = params.data.pictures;
                    const formData = new FormData();
                    formData.append("image", rawFile);
                    formData.append("type", params.data.imagetype);
                    const token = localStorage.getItem("Authorization");
                    const image = await axios.post(`${apiUrl}/image`, formData, {
                        headers: {
                            Authorization: `${token}`,
                        },
                    });
                    delete newData["pictures"];
                    delete newData["imagetype"];
                    newData.headerImageUrl = image.data.result.url;
                    params.data = newData;
                    return httpClient(`${apiUrl}/${resource}/${params.id}`, {
                        method: "PUT",
                        body: JSON.stringify(params.data),
                    }).then(({ json }) => ({
                        data: { ...json, id: json._id },
                    }));
                } else {
                    return httpClient(`${apiUrl}/${resource}/${params.id}`, {
                        method: "PUT",
                        body: JSON.stringify(params.data),
                    }).then(({ json }) => ({
                        data: { ...json, id: json._id },
                    }));
                }}
            case "notification": {
                if(params.data.phoneNumbersCount>0){
                    if(params.data.file==null){
                        let body = document.getElementById("textInput").value
                        let title = document.getElementById("textInputTitle").value
                        let testNumber =params.data.testPhoneNumber
                        if(params.data.imageUrl.src==undefined){
                            var imageUrl =params.data.imageUrl
                        }else{
                            var imageUrl =params.data.imageUrl.src
                        }
                        let newData = { ...params.data };
                        const formData = new FormData();
                        formData.append("body", body);
                        formData.append("title", title);
                        formData.append("testPhoneNumber", testNumber);
                        formData.append("imageUrl", imageUrl);
                        const token = localStorage.getItem("Authorization");
                        return  axios.put(`${apiUrl}/notification/${params.id}`, formData, {
                            headers: {
                                Authorization: `${token}`,
                            },
                        });
                    }
                    else{
                        let body = document.getElementById("textInput").value
                        let title = document.getElementById("textInputTitle").value
                        let testNumber =params.data.testPhoneNumber
                        if(params.data.imageUrl.src==undefined){
                            var imageUrl =params.data.imageUrl
                        }else{
                            var imageUrl =params.data.imageUrl.src
                        }
                        let newData = { ...params.data };
                        const { rawFile } = params.data.file;
                        const formData = new FormData();
                        formData.append("body", body);
                        formData.append("title", title);
                        formData.append("testPhoneNumber", testNumber);
                        formData.append("file", rawFile);
                        formData.append("imageUrl", imageUrl);
                        const token = localStorage.getItem("Authorization");
                        return  axios.put(`${apiUrl}/notification/${params.id}`, formData, {
                            headers: {
                                Authorization: `${token}`,
                            },
                        });
                    }
                }else{
                }
                if (params.data.title) {
                    params.data.title=document.getElementById("textInputTitle").value
                    if (!params.data.body) {
                        return Update.defaultUpdate(resource, params);
                    } else {
                        params.data.body= document.getElementById("textInput").value
                    }
                    if (params.data.imageUrl) {
                        let newData = { ...params.data };
                        const { rawFile } = params.data.imageUrl;
                        const formData = new FormData();
                        formData.append("image", rawFile);
                        formData.append("type", "general");
                        const token = localStorage.getItem("Authorization");
                        const image = await axios.post(`${apiUrl}/image`, formData, {
                            headers: {
                                Authorization: `${token}`,
                            },
                        });
                        delete newData["simple"];
                        newData.imageUrl = image.data.result.url;
                        params.data = newData;
                        return Update.defaultUpdate(resource, params);
                    }
                    else {
                    }
                    return Update.defaultUpdate(resource, params);
                } else {
                    return Update.defaultUpdate(resource, params);
                }
            }
            case "exerciseInstruction": {
                const token = localStorage.getItem("Authorization");
                if ( params.data.videos ) {
                    let newData = { ...params.data };
                    if(params.data.images){
                        const { rawFile } = params.data.images;
                        const formDataImage = new FormData();
                        formDataImage.append("image", rawFile);
                        const pictureUpload = await axios.post(`${apiUrl}/challenge/image`, formDataImage, {
                            headers: {
                                Authorization: `${token}`,
                            },
                        });
                        delete newData["images"];
                        newData.image = pictureUpload.data.result.url;
                    }
                    const videoFile = params.data.videos.rawFile 
                    const formDataVideo = new FormData()
                    formDataVideo.append("video" , videoFile)
                    formDataVideo.append("type" , "general")
                    const videoUpload = await axios.post(`${apiUrl}/video`, formDataVideo, {
                        headers: {
                            Authorization: `${token}`,
                        },
                    });
                    delete newData["videos"];
                    newData.video = videoUpload.data.result.url
                    params.data = newData;
                    return Update.defaultUpdate(resource, params);
                } else {
                    if(params.data.images){
                        const { rawFile } = params.data.images;
                        const formDataImage = new FormData();
                        formDataImage.append("image", rawFile);
                        const pictureUpload = await axios.post(`${apiUrl}/challenge/image`, formDataImage, {
                            headers: {
                                Authorization: `${token}`,
                            },
                        });
                        delete params.data["images"];
                        params.data.image = pictureUpload.data.result.url
                        return Update.defaultUpdate(resource, params);
                    };
                    return Update.defaultUpdate(resource, params);
                }
            }
            case "plan" : 
            case "shop-package" : 
            case "promotion" : {
              if(params.data.pictures){
                let newData = { ...params.data };
                if (params.data.promotionImage) {
                    const { rawFile } = params.data.promotionImage;
                    const formData = new FormData();
                    formData.append("image", rawFile);
                    formData.append("type","general");
                    const token = localStorage.getItem("Authorization");
                    const promotion = await axios.post(`${apiUrl}/image`, formData, {
                        headers: {
                            Authorization: `${token}`,
                        },
                    })
                    delete newData["promotionImage"];
                    newData.theme.promotionImage  = promotion.data.result.url;
                }
                const { rawFile } = params.data.pictures;
                const formData = new FormData();
                formData.append("image", rawFile);
                formData.append("type","general");
                const token = localStorage.getItem("Authorization");
                const image = await axios.post(`${apiUrl}/image`, formData, {
                    headers: {
                        Authorization: `${token}`,
                    },
                });
                delete newData["pictures"];
                delete newData["imagetype"];
                newData.theme.image = image.data.result.url;
                params.data = newData;
                return httpClient(`${apiUrl}/shop/v2/${resource}/${params.id}`, {
                    method: "PUT",
                    body: JSON.stringify(params.data),
                }).then(({ json }) => ({
                    data: { ...json, id: json.id },
                }));
              }else{
                let newData = { ...params.data };
                if (params.data.promotionImage) {
                    const { rawFile } = params.data.promotionImage;
                    const formData = new FormData();
                    formData.append("image", rawFile);
                    formData.append("type","general");
                    const token = localStorage.getItem("Authorization");
                    const promotion = await axios.post(`${apiUrl}/image`, formData, {
                        headers: {
                            Authorization: `${token}`,
                        },
                    })
                    delete newData["promotionImage"];
                    newData.theme.promotionImage  = promotion.data.result.url;
                    
                }
                params.data.isDeleted = false
                params.data = newData;
                return httpClient(`${apiUrl}/shop/v2/${resource}/${params.id}`, {
                  method: "PUT",
                  body: JSON.stringify(params.data),
                }).then(({ json }) => ({
                    data: { ...params.data, id: json.result.id },
                }))
                .catch(function(error){
                    let e =error.body.error.message
                    let description = error.body.error.description
                    alert(e)  
                    alert(description)  
                    
                  })
                ;
              }	
            }
            case "plansku" : {
                return httpClient(`${apiUrl}/shop/v2/sku/`, {
                    method: "PUT",
                    body: JSON.stringify(params.data),
                }).then(({ json }) => ({
                    data: { ...params.data, id: json.objectId },
                }));
            }
            case "regularDiscountCodes" : {
                return httpClient(`${apiUrl}/shop/v2/regularDiscountCode/${params.id}`, {
                    method: "PUT",
                    body: JSON.stringify(params.data),
                }).then(({ json }) => ({
                    data: { ...params.data, id: json.objectId },
                }));
            }
            case "remoteConfig":
                if (params.data.picture) {
                    let newData = { ...params.data };
                    const { rawFile } = params.data.picture;
                    const formData = new FormData();
                    formData.append("image", rawFile);
                    const token = localStorage.getItem("Authorization");
                    const image = await axios.post(
                        `${apiUrl}/challenge/image`,
                        formData,
                        {
                            headers: {
                                Authorization: `${token}`,
                            },
                        }
                    );
                    delete newData["picture"];
                    newData.type = image.data.result.url;
                    params.data = newData;
                    return Update.defaultUpdate(resource, params);
                } else {
                    return Update.defaultUpdate(resource, params);
                }
            case "CountryCode": {
                if (params.data.icon) {
                    params.data.icon =document.getElementById("textInput").value
                    return Update.defaultUpdate(resource, params);
                } else {
                    return Update.defaultUpdate(resource, params);
                }}
            case "plan" : {
              return httpClient(`${apiUrl}/shop/v2/${resource}/${params.id}`, {
                method: "PUT",
                body: JSON.stringify(params.data),
            }).then(({ json }) => ({ data: json,}))
            }
            case "tracker": {
                console.log(params)
                return httpClient(`${serverUrl}/api/${resource}/${params.id}`, {
                    method: "PUT",
                    body: JSON.stringify(params.data),
                }).then(({json}) => ({
                    data: {...params.data, id: json._id},
                }));
            }
            case "clinicUser": {
                console.log({
                    ...params.data,
                    pregnancyActive:params.data.cycle === 'pregnancy',
                    breastFeedingActive : params.data.cycle === 'breastFeeding'
                })
                return params
                // return httpClient(`${serverUrl}/api/${resource}/${params.id}`, {
                //     method: "PUT",
                //     body: JSON.stringify(params.data),
                // }).then(({json}) => ({
                //     data: {...params.data, id: json._id},
                // }));
            }
            default:
                return httpClient(`${apiUrl}/${resource}/${params.id}`, {
                    method: "PUT",
                    body: JSON.stringify(params.data),
                }).then(({ json }) => ({ data: json,}))
        }

    },
}