import { useDataProvider, useNotify } from "react-admin";
import React, { useEffect, useState } from "react";
import {
  AppBar,
  Button,
  Dialog,
  IconButton,
  Paper,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";

export default function (props) {
  const dataProvider = useDataProvider();
  const [data, setData] = useState("");
  const notify = useNotify();
  const { record } = props;


  function _getUserDiscountsData() {
    dataProvider
      .getList("userDiscountCodes", { id: record.user.objectId })
      .then(({ data }) => {
        setData(data);
        notify("اطلاعات کدهای تخفیف دریافت شد");
      })
      .catch((error) => {
        console.log(error);
        notify(
          `خطا در دریافت اطلاعات کدهای تخفیف : ${error.message}`,
          "warning"
        );
      });
  }
  useEffect(() => {
    record && _getUserDiscountsData();
  }, [dataProvider, notify, record]);
  return (
    <>
      <DiscountDialog
        transactions={data}
        title={"تراکنش های پرداختی بانکی موفق:"}
        record={record}
      >
        <Button variant="contained" color="primary">
          کدهای تخفیف استفاده شده کاربر
        </Button>
      </DiscountDialog>
    </>
  );
}

const DiscountDialog = (props) => {
  const [open, setOpen] = useState(false);
  const { transactions: discountCodes = [], title } = props;
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const renderChildren = () => {
    return React.Children.map(props.children, (child) => {
      return React.cloneElement(child, {
        onClick: handleClick,
        fullWidth: true,
      });
    });
  };
  return (
    <>
      {renderChildren()}
      <Dialog
        open={open}
        onClose={handleDialogClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        fullScreen
        TransitionComponent={Transition}
      >
        <AppBar style={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleDialogClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="body1" gutterTop style={{ margin: "0.5em" }}>
              {title}
            </Typography>
          </Toolbar>
        </AppBar>
        <Paper>
          {discountCodes && discountCodes.length === 0 ? (
            <Typography
              variant="body1"
              gutterBottom
              style={{ margin: "0.5em" }}
            >
              کدی استفاده نشده
            </Typography>
          ) : (
            <>
              <Table aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell align="right">کد</TableCell>
                    <TableCell align="right">استفاده شده</TableCell>
                    <TableCell align="right">آیدی پرداخت</TableCell>
                    <TableCell align="right">تاریخ استفاده</TableCell>
                    <TableCell align="right">پیگیری</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <>
                    {discountCodes &&
                      discountCodes.map((discount, index) => (
                        <TableRow key={index}>
                          <TableCell align="center">{discount?.code}</TableCell>
                          <TableCell align="center">
                            {discount?.used ? (
                              <DoneIcon style={{ color: green[500] }} />
                            ) : (
                              <CloseIcon style={{ color: red[500] }} />
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {discount?.transactionId}
                          </TableCell>
                          <TableCell align="center">
                            {new Date(
                              discount?.usedDate || "-"
                            ).toLocaleDateString("fa-IR")}
                          </TableCell>

                          <TableCell align="center">
                            <a
                              target={"_blank"}
                              rel="noopener noreferrer"
                              href={discount?.paymentUrl || "#"}
                            >
                              پیگیری
                            </a>
                          </TableCell>
                        </TableRow>
                      ))}
                  </>
                </TableBody>
              </Table>
            </>
          )}
        </Paper>
      </Dialog>
    </>
  );
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
