import { SERVER } from "../Constants";
import {httpClient} from "../utils/request";
import { stringify } from "query-string";
const apiUrl = SERVER;

export function getManyReference(resource, params) {
  const { page, perPage } = params.pagination;
  const { field, order } = params.sort;
  const query = {
    sort: JSON.stringify([field, order]),
    range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
    filter: JSON.stringify({
      ...params.filter,
      [params.target]: params.id,
    }),
  };
  const url = `${apiUrl}/${resource}?${stringify(query)}`;
  return httpClient(url).then(({ headers, json }) => ({
    data: json,
    total: parseInt(headers.get("X-Total-Count").split("/").pop(), 10),
  }));
};
