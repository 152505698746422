import React from "react";
import {BooleanField, Datagrid, DateField, Filter, List, SimpleShowLayout, TextField, TextInput,required} from "react-admin";
import EditView from "../../Components/EditView";
import CreateView from "../../Components/CreateView";
import ShowView from "../../Components/ShowView";

const CategoryFilter = (props) => (
  <Filter {...props}>
    <TextInput label="جست‌وجو" source="name" alwaysOn />
  </Filter>
);

export const FoodcategoryList = (props) => (
  <List {...props} filters={<CategoryFilter />} perPage={15}>
    <Datagrid rowClick="show">
      <TextField source="name" />
      <TextField source="id" />
      <DateField locales="fa-IR" source="updatedAt" />
      <BooleanField source="isDeleted" />
    </Datagrid>
  </List>
);

export const FoodcategoryEdit = (props) => (
  <EditView {...props}>
    <TextInput source="name" validate={required()} />
    <TextField source="id" />
  </EditView>
);

export const FoodcategoryCreate = (props) => (
  <CreateView {...props}>
    <TextInput  source="name"    validate={required()}/>
  </CreateView>
);
export const FoodcategoryShow = (props) => (
  <ShowView {...props}>
    <SimpleShowLayout>
      <TextField source="name" />
      <DateField locales="fa-IR" source="updatedAt" />
      <TextField source="id" />
      <BooleanField source="isDeleted" />
    </SimpleShowLayout>
  </ShowView>
);
