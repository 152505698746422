import React from "react";
import {
  BooleanField,
  BooleanInput,
  Datagrid,
  ImageField,
  ImageInput,
  List,
  NumberField,
  NumberInput,
  SelectInput,
  SimpleShowLayout,
  TextField,
  TextInput,
} from "react-admin";
import EditView from "../../Components/EditView";
import ShowView from "../../Components/ShowView";
import CreateView from "../../Components/CreateView";
import { UpdatePopUpData_Type} from "../../Constants/choices";

export const updatePopupdataList = (props) => (
  <List {...props}>
    <Datagrid rowClick="show">
      <TextField source="name" />
      <TextField source="mainHeader" />
      <TextField source="mainHeaderSubtitle" />
      <TextField source="footerTitle" />
      <TextField source="footerSubtitle" />
      <TextField source="callToAction" />
      <TextField source="outOfBox" />
      <TextField source="discountLabel" />
      <BooleanField source="shopButton" />
      <TextField source="dismissButton" />
      <NumberField source="priority"/>
      <NumberField source="androidVersionCode"/>
      <BooleanField source="active" />
      <TextField source="type" />
      <TextField source="id" />
      
    </Datagrid>
  </List>
);

export const updatePopupdataShow = (props) => (
  <ShowView {...props}>
    <SimpleShowLayout>
    <TextField source="id" />
      <TextField source="name" />
      <ImageField source="headerImageUrl"/>
      <TextField source="mainHeader" />
      <TextField source="mainHeaderSubtitle" />
      <TextField source="footerTitle" />
      <TextField source="footerSubtitle" />
      <TextField source="callToAction" />
      <TextField source="outOfBox" />
      <TextField source="discountLabel" />
      <BooleanField source="shopButton" />
      <TextField source="dismissButton" />
      <NumberField source="priority"/>
      <NumberField source="androidVersionCode"/>
      <BooleanField source="active" />
      <TextField source="type" />
    </SimpleShowLayout>
  </ShowView>
);

export const updatePopupdataEdit = (props) => (
  <EditView {...props}>
      <TextInput source="name" />
      <ImageInput source="headerImageUrl" label="تصویر مربوطه" accept="image/*">
        <ImageField source="src" />
      </ImageInput>
      <TextInput source="mainHeader" />
      <TextInput source="mainHeaderSubtitle" />
      <TextInput source="footerTitle" />
      <TextInput source="footerSubtitle" />
      <TextInput source="callToAction" />
      <TextInput source="outOfBox" />
      <TextInput source="discountLabel" />
      <BooleanInput source="shopButton" label="دکمه خرید" />
      <TextInput source="dismissButton" />
      <NumberInput source="priority"/>
      <NumberInput source="androidVersionCode"/>
      <BooleanInput source="active" label=" فعال "  />
      <SelectInput source="type" choices={UpdatePopUpData_Type} />
  </EditView>
);

export const updatePopupdataCreate = (props) => (
  <CreateView {...props}>
      <TextInput source="name" />
      <ImageInput source="headerImageUrl" label="تصویر مربوطه" accept="image/*">
        <ImageField source="src" />
      </ImageInput>
      <TextInput source="mainHeader" />
      <TextInput source="mainHeaderSubtitle" />
      <TextInput source="footerTitle" />
      <TextInput source="footerSubtitle" />
      <TextInput source="callToAction" />
      <TextInput source="outOfBox" />
      <TextInput source="discountLabel" />
      <BooleanInput source="shopButton" label="دکمه خرید" />
      <TextInput source="dismissButton" />
      <NumberInput source="priority"/>
      <NumberInput source="androidVersionCode"/>
      <BooleanInput source="active" label=" فعال "  />
      <SelectInput source="type" choices={UpdatePopUpData_Type} />
  </CreateView>
);
