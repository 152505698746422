import React, {useState} from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,  Typography,
} from "@material-ui/core";
import {useNotify, Loading,DateField} from "react-admin";
import {SERVER_DOMAIN} from "../../Constants";
import { axiosFetcher } from "../../utils/axiosFetcher";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import {toEnglishNumber} from "../../utils/utils";

export function UserPackeges() {
  const notify = useNotify()
  const [loading, setLoading] = useState(false);
  const [showTable,setShowTable] = useState(false)
  const [tableData,setTableData] = useState([])
  const [number,setNumber]= useState()
  function ChangeNumber(e) {
    setNumber(e.target.value)
  }
  function submitClick(){
    const url = `${SERVER_DOMAIN}/admin/shop/v2/shop-package/client`;
    setLoading(true);
    axiosFetcher
      .post(url, {
        "phoneNumber" : toEnglishNumber(number)
      })
      .then((res) => {
        setLoading(false);
        notify("انجام شد")
        setShowTable(true)
        setTableData(res.data.result.shopPackages)
        console.log(res.data.result.shopPackages);
      })
      .catch(() => {
        setLoading(false);
        notify("به مشکلی برخوردیم")

      });
  }
  return (
    <>
      <Card style={{marginTop:"2rem"}}>
        <CardHeader title="جستجوی پکیچ های یک کاربر با شماره همراه"/>
        <Typography style={{marginRight:"1.2rem"}}>شماره کاربر مورد نظر را وارد کنید </Typography>
        <CardContent>
          <TextField
            onChange={ChangeNumber}
            label="شماره"
            variant="filled"
          />
          <form >
            <Button variant="contained"  color="primary" onClick={submitClick}  style={{marginTop:"1rem"}}>جستجو </Button>
          </form>
        </CardContent>
        {loading===true ?<Loading/> : null}
        {showTable===true ?  <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">نام</TableCell>
              <TableCell align="center">نوع اشتراک</TableCell>
              <TableCell align="center">عنوان </TableCell>
              <TableCell align="center">مدت پکیج  </TableCell>
              <TableCell align="center"> ویژه</TableCell>
              <TableCell align="center">الویت</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map( (id,index) => (
              <TableRow>
                <TableCell align="center">{id.shopPackageName}</TableCell>
              
                <TableCell align="center">{id.shopPackageSubscriptionType}</TableCell>
                <TableCell align="center">{id.theme.title}</TableCell>
                <TableCell align="center">{id.theme.planName}</TableCell>
                <TableCell align="center">{String(id.theme.isSpecial) }</TableCell>
                <TableCell align="center">{id.order}</TableCell>
                {/* <TableCell align="center">{id.target.price}</TableCell>
                <TableCell align="center" >{String(id.consumed) }</TableCell>
                <TableCell align="center">
                  <DateField
                    record={tableData[index]}
                    locales="fa-IR"
                    source="create_time"
                  />
                </TableCell> */}
              </TableRow>
            ))}
          </TableBody>
        </Table>:null}
      </Card>
    </>
  );
}

