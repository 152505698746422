import React, { useState } from "react";
import {Button, Card, CardContent} from "@material-ui/core";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useNotify ,Loading} from "react-admin";
import { SERVER_DOMAIN } from "../../Constants";
import { axiosFetcher } from "../../utils/axiosFetcher";
import TextField from "@material-ui/core/TextField";
import Checkbox from '@material-ui/core/Checkbox';
import { Experiments } from "./Components/Experiment";
  
export const ABTestCreate = () => {
  const [openExperiment, setOpenExperiment] = useState(false);
    const notify = useNotify();
    const [active, setActive] = useState(true);
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [androidMinVersion, setAndroidMinVersion] = useState("");
    const [iosMinVersion, setIosMinVersion] = useState("");
    const [loading, setLoading]= useState(false)
    function activeChange(e){
        setActive(e.target.checked);
    }
    function nameChange(e){
       setName(e.target.value)
    }
    function descriptionChange(e){
      setDescription(e.target.value)
    }
    function androidMinVersionChange(e){
      setAndroidMinVersion(e.target.value)
    }
    function iosMinVersionChange(e){
      setIosMinVersion(e.target.value)
    }
    const [ ABcratedID, setABcratedID] = useState({
      id: "123"
    });
    const experimentHistory  = {}
    function submitClick() {
        setLoading(true);
        axiosFetcher
        .post(`${SERVER_DOMAIN}/admin/abtest`, {
            name : name ,
            description : description ,
            active:active,
            androidMinVersion : androidMinVersion,
            iosMinVersion : iosMinVersion,
        })
        .then((res) => {
          setLoading(false);
          setOpenExperiment(true);
          notify("A/B تست ساخته شد");
          setABcratedID(res.data);
        })
        .catch(() => {
          setLoading(false);
          notify("به مشکلی برخوردیم")
        })
    }
    return (
      <>
        <Card style={{ marginTop: "2rem" }}>
          <CardContent>
            <form  style={{ display:"flex", flexDirection : "column" , width :"256px" , alignItems:"flex-start"}}>
              <FormControlLabel style={{direction:"ltr", margin: "1em"}}
                    control={<Checkbox checked={active} onChange={activeChange}  />}
                    label="فعال"
                />
              <TextField id="filled-basic" label="نام" variant="filled" value={name} onChange={nameChange} />
              <span style={{ margin: "1em" }} />
              <TextField id="filled-basic" label="توضیحات" variant="filled" onChange={descriptionChange} value={description} />
              <span style={{ margin: "1em" }} />
              <TextField id="filled-basic" label="حداقل ورژن اندروید" type="number" onChange={androidMinVersionChange} variant="filled" value={androidMinVersion} />
              <span style={{ margin: "1em" }} />
              <TextField id="filled-basic" label="حداقل ورژن IOS" type="number" onChange={iosMinVersionChange} variant="filled" value={iosMinVersion} />
              <span style={{ margin: "1em" }} />
              <Button type="submit" variant="contained" color="primary" onClick={submitClick}  style={{marginTop:"1rem"}}>ذخیره و اضافه کردن اکسپریمنت </Button>
            </form>
            {openExperiment===true ? <Experiments record={ABcratedID}  openExperiment={true} history={experimentHistory} /> : null }
            {loading===true ?<Loading/> : null}
          </CardContent>
        </Card>
      </>
    );
  };