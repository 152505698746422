import React, { useState } from "react";
import {
  ArrayField,
  BooleanInput,
  Datagrid, DateField,
  Edit,
  Labeled,
  NumberInput,
  ReferenceArrayInput,
  SelectInput,
  SimpleForm,
  TextField,
  usePermissions,
  useTranslate,
} from "react-admin";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { MaterialDateInput } from "../../Components/RaMaterialDatePickers";
import UserTransactions from "./Components/UserTransactions";
import SendUserPopUp from "./Components/SendUserPopUp";
import {
  Box,
  Button,
  Card,
  Drawer,
  Grid,
  Paper,
  useMediaQuery,
} from "@material-ui/core";
import { ROLES } from "../../Constants";
import {
  USER_ACTIVITY_LEVEL_CHOICES,
  USER_BREAST_FEEDING_STATE_CHOICES,
  USER_GENDER_CHOICES,
} from "../../Constants/choices";
import UserDiscountCodes from "./Components/UserDiscountCodes";
import UserLogs from "./Components/UserLogs";
import { axiosFetcher } from "../../utils/axiosFetcher";
let user = {
  phone:null,
  a:null
}

function Aside(props) {
  const permission = usePermissions();
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [isOpen, setIsOpen] = React.useState(false);
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setIsOpen(open);
  };
  let anchor = "right";
  return (
    <Card
      style={{
        margin: "0 2em",
        padding: "1em",
        minWidth: "15vw",
      }}
    >
      {isSmall ? (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button>
          <Drawer
            anchor={anchor}
            open={isOpen}
            onClose={toggleDrawer(anchor, false)}
          >
            <Box style={{ padding: "0 2em" }}>
              {permission.permissions === ROLES.SUPER_ADMIN ? (
                <SendUserPopUp {...props} />
              ) : null}
              <UserTransactions {...props} />
            </Box>
          </Drawer>
        </React.Fragment>
      ) : (
        <>
          {permission.permissions === ROLES.SUPER_ADMIN ? (
            <SendUserPopUp {...props} />
          ) : null}
          <UserTransactions {...props} />
          <UserDiscountCodes {...props} />
          <UserLogs {...props} />
        </>
      )}
    </Card>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: "2em",
  },
  paper: {
    padding: theme.spacing(3),
    color: theme.palette.text.secondary,
    backgroundColor: "#eee",
  },
}));

const UserMeta = (props) => {
  const translate = useTranslate();
  const classes = useStyles();
  const fields = [
    "user.name",
    "user.phoneNumber",
    "user.versionName",
    "user.versionCode",
  ];

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        {fields.map((field) => (
          <Grid item xs>
            <Paper className={classes.paper}>
              <Labeled label={translate(`resources.user.fields.${field}`)}>
                <TextField
                  resource={props.resource}
                  record={props.record}
                  source={field}
                />
              </Labeled>
            </Paper>
          </Grid>
        ))}
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Paper className={classes.paper} xs={6}>
            <Labeled label={translate(`resources.user.fields.user.fcmToken`)}>
              <TextField
                multiline={true}
                resource={props.resource}
                record={props.record}
                source={"user.fcmToken"}
                style={{
                  wordBreak: "break-all",
                }}
              />
            </Labeled>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export const UserEdit = (props) => {
  const [userPageData, setUserPageData] = useState([]);
  const userPageDataShow={
    pagesDataTypes:[{page:null,type:null}]
  }
  console.log(props);
  const permission = usePermissions();
  user.phone = props.id
  function userPageDataChange(e) {
    setUserPageData(e.target.value);
  }
  function send() {
    axiosFetcher
      .put(`/editUserPagesData/${props.id}`, {
        pageDataTypeIds: userPageData,
      })
  }

  function userPageDataget(){
    axiosFetcher
    .get(`/getUserPagesData/${user.phone}`)
    .then(() => {
      document.getElementById("nextPageTable").style.display="table"
    })
  }

  return (
    <>
      <Edit
        {...props}
        transform={(data) => {
          let s = new Date(data.user.birthdate).getTime();
          return { ...data, user: { ...data.user, birthdate: s } };
        }}
        aside={<Aside />}
      >
        <SimpleForm redirect={null}>
          {/*{permission.permissions !== ROLES.USERS_ADMIN ? (*/}
            <UserMeta {...props} />
          {/*) : null}*/}
          <DateField locales="fa-IR" source="user.registeredDate"/>
          <NumberInput source="user.weight" />
          <NumberInput source="user.height" />
          <MaterialDateInput source="user.birthdate" />
          <SelectInput source="user.activityLevel" choices={USER_ACTIVITY_LEVEL_CHOICES}/>
          <SelectInput source="user.breastFeedingState" choices={USER_BREAST_FEEDING_STATE_CHOICES}/>
          <SelectInput source="user.sex" choices={USER_GENDER_CHOICES} />
          <BooleanInput source="user.isRamadan" defaultValue={false} />
          <TextField source="user.market"/>
          <Card style={{ backgroundColor: "#eee", paddingLeft: "10px" }}>
            <ReferenceArrayInput
              onChange={userPageDataChange}
              style={{ width: "100%" }}
              source="editUserPagesData"
              reference="pageDataType"
            >
              <SelectInput optionText="id" />
            </ReferenceArrayInput> 
            <Button
              style={{
                backgroundColor: "rgba(46, 125, 50, 1)",
                color: "white",
              }}
              onClick={send}
            >
              ذخیره
            </Button>
          </Card>
          <Button
              style={{
                backgroundColor: "rgba(46, 125, 50, 1)",
                color: "white",
                marginTop:"10px"
              }}
              onClick={userPageDataget}
            >
            مشاهده سناریوها
            </Button> 
         <Table aria-label="a dense table"  fullWidth={true} id="nextPageTable" style={{margin:"10px 0",display:"none"}}>
            <TableHead>
              <TableRow>
                <TableCell align="right">type</TableCell>
                <TableCell align="right">page</TableCell>
                <TableCell align="right">id</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="center">
                {userPageDataShow.pagesDataTypes[0].type}
                </TableCell>
                <TableCell align="center">
                {userPageDataShow.pagesDataTypes[0].page}
                </TableCell>
                <TableCell align="center">
                {userPageDataShow._id}
                </TableCell>
              </TableRow>
            </TableBody>
            </Table>
          <ArrayField source="editLogs" fullWidth={true}>
            <Datagrid>
              <TextField source={"tag"} />
              <TextField source={"content"} />
            </Datagrid>
          </ArrayField>
        </SimpleForm>
      </Edit>
    </>
  );
};
