import React from "react";
import {
    Datagrid,
    List,
    SimpleShowLayout,
    TextField,
    TextInput,
} from "react-admin";
import ShowView from "../../Components/ShowView";
import EditView from "../../Components/EditView";
import CreateView from "../../Components/CreateView";

export const SKUList = (props) => (
    <List {...props}>
        <Datagrid >
            <TextField source="sku" />
            <TextField source="price" />
            <TextField source="id" />
        </Datagrid>
    </List>
);

export const SKUShow = (props) => (
    <ShowView {...props}>
        <SimpleShowLayout>
            <TextField source="name" />
            <TextField source="id" />
        </SimpleShowLayout>
    </ShowView>
);

export const SKUEdit = (props) => (
    <EditView {...props}>
        <TextInput source="name" />
    </EditView>
);

export const SKUCreate = (props) => (
    <CreateView {...props}>
        <TextInput source="name"  />
    </CreateView>
);
