import React from "react";
import {
    Datagrid,
    List,
    NumberField,
    NumberInput,
    SimpleShowLayout,
    TextField,
    BooleanInput,
    BooleanField,
    TextInput,
    Button,
} from "react-admin";

import { Experiments } from "./Components/Experiment";
import ShowView from "../../Components/ShowView";
import EditView from "../../Components/EditView";

export const ABTestList = (props) => (
  <List {...props} >
    <Datagrid rowClick="show">
      <TextField    source="description" />
      <TextField    source="name"  />
      <NumberField  source="androidMinVersion"  textAlign="center"/>
      <NumberField  source="iosMinVersion" textAlign="center" />
      <BooleanField source="active"  />
      <TextField    source="id" />
    </Datagrid>
  </List>
);

export const ABTestEdit = (props) => (
  <EditView {...props}>
    <BooleanInput source="active" defaultValue="true"/>
    <TextInput source="name" />
    <TextInput source="description" />
    <NumberInput source="iosMinVersion" />
    <NumberInput source="androidMinVersion" />
  </EditView>
);

export const ABTestShow = (props) => (
  <ShowView {...props}>
    <SimpleShowLayout>
      <BooleanField source="active" />
      <TextField    source="name" />
      <TextField    source="description" />
      <NumberField  source="iosMinVersion" />
      <NumberField  source="androidMinVersion" />
      <TextField    source="id" />
      <Experiments> 
        <Button  label="Experiment لیست" variant="contained"/>
      </Experiments>
    </SimpleShowLayout>
  </ShowView>
);
