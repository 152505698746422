import React, {memo} from "react";
import Chip from "@material-ui/core/Chip";
import Box from "@material-ui/core/Box";
import get from "lodash/get";

export const ArrayTextField = memo(
  ({ className, source, record = {}, emptyText, ...rest }) => {
    const value = get(record, source);
    return (
      <Box>
        {Array.isArray(value) &&
          value.map((text, index) => (
            <Chip
              component="span"
              className={className}
              {...rest}
              style={{ margin: "3px" }}
              key={index}
              label={text}
            />
          ))}
      </Box>
    );
  }
);
ArrayTextField.propTypes = {};
ArrayTextField.displayName = "TextField";
ArrayTextField.defaultProps = {
  addLabel: true,
};
