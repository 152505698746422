import React from 'react';
import PropTypes from 'prop-types';

const TextField = ({ source, record = {}, choices }) => <>
    {
    // console.log(choices.filter(item => (String(item.id).toLowerCase() == String(record[source]).toLowerCase())).length > 0 ?  choices.filter(item => (String(item.id).toLowerCase() == String(record[source]).toLowerCase()))[0].name : choices[choices.length - 1].default)
    }
    <span>{choices.filter(item => (String(item.id).toLowerCase() == String(record[source]).toLowerCase())).length > 0 ?  choices.filter(item => (String(item.id).toLowerCase() == String(record[source]).toLowerCase()))[0].name : choices[choices.length - 1].default}</span>
</>

TextField.defaultProps = {
    //  label: ,
    addLabel: true,
};

TextField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};



export default TextField;