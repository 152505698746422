import {SERVER, SERVER_DOMAIN} from "../Constants";
import {httpClient} from "../utils/request";
import axios from "axios";
import {dataProvider} from "./dataProvider";
const apiUrl = SERVER;
const serverUrl = SERVER_DOMAIN;
export const Create = {
  defaultCreate : function (resource, params){
		return  httpClient(`${apiUrl}/${resource}`, {
				method: "POST",
				body: JSON.stringify(params.data),
			}).then(({json}) => ({
				data: {...params.data, id: json.id},
			}));
		},
	create:async (resource, params) => {
		switch (resource) {
			case "food": {
				let newParams = { ...params };
				try {
					let similarNames = [];
						if (
							newParams.data.similarNames &&
							newParams.data.similarNames.length > 0
						) 
						{
							newParams.data.similarNames.forEach(({ name }) => {
								similarNames.push(name);
							});
							newParams.data = { ...newParams.data, similarNames };
						}
						return Create.defaultCreate(resource, params);
				} 
				catch (err) {
					throw new Error(err);
				}
			}

			// $$$ ↓↓↓ SHOP ↓↓↓ $$$
			case "plan" : 
			case "shop-package" :
			case "promotion" : {				
				if(params.data.isDefault) {
					delete params.data.conditions
				}
				if(params.data.pictures){
					let newData = { ...params.data };
					if (params.data.promotionImage) {
						const { rawFile } = params.data.promotionImage;
						const formData = new FormData();
						formData.append("image", rawFile);
						formData.append("type","general");
						const token = localStorage.getItem("Authorization");
						const promotion = await axios.post(`${apiUrl}/image`, formData, {
							headers: {
								Authorization: `${token}`,
							},
						})
						delete newData["promotionImage"];
						newData.theme.promotionImage  = promotion.data.result.url;
					}
					const { rawFile } = params.data.pictures;
					const formData = new FormData();
					formData.append("image", rawFile);
					formData.append("type","general");
					const token = localStorage.getItem("Authorization");
					const image = await axios.post(`${apiUrl}/image`, formData, {
						headers: {
							Authorization: `${token}`,
						},
					});
					delete newData["pictures"];
					delete newData["imagetype"];
					newData.theme.image = image.data.result.url;
					params.data = newData;
					return httpClient(`${apiUrl}/shop/v2/${resource}`, {
							method: "POST",
							body: JSON.stringify(params.data),
					}).then(({ json }) => ({
							data: { ...json, id: json.id },
					}));
				}else{
					let newData = { ...params.data };
					if (params.data.promotionImage) {
						const { rawFile } = params.data.promotionImage;
						const formData = new FormData();
						formData.append("image", rawFile);
						formData.append("type","general");
						const token = localStorage.getItem("Authorization");
						const promotion = await axios.post(`${apiUrl}/image`, formData, {
							headers: {
								Authorization: `${token}`,
							},
						})
						delete newData["promotionImage"];
						newData.theme.promotionImage  = promotion.data.result.url;
						
					}
					params.data = newData;
					return httpClient(`${apiUrl}/shop/v2/${resource}/`, {
						method: "POST",
						body: JSON.stringify(params.data),
					}).then(({ json }) => ({
							data: { ...params.data, id: json.id },
					}))
					.catch(function(error){
						let e =error.body.error.message
						let description = error.body.error.description
						alert(e)  
						alert(description)  
					});
				}	
			}
			case "bulkDiscountCodes" : {
				let newData = { ...params.data };
				let rawFile = params.data.files ? params.data.files.rawFile : null;
				const formData = new FormData();
				formData.append("file", rawFile);
				delete newData["files"];
				let url = `${apiUrl}/shop/v2/bulkDiscountCode?`;
				formData.append("data", JSON.stringify(newData));
				return httpClient(url, {
						method: "POST",
						body: formData,
				}).then(({ json }) => ({
						data: { list: json, id: json.id },
				}));
			}
			case "regularDiscountCodes" : {
				return httpClient(`${apiUrl}/shop/v2/regularDiscountCode`, {
					method: "POST",
					body: JSON.stringify(params.data),
				}).then(({ json }) => ({
					data: { ...params.data, id: json.id },
				}));		
			}
			case "plansku" : {
				return httpClient(`${apiUrl}/shop/v2/sku/`, {
								method: "POST",
								body: JSON.stringify(params.data),
						}).then(({ json }) => ({
								data: { ...params.data, id: json.objectId },
				}));
			}
			case "adminUsers":
				return httpClient(`${apiUrl}/${resource}`, {
								method: "POST",
								body: JSON.stringify(params.data),
						}).then(({ json }) => ({
								data: { ...params.data, id: json.phoneNumber },
						}));
			case "note":
				return httpClient(`${apiUrl}/${resource}/${params.id}`, {
							method: "POST",
							body: JSON.stringify(params.data),
					}).then(({ json }) => ({
							data: { ...json },
					}));
			case "subscription":
				return httpClient(`${apiUrl}/user/${params.id}/${resource}`, {
							method: "POST",
							body: JSON.stringify(params.data),
					}).then(({ json }) => ({
							data: { ...json },
					}));
			case "revenuecat":
				return httpClient(`${apiUrl}/user/${params.id}/${resource}`, {
									method: "POST",
									body: JSON.stringify(params.data),
							}).then(({ json }) => ({
									data: { ...json },
							}));
			case "report/users/old/":
			case "getUsersTransaction":
			case "old_non_registered_karafs_users":{
				if(params.data.files){
							let newData = { ...params.data };
							let rawFile = params.data.files ? params.data.files.rawFile : null;
							const formData = new FormData();
							formData.append("file", rawFile);
							delete newData["files"];
							let url = `${apiUrl}/${resource}?`;
							if (newData.from) {
									url += 'from=' + newData.from + '&';
							}
							if (newData.to) {
									url += 'to=' + newData.to;
							}
							formData.append("data", JSON.stringify(newData));
							return httpClient(url, {
									method: "POST",
									body: formData,
							}).then(({ json }) => ({
									data: { list: json, id: 123 },
							}));
					}}
			case "bulkDiscountCode":{
				let newData = { ...params.data };
				let rawFile = params.data.files ? params.data.files.rawFile : null;
				const formData = new FormData();
				formData.append("file", rawFile);
				delete newData["files"];
				let url = `${apiUrl}/${resource}?`;
				formData.append("data", JSON.stringify(newData));
				return httpClient(url, {
						method: "POST",
						body: formData,
				}).then(({ json }) => ({
						data: { list: json, id: json._id },
				}));
			}
			case "updatePopUpData":{
					if (params.data.headerImageUrl) {
							let newData = { ...params.data };
							const { rawFile } = params.data.headerImageUrl;
							const formData = new FormData();
							formData.append("image", rawFile);
							formData.append("type", "general");
							const token = localStorage.getItem("Authorization");
							const image = await axios.post(`${apiUrl}/image`, formData, {
									headers: {
											Authorization: `${token}`,
									},
							});
							delete newData["headerImageUrl"];
							newData.headerImageUrl = image.data.result.url;
							params.data = newData;
							return Create.defaultCreate(resource, params);
					} else {
							return Create.defaultCreate(resource, params);
					}
			}
			case "popUpData":{
				if (params.data.pictures) {
					let newData = { ...params.data };
					const { rawFile } = params.data.pictures;
					const formData = new FormData();
					formData.append("image", rawFile);
					formData.append("type", params.data.imagetype);
					const token = localStorage.getItem("Authorization");
					const image = await axios.post(`${apiUrl}/image`, formData, {
						headers: {
								Authorization: `${token}`,
						},
					});
					delete newData["pictures"];
					delete newData["imagetype"];
					newData.headerImageUrl = image.data.result.url;
					params.data = newData;
					return httpClient(`${apiUrl}/${resource}`, {
							method: "POST",
							body: JSON.stringify(params.data),
					}).then(({ json }) => ({
							data: { ...json, id: json._id },
					}));
				}else {
					return httpClient(`${apiUrl}/${resource}`, {
							method: "POST",
							body: JSON.stringify(params.data),
					}).then(({ json }) => ({
							data: { ...json, id: json._id },
					}));
				}
			}
			case "getToKnow":{
					if (params.data.icon) {
							let newData = { ...params.data };
							const { rawFile } = params.data.icon;
							const formData = new FormData();
							formData.append("image", rawFile);
							formData.append("type", "general");
							const token = localStorage.getItem("Authorization");
							const image = await axios.post(`${apiUrl}/image`, formData, {
									headers: {
											Authorization: `${token}`,
									},
							});
							delete newData["icon"];
							newData.icon = image.data.result.url;
							params.data = newData;
							return Create.defaultCreate(resource, params);
					} else {
							return Create.defaultCreate(resource, params);
					}
			}
			case "campaignCard":{
					if (params.data.image) {
							let newData = { ...params.data };
							const { rawFile } = params.data.image;
							const formData = new FormData();
							formData.append("image", rawFile);
							formData.append("type", "general");
							const token = localStorage.getItem("Authorization");
							const image = await axios.post(`${apiUrl}/image`, formData, {
									headers: {
											Authorization: `${token}`,
									},
							});
							delete newData["image"];
							newData.image = image.data.result.url;
							params.data = newData;
							return Create.defaultCreate(resource, params);
					} else {
							return Create.defaultCreate(resource, params);
					}
			}
			case "notification":{
					if(params.data.file){
							let body = document.getElementById("textInput").value
							let title = document.getElementById("textInputTitle").value
							let testNumber =params.data.testPhoneNumber
							let imageUrl =params.data.imageUrl.src
							const { rawFile } = params.data.file;
							const formData = new FormData();
							formData.append("file", rawFile);
							formData.append("title", title);
							formData.append("body", body);
							formData.append("testPhoneNumber", testNumber);
							formData.append("imageUrl", imageUrl);
							const token = localStorage.getItem("Authorization");
							return  axios.post(`${apiUrl}/notification`, formData, {
									headers: {
											Authorization: `${token}`,
									},
							});
					}
					if (params.data.title) {
							if (!params.data.body) {
									return Create.defaultCreate(resource, params);
							}else{
									params.data.body= document.getElementById("textInput").value
							}
							if (params.data.imageUrl) {
									let newData = { ...params.data };
									const { rawFile } = params.data.imageUrl;
									const formData = new FormData();
									formData.append("image", rawFile);
									formData.append("type", "general");
									const token = localStorage.getItem("Authorization");
									const image = await axios.post(`${apiUrl}/image`, formData, {
											headers: {
													Authorization: `${token}`,
											},
									});
									delete newData["simple"];
									newData.imageUrl = image.data.result.url;
									params.data = newData;
									return Create.defaultCreate(resource, params);
							}
							params.data.title=document.getElementById("textInputTitle").value
							return Create.defaultCreate(resource, params);
					} else {
							return Create.defaultCreate(resource, params);
					}
			}
			case "transaction":{
					return httpClient(`${apiUrl}/${resource}/${params.id}/cancel`, {
							method: "POST",
							body: JSON.stringify(params.data),
					}).then(({ json }) => ({
							data: params.data,
					}));
			}
			case "foodRecipe":
					if (params.data.pictures) {
							let newData = { ...params.data };
							const { rawFile } = params.data.pictures;
							const formData = new FormData();
							formData.append("image", rawFile);
							const token = localStorage.getItem("Authorization");
							const image = await axios.post(
									`${apiUrl}/challenge/image`,
									formData,
									{
											headers: {
													Authorization: `${token}`,
											},
									}
							);
							delete newData["pictures"];
							newData.imageUrl = image.data.result.url;
							params.data = newData;
							return Create.defaultCreate(resource, params);
					} else {
							return Create.defaultCreate(resource, params);
			}
			case "challenge":
					if (params.data.pictures) {
							let newData = { ...params.data };
							const { rawFile } = params.data.pictures;
							const formData = new FormData();
							formData.append("image", rawFile);
							const token = localStorage.getItem("Authorization");
							const image = await axios.post(
									`${apiUrl}/challenge/image`,
									formData,
									{
											headers: {
													Authorization: `${token}`,
											},
									}
							);
							delete newData["pictures"];
							if (params.data.description) {
									newData.description=document.getElementById("textInput").value	
							}
							if(params.data.isDeleted===undefined){
									newData.isDeleted = false
							}
							if(params.data.isPremium===undefined){
									newData.isPremium = false
							}
						
							newData.imageId = image.data.result.url;
							params.data = newData;
							return Create.defaultCreate(resource, params);
					} else {
							if (params.data.description) {
									params.data.description=document.getElementById("textInput").value
							}
							return Create.defaultCreate(resource, params);
			}
			case "packagePricing": {
				if (params.data.pictures) {
					let newData = { ...params.data };
					const { rawFile } = params.data.pictures;
					const formData = new FormData();
					formData.append("image", rawFile);
					formData.append("type", "pricing");
					const token = localStorage.getItem("Authorization");
					const image = await axios.post(`${apiUrl}/image`, formData, {
							headers: {
									Authorization: `${token}`,
							},
					});
					delete newData["pictures"];
					newData.image = image.data.result.url;
					params.data = newData;
					return Create.defaultCreate(resource, params);
				} else {
					return Create.defaultCreate(resource, params);
				}
			}
			case "exerciseInstructionCategory": {
					if (params.data.pictures) {
							let newData = { ...params.data };
							const { rawFile } = params.data.pictures;
							const formData = new FormData();
							formData.append("image", rawFile);
							formData.append("type", "teaching");
							const token = localStorage.getItem("Authorization");
							const image = await axios.post(`${apiUrl}/image`, formData, {
									headers: {
											Authorization: `${token}`,
									},
							});
							delete newData["pictures"];
							newData.image = image.data.result.url;
							params.data = newData;
							return Create.defaultCreate(resource, params);
					} else {
							return Create.defaultCreate(resource, params);
					}
			}
			case "exerciseInstruction": {
					if (  params.data.image) {
							const token = localStorage.getItem("Authorization");
							let newData = { ...params.data };
							const { rawFile } = params.data.image;
							// const videoFile = params.data.video.rawFile 
							const formDataImage = new FormData();
							formDataImage.append("image", rawFile);
							const formDataVideo = new FormData()
							// formDataVideo.append("video" , videoFile)
							formDataVideo.append("type" , "general")	
							// const videoUpload = await axios.post(`${apiUrl}/video`, formDataVideo, {
							//     headers: {
							//         Authorization: `${token}`,
							//     },
							// });

							const pictureUpload = await axios.post(`${apiUrl}/challenge/image`, formDataImage, {
									headers: {
											Authorization: `${token}`,
									},
							});
							delete newData["image"];
							delete newData["video"];
							newData.image = pictureUpload.data.result.url;
							// newData.video = videoUpload.data.result.url
							params.data = newData;
							return Create.defaultCreate(resource, params);
					} else {
							return Create.defaultCreate(resource, params);
					}
			}
			case "CountryCode": {
					if (!params.data.icon) {
							params.data.icon =document.getElementById("textInput").value
							return Create.defaultCreate(resource, params);
					} else {
							params.data.icon =document.getElementById("textInput").value
							return Create.defaultCreate(resource, params);
					}
			}
			case "foodRecipeCategory": {
					if (params.data.pictures) {
							let newData = { ...params.data };
							const { rawFile } = params.data.pictures;
							const formData = new FormData();
							formData.append("image", rawFile);
							const token = localStorage.getItem("Authorization");
							const image = await axios.post(`${apiUrl}/teaching/image`, formData, {
									headers: {
											Authorization: `${token}`,
									},
							});
							delete newData["pictures"];
							newData.imageUrl = image.data.result.url;
							params.data = newData;
							return dataProvider.create(resource, {
									...params,
									data: {
											title: params.data.title,
											imageUrl: params.data.imageUrl,
											isPremium: params.data.isPremium,
									},
							});
					} else {
							return dataProvider.create(resource, {
									...params,
									data: {
											title: params.data.title,
											imageUrl: params.data.imageUrl,
											isPremium: params.data.isPremium,
									},
							});
					}
			}
			case "banner":
					if (params.data.pictures) {
							let newData = { ...params.data };
							const { rawFile } = params.data.pictures;
							const formData = new FormData();
							formData.append("image", rawFile);
							const token = localStorage.getItem("Authorization");
							const image = await axios.post(
									`${apiUrl}/challenge/image`,
									formData,
									{
											headers: {
													Authorization: `${token}`,
											},
									}
							);
							delete newData["pictures"];
							newData.imageId = image.data.result.url;
							params.data = newData;
							return Create.defaultCreate(resource, params);
					} else {
							return Create.defaultCreate(resource, params);
			}
			case "remoteConfig":
					if (params.data.picture) {
							console.log(params.data.picture);
							let newData = { ...params.data };
							const { rawFile } = params.data.picture;
							const formData = new FormData();
							formData.append("image", rawFile);
							const token = localStorage.getItem("Authorization");
							const image = await axios.post(
									`${apiUrl}/challenge/image`,
									formData,
									{
											headers: {
													Authorization: `${token}`,
											},
									}
							);
							delete newData["picture"];
							newData.type = image.data.result.url;
							params.data = newData;
							return Create.defaultCreate(resource, params);
					} else {
							return Create.defaultCreate(resource, params);
			}
			case "tracker":
				return httpClient(`${serverUrl}/api/${resource}`, {
					method: "POST",
					body: JSON.stringify(params.data),
				})
					.then(({json}) => ({
						data: {...json.data, id: json.data._id},
					}))
			default:
				return httpClient(`${apiUrl}/${resource}`, {
					method: "POST",
					body: JSON.stringify(params.data),
				})
				.then(({json}) => ({
					data: {...params.data, id: json.id},
			}))
		}
  }
};