import React, {useState} from "react";
import Picker from "emoji-picker-react";
import {Button} from "@material-ui/core";
import EmojiEmotionsIcon from "@material-ui/icons/EmojiEmotions";
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';

const Emoji = (props) => {
  const [chosenEmoji, setChosenEmoji] = useState("");
  const [text, setText] = useState(props.record.icon);
  let result=text + chosenEmoji
  props.record.icon = result
  function textChange(e) {
    setText(e.target.value)
    setChosenEmoji("")
  }
  const onEmojiClick = (event, emojiObject) => {
    setChosenEmoji(chosenEmoji +emojiObject.emoji);
  };
  return (
    <div>
      <TextField id="textInput"   variant="filled" onChange={textChange} value={result} style={{height:"48.5px"}} />
      <Button id="openPicker" style={{
        background: "rgba(0,0,0,0.09)",
        color: "silver",
        width:"15px" ,
        height:48,
        borderRadius:"3px 0 0 3px",
        borderBottom:"1px solid rgba(0, 0, 0, 0.42)",
        display:"inline-flex"
      }}
        onClick={() =>  {
          document.getElementById("emojiPicker").style.display="block"
          document.getElementById("openPicker").style.display="none"
          document.getElementById("closePicker").style.display="inline-flex"
        }}
        startIcon={<EmojiEmotionsIcon/>}
      >
      </Button>
      <Button id="closePicker" style={{
          background: "rgba(0,0,0,0.09)",
          color: "silver",
          width:"15px" ,
          height:48,
          borderRadius:"3px 0 0 3px",
          borderBottom:"1px solid rgba(0, 0, 0, 0.42)",
          display:"none"
      }}
        onClick={() =>  {
          document.getElementById("emojiPicker").style.display="none"
          document.getElementById("openPicker").style.display="inline-flex"
          document.getElementById("closePicker").style.display="none"
        }}
        startIcon={<CloseIcon/>}
      >
      </Button>
      <div id="emojiPicker" style={{display:"none"}} >
        <Picker onEmojiClick={onEmojiClick} disableAutoFocus={false}   />
      </div>
    </div>
  );
};

export default Emoji;