import React from 'react';
import {Datagrid, Filter, List, TextField,TextInput} from 'react-admin';

const ClinicUserList = (props) => {
    return (
        <List {...props} filters={<ClinicUserFilter/>}>
            <Datagrid rowClick={"edit"}>
                <TextField source={"phoneNumber"}/>
                <TextField source={"name"}/>
                <TextField source={"code"}/>
            </Datagrid>
        </List>
    );
};

const ClinicUserFilter = props => {
    return (
        <Filter {...props}>
            <TextInput source={"phoneNumber"} label={"شماره تماس"} alwaysOn/>
        </Filter>
    )
}

export default ClinicUserList;