import React , {useState} from "react";
import {
  TextField ,
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  MenuItem,
  InputLabel,
  Select,

} from "@material-ui/core";
import { useNotify} from "react-admin";
import {SERVER_DOMAIN} from "../../Constants";
import axios from "axios";
import { axiosFetcher } from "../../utils/axiosFetcher";



export function PackageUserTag() {
  const notify = useNotify() 
  const [tags,setTags]=useState([])
  const [state, setState] = useState({
    id: null,
    number : null,
  });
  function submitClick(){
    axios
    .put(`${SERVER_DOMAIN}/admin/packagePricingTag/${state.id}/${state.number}`)
      .then(() => {
      notify("انجام شد")
      })
      .catch(() => {
        notify("به مشکلی برخوردیم")
      });
  }

  function selectTag(){
    axiosFetcher
    .get(`${SERVER_DOMAIN}/admin/packagePricingTag`)
      .then((res) => {
        setTags(res.data)
      })
      .catch(() => {
          notify("به مشکلی برخوردیم")
      });
  }

  function numberchange(e){
    setState({id : state.id , number: e.target.value});
  }

  function idChange(e){
    setState({id : e.target.value  , number:state.number });
  }


  return (
    <>
      <Card style={{marginTop:"2rem"}}>
        <CardHeader title="اختصاص یک فرد به تگ خاص "/>
        <CardContent>
        <form >  
        <TextField  label=" شماره تلفن"  type="number" onChange={numberchange} variant="filled" />
        <br/>
        <FormControl variant="filled"  style={{marginTop:"1rem"}}>
        <InputLabel id="demo-simple-select-outlined-label">تگ</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          onMouseEnter={selectTag}
          label="تگ"
          onChange={idChange}
        >
          {tags.map(({id, name}) => (
            <MenuItem   value={id}>    {name}  </MenuItem>
            ))}
        </Select>
      </FormControl>
      <br/>
        <Button type="submit" variant="contained" onClick={submitClick}  style={{marginTop:"1rem"}}>ثبت</Button>

        </form>  
        </CardContent>
      </Card>   
    </>
  );
}
export default PackageUserTag;
