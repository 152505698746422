import React from "react";
import {
    Datagrid,
    List,
    NumberField,
    NumberInput,
    SelectField,
    SimpleShowLayout,
    TextField,
    AutocompleteInput,
    Filter,
    SelectInput,
} from "react-admin";
import {
  DATA_PAGE_NAMES_CHOICES as pageChoices,
  PAGE_DATA_TYPES_CHOICES as typeChoices,
} from "../../Constants/choices";

import ShowView from "../../Components/ShowView";
import EditView from "../../Components/EditView";
import CreateView from "../../Components/CreateView";

const PackagePricingFilter = (props) => (
    <Filter {...props}>
        <SelectInput source="type" label="فیلتر بر اساس نوع" choices={typeChoices}  />
        <SelectInput source="page" label="فیلتر بر اساس صفحه" choices={pageChoices}  />
        <NumberInput source="weight" label="فیلتر بر اساس وزن" />
        <NumberInput source="minAndroidVersionCode" label="فیلتر بر اساس ورژن اندروید" />
    </Filter>
);

export const PageDataTypeList = (props) => (
  <List {...props}  filters={<PackagePricingFilter/>}>
    <Datagrid rowClick="show">
      <SelectField source="type" choices={typeChoices} />
      <SelectField source="page" choices={pageChoices} />
      <NumberField source="weight" />
      <NumberField source="minAndroidVersionCode"  align="center"/>
      <TextField source="id" />
    </Datagrid>
  </List>
);

export const PageDataTypeEdit = (props) => (
  <EditView {...props}>
    <AutocompleteInput  choices={typeChoices}  source="type"/>
    <AutocompleteInput source="page" choices={pageChoices} />
    <NumberInput source="weight"/>
    <NumberInput source="minAndroidVersionCode" />
    <TextField source="id" />
  </EditView>
);

export const PageDataTypeCreate = (props) => (
  <CreateView {...props}>
    <AutocompleteInput source="type" choices={typeChoices} />
    <AutocompleteInput source="page" choices={pageChoices} />
    <NumberInput source="weight"/>
    <NumberInput source="minAndroidVersionCode" />
  </CreateView>
);

export const PageDataTypeShow = (props) => (
  <ShowView {...props}>
    <SimpleShowLayout>
      <SelectField source="type" choices={typeChoices} />
      <SelectField source="page" choices={pageChoices} />
      <NumberField source="weight" />
      <NumberField source="minAndroidVersionCode" />
      <TextField source="id" />
    </SimpleShowLayout>
  </ShowView>
);
