import React ,{useState,useEffect}from "react";
import {
  Datagrid,
  DateField,
  FileField,
  FileInput,
  List,
  NumberField,
  NumberInput,
  SimpleShowLayout,
  TextField,
  TextInput,
  required, 
  downloadCSV,
  SelectArrayInput,
} from "react-admin";

import { ArrayTextField } from "../../Components/ArrayTextField";
import EditView from "../../Components/EditView";
import { MaterialDateInput } from "../../Components/RaMaterialDatePickers";
import ShowView from "../../Components/ShowView";
import CreateView from "../../Components/CreateView";
import {Button, Typography} from "@material-ui/core";
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import jsonExport from "jsonexport/dist";
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';  

import {axiosFetcher} from "../../utils/axiosFetcher";

const DownloadCodes = (props) => {
  let usedCount =props.record.usedCount
  function fetchAndDownload(event) {
      event.stopPropagation()
      let skus = props.record.codes
      let usedCode = props.record.used
      let result = []
      for (let i = 0; i < skus.length; i++) {
          result[i] = {Codes: skus[i], Used: usedCode[i]}
      }
      jsonExport(result, (err, csv) => {
          downloadCSV(csv, `BulkDiscount${Date.now()}`);
      });
  }
  return (
    <>
    {usedCount !==undefined ?
      <Button style={{ background: "rgba(46, 125, 50, 1)", color: "white" }} onClick={fetchAndDownload} endIcon={<GetAppOutlinedIcon/>}>
            دریافت کدهای تخفیف گروهی
      </Button> : null
    }
    </>
  );
};

export const BulkdiscountcodeList = (props) => (
  <List {...props}>
    <Datagrid rowClick="show">
      <TextField source="prefix" />
      <DateField locales="fa-IR" source="expirationTime" />
      <ArrayTextField source="pricings" />
      <TextField source="number" />
        <TextField source="usedCount" />
      <TextField source="id" />
      <DownloadCodes/>
    </Datagrid>
  </List>
);

const Pricing = () => {
  // fetch data for Pricing drop down ↓
  const [fetchData,setFetchData] =  useState([])

  // fetch data for sku drop down ↓
  const [fetchSku,setFetchSku] = useState([])

  const [filtered,setFiltered] = useState(false)

  const [filteredValue,setFilteredValue] = useState(null)
  console.log(filteredValue);
  var pricingDropDownOption = []
  var skuDropDownOption = []
 
  if (filtered === true && filteredValue !=null) {
    var pricingDropDownOptionFiltered = []
   var b =fetchData.filter(function(item){
   return item.sku === filteredValue

  })
  for (let f = 0; f < b.length; f++) {
    pricingDropDownOptionFiltered[f] = {id :b[f].id  , name :`${b[f].title} / ${b[f].type} / ${b[f].subtitle} / ${b[f].currency} / ${b[f].sku} `,  }
  }
  }
  useEffect(()=> {
      axiosFetcher.get(`packagePricing?filter={"isAvailable":true}&range=[0,9]&sort=["id","ASC"]`)
          .then((res) => {
            setFetchData(res.data)
          })
        axiosFetcher.get(`sku`)
          .then((res) => {
              setFetchSku(res.data)
          })
  },[])

  // create a custom array from fetched data for PRICING drop down ↓
  for (let i = 0; i < fetchData.length; i++) {
    pricingDropDownOption[i] = {id :fetchData[i].id  , name :`${fetchData[i].title} / ${fetchData[i].type} / ${fetchData[i].subtitle} / ${fetchData[i].currency} / ${fetchData[i].sku} `,  sku :  fetchData[i].sku  }
  }

  // create a custom array from fetched data for SKU drop down ↓
  for ( let e = 0; e < fetchSku.length; e++) {
    skuDropDownOption[e] = {name :fetchSku[e].sku  }
  }

function skuChange(e){
    setFiltered(true)
    setFilteredValue(e.target.value)
}

return (
    <div className="regularDiscountCode-pricing">
        <FormControl variant="filled" >
       <InputLabel > فیلتر با SKU (اختیاری) </InputLabel>
        <Select
           labelId="demo-simple-select-label"
           label="فیلتر با sku "
           onChange={skuChange}
       >
           {skuDropDownOption.map((e) => (
               <MenuItem value={e?.name}>{e?.name}</MenuItem>
               ))}
       </Select>
       </FormControl>
       <br />
       {filtered===false ?  <SelectArrayInput source="pricings" label="قیمت " choices={pricingDropDownOption}  /> :  <SelectArrayInput source="pricings" label="قیمت " choices={pricingDropDownOptionFiltered}  />}
    </div>
);
};
export const BulkdiscountcodeEdit = (props) => (
  <EditView
    {...props}
    transform={(data) => {
      let e = new Date(data.expirationTime).getTime();
      return { ...data, expirationTime: e };
    }}
  >
    <MaterialDateInput source="expirationTime" options={{ format: "DD/MM/YYYY" }}/>
     <Pricing/>
    <TextField source="usedCount" />
  </EditView>
);

export const BulkdiscountcodeCreate = (props) => {
  return (
    <CreateView
      {...props}
      transform={(data) => {
        let e = new Date(data.expirationTime).getTime();
        return { ...data, expirationTime: e };
      }}
    >
      <TextInput
        source="prefix"
        helperText={"پیشوند باید در تمامی کدهای تخفیف یکتا باشد"}
      />
      <MaterialDateInput
        source="expirationTime"
        options={{ format: "DD/MM/YYYY" }}
        validate={required()}
      />
       <Pricing/>

      <NumberInput source="number" validate={required()} />
      <FileInput source="files" label="فایل مربوطه (.csv)" accept=".csv">
        <FileField source="src" title="title" />
      </FileInput>
    </CreateView>
  );
};

export const BulkdiscountcodeShow = (props) => {
  return (
    <ShowView {...props}>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="prefix" />
        <Typography variant={"caption"}>
          پیشوند باید در تمامی کدهای تخفیف یکتا باشد
        </Typography>
        <DateField source="expirationTime" locales={"fa-IR"} />
        <ArrayTextField source="pricings" />
        <NumberField source="number" />
        <TextField source="usedCount" />
        <DownloadCodes/>
      </SimpleShowLayout>
    </ShowView>
  );
};
