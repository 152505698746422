// NOTE: delete btn works

import React from "react";
import {BooleanField, Datagrid, DateField, List, SimpleShowLayout, TextField, TextInput,required} from "react-admin";
import CreateView from "../../Components/CreateView";
import EditView from "../../Components/EditView";
import ShowView from "../../Components/ShowView";

export const ExerciseUnitCreate = (props) => (
  <CreateView {...props}>
    <TextInput source="name" lable="نام"  validate={required()}/>
  </CreateView>
);

export const ExerciseUnitList = (props) => (
  <List {...props} perPage={15}>
    <Datagrid rowClick="show">
      <TextField source="name" />
      <DateField source="updatedAt" locales="fa" textAlign="center" />
      <BooleanField source="isDeleted" />
    </Datagrid>
  </List>
);

export const ExerciseUnitEdit = (props) => (
  <EditView {...props}>
    <TextInput source="name"  validate={required()}/>
  </EditView>
);

export const ExerciseUnitShow = (props) => (
  <ShowView {...props}>
    <SimpleShowLayout>
      <BooleanField source="isDeleted" />
      <TextField source="name" />
      <TextField source="id" />
      <DateField source="updatedAt" />
    </SimpleShowLayout>
  </ShowView>
);
