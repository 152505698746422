import React from 'react';
import {BooleanField, DateField, Show, SimpleShowLayout, TextField} from 'react-admin';
import TrackerUrlField from "./TrackerUrlField";

const TrackerShow = (props) => {
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <BooleanField addLabel={true} source={"deleted"}/>
                <TextField source={"trackerLink"}/>
                <TextField source={"identifier"}/>
                <TextField source={"baseURL"}/>
                <TrackerUrlField source={"url"}/>
                <TextField source={"description"} fullWidth multiline/>
                <DateField source={"createdAt"} locales={"fa-IR"}/>
                <DateField source={"updatedAt"} locales={"fa-IR"}/>
            </SimpleShowLayout>
        </Show>
    );
};

export default TrackerShow;