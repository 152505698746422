import {food, foodBarcode, foodCategory, foodFact, foodRecipe, foodRecipeCategory, foodUnit,} from "../Panels/Food";
import {
    exercise,
    exerciseCategory,
    exerciseFact,
    exerciseInstruction,
    exerciseInstructionCategory,
    exerciseUnit,
} from "../Panels/Exercise";
import {campaign} from '../Panels/Campaign'
import {challenge, challengeCategory} from "../Panels/Challange";
import {banner} from "../Panels/Banner";
import {adminUsers} from "../Panels/AdminUsers";
import {advice} from "../Panels/Advice";
import {packagePricingTag, packages, PackageUserTags, Skus} from "../Panels/Package";
import {user, userNotes} from "../Panels/User";
import {popUpData} from "../Panels/PopUpData";
import {packagePricing} from "../Panels/PackagePricing";

import {transaction} from "../Panels/Transaction";
import {invalidFood, invalidFoodFacts} from "../Panels/InvalidFoods";
import {config} from "../Panels/Config";
import {downloadLinks} from "../Panels/DownloadLinks";
import {notification} from "../Panels/Notification";
import {bulkDiscountCode, bulkDiscountCodeUser, regularDiscountCode} from "../Panels/Discount";
import {ABTests, pageDataType, pagesData, RemoteConfigs} from "../Panels/AbTesting";
import {paymentServerTroubleshoot} from "../Panels/PaymentServerTroubleshoot";
import {errorMessages} from "../Panels/ErrorMessage";
import {
    bulkDiscountCodes,
    plans,
    promotions,
    regularDiscountCodes,
    shopPackages,
    skus,
    userPackeges
} from "../Panels/Shop";
import {faqCategory, faqQuestion, faqSubcategory, faqTaq, faqUserQuestion,} from "../Panels/FAQ";
import {userDiscountCodes} from "../Panels/UserDiscountCodes";
import {getToKnow} from "../Panels/getToKnow";
import {SibApp} from '../Panels/SibApp'
import {updatePopUpData} from "../Panels/UpdatePopUpData";
import {
    addGroupSubscriptions,
    BarcodeLogCount,
    FoodUnitsUsage,
    GetMigratedUser,
    GetUsersTransactions,
    NonPaidUsers,
    OldNonRegisterUser,
    RandomFoods,
    RemoveDuplicateData,
    ReportOldUser,
    SaleReport,
    ScoreboardReports,
    ScoreboardVoteReports,
    SignUpsReport,
    UserLogCount,
    UsersByExpirationDates,
} from "../Panels/API";
import {CountryCodes} from "../Panels/CountryCode";
import {tracker} from '../Panels/Tracker';
import {clinic} from "../Panels/clinic";

export const SUPER_ADMIN = [
    //  FOOD ↓
    food,
    foodFact,
    foodUnit,
    foodCategory,
    foodRecipe,
    foodRecipeCategory,
    foodBarcode,

    //  EXERCISE ↓
    exercise,
    exerciseFact,
    exerciseUnit,
    exerciseCategory,
    exerciseInstruction,
    exerciseInstructionCategory,

    // Marketing ↓
    challenge,
    challengeCategory,
    banner,
    popUpData,
    campaign,
    getToKnow,
    advice,
    user,
    adminUsers,

    userNotes,
    transaction,
    invalidFood,
    invalidFoodFacts,
    downloadLinks,
    notification,
    userDiscountCodes,
    updatePopUpData,
    errorMessages,
    tracker,

    //  A/b  ↓
    pageDataType,
    pagesData,
    ABTests,
    RemoteConfigs,

    //  Price ↓
    packages,
    packagePricing,
    packagePricingTag,
    PackageUserTags,
    Skus,
    bulkDiscountCode,
    regularDiscountCode,

    //  FAQ ↓
    faqCategory,
    faqSubcategory,
    faqQuestion,
    faqUserQuestion,
    faqTaq,

    // Shop
    plans,
    skus,
    promotions,
    shopPackages,
    userPackeges,
    bulkDiscountCodes,
    regularDiscountCodes,

    //  CUSTOM API ↓
    NonPaidUsers,
    RemoveDuplicateData,
    ReportOldUser,
    UserLogCount,
    BarcodeLogCount,
    OldNonRegisterUser,
    SaleReport,
    SignUpsReport,
    FoodUnitsUsage,
    RandomFoods,
    addGroupSubscriptions,
    UsersByExpirationDates,
    GetUsersTransactions,
    GetMigratedUser,
    ScoreboardReports,
    ScoreboardVoteReports,

    //  OTHER ↓
    config,
    paymentServerTroubleshoot,
    CountryCodes,
    SibApp,
    clinic
];

export const FOOD_ADMIN = [
    food,
    foodFact,
    foodUnit,
    foodCategory,
    foodRecipe,
    foodRecipeCategory,
    foodBarcode,
    exercise,
    exerciseFact,
    exerciseUnit,
    exerciseCategory,
    exerciseInstruction,
    exerciseInstructionCategory,
];

export const USERS_ADMIN = [
    user,
    //  A/b  ↓
    pageDataType,
    pagesData,
    bulkDiscountCodeUser,
    faqCategory,
    faqSubcategory,
    faqQuestion,
    faqUserQuestion,
    faqTaq,
    SibApp,
    clinic,
];

export const MARKETING_ADMIN = [
    challenge,
    challengeCategory,
    banner,
    advice,
    packages,
    notification,
    bulkDiscountCode,
    regularDiscountCode,

    //  A/b  ↓
    pageDataType,
    pagesData,


    userNotes,
    tracker
];

export const DOCTOR = [
    clinic
];

