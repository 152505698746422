import {
  PopupdataCreate,
  PopupdataEdit,
  PopupdataList,
  PopupdataShow,
} from "./PopUpData";
import FastfoodIcon from "@material-ui/icons/Fastfood";

export const popUpData = {
  name: "popUpData",
  list: PopupdataList,
  edit: PopupdataEdit,
  show: PopupdataShow,
  create: PopupdataCreate,
  icon: FastfoodIcon,
  options: { cat: "marketing" },
};
