import React from "react";
import {
  Datagrid,
  List, SelectArrayInput,
  SimpleShowLayout,
  TextField,
  TextInput,
} from "react-admin";
import ShowView from "../../Components/ShowView";
import EditView from "../../Components/EditView";
import CreateView from "../../Components/CreateView";
import { MARKETS_CHOICES } from "../../Constants/choices";
import Emoji from "./Components/Emoji";
import {ArrayTextField} from "../../Components/ArrayTextField";

export const CountryCodeList = (props) => (
  <List {...props}>
    <Datagrid rowClick="show">
      <TextField source="name" />
      <TextField source="prefixCode" />
      <TextField source="pattern"  />
      <ArrayTextField source="markets"/>
      <TextField source="id" />
    </Datagrid>
  </List>
);

export const CountryCodeShow = (props) => (
  <ShowView {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="prefixCode" />
      <TextField source="pattern"  />
      <ArrayTextField source="markets"/>
      <TextField source="icon" />
    </SimpleShowLayout>
  </ShowView>
);

export const CountryCodeEdit = (props) => (
  <EditView {...props} >
    <TextInput source="name"  />
    <TextInput source="prefixCode"  />
    <TextInput source="pattern"  />
    <SelectArrayInput source="markets" choices={MARKETS_CHOICES} optionText={"name"} optionValue={"id"}/>
    <Emoji/>
  </EditView>
);

export const CountryCodeCreate = (props) => (
  <CreateView {...props}>
    <TextInput source="name"  />
    <TextInput source="prefixCode"  />
    <TextInput source="pattern"  />
      <SelectArrayInput source="markets" choices={MARKETS_CHOICES} optionText={"name"} optionValue={"id"}/>
    <Emoji />
  </CreateView>
);
