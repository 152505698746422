import BorderVerticalIcon from "@material-ui/icons/BorderVertical";
import {
  PlanCreate,
  PlanEdit,
  PlanList,
  PlanShow,
} from "./Plan";
import {
  PlanSKUCreate,
  PlanSKUList,
  PlanSKUShow,
} from "./PlanSKU";

import {
  PlanPromotionCreate,
  PlanPromotionEdit,
  PlanPromotionList,
  PlanPromotionShow,
} from "./PlanPromotion";

import {
 ShopPackageCreate,
 ShopPackageEdit,
 ShopPackageList,
 ShopPackageShow,
} from "./ShopPackage";

import {
  ShopBulkDiscountCreate,
  ShopBulkDiscountEdit,
  ShopBulkDiscountList,
  ShopBulkDiscountShow,
} from "./ShopBulkDiscount";
import {
  ShopRegularDiscountEdit,
  ShopRegularDiscountCreate,
  ShopRegularDiscountList,
  ShopRegularDiscountShow,
} from "./ShopRegularDiscount";

import { UserPackeges } from "./UserPackeges";

export const plans = {
  name: "plan",
  list  : PlanList,
  edit:   PlanEdit,
  show:   PlanShow,
  create: PlanCreate,
  icon: BorderVerticalIcon,
  options: { cat: "Shop" },
};
export const skus = {
  name: "plansku",
  list  : PlanSKUList,
  edit:   null,
  show:   PlanSKUShow,
  create: PlanSKUCreate,
  icon: BorderVerticalIcon,
  options: { cat: "Shop" },
};
export const promotions = {
  name: "promotion",
  list  : PlanPromotionList,
  edit:   PlanPromotionEdit,
  show:   PlanPromotionShow,
  create: PlanPromotionCreate,
  icon: BorderVerticalIcon,
  options: { cat: "Shop" },
};
export const shopPackages = {
  name: "shop-package",
  list  :ShopPackageList,
  edit:  ShopPackageEdit,
  show:  ShopPackageShow,
  create:ShopPackageCreate,
  icon: BorderVerticalIcon,
  options: { cat: "Shop" },
};

export const userPackeges = {
  name: "userPackeges",
  list  : UserPackeges,
  edit:   null,
  show:   null,
  create: null,
  icon: BorderVerticalIcon,
  options: { cat: "Shop" },
}; 
export const bulkDiscountCodes = {
  name: "bulkDiscountCodes",
  list  :  ShopBulkDiscountList,
  edit:    ShopBulkDiscountEdit,
  show:    ShopBulkDiscountShow,
  create:  ShopBulkDiscountCreate,
  icon: BorderVerticalIcon,
  options: { cat: "Shop" },
};
export const regularDiscountCodes = {
  name: "regularDiscountCodes",
  list  :  ShopRegularDiscountList,
  edit:    ShopRegularDiscountEdit,
  show:    ShopRegularDiscountShow,
  create:  ShopRegularDiscountCreate,
  icon: BorderVerticalIcon,
  options: { cat: "Shop" },
};






