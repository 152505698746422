import { useDataProvider, useMutation, useNotify } from "react-admin";
import React, { useEffect, useState } from "react";
import {
  Button,
  Typography,
} from "@material-ui/core";
import TransactionsDialog from "./TransactionDialog";

export default function (props) {
  const dataProvider = useDataProvider();
  const { record } = props;
  const [data, setData] = useState("");
  const [subscriptionType , setSubscriptionType] = useState("regular")
  console.log(subscriptionType);
  console.log("props", props)
  const notify = useNotify();

  function _getsubscriptionData() {
    dataProvider
      .getOne("subscription", { id: record.id })
      .then(({ data }) => {
        setData(data);
        notify("اطلاعات مالی دریافت شد");
      })
      .catch((error) => {
        notify(`خطا در دریافت اطلاعات مالی: ${error.message}`, "warning");
      });
  }
  useEffect(() => {
    record && _getsubscriptionData();
  }, [dataProvider, notify, record]);

  return (
    <>
      <Typography variant="body1" gutterBottom style={{ margin: "0.5em" }}>
        اطلاعات مالی کاربر
      </Typography>
      <div style={{ margin: "5px 0" }}>
        <TransactionsDialog
          refreshSubDate={_getsubscriptionData}
          transactions={data.completedPaidTransactions}
          title={"تراکنش های پرداختی بانکی موفق:"}
          record={record}
        >
          <Button variant="contained" color="primary" style={{maxWidth: "25vh", marginRight: "16px"}}>
            تراکنش های پرداختی بانکی موفق:{"  "}
            {data.completedPaidTransactions &&
              data.completedPaidTransactions.length.toString()}
          </Button>
        </TransactionsDialog>
      </div>
      
    </>
  );
}
