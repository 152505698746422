import React from "react";
import {
  Datagrid,
  DateField,
  List,
  ReferenceField,
  Filter,
  ReferenceArrayInput,
  SelectArrayInput,
  Button,
  SimpleShowLayout,
  TextField,
  TextInput,
  useRedirect,
  BooleanInput,
  BooleanField, 
  SelectInput, 
  ImageField
} from "react-admin";
import QuestionDialog from "./Components/QuestionDialog";
import ShowView from "../../Components/ShowView";
import EditView from "../../Components/EditView";
import CreateView from "../../Components/CreateView";

const UserDataBtn = (props) => {
  const redirect = useRedirect()
  function redirecting() {
    redirect(`/user/${props.record.phoneNumber}`);
  }
  return(
    <Button label="اطلاعات کاربر" onClick={redirecting} />
  )
}

const FaqUserQuestionFilter = (props) => (

  <Filter {...props}>
    <TextInput label="جست‌وجو" source="name" alwaysOn />

      <ReferenceArrayInput
      label="دسته بندی  "
      source="faqCategoryId"
      reference="faqCategory"
      allowEmpty
    >
      <SelectArrayInput optionText="title" />
    </ReferenceArrayInput>
    <ReferenceArrayInput
      label= "  زیر دسته بندی  "
      source="faqSubcategoryId"
      reference="faqSubcategory"
      allowEmpty
    >
      <SelectArrayInput optionText="title" />
    </ReferenceArrayInput>
    <ReferenceArrayInput
      label= "  سوال    "
      source="questionId"
      reference="faqQuestion"
      allowEmpty
    >
      <SelectInput  optionText="subject" />
    </ReferenceArrayInput>
    <BooleanInput label="پاسخ داده شده" source="answered" />
  </Filter>
);
export const FaqUserQuestionList = (props) => (
  <List {...props}  filters={<FaqUserQuestionFilter/>} sort={{ field: "createdAt", order: "DESC" }}>
    <Datagrid rowClick="show">
      <ReferenceField source="questionId" reference="faqQuestion" label=" متن سوال FAQ">
      <TextField source="body" />
      </ReferenceField>
      <TextField source="text" />
      <DateField locales="fa-IR" source="createdAt" />
      <BooleanField source="answered" />
      <TextField source="adminName" />
      <ImageField source="imageUrl" />
    </Datagrid>
  </List>
);

export const FaqUserQuestionShow = (props) => (
  <ShowView {...props}>
    <SimpleShowLayout>
    <TextField source="admin" />
      <TextField source="questionId" />
      <TextField source="phoneNumber"/>
      <UserDataBtn />
      <ReferenceField label="عنوان سوال" source="questionId" reference="faqQuestion">
        <TextField source="subject" className="shit" />
      </ReferenceField>
      <ReferenceField label="متن سوال" source="questionId" reference="faqQuestion">
        <TextField source="body" className="shit" />
      </ReferenceField>
      <TextField source="text" />
      <DateField locales="fa-IR" source="createdAt" />
      <TextField source="userId" />
      <ImageField source="imageUrl" />
      <QuestionDialog>
        <Button label="پاسخ به سوال کاربر" />
      </QuestionDialog>
    </SimpleShowLayout>
  </ShowView>
);

export const FaqUserQuestionEdit = (props) => (
  <EditView {...props}>
    <TextInput source="text" />
  </EditView>
);

export const FaqUserQuestionCreate = (props) => (
  <CreateView {...props}>
    <TextInput source="text"  />
  </CreateView>
);
