import React, { useState , useEffect } from "react";
import {
  AppBar,
  Dialog,
  IconButton,
  Paper,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from "@material-ui/core";
import {ArrayTextField} from "../../../Components/ArrayTextField";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { useNotify } from "react-admin";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from '@material-ui/icons/Done';
import { axiosFetcher } from "../../../utils/axiosFetcher";
import { SERVER_DOMAIN } from "../../../Constants";
import AddIcon from '@material-ui/icons/Add';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export const Experiments = (props) => {
  const records = props.record
  const notify = useNotify();
  const [open, setOpen] = useState(false);
  const [his, setHis] = useState([]);
  const [experimentName, setExperimentName] = useState("");
  const [experimentWeight, setExperimentWeight] = useState("");
  const [rcvalue,setRcvalue] = useState([])
  const [openAnswerBox , setOpenAnswerBox] = useState(false)
  const [edit,setEdit] = useState(false)
  const [answerId,setAnswerId] = useState(null)
  const [editInfo,setEditInfo] =  useState([])
  const [editAnswerValue,setEditAnswerValue] = useState("")
  const [editWeightValue,setEditWeightValue] = useState("")
  const [editRemoteConfigValue,setEditRemoteConfigValue] = useState([])
  const [deleteId,setDeleteId] = useState(null)
  const [deleteConfirm , setDeleteConfirm] = useState(false)
  const [remoteConfig,setRemoteConfig] = useState([])

  useEffect(()=> {
    if(props?.openExperiment){
      setOpen(true)
     }
    axiosFetcher.get(`remoteConfig`)
        .then((res) => {
          setRemoteConfig(res.data);
        })  
  },[])

  function experimentNameChange(e) {
    setExperimentName(e.target.value);
  }
  function experimentWeightChange(e) {
    setExperimentWeight(e.target.value);
  }

  function editAnswerChange(e) {
    setEditAnswerValue(e.target.value)
  }
  function editWeightChange(e) {
    setEditWeightValue(e.target.value)
  }
  
  function editAnswer(e) {
    setAnswerId(his[e.target.value].id)
    setEditInfo(his[e.target.value]);
    setEditAnswerValue(his[e.target.value].name)
    setEditWeightValue(his[e.target.value].weight)
    setEditRemoteConfigValue(his[e.target.value].remoteConfigIds)
    setEdit(true)

  }
  function editAnswerRequest(params) {
    axiosFetcher
    .put(`${SERVER_DOMAIN}/admin/experiment/${answerId}`, {
      name: editAnswerValue,
      weight : editWeightValue,
      remoteConfigIds : editRemoteConfigValue

    })
    .then((res) => {
      notify("  ویرایش شد ");
      setEdit(false)
      setTimeout(() => {
        setOpen(false);
      }, 500);

    })
    .catch((err) => {
      notify("به مشکلی برخوردیم");
     
      setEdit(false)
    });
  }

  const handleChange = (event) => {
    setRcvalue(event.target.value)
  };
  const editRemoteConfigChange = (event) => {
    setEditRemoteConfigValue(event.target.value)
  };

  const handleClick = () => {
    setOpen(true);
    axiosFetcher
    .get(`${SERVER_DOMAIN}/admin/experiment/abtest/${records.id}`)
    .then((res) => {
      notify("   لیست اکسپریمنت ها دریافت شد ");
      setHis(res.data);
     
    })
    .catch((err) => {
      notify("به مشکلی برخوردیم");
    }); 
  };

  const handleDialogClose = () => setOpen(false);
  const renderChildren = () => {
    return React.Children.map(props.children, (child) => {
      return React.cloneElement(child, {
        onClick: handleClick,
        fullWidth: false,
      });
    });
  };
  
  // CREATE AN EXPERIMENT =================>

  function openNewAnswer() {
    setOpenAnswerBox(true)
  }

  function sendCreateExperimentRequest() {
    axiosFetcher
      .post(`${SERVER_DOMAIN}/admin/experiment`, {
        name: experimentName,
        weight: experimentWeight,
        remoteConfigIds: rcvalue ,
        abtestId : records.id,
      })
      .then((res) => {
        notify("اکسپریمنت ایجاد شد ");
        setOpenAnswerBox(false)
      })
      .catch(() => {
        notify("اکسپریمنت ساخته نشد", "warning");
        setExperimentName("")
        setExperimentWeight("")
        setRcvalue([]) 
      });
    axiosFetcher
      .get(`${SERVER_DOMAIN}/admin/experiment/abtest/${records.id}`)
      .then((res) => {
        setHis(res.data)
      })
      .catch((err) => {});
  }

  // DELETE AN EXPERIMENT =================>

  function deleteExperimentRequest(e) {
    setDeleteId(e.target.value)
    setDeleteConfirm(true)
  }

  function denyDeleteConfirm() {
    setDeleteConfirm(false)
  }

  function acceptDeleteConfirm() {
    setDeleteConfirm(false)
    axiosFetcher
        .delete(`${SERVER_DOMAIN}/admin/experiment/${deleteId}`)
        .then((res) => {
          notify("  حذف شد ");
          setTimeout(() => {
            axiosFetcher
            .get(`${SERVER_DOMAIN}/admin/experiment/abtest/${records.id}`)
            .then((res) => {
              setHis(res.data)
            })
            .catch((err) => {});
          }, 200);
        })
        .catch((err) => {
          notify("به مشکلی برخوردیم");
        });
  }
 
  return (
    <>
       {renderChildren()}
      <Dialog
        open={open}
        onClose={handleDialogClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        fullScreen
        TransitionComponent={Transition}
      >
        <AppBar style={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleDialogClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="body1" gutterTop style={{ margin: "0.5em" }}>
   اکسپریمنت ها  
            </Typography>
          </Toolbar>
        </AppBar>
        <Paper>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">نام</TableCell>
                <TableCell align="center">  وزن  </TableCell>
                <TableCell align="center"> ریموت کانفیگ ها</TableCell>
                <TableCell align="center"> ویرایش  </TableCell>
                <TableCell align="center"> حذف  </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {his.map((e, index) => (
                <TableRow >
                  <TableCell align="center">{e?.name}</TableCell>
                  <TableCell align="center">{e?.weight} </TableCell>
                  <TableCell align="center"> <ArrayTextField record={e} source="remoteConfigIds" /> </TableCell>
                  <TableCell align="center"  >
                    <button style={{padding: "10px 15px",fontFamily: "IRANSansWeb", border : "none", cursor:"pointer", color: "white", backgroundColor:"silver"}} value = {index} onClick={editAnswer}>
                      ویرایش
                    </button>
                  </TableCell>
                  <TableCell align="center"  >
                    <button style={{padding: "10px 15px",fontFamily: "IRANSansWeb", border : "none", cursor:"pointer", color: "white", backgroundColor:"red"}} value = {e?.id} onClick={deleteExperimentRequest}>
                      حذف
                    </button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {deleteConfirm == true ?
              <>
                <div  style={{ width : "100%"  , display : "flex", justifyContent : "center" , marginTop : "20px"}}>
                  <Typography style={{ marginTop : "10px" }}> آیا از حذف این اکسپریمنت اطمینان دارید؟</Typography>
                </div>
                <div  style={{ width : "100%"  , display : "flex", justifyContent : "center" , marginTop : "20px"}}>
                  <Button variant="contained" onClick={denyDeleteConfirm}  endIcon={<CloseIcon/>} style={{ backgroundColor : "red" , color : "white" , marginLeft: "10px"}}>
                  خیر
                  </Button>
                  <Button variant="contained" color="primary" onClick={acceptDeleteConfirm} endIcon={<DoneIcon/>}>
                    بله
                  </Button>
                </div>
              </>: null}
          
            <div  style={{ width : "100%"  , display : "flex", justifyContent : "center" , marginTop : "20px"}}>
              <Button variant="contained" color="primary" onClick={openNewAnswer} endIcon={<AddIcon/>}>
                اکسپریمنت جدید
              </Button>
            </div>
      
          <br/>
          { edit === true ?
          <>
            <Typography style={{marginRight:"2rem",marginTop:"2rem", color:"red"}}>  ویرایش جواب     </Typography>
            <TextField
              value={editAnswerValue}
              onChange={editAnswerChange}
              label="نام"
              style={{ margin: "2em", width: "255px" }}
            />
            <TextField
              value={editWeightValue}
              onChange={editWeightChange}
              label="وزن"
              style={{ margin: "2em", width: "255px" }}
            />
              <Select
                  labelId="demo-mutiple-name-label"
                  id="demo-mutiple-name"
                  multiple
                  onChange={editRemoteConfigChange}
                  value={editRemoteConfigValue}
                  input={<Input />}
                  renderValue={(selected) => (
                    <div >
                      {selected.map((value) => (
                        <Chip key={value} label={value}  />
                      ))}
                    </div>
                  )}
                  > 
                  {remoteConfig.map((rc, ) => (
                      <MenuItem  value={rc.id}>
                      {rc.page} // {rc.type}
                      </MenuItem>
                  ))}
                </Select>
            <Button
              variant="contained"
              onClick={editAnswerRequest}
              color="primary"
              style={{ margin: "1.2em", marginLeft : "1.5rem" , float: "left" }}
            >
              ثبت ویرایش
            </Button>
          </>
          :null}
          {openAnswerBox === true ?
            <>
              <TextField
                value={experimentName}
                onChange={experimentNameChange}             
                label="نام"
                style={{ margin: "2em", width: "256px" }}
              />
              <TextField
                value={experimentWeight}
                onChange={experimentWeightChange}   
                type="number"          
                label="وزن"
                style={{ margin: "2em", width: "256px" }}
              />
              <FormControl style={{ margin: "2em", width: "256px" }} >
                <InputLabel id="demo-mutiple-name-label">ریموت کانفیگ</InputLabel>
                <Select
                  labelId="demo-mutiple-name-label"
                  id="demo-mutiple-name"
                  multiple
                  onChange={handleChange}
                  value={rcvalue}
                  input={<Input />}
                  renderValue={(selected) => (
                    <div >
                      {selected.map((value) => (
                        <Chip key={value} label={value}  />
                      ))}
                    </div>
                  )}
                  > 
                  {remoteConfig.map((rc, ) => (
                      <MenuItem  value={rc.id}>
                      {rc.page} // {rc.type}
                      </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Button
                variant="contained"
                onClick={sendCreateExperimentRequest}
                color="primary"
                style={{margin: "1.2em", marginLeft : "1.5rem" , float: "left"}}
              >
                ذخیره اکسپریمنت
              </Button>
            </>
           : null }
        </Paper>
      </Dialog>
    </>
  );
};

