import React, { useState } from "react";
import {
  AppBar,
  Dialog,
  IconButton,
  Paper,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { useMutation,DateField } from "react-admin";
import { useNotify } from "react-admin";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from '@material-ui/icons/Done';
import { axiosFetcher } from "../../../utils/axiosFetcher";
import { SERVER_DOMAIN } from "../../../Constants";

import AddIcon from '@material-ui/icons/Add';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const GenericDialog = (props) => {
  const notify = useNotify();
  const [open, setOpen] = useState(false);
  const [openAnswerBox , setOpenAnswerBox] = useState(false)
  const [edit,setEdit] = useState(false)
  const [answerId,setAnswerId] = useState(null)
  const [editInfo,setEditInfo] =  useState([])
  const [his, setHis] = useState([]);
  const [answer, setAnswer] = useState("");
  console.log(his.length)
  const [editAnswerValue,setEditAnswerValue] = useState("")
  // Delete const
  const [deleteId,setDeleteId] = useState(null)
  const [deleteConfirm , setDeleteConfirm] = useState(false)
  function deleteAnswerRequest(e) {
    setDeleteId(e.target.value)
    setDeleteConfirm(true)
  }

  function answerChange(e) {
    setAnswer(e.target.value);
  }
  function editAnswerChange(e) {
    setEditAnswerValue(e.target.value)
  }

  function getHistory(params) {
    axiosFetcher
      .get(`/faqUserQuestionAnswer/${props.record.id}`)
      .then((res) => {
        setHis(res.data);
        console.log(res)
      })
      .catch((e) => {
        console.log(props);
      });
  }
  const handleClick = () => {
    setOpen(true);
    getHistory();
  };
  const handleDialogClose = () => setOpen(false);
  const renderChildren = () => {
    return React.Children.map(props.children, (child) => {
      return React.cloneElement(child, {
        onClick: handleClick,
        fullWidth: false,
      });
    });
  };
  const [sendAnswer, { loading }] = useMutation({
    type: "post",
    resource: `faqUserQuestionAnswer
    `,
    payload: { id: props.id, data: { answer } },
  });
  function openNewAnswer() {
    setOpenAnswerBox(true)
  }
  function sendAn() {
    axiosFetcher
      .post(`${SERVER_DOMAIN}/admin/faqUserQuestionAnswer`, {
        userQuestionId: props.record.id,
        text: answer,
      })
      .then((res) => {
        notify("پاسخ داده شد ");
        setOpenAnswerBox(false)
        setTimeout(() => {
          setOpen(false);
        }, 500);
        console.log(props.record.id);
      })
      .catch((err) => {
        notify("به مشکلی برخوردیم");
        console.log(props.record.id);
      });
    axiosFetcher
      .get(`${SERVER_DOMAIN}/admin/faqUserQuestionAnswer`)
      .then((res) => {
        console.log("res", res);
      })
      .catch((err) => {});
  }
  function editAnswer(e) {
   setAnswerId(e.target.value)
    setEditInfo(his.filter(function(item) {
      return item.id === e.target.value;
    }));
    setEdit(true)
  }
  function editAnswerRequest(params) {
    axiosFetcher
    .put(`${SERVER_DOMAIN}/admin/faqUserQuestionAnswer`, {
      text: `${editAnswerValue}`,
      userQuestionId : `${props.record.id}`
    })
    .then((res) => {
      notify("  ویرایش شد ");
      setEdit(false)
      setTimeout(() => {
        setOpen(false);
      }, 500);
      console.log(props.record.id);
    })
    .catch((err) => {
      notify("به مشکلی برخوردیم");
      console.log(props.record.id);
      setEdit(false)
    });
  }
  function acceptDeleteConfirm() {
    setDeleteConfirm(false)
    axiosFetcher
        .delete(`${SERVER_DOMAIN}/admin/faqUserQuestionAnswer/${deleteId}`)
        .then((res) => {
          notify("  حذف شد ");
          setTimeout(() => {
            setOpen(false);
          }, 200);
        })
        .catch((err) => {
          notify("به مشکلی برخوردیم");

        });
  }
  function denyDeleteConfirm() {
    setDeleteConfirm(false)
  }



  return (
    <>
      {renderChildren()}
      <Dialog
        open={open}
        onClose={handleDialogClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        fullScreen
        TransitionComponent={Transition}
      >
        <AppBar style={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleDialogClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="body1" gutterTop style={{ margin: "0.5em" }}>
   سوال کاربر : {props.record.text}
            </Typography>
          </Toolbar>
        </AppBar>
        <Paper>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">متن جواب به کاربر</TableCell>
                <TableCell align="center">ادمین پاسخگو    </TableCell>
                <TableCell align="center">آخرین آپدیت     </TableCell>
                <TableCell align="center">ویرایش   </TableCell>
                <TableCell align="center"> حذف  </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {his.map((e) => (
                <TableRow >
                  <TableCell align="center">{e?.text}</TableCell>
                  <TableCell align="center">{e?.adminName} </TableCell>
                  <TableCell align="center">  <DateField
                      record={e}
                      locales="fa-IR"
                      source="updatedAt"
                  /> </TableCell>
                  <TableCell align="center"  >
                    <button style={{padding: "10px 15px",fontFamily: "IRANSansWeb", border : "none", cursor:"pointer"}} value = {e?.id} onClick={editAnswer}>
                      ویرایش
                    </button>
                  </TableCell>
                  <TableCell align="center"  >
                    <button style={{padding: "10px 15px",fontFamily: "IRANSansWeb", border : "none", cursor:"pointer", color: "white", backgroundColor:"red"}} value = {e?.id} onClick={deleteAnswerRequest}>
                      حذف
                    </button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {deleteConfirm == true ?
              <>
                <div  style={{ width : "100%"  , display : "flex", justifyContent : "center" , marginTop : "20px"}}>
                  <Typography style={{ marginTop : "10px" }}> آیا از حذف این جواب اطمینان دارید؟</Typography>
                </div>
                <div  style={{ width : "100%"  , display : "flex", justifyContent : "center" , marginTop : "20px"}}>
                  <Button variant="contained" onClick={denyDeleteConfirm}  endIcon={<CloseIcon/>} style={{ backgroundColor : "red" , color : "white" , marginLeft: "10px"}}>
                  خیر
                  </Button>
                  <Button variant="contained" color="primary" onClick={acceptDeleteConfirm} endIcon={<DoneIcon/>}>
                    بله
                  </Button>
                </div>
              </>: null}
          {openAnswerBox === false && his.length === 0 ?
            <div  style={{ width : "100%"  , display : "flex", justifyContent : "center" , marginTop : "20px"}}>
              <Button variant="contained" color="primary" onClick={openNewAnswer} disabled={loading} endIcon={<AddIcon/>}>
                پاسخ جدید
              </Button>
            </div>
          : null}
          <br/>
          { edit === true ?
          <>
          <Typography style={{marginRight:"2rem",marginTop:"2rem", color:"red"}}>  ویرایش جواب     </Typography>
          <TextField
            value={editAnswerValue}
            onChange={editAnswerChange}
            label="ویرایش را بنویسید"
            style={{ margin: "2em", width: "70%" }}
          />
          <Button
            variant="contained"
            onClick={editAnswerRequest}
            color="primary"
            disabled={loading}
            style={{ margin: "1.2em", marginLeft : "1.5rem" , float: "left" }}
          >
            ثبت ویرایش
                      </Button>
                      </>
          :null}
          {openAnswerBox === true ?
              <>
            <TextField
                value={answer}
                onChange={answerChange}
                label="پاسخ جدید به سوال کاربر"
                style={{ margin: "2em", width: "70%" }}
            />
            <Button
            variant="contained"
            onClick={sendAn}
            color="primary"
            disabled={loading}
            style={{margin: "1.2em", marginLeft : "1.5rem" , float: "left"}}
            >
            ارسال پاسخ
            </Button>
              </>
           : null }
        </Paper>
      </Dialog>

    </>
  );
};

export default GenericDialog;
