import React, {useState,useEffect} from "react";
import {
  ChipField,
  Datagrid,
  DateField,
  List,
  NumberField,
  NumberInput,
  ReferenceArrayField,
  SelectArrayInput, 
  SimpleShowLayout, SingleFieldList,
  TextField,
  TextInput,
} from "react-admin";
import { MaterialDateInput } from "../../Components/RaMaterialDatePickers";
import EditView from "../../Components/EditView";
import ShowView from "../../Components/ShowView";
import { ArrayTextField } from "../../Components/ArrayTextField";
import CreateView from "../../Components/CreateView";
import {axiosFetcher} from "../../utils/axiosFetcher";
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

export const RegulardiscountcodeList = (props) => (
  <List {...props}>
    <Datagrid rowClick="show">
      <TextField source="code" />
      <NumberField source="limit" />
      <ReferenceArrayField
          source="pricings"
          reference="packagePricing"
          linkType="show"
      >
        <SingleFieldList>
          <ChipField source="id" />
        </SingleFieldList>
      </ReferenceArrayField>
      <NumberField source="usedCount" />
      <TextField source="id" />
      <DateField locales="fa-IR" source="expirationTime" />
    </Datagrid>
  </List>
);

const Pricing = () => {
    // fetch data for Pricing drop down ↓
    const [fetchData,setFetchData] =  useState([])

    // fetch data for sku drop down ↓
    const [fetchSku,setFetchSku] = useState([])

    const [filtered,setFiltered] = useState(false)

    const [filteredValue,setFilteredValue] = useState(null)
    console.log(filteredValue);
    var pricingDropDownOption = []
    var skuDropDownOption = []
   
    if (filtered === true && filteredValue !=null) {
      var pricingDropDownOptionFiltered = []
     var b =fetchData.filter(function(item){
     return item.sku === filteredValue
 
    })
    for (let f = 0; f < b.length; f++) {
      pricingDropDownOptionFiltered[f] = {id :b[f].id  , name :`${b[f].title} / ${b[f].type} / ${b[f].subtitle} / ${b[f].currency} / ${b[f].sku} `,  }
    }
    }

    useEffect(()=> {
        axiosFetcher.get(`packagePricing?filter={"isAvailable":true}&range=[0,9]&sort=["id","ASC"]`)
            .then((res) => {
              setFetchData(res.data)
            })
          axiosFetcher.get(`sku`)
              .then((res) => {
                  setFetchSku(res.data)
              })
    },[])

    // create a custom array from fetched data for PRICING drop down ↓
    for (let i = 0; i < fetchData.length; i++) {
      pricingDropDownOption[i] = {id :fetchData[i].id  , name :`${fetchData[i].title} / ${fetchData[i].type} / ${fetchData[i].subtitle} / ${fetchData[i].currency} / ${fetchData[i].sku} `,  sku :  fetchData[i].sku  }
    }

    // create a custom array from fetched data for SKU drop down ↓
    for ( let e = 0; e < fetchSku.length; e++) {
      skuDropDownOption[e] = {name :fetchSku[e].sku  }
    }

  function skuChange(e){
      setFiltered(true)
      setFilteredValue(e.target.value)
  }

    console.log(filtered);
  return (
      <div className="regularDiscountCode-pricing">
          <FormControl variant="filled" >
         <InputLabel > فیلتر با SKU (اختیاری) </InputLabel>
          <Select
             labelId="demo-simple-select-label"
             id="demo-simple-select"
       
             label="فیلتر با sku "
             onChange={skuChange}
         >
             {skuDropDownOption.map((e) => (
                 <MenuItem value={e?.name}>{e?.name}</MenuItem>
                 ))}
         </Select>
         </FormControl>
         <br />
         {filtered===false ?  <SelectArrayInput source="pricings" choices={pricingDropDownOption}  /> :  <SelectArrayInput source="pricings" choices={pricingDropDownOptionFiltered}  />}
      </div>
  );
};
export const RegulardiscountcodeEdit = (props) => (
  <EditView
    {...props}
    transform={(data) => {
      let e = new Date(data.expirationTime).getTime();
      return { ...data, expirationTime: e };
    }}
  >
    <NumberInput source="limit" />
    <MaterialDateInput source="expirationTime" options={{ format: "DD/MM/YYYY" }}/>
    <Pricing/>
  </EditView>
);

export const RegulardiscountcodeCreate = (props) => (
  <CreateView
    {...props}
    transform={(data) => {
      let e = new Date(data.expirationTime).getTime();
      return { ...data, expirationTime: e };
    }}
  >
    <TextInput source="code" />
    <NumberInput source="limit"  />
    <MaterialDateInput source="expirationTime" options={{ format: "DD/MM/YYYY" }}/>
    <Pricing/>
  </CreateView>
);

export const RegulardiscountcodeShow = (props) => (
  <ShowView {...props}>
    <SimpleShowLayout>
      <TextField source="code" />
      <NumberField source="limit" />
      <ArrayTextField source="pricings" />
      <NumberField source="usedCount" />
      <DateField locales="fa-IR" source="expirationTime" />
      <TextField source="id" />
    </SimpleShowLayout>
  </ShowView>
);
