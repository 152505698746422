import React from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const UserLog = ({ logs }) => (
  <Accordion
    fullWidth
    style={{ margin: "1em 0", maxHeight: "400px", overflow: "auto" }}
  >
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel1a-content"
      id="panel1a-header"
    >
      <Typography>تاریخچه</Typography>
    </AccordionSummary>
    {logs &&
      logs.map((log, index) => (
        <AccordionDetails key={index}>
          <Typography variant="caption">
            {log}
          </Typography>
        </AccordionDetails>
      ))}
  </Accordion>
);

export default UserLog;
