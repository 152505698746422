import React   from "react";
import { FileInput,FileField} from "react-admin";
import {
  Create,
  SimpleForm,
  downloadCSV,
  
} from "react-admin";
import jsonExport from "jsonexport/dist";
import {CardHeader, Paper, Typography} from "@material-ui/core";
import {MaterialDateInput} from "../../Components/RaMaterialDatePickers";

export function GetUsersTransaction(props) {
  const onSuccess = ({ data }) => {
    console.log(data);
    jsonExport(data.list, (err, csv) => {
      downloadCSV(csv, `GetUsersTransaction${Date.now()}`);
    });
  };
  const transform = (data) => {
    let from = new Date(Date.parse(data.from)).setHours(0, 0, 0).toString();
    let to = new Date(Date.parse(data.to)).setHours(23, 59, 0).toString();
    return { ...data, from, to };
  };
  return (
    <Paper>
      <Create {...props} transform={transform} onSuccess={onSuccess}>
        <SimpleForm {...props} >
        <CardHeader style={{width:"100%",marginRight:"-10px"}} title="گزارش کاربران خرید کرده"/>
        <Typography style={{width:"100%"}}>  لیستی از کاربران خرید کرده در بازه زمانی انتخاب شده در زیر را بر می گرداند</Typography>
        <MaterialDateInput source={"from"} label="از تاریخ"/>
        <MaterialDateInput source={"to"} label="تا تاریخ"/>
        <FileInput source="files" label="فایل مربوطه (.csv)" accept=".csv">
        <FileField source="src" title="title" />
      </FileInput>
          <Typography>فایل خروجی csv به صورت خودکار دانلود خواهد شد.</Typography>
        </SimpleForm>
      </Create>
    </Paper>
  );
}
