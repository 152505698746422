import React, {useState} from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,  Typography,
} from "@material-ui/core";
import {useNotify, Loading,DateField} from "react-admin";
import {SERVER_DOMAIN} from "../../Constants";
import { axiosFetcher } from "../../utils/axiosFetcher";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";

export function SibAppList() {
  const notify = useNotify()
  const [loading, setLoading] = useState(false);
  const [showTable,setShowTable] = useState(false)
  const [tableData,setTableData] = useState([])
  const [number,setNumber]= useState()
  function ChangeNumber(e) {
    setNumber(e.target.value)
  }
  function submitClick(){
    const url = `${SERVER_DOMAIN}/admin/payment/report/sibapp?startDate=2021-08-10&endDate=2021-08-20&page=1&perPage=10&phoneNumber=${number}`;
    setLoading(true);
    axiosFetcher
      .get(url)
      .then((res) => {
        setLoading(false);
        notify("انجام شد")
        setShowTable(true)
        setTableData(res.data?.result?.list || [])
      })
      .catch(() => {
        setLoading(false);
        notify("به مشکلی برخوردیم")

      });
  }
  return (
    <>
      <Card style={{marginTop:"2rem"}}>
        <CardHeader title="سیب اپ"/>
        <Typography style={{marginRight:"1.2rem"}}>شماره کاربر مورد نظر را وارد کنید </Typography>
        <CardContent>
          <TextField
            onChange={ChangeNumber}
            label="شماره"
            variant="filled"
          />
          <form >
            <Button variant="contained"  color="primary" onClick={submitClick}  style={{marginTop:"1rem"}}>جستجو </Button>
          </form>
        </CardContent>
        {loading===true ?<Loading/> : null}
        {showTable===true ?  <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">نام</TableCell>
              <TableCell align="center">توضیحات</TableCell>
              <TableCell align="center">قیمت</TableCell>
              <TableCell align="center">مصرف شده </TableCell>
              <TableCell align="center"> ایجاد شده</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map( (id,index) => (
              <TableRow>
                <TableCell align="center">{id.target.name}</TableCell>
                <TableCell align="center">{id.target.description}</TableCell>
                <TableCell align="center">{id.target.price}</TableCell>
                <TableCell align="center" >{String(id.consumed) }</TableCell>
                <TableCell align="center">
                  <DateField
                    record={tableData[index]}
                    locales="fa-IR"
                    source="create_time"
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>:null}
      </Card>
    </>
  );
}

