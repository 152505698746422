import React , {useState} from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader, 
} from "@material-ui/core";
import {useNotify, downloadCSV,Loading} from "react-admin";
import {SERVER_DOMAIN} from "../../Constants";
import {PersianDatePicker} from "../../Components/PersianDatePickers"
import { axiosFetcher } from "../../utils/axiosFetcher";
import jsonExport from "jsonexport/dist";

export function ScoreboardVoteReport(props) {
   const notify = useNotify();
    const [fromDate, setFromDate] = useState(new Date().setHours(0,0));
    const [toDate, setToDate] = useState(new Date().setHours(23,59));
    const [loading, setLoading] = useState(false);
    function fromChange(e){
        setFromDate(e._d.setHours(0,0))
    }
    function toChange(e){
        setToDate(e._d.setHours(23,59))
    }

  function submitClick({data}){
    setLoading(true);
    axiosFetcher
    .get(`${SERVER_DOMAIN}/admin/referral/ScoreboardVoteReport?from=${fromDate}&to=${toDate}`)
      .then((res) => {
        setLoading(false);
        notify(`انجام شد` , "info")
        jsonExport(res.data, (err, csv) => {
        downloadCSV(csv, `ScoreboardVoteReport${Date.now()}`);
      });
      })
      .catch(() => {
        setLoading(false);
        notify("به مشکلی برخوردیم")
      });
  }
  return (
    <>
      <Card style={{marginTop:"2rem"}}>
        <CardHeader title="گزارش امتیازها برای رای گیری ریفرال "/>
        <CardContent>
          <form >  
            <PersianDatePicker
              name="from"
              value={fromDate}
              onChange={fromChange}
              label={"از"}
            />
            <span style={{ margin: "0 1em" }} />
            <PersianDatePicker
              name="to"
              value={toDate}
              onChange={toChange}
              label={"تا"}
            />
            <span style={{ margin: "0 1em" }} />
            <br/>
            <Button type="submit" variant="contained" color="primary" onClick={submitClick}  style={{marginTop:"1rem"}}>اعمال و دریافت فایل </Button>
          </form>
          {loading===true ?<Loading/> : null}
        </CardContent>
      </Card>   
    </>
  );
}

