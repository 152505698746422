import React, { useState } from "react";
import { Button, Card, CardContent, CardHeader } from "@material-ui/core";
import { useNotify, downloadCSV,Loading } from "react-admin";
import { SERVER_DOMAIN } from "../../Constants";
import { PersianDatePicker } from "../../Components/PersianDatePickers";
import { axiosFetcher } from "../../utils/axiosFetcher";
import jsonExport from "jsonexport/dist";

export function UserLog() {
  const notify = useNotify();
  const [fromDate, setFromDate] = useState(new Date().setHours(0,0));
  const [toDate, setToDate] = useState(new Date().setHours(23,59));
  const [loading, setLoading] = useState(false);
  function fromChange(e){
      setFromDate(e._d.setHours(0,0))
  }
  function toChange(e){
      setToDate(e._d.setHours(23,59))
  }
  function submitClick() {
    setLoading(true);
    axiosFetcher
      .get(`${SERVER_DOMAIN}/custom/statistics/?from=${fromDate}&to=${toDate}`)
      .then((res) => {
        setLoading(false);
        notify("انجام شد");
        jsonExport(res.data, (err, csv) => {
          downloadCSV(csv, `UserLog${Date.now()}`);
        });
      })
      .catch(() => {
        setLoading(false);
        notify("به مشکلی برخوردیم");
      });
  }
  return (
    <>
      <Card style={{ marginTop: "2rem" }}>
        <CardHeader title="گزارش تعداد لاگ‌های وارد شده کاربران" />
        <CardContent>
          <form>
            <PersianDatePicker name="from" value={fromDate} onChange={fromChange} label={"از"}/>
            <span style={{ margin: "0 1em" }} />
            <PersianDatePicker name="from" value={toDate} onChange={toChange} label={"تا"}/>
            <span style={{ margin: "0 1em" }} />
            <br />
            <Button type="submit" variant="contained" color="primary" onClick={submitClick}  style={{marginTop:"1rem"}}>اعمال و دریافت فایل </Button>
          </form>
        </CardContent>
        {loading===true ?<Loading/> : null}
      </Card>
    </>
  );
}

