import FastfoodIcon from "@material-ui/icons/Fastfood";
import {
  CampaignCreate,
  CampaignEdit,
  CampaignList,
  CampaignShow,
} from "./Campaign";

export const campaign = {
  name: "campaignCard",
  list: CampaignList,
  edit: CampaignEdit,
  show: CampaignShow,
  create: CampaignCreate,
  icon: FastfoodIcon,
  options: { cat: "marketing" },
};
