import {
  AdminUserCreate,
  AdminUserEdit,
  AdminUserList,
  AdminUserShow,
} from "./AdminUsers";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";

export const adminUsers = {
  name: "adminUsers",
  list: AdminUserList,
  edit: AdminUserEdit,
  show: AdminUserShow,
  create: AdminUserCreate,
  icon: VerifiedUserIcon,
  options: { cat: "users" },
};
