import React  from "react";
import {
    Datagrid,
    DateField,
    ImageField,
    ImageInput,
    List,
    NumberField,
    NumberInput,
    ReferenceField,
    ReferenceInput,
    SelectArrayInput,
    SelectInput,
    SimpleShowLayout,
    TextField,
    ReferenceArrayInput,
    TextInput,
    ReferenceArrayField,
    SingleFieldList,
    ChipField,
    useLoading,
    required,
    useNotify,
    useDataProvider,
    BooleanField,
    BooleanInput,
    Filter,
    AutocompleteInput,

} from "react-admin";
import {Button,Typography} from "@material-ui/core";
import EditView from "../../Components/EditView";
import CreateView from "../../Components/CreateView";
import ShowView from "../../Components/ShowView";
import { MaterialDateInput } from "../../Components/RaMaterialDatePickers";
import { ArrayTextField } from "../../Components/ArrayTextField";
import { MARKETS_CHOICES, PRICING_CHOICES,CURRENCY_CHOICES } from "../../Constants/choices";
import {axiosFetcher} from "../../utils/axiosFetcher";
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import OpenInNewOutlinedIcon from '@material-ui/icons/OpenInNewOutlined';

const TagsField = ({ record, source }) => {
  return (
    <ul>
      {record[source].map((item) => (
        <li key={item}>{item}</li>
      ))}
    </ul>
  );
};
TagsField.defaultProps = {
  addLabel: true,
};
const DuplicateBtn = (props) => {
  const { handleUserCount } = props;
  const loading = useLoading();  
  return (
    <Button style={{ background: "rgba(46, 125, 50, 1)", color: "white" }}
      onClick={(event) => handleUserCount(event,props.record)}
      disabled={loading}
      startIcon={<FileCopyOutlinedIcon/>}
    >
      دوپلیکیت
    </Button>
  );
};

// SKU Button
const Sku = () => {
  function desiredSku() {
    document.getElementById("textSku").style.display = "block"
    document.getElementById("autoSku").style.display = "none"
  }
  function selectSku() {
    document.getElementById("textSku").style.display = "none"
    document.getElementById("autoSku").style.display = "block"
  }
  return(
      <>
        <div id="autoSku" >
        <ReferenceInput label="Sku انتخابی" source="sku" reference="sku"  >
          <AutocompleteInput optionText="id" style={{width:"256px"}}/>
        </ReferenceInput>
        <Button style={{ background: "rgba(46, 125, 50, 1)", color: "white"}} onClick={desiredSku}>
          Sku دلخواه
        </Button>
        </div>
        <div id="textSku" style={{display:"none"}}>
          <TextInput source="sku"  />
          <br/>
          <Button style={{ background: "rgba(46, 125, 50, 1)", color: "white" }} onClick={selectSku}>
            Sku انتخابی
          </Button>
        </div>
      </>
  )
}
const PackagePricingFilter = (props) => {
    return(
      <Filter {...props}>
        <ReferenceArrayInput label="فیلتر براساس تگ " source="packagePricingTagId" reference="packagePricingTag">
          <SelectInput optionText="name" defaultValue={[]} />
        </ReferenceArrayInput>
        <ReferenceArrayInput label="فیلتر براساس پکیج " source="package" reference="package" allowEmpty>
          <SelectInput optionText="name" />
        </ReferenceArrayInput>
          <BooleanInput  source="isAvailable"/>
          <ReferenceArrayInput label=" نوع " source="type" reference="packagePricing">
              <SelectInput optionText="type" defaultValue={[]} />
          </ReferenceArrayInput>
      </Filter>
)};
export const PackagePricingList = (props) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const newTab = (event,record) => {
    event.stopPropagation()
    window.open(`${window.location.origin}/#/packagePricing/${record.id}/show`,"_blank")
  };
  const handleUserCount = (event,record) => {
    event.stopPropagation()
    let newData = {}
    dataProvider
      .getOne(`packagePricing/duplicate`, { id: record.id })
      .then((res) => {
        notify(`دوپلیکیت شد، منتظر بمانید `, "info");
        newData.id = res.data.id
          setTimeout(() => {
              window.open(`${window.location.origin}/#/packagePricing/${newData.id}`,"_blank")
          }, 1000);
      })
      .catch((er) => {
        return notify(er.message, "error");
      });
  };
  const NewTabBtn = (props) => {
    const { newTab } = props;
    const loading = useLoading();
    return (
        <Button style={{ background: "rgba(46, 125, 50, 1)", color: "white" }}
          onClick={(event) => newTab(event,props.record)}
          disabled={loading}
          startIcon={<OpenInNewOutlinedIcon/>}
        >
            جدید
        </Button>
        );
    };

  return (
    <List {...props} filters={<PackagePricingFilter />}>
      <Datagrid rowClick="show">
        <ReferenceField source="packageId" reference="package" linkType={"show"} sortable={true}>
          <TextField source="name" />
        </ReferenceField>
        <TextField source="type" />
        <NumberField source="priority" />
        <TextField source="title" />
        <TextField source="subtitle" />
        <DuplicateBtn handleUserCount={handleUserCount} />
        <NewTabBtn newTab={newTab} />
        <NumberField source="price" />
        <NumberField source="planAmount" />
        <NumberField source="discountPercent" />
        <TextField source="currency" />
        <TextField source="percent" label=" تخفیف" />
        <ReferenceArrayField source="packagePricingTagIds" reference="packagePricingTag" label="تگ قیمت">
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <TextField source="sku" />
        <DateField locales="fa-IR" source="from" />
        <DateField locales="fa-IR" source="to" />
        <BooleanField source="isAvailable" />
        <ArrayTextField source={"market"} />
        <TextField source="description" />
      </Datagrid>
    </List>
  );
};



export const PackagePricingEdit = (props) => {
  return (
    <EditView
      {...props}
      noDel={true}
      transform={(data) => {
        if (data.from && data.to) {
          let from = new Date(data.from).getTime();
          let to = new Date(data.to).getTime();
          return { ...data, from, to };
        } else {
          return data;
        }
      }}
    >
      <SelectInput source="type" choices={PRICING_CHOICES} validate={required()}/>
      <NumberInput source="priority" validate={required()} />
      <TextInput source="title" validate={required()} />
        <TextInput source="subtitle" />
      <Typography style={{width:400, fontSize:14, color:"red"}}> * در صورتی که SKU را پر کنید میتوانید این فیلد را خالی بگذارید *</Typography>
      <NumberInput source="price" />
      <NumberInput source="planAmount" />
      <NumberInput source="discountPercent" />
      <TextInput source="eachMonth"  />
      <SelectInput source="currency" choices={CURRENCY_CHOICES} defaultValue={"TOMAN"}/>
      <ReferenceArrayInput source="packagePricingTagIds" reference="packagePricingTag">
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>
      <Sku/>
      <TextInput source={"percent"} />
      <MaterialDateInput source="from" options={{ format: "DD/MM/YYYY", clearable: true }}/>
      <MaterialDateInput source="to" options={{ format: "DD/MM/YYYY", clearable: true }}/>
      <BooleanInput source="isAvailable" defaultValue={false} />
      <ImageInput source="pictures" label="تصویر مربوطه" accept="image/*">
        <ImageField source="image" title="تصویر جدید" />
      </ImageInput>
      <ImageField source="image" title={"تصویر فعلی"} label={"تصویر فعلی"} />
      <SelectArrayInput source="market" choices={MARKETS_CHOICES} />
      <TextInput source="description" />
    </EditView>
  );
};

export const PackagePricingCreate = (props) => (
  <CreateView
    {...props}
    transform={(data) => {
      if (data.from && data.to) {
        let from = new Date(data.from).getTime();
        let to = new Date(data.to).getTime();
        return { ...data, from, to };
      } else {
        return data;
      }
    }}
    >
    <ReferenceInput source="packageId" reference="package">
      <SelectInput optionText="name" validate={required()} />
    </ReferenceInput>
    <SelectInput source="type" choices={PRICING_CHOICES} validate={required()}/>
    <NumberInput source="priority" validate={required()} />
    <TextInput source="title" validate={required()} />
    <TextInput source="subtitle" />
    <Typography style={{width:400, fontSize:14, color:"red"}}> * در صورتی که SKU را پر کنید میتوانید این فیلد را خالی بگذارید *</Typography>
    <NumberInput source="price" />
    <NumberInput source="planAmount" />
    <NumberInput source="discountPercent" />
    <SelectInput source="currency" choices={CURRENCY_CHOICES} defaultValue={"TOMAN"}/>
    <TextInput source="eachMonth"  />
    <Sku/>
    <ReferenceArrayInput source="packagePricingTagIds" reference="packagePricingTag">
      <SelectArrayInput optionText="name" />
    </ReferenceArrayInput>
    <TextInput source={"percent"} />
    <MaterialDateInput source="from" options={{ format: "DD/MM/YYYY", clearable: true }}/>
    <BooleanInput source="isAvailable" defaultValue={true} />
    <MaterialDateInput source="to" options={{ format: "DD/MM/YYYY", clearable: true }}/>
    <ImageInput source="pictures" label="تصویر مربوطه" accept="image/*">
      <ImageField source="src" title="image" />
    </ImageInput>
    <SelectArrayInput source="market" choices={MARKETS_CHOICES} />
    <TextInput source="description" />
  </CreateView>
);

export const PackagePricingShow = (props) => {
    const handleUserCount = (event,record) => {
        event.stopPropagation()
        let newData = {}
        axiosFetcher.get(`packagePricing/duplicate/${record.id}`)
            .then((res) => {
                newData.id = res.data.id
            })
            .catch((err) => {
                console.log(err);
            });
        setTimeout(() => {
            window.open(`${window.location.origin}/#/packagePricing/${newData.id}`,"_blank")
        }, 2500);
    };
  return (
    <ShowView {...props}>
      <SimpleShowLayout>
        <TextField source="id" />
        <ReferenceField source="packageId" reference="package">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="type" />
        <NumberField source="priority" />
        <TextField source="title" />
        <TextField source="subtitle" />
        <NumberField source="price" />
        <NumberField source="planAmount" />
        <NumberField source="discountPercent" />
        <TextField source="currency" />
        <TextField source="sku" />
        <TextField source="eachMonth" />
        <ReferenceArrayField  source="packagePricingTagIds"  reference="packagePricingTag"  label="تگ قیمت">
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <ArrayTextField source="market" />
        <TextField source="percent" />
        <BooleanField source="isAvailable" />
        <ImageField source="image" />
        <DateField locales={"fa-IR"} source={"from"} />
        <DateField locales={"fa-IR"} source={"to"} />
        <TextField source="description" />
        <DuplicateBtn  handleUserCount={handleUserCount}/>
      </SimpleShowLayout>
    </ShowView>
  );
};
