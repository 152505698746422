import React from "react";
import {
  Datagrid,
  DateField,
  List,
  required,
  SelectInput,
  SimpleShowLayout,
  TextField,
  TextInput,
} from "react-admin";
import EditView from "../../Components/EditView";
import ShowView from "../../Components/ShowView";
import CreateView from "../../Components/CreateView";
import {
  CONFIG_TYPE as configChoices,
} from "../../Constants/choices";

export const ConfigList = (props) => (
  <List {...props}>
    <Datagrid rowClick="show">
      <TextField source="type" />
      <TextField source="value" />
      <TextField source="id" />
      <DateField locales={"fa-IR"} source="updatedAt" />
    </Datagrid>
  </List>
);

export const ConfigEdit = (props) => (
  <EditView {...props}>
    <TextField source="type" />
    <TextInput source="value" />
    <TextField source="id" />
  </EditView>
);

export const ConfigCreate = (props) => (
  <CreateView {...props}>
    <SelectInput
      choices={configChoices}
      optionText={"name"}
      optionValue={"value"}
      source={"type"}
      validate={required()}
    />
    <TextInput source="value" validate={required()} />
  </CreateView>
);

export const ConfigShow = (props) => (
  <ShowView {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="type" />
      <TextField source="value" />
      <DateField locales={"fa-IR"} source="updatedAt" />
    </SimpleShowLayout>
  </ShowView>
);
