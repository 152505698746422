// NOTE: delete btn works

import React from "react";
import {
    BooleanField,
    Datagrid,
    DateField,
    List,
    NumberField,
    NumberInput,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    SimpleShowLayout,
    TextField,
    required, Filter, AutocompleteInput
} from "react-admin";
import EditView from "../../Components/EditView";
import CreateView from "../../Components/CreateView";
import ShowView from "../../Components/ShowView";

const ExerciseFactFilter = (props) => (
    <Filter {...props}>
        <ReferenceInput
            label="جست‌وجو"
            source="exerciseId"
            reference="exercise"
            alwaysOn
            filterToQuery={(searchText) => ({ name: searchText })}
        >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
    </Filter>
);

export const ExerciseFactList = (props) => (
  <List {...props} filters={<ExerciseFactFilter />} perPage={15}>
    <Datagrid rowClick="show">
      <ReferenceField source="exerciseId" reference="exercise">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="exerciseUnitId" reference="exerciseUnit">
        <TextField source="name" />
      </ReferenceField>
      <NumberField source="met" />
      <DateField locales="fa-IR" source="updatedAt" />
      <BooleanField source="isDeleted" />
    </Datagrid>
  </List>
);

export const ExerciseFactEdit = (props) => (
  <EditView {...props}>
    <ReferenceInput source="exerciseId" reference="exercise" perPage="200" validate={required()}>
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput source="exerciseUnitId" reference="exerciseUnit" validate={required()}>
      <SelectInput optionText="name" />
    </ReferenceInput>
    <NumberInput source="met"  validate={required()}/>
  </EditView>
);

export const ExerciseFactCreate = (props) => (
  <CreateView {...props}>
    <ReferenceInput source="exerciseId" reference="exercise" perPage="200" validate={required()}>
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput source="exerciseUnitId" reference="exerciseUnit" validate={required()}>
      <SelectInput optionText="name" />
    </ReferenceInput>
    <NumberInput source="met" validate={required()} />
  </CreateView>
);

export const ExerciseFactShow = (props) => (
  <ShowView {...props}>
    <SimpleShowLayout>
      <BooleanField source="isDeleted" />
      <ReferenceField source="exerciseId" reference="exercise">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="exerciseUnitId" reference="exerciseUnit">
        <TextField source="name" />
      </ReferenceField>
      <NumberField source="met" />
      <TextField source="id" />
      <DateField locales="fa-IR" source="updatedAt" />
    </SimpleShowLayout>
  </ShowView>
);
