import { SERVER } from "../Constants";
import {httpClient} from "../utils/request";
import { stringify } from "query-string";
const apiUrl = SERVER;

export function deleteMany(resource, params) {
  const query = {
    filter: JSON.stringify({ id: params.ids}),
  };
  return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
    method: "DELETE",
    body: JSON.stringify(params.data),
  }).then(({ json }) => ({ data: json }));
};