import React from "react";
import ShowActions from "./ShowActions";
import {Show} from "react-admin";
import PropTypes from "prop-types";

ShowView.propTypes = {
  noDel: PropTypes.bool,
};

function ShowView(props) {
  const { noDel = false } = props;

  return (
    <Show {...props} actions={<ShowActions noDel={noDel} />}>
      {props.children}
    </Show>
  );
}

export default ShowView;
