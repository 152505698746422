import React, { createElement, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useMediaQuery } from "@material-ui/core";
import {
  DashboardMenuItem,
  getResources,
  MenuItemLink,
  usePermissions,
  useTranslate,
} from "react-admin";

// Import Icons ---->
import PeopleIcon from "@material-ui/icons/People";
import StoreIcon from "@material-ui/icons/Store";
import GetAppIcon from "@material-ui/icons/GetApp";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import SettingsIcon from "@material-ui/icons/Settings";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import MoreIcon from "@material-ui/icons/More";
import DirectionsRunIcon from "@material-ui/icons/DirectionsRun";

import SubMenu from "./SubMenu";
import { food } from "../Panels/Food";
import { exercise } from "../Panels/Exercise";
import { ROLES } from "../Constants";

const Menu = ({ onMenuClick, dense, logout }) => {
  const [state, setState] = useState({
    menuFood: false,
    menuExercise: false,
    menuExerciseClass: false,
    menuMarketing: false,
    menuUsers: false,
    menuMisc: false,
    menuAB: false,
    menuShop: false,
    menuFAQ: false,
    menuDownload: false,
  });
  const resources = useSelector(getResources);
  const translate = useTranslate();
  const { permissions } = usePermissions();
  const isXsmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  const userMenuData = resources.filter((res) => res.options.cat === "users");
  const marketingMenuData = resources.filter(
    (res) => res.options.cat === "marketing"
  );
  const exerciseMenuData = resources.filter(
    (res) => res.options.cat === "exercise"
  );
  const foodMenuData = resources.filter((res) => res.options.cat === "food");
  const miscMenuData = resources.filter((res) => res.options.cat === "misc");
  const abData = resources.filter((res) => res.options.cat === "a/b");
  const exerciseClass = resources.filter(
    (res) => res.options.cat === "exerciseClass"
  );
  const FAQMenuData = resources.filter((res) => res.options.cat === "FAQ");
  const DownloadData = resources.filter((res) => res.options.cat === "Download");
  //const PriceData = resources.filter((res) => res.options.cat === "Price");
  const Shop = resources.filter((res) => res.options.cat === "Shop");
  const ApiData = resources.filter((res) => res.options.cat === "API");
  const clinicData = resources.filter((res) => res.options.cat === "clinic");
  useSelector((state) => state.theme); // force rerender on theme change
  const handleToggle = (menu) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };

  

  return (
    <div style={{ marginTop: "3em" }}>
      {" "}
      <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open} />
      {permissions === ROLES.SUPER_ADMIN || permissions === ROLES.FOOD_ADMIN ? (
        <>
          <SubMenu
            handleToggle={() => handleToggle("menuFood")}
            isOpen={state.menuFood}
            sidebarIsOpen={open}
            name="karafs.menu.food"
            icon={<food.icon />}
            dense={dense}
          >
            {foodMenuData.map((resource) => {
              return (
                <MenuItemLink
                  key={resource.name}
                  to={`/${resource.name}`}
                  primaryText={translate(`resources.${resource.name}.name`, {
                    smart_count: 2,
                  })}
                  leftIcon={createElement(resource.icon)}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              );
            })}
          </SubMenu>
          <SubMenu
            handleToggle={() => handleToggle("menuExercise")}
            isOpen={state.menuExercise}
            sidebarIsOpen={open}
            name="karafs.menu.exercise"
            icon={<DirectionsRunIcon />}
            dense={dense}
          >
            {exerciseMenuData.map((resource) => {
              return (
                <MenuItemLink
                  key={resource.name}
                  to={`/${resource.name}`}
                  primaryText={translate(`resources.${resource.name}.name`, {
                    smart_count: 2,
                  })}
                  leftIcon={createElement(resource.icon)}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              );
            })}
          </SubMenu>
        </>
      ) : null}
      {permissions === ROLES.SUPER_ADMIN ? (
        <SubMenu
          handleToggle={() => handleToggle("menuExerciseClass")}
          isOpen={state.menuExerciseClass}
          sidebarIsOpen={open}
          name="karafs.menu.exerciseClass"
          icon={<exercise.icon />}
          dense={dense}
        >
          {exerciseClass.map((resource) => {
            return (
              <MenuItemLink
                key={resource.name}
                to={`/${resource.name}`}
                primaryText={translate(`resources.${resource.name}.name`, {
                  smart_count: 2,
                })}
                leftIcon={createElement(resource.icon)}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
            );
          })}
        </SubMenu>
      ) : null}
      {permissions === ROLES.SUPER_ADMIN ||
      permissions === ROLES.MARKETING_ADMIN ? (
        <SubMenu
          handleToggle={() => handleToggle("menuMarketing")}
          isOpen={state.menuMarketing}
          sidebarIsOpen={open}
          name="karafs.menu.marketing"
          icon={<StoreIcon />}
          dense={dense}
        >
          {marketingMenuData.map((resource) => {
            return (
              <MenuItemLink
                key={resource.name}
                to={`/${resource.name}`}
                primaryText={translate(`resources.${resource.name}.name`, {
                  smart_count: 2,
                })}
                leftIcon={createElement(resource.icon)}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
            );
          })}
        </SubMenu>
      ) : null}
      {permissions === ROLES.SUPER_ADMIN ||
      permissions === ROLES.MARKETING_ADMIN ? (
        <SubMenu
          handleToggle={() => handleToggle("menuAB")}
          isOpen={state.menuAB}
          sidebarIsOpen={open}
          name="karafs.menu.a/b"
          icon={<StoreIcon />}
          dense={dense}
        >
          {abData.map((resource) => {
            return (
              <MenuItemLink
                key={resource.name}
                to={`/${resource.name}`}
                primaryText={translate(`resources.${resource.name}.name`, {
                  smart_count: 2,
                })}
                leftIcon={createElement(resource.icon)}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
            );
          })}
        </SubMenu>
      ) : null}
      {permissions === ROLES.SUPER_ADMIN ||
      permissions === ROLES.USERS_ADMIN ||
      permissions === ROLES.MARKETING_ADMIN ? (
        <SubMenu
          handleToggle={() => handleToggle("menuFAQ")}
          isOpen={state.menuFAQ}
          sidebarIsOpen={open}
          name="karafs.menu.FAQ"
          icon={<ContactSupportIcon />}
          dense={dense}
        >
          {FAQMenuData.map((resource) => {
            return (
              <MenuItemLink
                key={resource.name}
                to={`/${resource.name}`}
                primaryText={translate(`resources.${resource.name}.name`, {
                  smart_count: 2,
                })}
                leftIcon={createElement(resource.icon)}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
            );
          })}
        </SubMenu>
      ) : null}
      {permissions === ROLES.SUPER_ADMIN ||
      permissions === ROLES.USERS_ADMIN ? (
        <SubMenu
          handleToggle={() => handleToggle("menuUsers")}
          isOpen={state.menuUsers}
          sidebarIsOpen={open}
          name="karafs.menu.users"
          icon={<PeopleIcon />}
          dense={dense}
        >
          {userMenuData.map((resource) => {
            return (
              <MenuItemLink
                key={resource.name}
                to={`/${resource.name}`}
                primaryText={translate(`resources.${resource.name}.name`, {
                  smart_count: 2,
                })}
                leftIcon={createElement(resource.icon)}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
            );
          })}
        </SubMenu>
      ) : null}
      {permissions === ROLES.SUPER_ADMIN ||
      permissions === ROLES.MARKETING_ADMIN ? (
        <SubMenu
          handleToggle={() => handleToggle("menuDownload")}
          isOpen={state.menuDownload}
          sidebarIsOpen={open}
          name="karafs.menu.downloadLink"
          icon={<GetAppIcon />}
          dense={dense}
        >
          {DownloadData.map((resource) => {
            return (
              <MenuItemLink
                key={resource.name}
                to={`/${resource.name}`}
                primaryText={translate(`resources.${resource.name}.name`, {
                  smart_count: 2,
                })}
                leftIcon={createElement(resource.icon)}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
            );
          })}
        </SubMenu>
      ) : null}
      {permissions === ROLES.SUPER_ADMIN ||
      permissions === ROLES.USERS_ADMIN ||
      ROLES.MARKETING_ADMIN ? (
        <SubMenu
          handleToggle={() => handleToggle("menuShop")}
          isOpen={state.menuShop}
          sidebarIsOpen={open}
          name="karafs.menu.shop"
          icon={<MonetizationOnIcon />}
          dense={dense}
        >
          {Shop.map((resource) => {
            return (
              <MenuItemLink
                key={resource.name}
                to={`/${resource.name}`}
                primaryText={translate(`resources.${resource.name}.name`, {
                  smart_count: 2,
                })}
                leftIcon={createElement(resource.icon)}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
            );
          })}
        </SubMenu>
      ) : null}
      {permissions === ROLES.SUPER_ADMIN ||
      permissions === ROLES.MARKETING_ADMIN ? (
        <SubMenu
          handleToggle={() => handleToggle("menuApies")}
          isOpen={state.menuApies}
          sidebarIsOpen={open}
          name="karafs.menu.Apies"
          icon={<SettingsIcon />}
          dense={dense}
        >
          {ApiData.map((resource) => {
            return (
              <MenuItemLink
                key={resource.name}
                to={`/${resource.name}`}
                primaryText={translate(`resources.${resource.name}.name`, {
                  smart_count: 2,
                })}
                leftIcon={createElement(resource.icon)}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
            );
          })}
        </SubMenu>
      ) : null}
      {permissions === ROLES.SUPER_ADMIN ||
      permissions === ROLES.USERS_ADMIN ? (
        <SubMenu
          handleToggle={() => handleToggle("menuMisc")}
          isOpen={state.menuMisc}
          sidebarIsOpen={open}
          name="karafs.menu.misc"
          icon={<MoreIcon />}
          dense={dense}
        >
          {miscMenuData.map((resource) => {
            return (
              <MenuItemLink
                key={resource.name}
                to={`/${resource.name}`}
                primaryText={translate(`resources.${resource.name}.name`, {
                  smart_count: 2,
                })}
                leftIcon={createElement(resource.icon)}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
            );
          })}
        </SubMenu>
      ) : null}
      {permissions === ROLES.SUPER_ADMIN || permissions === ROLES.DOCTOR ||
      permissions === ROLES.USERS_ADMIN ? (
          <SubMenu
              handleToggle={() => handleToggle("menuClinic")}
              isOpen={state.menuClinic}
              sidebarIsOpen={open}
              name="karafs.menu.clinic"
              icon={<SettingsIcon />}
              dense={dense}
          >
            {clinicData.map((resource) => {
              return (
                  <MenuItemLink
                      key={resource.name}
                      to={`/${resource.name}`}
                      primaryText={translate(`resources.${resource.name}.name`, {
                        smart_count: 2,
                      })}
                      leftIcon={createElement(resource.icon)}
                      onClick={onMenuClick}
                      sidebarIsOpen={open}
                      dense={dense}
                  />
              );
            })}
          </SubMenu>
      ) : null}
      {isXsmall && logout}
    </div>
  );
};

Menu.propTypes = {
  onMenuClick: PropTypes.func,
  logout: PropTypes.object,
};

export default Menu;
