import FastfoodIcon from "@material-ui/icons/Fastfood";
import {
  PackagePricingCreate,
  PackagePricingEdit,
  PackagePricingList,
  PackagePricingShow,
} from "./PackagePricing";

export const packagePricing = {
  name: "packagePricing",
  list: PackagePricingList,
  edit: PackagePricingEdit,
  show: PackagePricingShow,
  create: PackagePricingCreate,
  icon: FastfoodIcon,
  options: { cat: "Price" },
};
