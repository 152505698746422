import ClinicUserList from "./ClinicUserList";
import VerifiedUserIcon from "@material-ui/icons/SupervisedUserCircle";
import ClinicUserEdit from "./ClinicUserEdit";

export const clinic = {
    name:'clinicUser',
    list:ClinicUserList,
    edit:ClinicUserEdit,
    icon: VerifiedUserIcon,
    options: { cat: "clinic" },
}