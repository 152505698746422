import {
  DATA_PAGE_NAMES_ENUMS,
  MARKET_ENUMS,
  Notification_Data_Goal_Enums,
  PAGE_ACTIONS_NAMES_ENUMS,
  PAGE_DATA_TYPES_ENUMS,
  POPUP_DATA_ENUMS,
  PRICING_ENUMS,
  CURRENCY_ENUMS,
  USER_ACTIVITY_LEVEL_ENUMS,
  USER_BREAST_FEEDING_STATE_ENUMS,
  NEW_USER_BREAST_FEEDING_STATE_ENUMS,
  USER_GENDER_ENUMS,
  UpdatePopUpData_Type_Enums,
  RemoteConfigPageNames_Enums,
  RemoteConfigPageTypes_Enums,
  Advice_Enums,
} from "./enums";

export const MARKETS_CHOICES = [
  {
    id: MARKET_ENUMS.NONE,
    name: "هیچکدام",
  },
  {
    id: MARKET_ENUMS.GOOGLE_PLAY_BETA,
    name: "گوگل پلی بتا",
  },
  {
    id: MARKET_ENUMS.GOOGLE_PLAY,
    name: "گوگل پلی",
  },
  {
    id: MARKET_ENUMS.CAFE_BAZAAR,
    name: "کافه بازار",
  },
  {
    id: MARKET_ENUMS.MYKET,
    name: "مایکت",
  },
  {
    id: MARKET_ENUMS.IOS_TESTFLIGHT,
    name: "IOS",
  },
  {
    id: MARKET_ENUMS.IOS_SIBAPP,
    name: "سیب اپ",
  },
  {
    id: MARKET_ENUMS.IOS_APP_STORE,
    name: "اپ استور IOS",
  },
  {
    id: MARKET_ENUMS.APP_STORE,
    name: "اپ استور",
  },
  {
    id: MARKET_ENUMS.SAAM,
    name: "SAAM",
  },
  {
    id: MARKET_ENUMS.PWA,
    name: "PWA",
  },
  {
    id: MARKET_ENUMS.SNAP_PAY,
    name: "SNAP_PAY",
  },
];
export const MARKETS_CHOICES_NOTIFICATION = [
  {
    id: MARKET_ENUMS.GOOGLE_PLAY_BETA,
    name: "گوگل پلی بتا",
  },
  {
    id: MARKET_ENUMS.GOOGLE_PLAY,
    name: "گوگل پلی",
  },
  {
    id: MARKET_ENUMS.CAFE_BAZAAR,
    name: "کافه بازار",
  },
  {
    id: MARKET_ENUMS.MYKET,
    name: "مایکت",
  },
  {
    id: MARKET_ENUMS.IOS_TESTFLIGHT,
    name: "IOS",
  },
  {
    id: MARKET_ENUMS.IOS_SIBAPP,
    name: "سیب اپ",
  },
  {
    id: MARKET_ENUMS.SAAM,
    name: "SAAM",
  },
  {
    id: MARKET_ENUMS.PWA,
    name: "PWA",
  },
];

export const PRICING_CHOICES = [
  {
    id: PRICING_ENUMS.REGULAR_PAID,
    name: "پرداخت عادی",
  },
  {
    id: PRICING_ENUMS.FIRST_PAY_SALE,
    name: "اولین پرداخت",
  },
  {
    id: PRICING_ENUMS.FIRST_PAY_SALE_TIME_LIMITED,
    name: "اولین پرداخت با زمان محدود",
  },
  {
    id: PRICING_ENUMS.SECOND_PAY_SALE_TIME_LIMITED,
    name: "دومین پرداخت با زمان محدود",
  },
  { id: PRICING_ENUMS.GIFT, name: "هدیه" },
  { id: PRICING_ENUMS.CAMPAIGN, name: "کمپین" },
  { id: PRICING_ENUMS.TRIAL, name: "آزمایشی" },
];
export const CURRENCY_CHOICES = [
  { id: CURRENCY_ENUMS.TOMAN, name: "تومان" },
  { id: CURRENCY_ENUMS.DOLLAR, name: "دلار" },
  { id: CURRENCY_ENUMS.EURO, name: "یورو" },
];

export const PAGE_DATA_TYPES_CHOICES = [
  {
    id: PAGE_DATA_TYPES_ENUMS.GOAL_SCENARIO_TYPE_A,
    name: "نوع سناریو هدف (آ) - (GOAL_SCENARIO_TYPE_A)",
  },
  {
    id: PAGE_DATA_TYPES_ENUMS.GOAL_SCENARIO_TYPE_B,
    name: "نوع سناریو هدف (ب) - (GOAL_SCENARIO_TYPE_B)",
  },
  {
    id: PAGE_DATA_TYPES_ENUMS.GOAL_SCENARIO_TYPE_C,
    name: "نوع سناریو هدف (ث) - (GOAL_SCENARIO_TYPE_C)",
  },
  {
    id: PAGE_DATA_TYPES_ENUMS.GOAL_SCENARIO_TYPE_D,
    name: "نوع سناریو هدف (د) - (GOAL_SCENARIO_TYPE_D)",
  },
  {
    id: PAGE_DATA_TYPES_ENUMS.SHOP_PREMIUM_WITH_BUTTON,
    name: "فروشگاه پرمیوم با دکمه - (SHOP_PREMIUM_WITH_BUTTON)",
  },
  {
    id: PAGE_DATA_TYPES_ENUMS.SHOP_PREMIUM_WITH_SCROLL_CONTENT,
    name:
      "فروشگاه پرمیوم با محتوای اسکرول - (SHOP_PREMIUM_WITH_SCROLL_CONTENT)",
  },
  {
    id: PAGE_DATA_TYPES_ENUMS.SHOP_PREMIUM_WITH_SCROLL_PAGE,
    name: " فروشگاه پرمیوم با صفحه اسکرول  - (SHOP_PREMIUM_WITH_SCROLL_PAGE)",
  },
  {
    id: PAGE_DATA_TYPES_ENUMS.ADVICE_ACTIONABLE,
    name: "توصیه اکشن دار - (ADVICE_ACTIONABLE)",
  },
  {
    id: PAGE_DATA_TYPES_ENUMS.ADVICE_NON_ACTIONABLE,
    name: "توصیه بی اکشن - (ADVICE_NON_ACTIONABLE)",
  },
  {
    id: PAGE_DATA_TYPES_ENUMS.AFTER_REGISTER_TEXT_DEFAULT,
    name: "پیشفرض صفحه بعد از ثبت نام - (AFTER_REGISTER_TEXT_DEFAULT)",
  },
  {
    id: PAGE_DATA_TYPES_ENUMS.AFTER_REGISTER_TEXT_BMI,
    name:
      "نمایش صفحه بعد از ثبت نام بر اساس بی ام آی - (AFTER_REGISTER_TEXT_BMI)",
  },
  {
    id: PAGE_DATA_TYPES_ENUMS.TYPE_F,
    name: " نوع اف (TYPE_F)",
  },
  {
    id: PAGE_DATA_TYPES_ENUMS.TYPE_G,
    name: " نوع جی (TYPE_G)",
  },
  {
    id: PAGE_DATA_TYPES_ENUMS.TYPE_H,
    name: " نوع اچ (TYPE_H)",
  },
  {
    id: PAGE_DATA_TYPES_ENUMS.NOTIFICATION_FREQ_0,
    name: " ارسال نوتیفیکیشن با فرکانس 0- (NOTIFICATION_FREQ_0) ",
  },
  {
    id: PAGE_DATA_TYPES_ENUMS.NOTIFICATION_FREQ_0_1,
    name: " ارسال نوتیفیکیشن با فرکانس 0.1 - (NOTIFICATION_FREQ_0_1) ",
  },
  {
    id: PAGE_DATA_TYPES_ENUMS.NOTIFICATION_FREQ_0_3,
    name: " ارسال نوتیفیکیشن با فرکانس 0.3- (NOTIFICATION_FREQ_0_3) ",
  },
  {
    id: PAGE_DATA_TYPES_ENUMS.NOTIFICATION_FREQ_0_5,
    name: " ارسال نوتیفیکیشن با فرکانس 0.5 - (NOTIFICATION_FREQ_0_5) ",
  },
  {
    id: PAGE_DATA_TYPES_ENUMS.NOTIFICATION_FREQ_0_7,
    name: " ارسال نوتیفیکیشن با فرکانس 0.7 - (NOTIFICATION_FREQ_0_7) ",
  },
  {
    id: PAGE_DATA_TYPES_ENUMS.NOTIFICATION_FREQ_0_9,
    name: " ارسال نوتیفیکیشن با فرکانس 0.9 - (NOTIFICATION_FREQ_0_9) ",
  },

  {
    id: PAGE_ACTIONS_NAMES_ENUMS.SHOP_CARD_POPULAR_CARD,
    name: "نشان دادن محبوب ترین کارت - (SHOP_CARD_POPULAR_CARD)",
  },
  {
    id: PAGE_ACTIONS_NAMES_ENUMS.SHOP_FEATURES_DETAILS_IN_POP_UP,
    name: "نشان دادن امکانات در پاپ آپ - (SHOP_FEATURES_DETAILS_IN_POP_UP)",
  },
  {
    id: PAGE_ACTIONS_NAMES_ENUMS.SHOP_FEATURES_STATIC_WITHOUT_SCROLL_DETAILS,
    name:
      "  نشان دادن امکانات بدون اسکرول - (SHOP_FEATURES_STATIC_WITHOUT_SCROLL_DETAILS)",
  },
  {
    id: PAGE_ACTIONS_NAMES_ENUMS.SHOP_FEATURES_STATIC_WITH_SCROLL_DETAILS,
    name:
      " نشان دادن توضیحات امکانات با اسکرول - (SHOP_FEATURES_STATIC_WITH_SCROLL_DETAILS)",
  },
  {
    id: PAGE_ACTIONS_NAMES_ENUMS.SHOP_CARD_SALE_DETAILS,
    name: "نشان دادن سود خرید - (SHOP_CARD_SALE_DETAILS)",
  },
  {
    id: PAGE_ACTIONS_NAMES_ENUMS.SHOP_CARD_ACTION_WITH_BUTTON,
    name: "خرید با نشان دادن دکمه - (SHOP_CARD_ACTION_WITH_BUTTON)",
  },
  {
    id: PAGE_ACTIONS_NAMES_ENUMS.SHOP_CARD_ACTION_WITHOUT_BUTTON,
    name: "خرید بدون نشان دادن دکمه- (SHOP_CARD_ACTION_WITHOUT_BUTTON)",
  },
  {
    id: PAGE_ACTIONS_NAMES_ENUMS.SHOP_CARD_ADVICE_WEIGHT_CHANGE_DETAILS,
    name: " نشان دادن تغییرات وزن -(SHOP_CARD_ADVICE_WEIGHT_CHANGE_DETAILS)",
  },
  {
    id: PAGE_ACTIONS_NAMES_ENUMS.SHOP_CARD_ADVICE_EMPTY,
    name: "نشان ندادن تغییرات وزن - (SHOP_CARD_ADVICE_EMPTY)",
  },
  {
    id: PAGE_ACTIONS_NAMES_ENUMS.SHOP_CARD_ADVICE_WEIGHT_CHANGE_BANNER,
    name:
      "نشان دادن تغییرات وزن با بنر - (SHOP_CARD_ADVICE_WEIGHT_CHANGE_BANNER)",
  },
];

export const DATA_PAGE_NAMES_CHOICES = [
  {
    id: DATA_PAGE_NAMES_ENUMS.GOAL_SCENARIO_TYPE,
    name: "نوع سناریو هدف",
  },
  {
    id: DATA_PAGE_NAMES_ENUMS.ADVICE,
    name: "توصیه",
  },
  {
    id: DATA_PAGE_NAMES_ENUMS.SHOP,
    name: "فروشگاه",
  },
  {
    id: DATA_PAGE_NAMES_ENUMS.SHOP_CARD_SALE,
    name: "کارتهای فروشگاه ",
  },
  {
    id: DATA_PAGE_NAMES_ENUMS.SHOP_DESIGN,
    name: " طراحی فروشگاه",
  },
  {
    id: DATA_PAGE_NAMES_ENUMS.INNER_NOTIFICATION_TYPE,
    name: "نوع اعلانات داخلی",
  },
  {
    id: DATA_PAGE_NAMES_ENUMS.AFTER_REGISTER_TEXT_TYPE,
    name: "نوع صفحه بعد ثبت نام",
  },
];
export const REMOTE_CONFIG_PAGE_NAME_CHOICES = [
  {
    id: RemoteConfigPageNames_Enums.SHOP,
    name: " شاپ SHOP",
  },
  {
    id: RemoteConfigPageNames_Enums.AFTER_REGISTRATION,
    name: "پس از ثبت نام AFTER_REGISTRATION ",
  },
  {
    id: RemoteConfigPageNames_Enums.INNER_NOTIFICATION_TYPE,
    name: "نوع اعلان داخلی INNER_NOTIFICATION_TYPE",
  },
  {
    id: RemoteConfigPageNames_Enums.GOAL_SCENARIO_TYPE,
    name: "نوع سناریو هدف GOAL_SCENARIO_TYPE ",
  },
  {
    id: RemoteConfigPageNames_Enums.INVITE_FRIEND_TEXT,
    name: " متن دعوت از دوستان INVITE_FRIEND_TEXT",
  },
  {
    id: RemoteConfigPageNames_Enums.INVITE_FRIEND_FULL_TEXT,
    name: " متن  کل دعوت از دوستان INVITE_FRIEND_TEXT",
  },
  {
    id: RemoteConfigPageNames_Enums.INVITE_FRIEND_POPUP_NO_CODE_TEXT,
    name: "  INVITE_FRIEND_POPUP_NO_CODE_TEXT",
  },
  {
    id: RemoteConfigPageNames_Enums.INVITE_FRIEND_POPUP_TEXT,
    name: "  INVITE_FRIEND_POPUP_TEXT",
  },
  {
    id: RemoteConfigPageNames_Enums.INVITE_FRIEND_SHARE_TEXT,
    name: "INVITE_FRIEND_SHARE_TEXT",
  },
];

export const REMOTE_CONFIG_PAGE_TYPE_CHOICES = [
  {
    id: RemoteConfigPageTypes_Enums.TYPE_F,
    name: "نوع اف TYPE_F ",
  },
  {
    id: RemoteConfigPageTypes_Enums.TYPE_G,
    name: "نوع جی TYPE_G ",
  },
  {
    id: RemoteConfigPageTypes_Enums.TYPE_H,
    name: "نوع اچ TYPE_H ",
  },
  {
    id: RemoteConfigPageTypes_Enums.AFTER_REGISTER_TEXT_DEFAULT,
    name: " پس از ثبت نام متن پیشفرض AFTER_REGISTER_TEXT_DEFAULT ",
  },
  {
    id: RemoteConfigPageTypes_Enums.AFTER_REGISTER_TEXT_BMI,
    name: " پس از ثبت نام متن BMI AFTER_REGISTER_TEXT_BMI ",
  },
  {
    id: RemoteConfigPageTypes_Enums.NOTIFICATION_FREQ_0,
    name: " فرکانس 0  NOTIFICATION_FREQ_0 ",
  },
  {
    id: RemoteConfigPageTypes_Enums.NOTIFICATION_FREQ_0_1,
    name: " فرکانس 0.1  NOTIFICATION_FREQ_0_1 ",
  },
  {
    id: RemoteConfigPageTypes_Enums.NOTIFICATION_FREQ_0_3,
    name: " فرکانس 0.3  NOTIFICATION_FREQ_0_3 ",
  },
  {
    id: RemoteConfigPageTypes_Enums.NOTIFICATION_FREQ_0_5,
    name: " فرکانس 0.5  NOTIFICATION_FREQ_0_5 ",
  },
  {
    id: RemoteConfigPageTypes_Enums.NOTIFICATION_FREQ_0_7,
    name: " فرکانس 0.7  NOTIFICATION_FREQ_0_7 ",
  },
  {
    id: RemoteConfigPageTypes_Enums.NOTIFICATION_FREQ_0_9,
    name: " فرکانس 0.9  NOTIFICATION_FREQ_0_9 ",
  },
  {
    id: RemoteConfigPageTypes_Enums.GOAL_SCENARIO_TYPE_A,
    name: "سناریو نو الف  GOAL_SCENARIO_TYPE_A ",
  },
  {
    id: RemoteConfigPageTypes_Enums.GOAL_SCENARIO_TYPE_B,
    name: "سناریو نو ب  GOAL_SCENARIO_TYPE_B ",
  },
  {
    id: RemoteConfigPageTypes_Enums.GOAL_SCENARIO_TYPE_C,
    name: "سناریو نو سی  GOAL_SCENARIO_TYPE_C ",
  },
  {
    id: RemoteConfigPageTypes_Enums.GOAL_SCENARIO_TYPE_D,
    name: "سناریو نو د  GOAL_SCENARIO_TYPE_D ",
  },
];

export const POPUP_DATA_CHOICES = [
  { id: POPUP_DATA_ENUMS.FIRST_PAY_SALE, name: "فروش اولین پرداخت" },
  { id: POPUP_DATA_ENUMS.OFFER, name: "پیشنهاد" },
  { id: POPUP_DATA_ENUMS.CAMPAIGN, name: "کمپین" },
  { id: POPUP_DATA_ENUMS.GIFT, name: "هدیه" },
  { id: POPUP_DATA_ENUMS.TRIAL, name: "آزمایشی" },
];

export const USER_ACTIVITY_LEVEL_CHOICES = [
  { id: USER_ACTIVITY_LEVEL_ENUMS.VERY_LOW, name: "خیلی کم" },
  { id: USER_ACTIVITY_LEVEL_ENUMS.LOW, name: "کم" },
  { id: USER_ACTIVITY_LEVEL_ENUMS.MODERATE, name: "متوسط" },
  { id: USER_ACTIVITY_LEVEL_ENUMS.HIGH, name: "زیاد" },
  { id: USER_ACTIVITY_LEVEL_ENUMS.VERY_HIGH, name: "خیلی زیاد" },
];

export const CLINIC_USER_ACTIVITY_LEVEL_CHOICES = [
  { id: "sedentary", name: "خیلی کم" },
  { id: "light", name: "کم" },
  { id: "moderate", name: "متوسط" },
  { id: "active", name: "زیاد" },
  { id: "extreme", name: "خیلی زیاد" },
];

export const CLINIC_USER_Difficulty_CHOICES = [
  { id: "easy", name: "آسان" },
  { id: "medium", name: "متوسط" },
  { id: "hard", name: "سخت" },
];

export const USER_BREAST_FEEDING_STATE_CHOICES = [
  { id: USER_BREAST_FEEDING_STATE_ENUMS.INACTIVE, name: "غیرفعال" },
  { id: USER_BREAST_FEEDING_STATE_ENUMS.MILK_ONLY, name: "فقط شیر" },
  { id: USER_BREAST_FEEDING_STATE_ENUMS.MILK_AND_FOOD, name: "شیر و غذا" },
];

export const NEW_USER_BREAST_FEEDING_STATE_CHOICES = [
  { id: NEW_USER_BREAST_FEEDING_STATE_ENUMS.BREASTFEEDING, name: "شیردهی" },
  { id: NEW_USER_BREAST_FEEDING_STATE_ENUMS.PREGNANCY, name: "بارداری" },
  { default: "هیچکدام" },
];

export const USER_GENDER_CHOICES = [
  { id: USER_GENDER_ENUMS.FEMALE, name: "مونث" },
  { id: USER_GENDER_ENUMS.MALE, name: "مذکر" },
];

export const CLINIC_USER_GENDER_CHOICES = [
  { id: USER_GENDER_ENUMS.FEMALE, name: "زن" },
  { id: USER_GENDER_ENUMS.MALE, name: "مرد" },
];


export const Notification_Data_Goal_CHOICES = [
  { id: Notification_Data_Goal_Enums.ALL, name: "همه" },
  { id: Notification_Data_Goal_Enums.HAS_GOAL, name: "هدف‌دار" },
  { id: Notification_Data_Goal_Enums.NO_GOAL, name: "بی‌هدف" },
];

export const UpdatePopUpData_Type = [
  { id: UpdatePopUpData_Type_Enums.FORCE_UPDATE, name: "اپدیت اجباری" },
  { id: UpdatePopUpData_Type_Enums.VOLUNTARY_UPDATE, name: "اپدیت اختیاری " },
];
export const Notification_Data_Sex_Enums = [
  { id: "ALL", name: "همه" },
  { id: "MALE", name: "مرد" },
  { id: "FEMALE", name: "زن" },
];

export const ACTION_NAMES_ENUM = [
  { id: "URL", name: "لینک" },
  { id: "PAGE", name: "صفحه" },
];

export const PAGE_NAMES_ENUM = [
  { id: "SHOP", name: "فروشگاه" },
  { id: "GOAL", name: "هدف" },
  { id: "CHALLENGE", name: "چالش" },
  { id: "CHALLENGES", name: " چالش ها" },
  { id: "HOME", name: "خانه" },
  { id: "AFTER_REGISTRATION", name: "پس از ثبت‌نام" },
  { id: "OFFER_REGISTRATION", name: "پیشنهاد ثبت‌نام" },
  { id: "GOAL_POPUP", name: "پاپ‌اپ هدف" },
  { id: "TRIAL_POPUP", name: "پاپ‌اپ ترایل" },
  { id: "INVITE_FRIEND", name: "دعوت از دوستان " },
];
export const Notification_Data_Goals_Enums = [
  { id: "ALL", name: "همه" },
  { id: "HAS_GOAL", name: "هدف‌دار" },
  { id: "NO_GOAL", name: "بی‌هدف" },
];

export const Notification_Data_Subscription_Enums = [
  { id: "ALL", name: "همه" },
  { id: "HAS_SUBSCRIPTION", name: "اشتراک دارد" },
  { id: "NO_SUBSCRIPTION", name: "بدون اشتراک" },
];
export const EXERCISE_LEVEL_ENUM = [
  { id: "EASY", name: "آسان" },
  { id: "MEDIUM", name: "متوسط" },
  { id: "HARD", name: "دشوار" },
];
export const Roles = [
  { id: "SUPER_ADMIN", name: "ویژه" },
  { id: "FOOD_ADMIN", name: "غذا" },
  { id: "USERS_ADMIN", name: "کاربر" },
  { id: "MARKETING_ADMIN", name: "مارکتینگ" },
  { id: "DOCTOR", name: "دکتر" },
];
export const EXERCISE_TYPE_ENUM = [
  { id: "CLASS", name: "کلاس / CLASS" },
  { id: "EXERCISE", name: "تمرین / EXERCISE" },
];
export const ADVICE_FILTER_CHOICES = [
  { id: Advice_Enums.WEIGHT, name: "وزن" },
  { id: Advice_Enums.WATER, name: "آب" },
  { id: Advice_Enums.STEP, name: "قدم" },
  { id: Advice_Enums.FOOD, name: "غذا" },
  { id: Advice_Enums.ACTIVITY, name: "فعالیت" },
  { id: Advice_Enums.CALORIE, name: "کالری" },
  { id: Advice_Enums.Actionable, name: "فعال" },
  { id: Advice_Enums.NonActionable, name: "غیرفعال" },
];
export const ADVICE_CHOICES = [
  { id: Advice_Enums.WEIGHT, name: "وزن" },
  { id: Advice_Enums.WATER, name: "آب" },
  { id: Advice_Enums.STEP, name: "قدم" },
  { id: Advice_Enums.FOOD, name: "غذا" },
  { id: Advice_Enums.ACTIVITY, name: "فعالیت" },
  { id: Advice_Enums.CALORIE, name: "کالری" },
];
export const CONFIG_TYPE = [
  { id: "CAFE_BAZAAR_ACCESS_TOKEN", name: "Bazaar Access Token" },
  { id: "CAFE_BAZAAR_REFRESH_TOKEN", name: "Bazaar Refresh Token" },
  { id: "TEST_FLIGHT_REDIRECT_URL", name: "Testflight Redirect" },
  { id: "WEBSITE_DOWNLOAD_DEFAULT_URL", name: "Website Default URL" },
  {
    id: "TEST_FLIGHT_REDIRECT_URL_ALTERNATIVE_1",
    name: "Test Flight Redirect Url Alternative 1",
  },
];

export const SHOP_CONDITION_CHOICES = [
  { id: 1, name: "One Day" },
  { id: 7, name: "One Week" },
];

export const REMAINIG_DAY_CONDITION_CHOICES = [
  { id: 0, name: " صفر روز  (بدون اشتراک)" },
  { id: 1, name: " یک روز " },
  { id: 3, name: " سه روز  (2 تا 3 روز)" },
  { id: 7, name: " هفت روز  (4 تا 7 روز)" },
  { id: 30, name: "  یک ماه  (8 تا 30 روز)" },
  { id: 60, name: " دو ماه  (31 تا 60 روز)" },
  { id: 90, name: " سه ماه  (61 تا 90 روز)" },
  { id: 120, name: " چهار ماه  (91 تا 120 روز)" },
  { id: 150, name: " پنج ماه  (121 تا 150 روز)" },
  { id: 180, name: " شش ماه  (151 تا 180 روز)" },
  { id: 210, name: " هفت ماه  (181 تا 210 روز)" },
  { id: 240, name: " هشت ماه  (211 تا 240 روز)" },
  { id: 270, name: " نه ماه  (241 تا 270 روز)" },
  { id: 300, name: " ده ماه  (271 تا 300 روز)" },
  { id: 330, name: " یازده ماه  (301 تا 330 روز)" },
  { id: 360, name: " یک سال  (331 تا 360 روز )" },
  { id: 360 * 2, name: " دو سال  (1 تا 2 سال )" },
  { id: 360 * 3, name: " سه سال  (2 تا 3 سال )" },
  { id: 360 * 4, name: " چهار سال  (3 تا 4 سال )" },
  { id: 360 * 5, name: " پنج سال  (4 سال و بیشتر )" },
];

export const SHOP_PAYMENT_CHOICES = [
  { id: "regular", name: "عادی" },
  { id: "directDebit", name: " (direct Debit)پرداخت مستقیم  " },
  { id: "bazaarPay", name: "بازار" },
  { id: "bazaarDirectPay", name: "bazaarDirectPay" },
  { id: "snapPay", name: "اسنپ" },
];

export const SHOP_PRO_SUBSCRIPTION_CHOICES = [
  { id: 0, name: "false" },
  { id: 1, name: "true" },
];

export const SHOP_SELECT_MARKET = [
  { id: "TOMAN", name: "تومن " },
  { id: "DOLLAR", name: "دلار " },
];

export const PLAN_SUBSCRIPTION_TYPE = [
  { id: "regular", name: "regular (معمولی)" },
  { id: "pro", name: "pro (ویژه)" },
];
export const SHOP_SUBSCRIPTION_TYPE = [
  { id: "regular", name: "regular" },
  { id: "pro", name: "pro" },
  { id: "upgrade", name: "upgrade" },
];
