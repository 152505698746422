import TrackerList from "./TrackerList";
import TrackerShow from "./TrackerShow";
import TrackerCreate from "./TrackerCreate";
import TrackerEdit from "./TrackerEdit";
import BorderVerticalIcon from "@material-ui/icons/BorderVertical";

export const tracker = {
    name:'tracker',
    list:TrackerList,
    show:TrackerShow,
    create:TrackerCreate,
    edit:TrackerEdit,
    icon:BorderVerticalIcon,
    options:{cat:'marketing'}
}