import React, { useState } from "react";
import { useForm } from "react-hook-form";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import {
  Dialog,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import {
  DateRange as DateRangeIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Search as SearchIcon,
} from "@material-ui/icons";
import {
  Confirm,
  Loading,
  useDataProvider,
  useMutation,
  useNotify,
  useRedirect,
  useTranslate,
} from "react-admin";
import { ROLES } from "../../Constants";

const initData = [
  {
    user: {
      activityLevel: "",
      birthdate: "",
      breastFeedingState: "",
      height: "",
      isRamadan: false,
      objectId: "",
      phoneNumber: "",
      sex: "",
      weight: "",
      error: "",
      email: null,
    },
  },
];
const DeleteButton = ({ phoneNum, setData }) => {
  const [open, setOpen] = useState(false);
  const notify = useNotify();
  const redirect = useRedirect();
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const [deleteUser, { loading }] = useMutation(
    {
      type: "delete",
      resource: "user",
      payload: { id: phoneNum },
    },
    {
      onSuccess: ({ data }) => {
        redirect("/user");
        setOpen(false);
        setData(initData);
        notify("کاربر حذف شد");
      },
      onFailure: (error) =>
        notify(`خطایی پیش آمد: ${error.message}`, "warning"),
    }
  );
  return (
    <>
      <IconButton aria-label="delete" onClick={handleClick} disabled={loading}>
        <DeleteIcon />
      </IconButton>
      <Confirm
        isOpen={open}
        loading={loading}
        title="حذف کاربر!"
        content="آیا از حذف این کاربر مطمئن هستید؟"
        onConfirm={deleteUser}
        onClose={handleDialogClose}
      />
    </>
  );
};
const CodeListButton = ({ sentCodes }) => {
  const [open, setOpen] = useState(false);
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  return (
    <>
      <IconButton aria-label="delete" onClick={handleClick}>
        <DateRangeIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleDialogClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Paper style={{ width: "500px" }}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>کد</TableCell>
                <TableCell>تاریخ</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sentCodes &&
                sentCodes.map((sentCode, index) => (
                  <TableRow key={sentCode.sentAt}>
                    <TableCell component="th" scope="row" align="center">
                      {index + 1}
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      {sentCode.code}
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      {new Date(sentCode.sentAt).toLocaleString("fa-IR")}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Paper>
      </Dialog>
    </>
  );
};
export const User = (props) => {

  const { register, handleSubmit } = useForm(); // initialise the hook
  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [data, setData] = useState(initData);
  const translate = useTranslate();
  const onSubmit = (phoneNum) => {
    setLoading(true);
    setError("");
    dataProvider
      .getOne("user", { id: phoneNum.phone  })
      .then((res) => {
        const { data } = res;
        setData([data]);
        setLoading(false);
      })
      .catch(() => {
        setError({ error: "چیزی پیدا نشد" });
        setLoading(false);
      });
  };
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex" }}>
        <TextField name="phone" label="شماره تلفن و ایمیل" inputRef={register} />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          style={{ marginRight: "1em" }}
        >
          جست‌وجو
          <SearchIcon />
        </Button>
      </form>
      <Paper style={{ margin: "2em 0px" }}>
        {loading ? (
          <Loading />
        ) : error ? (
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>خطایی پیش آمد</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  کاربر یافت نشد
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        ) : (
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">سطح فعالیت</TableCell>
                <TableCell align="center">تاریخ تولد</TableCell>
                <TableCell align="center">وضعیت شیردهی</TableCell>
                <TableCell align="center">قد</TableCell>
                <TableCell align="center">ماه رمضان</TableCell>
                {data[0].user.email=== undefined ?   <TableCell align="center">شماره همراه</TableCell>:   <TableCell align="center">ایمیل </TableCell> }
                <TableCell align="center">کدهای ارسالی</TableCell>
                <TableCell align="center">ویرایش</TableCell>
                <TableCell align="center">حذف</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row) => {
                const { user } = row;
                return (
                  <TableRow key={user.objectId}>
                    <TableCell align="center" component="th" scope="row">
                      {/* {row.activityLevel} */}
                      {user.activityLevel
                        ? translate(
                            `resources.user.fields.${row.user.activityLevel.toLowerCase()}`
                          )
                        : ""}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      <p>
                        {new Date(user.birthdate).toLocaleDateString("fa-IR")}
                      </p>
                    </TableCell>
                    <TableCell align="center">
                      {user.breastFeedingState
                        ? translate(
                            `resources.user.fields.${user.breastFeedingState.toLowerCase()}`
                          )
                        : ""}
                    </TableCell>
                    <TableCell align="center">{user.height}</TableCell>
                    <TableCell align="center">
                      {user.activityLevel
                        ? user.isRamadan
                          ? "بله"
                          : "خیر"
                        : null}
                    </TableCell>
                    {row.user.email=== undefined ?  <TableCell align="center">{user.phoneNumber}</TableCell> :  <TableCell align="center">{user.email}</TableCell>}

                    <TableCell align="center">
                      {row.sentCodes ? (
                        <CodeListButton
                          sentCodes={row.sentCodes}
                          setData={setData}
                        />
                      ) : null}
                    </TableCell>
                    {row.user.email=== undefined ?
                    <TableCell align="center">
                    <Link to={`user/${user.phoneNumber}`}>
                        <IconButton>
                          <EditIcon />
                        </IconButton>
                      </Link>
                    </TableCell> 
                    :
                    <TableCell align="center">
                    <Link to={`user/${user.email}`}>
                        <IconButton>
                          <EditIcon />
                        </IconButton>
                      </Link>
                    </TableCell>}
                 
                    <TableCell align="center">
                      {user.activityLevel &&
                      props.permissions === ROLES.SUPER_ADMIN ? (
                        <DeleteButton
                          phoneNum={user.phoneNumber}
                          setData={setData}
                        />
                      ) : null}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        )}
      </Paper>
    </div>
  );
};
