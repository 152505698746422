import React ,{useState, useEffect} from "react";
import {
  Datagrid,
  List,
  SimpleShowLayout,
  TextField,
  TextInput,
  NumberInput,
  BooleanInput,
  SelectInput,
  ImageInput,
  ImageField,
  SelectArrayInput,
  ReferenceInput,
  BooleanField,
  required,
  Pagination,

} from "react-admin";
import { Box, Grid, Typography } from "@material-ui/core";
import ShowView from "../../Components/ShowView";
import EditView from "../../Components/EditView";
import CreateView from "../../Components/CreateView";
import { MARKETS_CHOICES , SHOP_CONDITION_CHOICES, SHOP_PAYMENT_CHOICES, SHOP_SUBSCRIPTION_TYPE , REMAINIG_DAY_CONDITION_CHOICES, SHOP_PRO_SUBSCRIPTION_CHOICES} from "../../Constants/choices";
import { axiosFetcher } from "../../utils/axiosFetcher";


const ShopPackageMain = (props) => {
  const { record} = props;
  return(
    <Box p={2}>
      <Grid container spacing={3}>
        <Grid item>
          <TextInput source="name" label="نام" record={record}/>
        </Grid>
        <Grid item>
          <NumberInput source="order"  label="اولویت" record={record}/>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item>
          <ReferenceInput source="planId" reference="plan" label="پلن" style={{ width : "240px"}}    record={record}  perPage={300}>
          <SelectInput optionText={(record)=> `${record.subscriptionType}/${record.name}`} />
          </ReferenceInput>
        </Grid>
        <Grid item>
          <ReferenceInput source="skuId" reference="plansku" style={{ width : "240px"}}  record={record} perPage={300} >
            <SelectInput optionText="name" />
          </ReferenceInput>
        </Grid>
        <Grid item>
          <ReferenceInput source="promotionId" reference="promotion"  label="پروموشن" style={{ width : "240px"}}  record={record}   perPage={300}>
            <SelectInput optionText="name" />
          </ReferenceInput>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item>
          <BooleanInput source="canApplyDiscount"defaultValue={false} label="قابلیت اعمال تخفیف"  record={record}/>
        </Grid>
        <Grid item>
          <BooleanInput source="isPopUp" defaultValue={true} label="پاپ آپ" record={record}/>
        </Grid>
        <Grid item>
          <BooleanInput source="isActive" defaultValue={true} label="فعال" record={record}/>
        </Grid>
        <Grid item>
          <BooleanInput source="removePromotionAfterApplyDiscount" defaultValue={true} label="پاک کردن پروموشن بعد از اعمال کد تخفیف" record={record}/>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item>
          <SelectInput label="نوع پرداخت" source="paymentType" choices={SHOP_PAYMENT_CHOICES}  style={{ width : "240px"}}   record={record} />
        </Grid>
        <Grid item  >
          <SelectArrayInput source="markets"       validate={required()}  label="مارکت"  choices={MARKETS_CHOICES}  style={{ width : "240px"}}  record={record}  />
        </Grid>
        <Grid item  >
          <SelectInput source="subscriptionType" choices={SHOP_SUBSCRIPTION_TYPE} label="نوع اشتراک"  style={{ width : "240px"}}  record={record}  />
        </Grid>
      </Grid>
    </Box>
  )
};

const ShopPackageTheme = (props) => {
  const { record } = props;
  return(
    <Box p={2} style={{background : "#eee"}}>
      <Typography align={"center"} gutterBottom variant={"h6"}>
        تم
      </Typography>
      <Grid container spacing={3}>
        <Grid item>
          <TextInput source="theme.title" label="عنوان" record={record}/>
        </Grid>
        <Grid item>
          <TextInput source="theme.subTitle" label="زیر عنوان" record={record}/>
        </Grid>
        <Grid item>
          <TextInput source="theme.description" label="توضیحات" record={record}/>
        </Grid>
        <Grid item>
          <BooleanInput source="theme.isSpecial" label="ویژه" defaultValue={false} record={record}/>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <ImageInput source="promotionImage" label="تصویر پروموشن" accept="image/*">
          <ImageField source="src" title="title" />
        </ImageInput>
      </Grid>   
      <Grid container spacing={3}>
        <ImageInput source="pictures" label="تصویر مربوطه" accept="image/*">
          <ImageField source="src" title="title" />
        </ImageInput>
      </Grid>   
    </Box>
  )
};

const ShopPackageCondition = (props) => {
  const [experiments , setExperiments] = useState([])
  useEffect(() => {
    axiosFetcher
    .get(`/experiment/get-experiments-with-ab-test/`)
    .then((res) => {
      let response = res.data
      let final  = []
      for (let index = 0; index < response.length; index++) {
        final[index] = {name  : `${response[index].name} / ${response[index].abTestName}` , id: response[index]._id};  
      }
      setExperiments(final)
    })
    .catch((e) => {
      let final  = []
        for (let index = 0; index < experiments.length; index++) {
          final[index] = {name  : `${experiments[index].name} / ${experiments[index].abTestName}` , id: experiments[index]._id};  
        }
        setExperiments(final)
    });
   },[]);
  const { record } = props;
  const [isDefaultValue, setIsDefaultValue] = useState(record.isDefault)
  function isDefaultChange(e) {
  if (isDefaultValue ===false || isDefaultValue === undefined ) {
    setIsDefaultValue(true)
  }else{
    setIsDefaultValue(false)
  }
  }
  return(
    <>
    <BooleanInput source="isDefault" record={record}   onChange={isDefaultChange}/>
    {isDefaultValue ? null : 
      <Box style={{background : "#eee"}} p={2}>
        <Typography align={"center"} gutterBottom variant={"h6"}>
         کاندیشن (شرایط)
        </Typography>   
        <BooleanInput source="conditions.hasPurchase.allItems" label="(انتخاب همه) خرید کرده"  defaultValue={false} record={record}/>
        <SelectArrayInput source="conditions.hasPurchase.items" choices={SHOP_PRO_SUBSCRIPTION_CHOICES}   record={record}  label=" انتخاب آیتم های خرید کرده "  style={{width : "270px"}} />
        <BooleanInput source="conditions.remainingSubscriptionDays.allItems" label="(انتخاب همه) روزهای باقی مانده اشتراک" defaultValue={false}  record={record}/>
        <SelectArrayInput source="conditions.remainingSubscriptionDays.items" choices={REMAINIG_DAY_CONDITION_CHOICES}   record={record}   label=" انتخاب آیتم های روزهای باقی مانده از اشتراک"  style={{width : "270px"}}/>
        <BooleanInput source="conditions.experiments.allItems" defaultValue={false} label="(انتخاب همه)  اکسپریمنت  "  record={record}/>
        <SelectArrayInput  source="conditions.experiments.items" choices={experiments}  record={record}   label=" انتخاب آیتم های اکسپریمنت  "  style={{width : "270px"}}/>
        <BooleanInput source="conditions.passedRegistrationDays.allItems" defaultValue={false}   label="(انتخاب همه) روزهای سپری شده از ثبت نام  " record={record}/>
        <SelectArrayInput source="conditions.passedRegistrationDays.items" choices={SHOP_CONDITION_CHOICES}  record={record}   label=" انتخاب آیتم های روزهای سپری شده"  style={{width : "270px"}}/>
        <BooleanInput source="conditions.hasProSubscription.allItems" defaultValue={false}   label="(انتخاب همه) اشتراک  ویژه  " record={record}/>
        <SelectArrayInput source="conditions.hasProSubscription.items" choices={SHOP_PRO_SUBSCRIPTION_CHOICES}  record={record}   label=" انتحاب آیتم های اشتراک  ویژه"  style={{width : "270px"}}/>
      </Box> 
    }
    </>
  )
};

const PostPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;
export const ShopPackageList = (props) => (
  <List {...props}  pagination={<PostPagination />}>
    <Datagrid rowClick="show">
      <TextField source="name" />
      <TextField source="order"/>
      <TextField source="subscriptionType"/>
      <TextField source="paymentType"/>
      <BooleanField source="theme.isSpecial" label="ویژه"/>
      <BooleanField source="isDefault"/>
      <BooleanField source="isPopUp"  />
      <BooleanField source="isActive" />
    </Datagrid>
  </List>
);

export const ShopPackageEdit = (props) => (
  <EditView {...props}>
    <ShopPackageMain {...props} />
    <ShopPackageTheme {...props} source="theme" />
    <ShopPackageCondition {...props} source="condition"  />
  </EditView>
);

export const ShopPackageCreate = (props) => (
  <CreateView {...props}>
    <ShopPackageMain {...props} />
    <ShopPackageTheme {...props} source="theme" />
    <ShopPackageCondition {...props} source="condition" />
  </CreateView>
);

export const ShopPackageShow = (props) => (
  <ShowView {...props}>
    <SimpleShowLayout>
      <TextField source="name" />
      <TextField source="order"/>
      <BooleanField source="canApplyDiscount"/>
      <BooleanField source="isDefault" />
      <BooleanField source="isPopUp" />
      <BooleanField source="isActive" />
      <TextField source="paymentType"/>
      <TextField source="skuId"/>
      <TextField source="planId"/>
      <TextField source="subscriptionType"/>
      <TextField source="theme.description"  label="توضیحات"/>
      <ImageField source="theme.image" label="تصویر" title="title" />
      <ImageField source="theme.promotionImage" label="تصویر پروموشن " title="title" />
    </SimpleShowLayout>
  </ShowView>
);
