import {
  PackageCreate,
  PackageEdit,
  PackageList,
  PackageShow,
} from "./Package";
import AppsIcon from "@material-ui/icons/Apps";

import {
  PackagePricingTagCreate,
  PackagePricingTagEdit,
  PackagePricingTagList,
  PackagePricingTagShow,
} from "./PackagePricingTag";

import {
  SKUList,
  SKUShow,
} from './SKU'
import {
  PackageUserTag
} from "./PackageUserTag"

export const packagePricingTag = {
  name: "packagePricingTag",
  list: PackagePricingTagList,
  edit: PackagePricingTagEdit,
  show:PackagePricingTagShow,
  create: PackagePricingTagCreate,
  icon: AppsIcon,
  options: { cat: "Price" },
};

export const packages = {
  name: "package",
  list: PackageList,
  edit: PackageEdit,
  show: PackageShow,
  create: PackageCreate,
  icon: AppsIcon,
  options: { cat: "Price" },
};


export const PackageUserTags = {
  name: "PackageUserTag",
  list: PackageUserTag,
  edit: null,
  show: null,
  create: null,
  icon: AppsIcon,
  options: { cat: "Price" },
};

export const Skus = {
  name: "sku",
  list: SKUList,
  edit: null,
  show: SKUShow,
  create: null,
  icon: AppsIcon,
  options: { cat: "Price" },
};

