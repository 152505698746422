import React from "react";
import {
  Datagrid,
  List, NumberInput,
  SelectInput,
  SimpleShowLayout,
  TextField,
  TextInput,
} from "react-admin";

import EditView from "../../Components/EditView";
import CreateView from "../../Components/CreateView";
import ShowView from "../../Components/ShowView";
import {Roles} from "../../Constants/choices"

export const AdminUserList = (props) => (
  <List {...props}>
    <Datagrid rowClick="show">
      <TextField source="name" />
      <TextField source="phoneNumber" />
      <TextField source="role" />
    </Datagrid>
  </List>
);

export const AdminUserEdit = (props) => (
  <EditView {...props}>
    <TextInput disabled={true} source="name" />
    <NumberInput disabled={true} source="phoneNumber" />
    <SelectInput source="role" choices={Roles}/>
  </EditView>
);

export const AdminUserShow = (props) => (
  <ShowView {...props}>
    <SimpleShowLayout>
      <TextField disabled={true} source="name" />
      <TextField disabled={true} source="phoneNumber" />
      <TextField disabled={true} source="role" />
    </SimpleShowLayout>
  </ShowView>
);

export const AdminUserCreate = (props) => (
  <CreateView {...props}>
    <SelectInput source="role" choices={Roles}/>
    <TextInput source="phoneNumber" />
  </CreateView>
);
