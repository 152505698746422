// in src/MyLayout.js
import React from "react";
import { Layout } from "react-admin";
import Menu from "./Menu.jsx";
// import MyAppBar from "./MyAppBar";
// import MySidebar from "./MySidebar";
// import MyMenu from "./MyMenu";
// import MyNotification from "./MyNotification";

const MyLayout = (props) => (
  <Layout
    {...props}
    // appBar={MyAppBar}
    // sidebar={MySidebar}
    menu={Menu}
    // notification={MyNotification}
  />
);

export default MyLayout;
