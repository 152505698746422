import React from "react";
import "./App.css";
import { dataProvider } from "./Data/dataProvider";
import { Admin, Resource } from "react-admin";
import { myTheme } from "./Style/theme";
import { i18nProvider } from "./i18n/i18nProvider";
import { RTL } from "./Style/RTL";
import MyLayout from "./Components/Layout";
import { authProvider } from "./auth/authProvider";
import _LoginPage from "./Components/LoginPage";
import {
  FOOD_ADMIN,
  MARKETING_ADMIN,
  SUPER_ADMIN,
  USERS_ADMIN,
  DOCTOR,
} from "./auth/rolesResources";
import { ROLES } from "./Constants";
import Dashboard from "./Panels/Dashboard/Dashboard";

function App() {
  function _renderResources(permissions) {
    switch (permissions) {

      
      case ROLES.SUPER_ADMIN:
      console.log(ROLES);
      return SUPER_ADMIN.map((el) => (
          <Resource
            name={el.name}
            list={el.list}
            edit={el.edit}
            show={el.show}
            create={el.create}
            icon={el.icon}
            options={el.options}
          />
        ));
      case ROLES.FOOD_ADMIN:
        return FOOD_ADMIN.map((el) => (
          <Resource
            name={el.name}
            list={el.list}
            edit={el.edit}
            show={el.show}
            create={el.create}
            icon={el.icon}
            options={el.options}
          />
        ));
      case ROLES.USERS_ADMIN:
        return USERS_ADMIN.map((el) => (
          <Resource
            name={el.name}
            list={el.list}
            edit={el.edit}
            show={el.show}
            create={el.create}
            icon={el.icon}
            options={el.options}
          />
        ));

      case ROLES.MARKETING_ADMIN:
        return MARKETING_ADMIN.map((el) => (
          <Resource
            name={el.name}
            list={el.list}
            edit={el.edit}
            show={el.show}
            create={el.create}
            icon={el.icon}
            options={el.options}
          />
        ));
      case ROLES.DOCTOR:
        return DOCTOR.map((el) => (
          <Resource
            name={el.name}
            list={el.list}
            edit={el.edit}
            show={el.show}
            create={el.create}
            icon={el.icon}
            options={el.options}
          />
        ));
      default:
        break;
    }
  }

  return (
    <RTL>
      <Admin
        loginPage={_LoginPage}
        dataProvider={dataProvider}
        authProvider={authProvider}
        theme={myTheme}
        i18nProvider={i18nProvider}
        title="Karafs Admin Panel"
        layout={MyLayout}
        dashboard={Dashboard}
      >
        {(permissions) => [..._renderResources(permissions),<Resource/>,<Resource name={"hatedFood"}/>,<Resource name={"disease"}/>,<Resource name={"specialType"}/>]}
      </Admin>
    </RTL>
  );
}

export default App;
