import React  from "react";
import {
    BooleanField,
    BooleanInput,
    Datagrid,
    DateField,
    Filter,
    ImageField,
    ImageInput,
    List,
    NumberField,
    ReferenceInput,
    SelectInput,
    SimpleShowLayout,
    TextField,
    TextInput,
    required
} from "react-admin";
import EditView from "../../Components/EditView";
import ShowView from "../../Components/ShowView";
import CreateView from "../../Components/CreateView";

const postRowStyle = () => ({
  textAlign: "right",
});
export const FoodFilter = (props) => (
  <Filter {...props}>
    <TextInput label="جست‌وجو" source="title" alwaysOn  />
    <ReferenceInput
      label="دسته‌بندی غذا"
      source="foodCategories"
      reference="foodCategory"
      allowEmpty
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
);

export const FoodRecipeCategoryList = (props) => (
  <List {...props} filters={<FoodFilter />} perPage={20}>
    <Datagrid rowClick="show" rowStyle={postRowStyle}>
      <TextField source="title" />
      <ImageField source="imageUrl" />
      <NumberField source="id" />
      <DateField locales="fa-IR" source="createdAt" />
      <DateField locales="fa-IR" source="updatedAt" />
      <BooleanField source="isDeleted" />
    </Datagrid>
  </List>
);
export const FoodRecipeCategoryEdit = (props) => (
  <EditView {...props} title={<FoodTitle />}>
    <TextInput source="title"  validate={required()}/>
    <BooleanInput source="isPremium" />
    <ImageInput source="pictures" label="تصویر مربوطه" accept="image/*">
      <ImageField source="src" title="imageUrl" />
    </ImageInput>
    <ImageField source="imageUrl" label={"تصویر فعلی"} />
  </EditView>
);
const FoodTitle = ({ record }) => {
  return <span> غذای {record ? `"${record.name}"` : ""}</span>;
};
export const FoodRecipeCategoryShow = (props) => (
  <ShowView {...props} title={<FoodTitle />}>
    <SimpleShowLayout>
      <TextField source="title" />
      <ImageField source="imageUrl" />
      <BooleanField source="isPremium" />
      <BooleanField source="isDeleted" />
      <DateField locales="fa-IR" source="updatedAt" />
      <TextField source="id" />
    </SimpleShowLayout>
  </ShowView>
);

export const FoodRecipeCategoryCreate = (props) => (
  <CreateView {...props}>
    <TextInput source="title" validate={required()} />
    <ImageInput source="pictures" label="تصویر مربوطه" accept="image/*">
      <ImageField source="src" title="imageUrl" />
    </ImageInput>
    <BooleanInput defaultValue={false} source="isPremium" />
  </CreateView>
);
