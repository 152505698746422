import React from "react";
import {
  Datagrid,
  List,
  SimpleShowLayout,
  TextField,
  TextInput,
  NumberInput,
  NumberField,
  SelectInput,
  SelectField,
  BooleanInput
} from "react-admin";
import ShowView from "../../Components/ShowView";
import EditView from "../../Components/EditView";
import CreateView from "../../Components/CreateView";
import {
  PLAN_SUBSCRIPTION_TYPE
} from "../../Constants/choices";

export const PlanList = (props) => (
  <List {...props} >
    <Datagrid rowClick="show">
      <TextField source="name" />
      <TextField source="subscription"/>
      <SelectField source="subscriptionType" choices={PLAN_SUBSCRIPTION_TYPE} label="نوع اشتراک"  />
    </Datagrid>
  </List>
);

export const PlanEdit = (props) => (
  <EditView {...props}>
    <TextInput source="name"/>
    <NumberInput source="subscription"/>
    <SelectInput source="subscriptionType" choices={PLAN_SUBSCRIPTION_TYPE}  label="نوع اشتراک"  />
    <BooleanInput source="isDeleted"  />
  </EditView>
);

export const PlanCreate = (props) => (
  <CreateView {...props}>
    <TextInput source="name"/>
    <NumberInput source="subscription"/>
    <SelectInput source="subscriptionType" choices={PLAN_SUBSCRIPTION_TYPE} label="نوع اشتراک"  />
    <BooleanInput source="isDeleted" defaultValue={false} />
  </CreateView>
);

export const PlanShow = (props) => (
  <ShowView {...props}>
    <SimpleShowLayout>
      <TextField source="name"/>
      <NumberField source="subscription"/>
      <SelectField source="subscriptionType" choices={PLAN_SUBSCRIPTION_TYPE}  label="نوع اشتراک"   />
    </SimpleShowLayout>
  </ShowView>
);
