import { FoodCreate, FoodEdit, FoodList, FoodShow } from "./Food";
import {
  FoodBarcodeCreate,
  FoodBarcodeEdit,
  FoodBarcodeList,
  FoodBarcodeShow,
} from "./FoodBarcode";
import {
  FoodcategoryCreate,
  FoodcategoryEdit,
  FoodcategoryList,
  FoodcategoryShow,
} from "./FoodCategory";
import {
  FoodRecipeCreate,
  FoodRecipeEdit,
  FoodRecipeList,
  FoodRecipeShow,
} from "./FoodRecipe";
import {
  FoodRecipeCategoryCreate,
  FoodRecipeCategoryEdit,
  FoodRecipeCategoryList,
  FoodRecipeCategoryShow,
} from "./FoodRecipeCategory";
import {
  FoodunitCreate,
  FoodunitEdit,
  FoodunitList,
  FoodunitShow,
} from "./FoodUnit";
import {
  FoodfactCreate,
  FoodfactEdit,
  FoodfactList,
  FoodfactShow,
} from "./FoodFact";
import CategoryIcon from "@material-ui/icons/Bookmark";
import FastfoodIcon from "@material-ui/icons/Fastfood";
import InfoIcon from "@material-ui/icons/Info";
import ScoreIcon from "@material-ui/icons/Score";
import ReceiptIcon from "@material-ui/icons/Receipt";
import CropFreeIcon from "@material-ui/icons/CropFree";

export const food = {
  name: "food",
  list: FoodList,
  edit: FoodEdit,
  show: FoodShow,
  create: FoodCreate,
  icon: FastfoodIcon,
  options: { cat: "food" },
};

export const foodBarcode = {
  name: "personalfood",
  list: FoodBarcodeList,
  edit: FoodBarcodeEdit,
  show: FoodBarcodeShow,
  create: FoodBarcodeCreate,
  icon: CropFreeIcon,
  options: { cat: "food" },
};

export const foodCategory = {
  name: "foodCategory",
  list: FoodcategoryList,
  edit: FoodcategoryEdit,
  show: FoodcategoryShow,
  create: FoodcategoryCreate,
  icon: CategoryIcon,
  options: { cat: "food" },
};
export const foodFact = {
  name: "foodFact",
  list: FoodfactList,
  edit: FoodfactEdit,
  show: FoodfactShow,
  create: FoodfactCreate,
  icon: InfoIcon,
  options: { cat: "food" },
};
export const foodRecipe = {
  name: "foodRecipe",
  list: FoodRecipeList,
  edit: FoodRecipeEdit,
  show: FoodRecipeShow,
  create: FoodRecipeCreate,
  icon: ReceiptIcon,
  options: { cat: "food" },
};
export const foodRecipeCategory = {
  name: "foodRecipeCategory",
  list: FoodRecipeCategoryList,
  edit: FoodRecipeCategoryEdit,
  show: FoodRecipeCategoryShow,
  create: FoodRecipeCategoryCreate,
  icon: CategoryIcon,
  options: { cat: "food" },
};
export const foodUnit = {
  name: "foodUnit",
  list: FoodunitList,
  edit: FoodunitEdit,
  show: FoodunitShow,
  create: FoodunitCreate,
  icon: ScoreIcon,
  options: { cat: "food" },
};
