import React from "react";
import {
    BooleanField,
    BooleanInput,
    Create,
    Datagrid,
    DateField,
    Filter,
    ImageField,
    List,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    Show,
    SimpleForm,
    SimpleShowLayout,
    TextField,
    TextInput,
    required,
    FileInput,
    ImageInput,
    FileField,
    AutocompleteInput
} from "react-admin";
import ShowView from "../../Components/ShowView";
import EditView from "../../Components/EditView";
import { EXERCISE_LEVEL_ENUM  } from "../../Constants/choices";
const ExerciseInstructionFilter = (props) => (
  <Filter {...props}>
    <TextInput label="جست‌وجو" source="title" alwaysOn />
  </Filter>
);
export const ExerciseInstructionList = (props) => (
  <List {...props} perPage={15} filters={<ExerciseInstructionFilter />}>
    <Datagrid rowClick="show" expand={<ExpandExerciseInstructionList />}>
      <TextField source="title" />
      <ReferenceField source="categoryId" reference="exerciseInstructionCategory">
        <TextField source="title" />
      </ReferenceField>
      <ReferenceField source="exerciseId" reference="exercise" label="فعالیت" >
        <TextField source="name" />
      </ReferenceField> 
      <TextField source="description" />
      <TextField source="level" />
      <BooleanField source="isPremium" />
      <DateField locales="fa-IR" source="updatedAt" />
      <BooleanField source="isDeleted" />
      <BooleanField source="isClass" />
    </Datagrid>
  </List>
);

const ExpandExerciseInstructionList = (props) => (
  <Show {...props} className="showShit" title=" ">
    <SimpleShowLayout>
      <TextField source="video" />
      <ImageField source="image" />
    </SimpleShowLayout>
  </Show>
);
export const ExerciseInstructionEdit = (props) => (
  <EditView {...props}>
      <BooleanInput source="isPremium" />
      <TextInput source="title"  />
      <TextInput source="description" />
      <SelectInput source="level" choices={EXERCISE_LEVEL_ENUM} />
      <ReferenceInput source="categoryId" reference="exerciseInstructionCategory" >
        <SelectInput optionText="title" />
      </ReferenceInput>
      <ReferenceInput source="exerciseId" reference="exercise" label="فعالیت" >
        <AutocompleteInput optionText="name" />
      </ReferenceInput> 
      <ImageInput source="images" label="تصویر مربوطه" accept="image/*">
        <ImageField source="src" title="title" />
      </ImageInput>
      <FileInput source="videos" label="ویدیو مربوطه "   >
        <FileField source="src" title="title" />
      </FileInput>
  </EditView>
);
export const ExerciseInstructionShow = (props) => (
  <ShowView {...props}>
    <SimpleShowLayout>
      <TextField source="title" />
      <ReferenceField source="categoryId" reference="exerciseInstructionCategory">
        <TextField source="title" />
      </ReferenceField>
      <ReferenceField source="exerciseId" reference="exercise" label="فعالیت" >
            <TextField source="name" />
      </ReferenceField> 
      <TextField source="description" />
      <TextField source="level" />
      <TextField source="video" />
      <ImageField source="image" />
      <BooleanField source="isPremium" />
      <DateField locales="fa-IR" source="updatedAt" />
      <TextField source="id" />
    </SimpleShowLayout>
  </ShowView>
);
export const ExerciseInstructionCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <BooleanInput source="isPremium"  defaultValue={true}/>
      <TextInput source="title"  />
      <TextInput source="description" />
      <SelectInput source="level" choices={EXERCISE_LEVEL_ENUM} />
      <ReferenceInput source="categoryId" reference="exerciseInstructionCategory" >
        <SelectInput optionText="title" />
      </ReferenceInput>
      <ReferenceInput source="exerciseId" reference="exercise" label="فعالیت" >
        <AutocompleteInput optionText="name" />
      </ReferenceInput> 
      <ImageInput source="image" label="تصویر مربوطه" accept="image/*" validate={required()}>
        <ImageField source="src" title="title" />
      </ImageInput>
      <FileInput source="video" label="ویدیو مربوطه "   validate={required()} >
        <FileField source="src" title="title" />
      </FileInput>
    </SimpleForm>
  </Create>
);
