import React, {useState} from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader, Typography,
} from "@material-ui/core";
import {useNotify, downloadCSV, Loading} from "react-admin";
import {SERVER_DOMAIN} from "../../Constants";
import jsonExport from "jsonexport/dist";
import { axiosFetcher } from "../../utils/axiosFetcher";

export function RemoveDuplicateClient() {
  const notify = useNotify()
  const [loading, setLoading] = useState(false);
  function submitClick(){
    setLoading(true);
    axiosFetcher
    .post(`${SERVER_DOMAIN}/admin/fix_duplicate_client_data`)
      .then((res) => {
        setLoading(false);
      notify("انجام شد")

      jsonExport(res.data.fixedUserIds, (err, csv) => {
        downloadCSV(csv, `FixDuplicate${Date.now()}`);
      });
      })
      .catch(() => {
        setLoading(false);
        notify("به مشکلی برخوردیم")
      });
  }
  return (
    <>
      <Card style={{marginTop:"2rem"}}>
        <CardHeader title="حذف تکراری اطلاعات Client Data"/>
        <Typography style={{marginRight:"1.2rem"}}>اطلاعات کلاینت دیتا هر کاربری که دو بار در پایگاه داده ذخیره شده باشد را حذف می‌کند</Typography>
        <CardContent>
        <form >
        <Button type="submit" variant="contained"  color="primary" onClick={submitClick}  style={{marginTop:"1rem"}}>اعمال </Button>
        </form>  
        </CardContent>
        {loading===true ?<Loading/> : null}
      </Card>   
    </>
  );
}
export default RemoveDuplicateClient;
