import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import {
  BulkdiscountcodeCreate,
  BulkdiscountcodeEdit,
  BulkdiscountcodeList,
  BulkdiscountcodeShow,
} from "./BulkDiscount";
import {
  RegulardiscountcodeCreate,
  RegulardiscountcodeEdit,
  RegulardiscountcodeList,
  RegulardiscountcodeShow,
} from "./RegularDiscount";

export const bulkDiscountCode = {
  name: "bulkDiscountCode",
  list: BulkdiscountcodeList,
  edit: BulkdiscountcodeEdit,
  show: BulkdiscountcodeShow,
  create: BulkdiscountcodeCreate,
  icon: AttachMoneyIcon,
  options: { cat: "Price" },
};
export const bulkDiscountCodeUser = {
  name: "bulkDiscountCode",
  list: BulkdiscountcodeList,
  edit: null,
  show: BulkdiscountcodeShow,
  create: null,
  icon: AttachMoneyIcon,
  options: { cat: "Price" },
};

export const regularDiscountCode = {
  name: "regularDiscountCode",
  list: RegulardiscountcodeList,
  edit: RegulardiscountcodeEdit,
  show: RegulardiscountcodeShow,
  create: RegulardiscountcodeCreate,
  icon: AttachMoneyIcon,
  options: { cat: "Price" },
};
