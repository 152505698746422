import React, { useEffect } from "react";
import { useDataProvider } from "react-admin";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField as MuTextField,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import { MARKETS_CHOICES } from "../../../Constants/choices";
import { SendPopUpBtn } from "../../../Components/Buttons";
import { axiosFetcher } from "../../../utils/axiosFetcher";
import { SERVER } from "../../../Constants";

SendUserPopUp.propTypes = {};
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
function SendUserPopUp(props) {
  const { record } = props;
  const [market, setMarket] = React.useState("NONE");
  const [sku, setSku] = React.useState("");
  const [fetchSku, setFetchSku] = React.useState([]);
  const classes = useStyles();
  const dataprovider = useDataProvider();
  useEffect(() => {
    dataprovider
      .getList("popUpData", {
        pagination: { page: 1, perPage: 10 },
        sort: { field: "published_at", order: "DESC" },
      })
      .then((res) => {
        const data = [...res.data];
        let x = [].concat.apply(
          [],
          data.map((item) => {
            return item.sku;
          })
        );
        setFetchSku(x);
      })
  }, []);
  const _handleSubmit = () => {
    axiosFetcher.post(`${SERVER}/forceSendPopUpViewData`, {
      market,
      sku,
      userId: record?.user?.objectId,
    });
  };

  const handleChange = (event) => {
    setMarket(event.target.value);
  };
  return (
    <div style={{ marginBottom: "1em" }}>
      <Accordion
        style={{ margin: "1em 0", maxHeight: "400px", overflow: "auto" }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="body1" gutterBottom style={{ margin: "0.5em" }}>
            ارسال پاپ‌آپ
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          style={{
            flexDirection: "column",
            alignItems: "center",
            overflow: "hidden",
          }}
        >
          <FormControl
            variant="filled"
            className={classes.formControl}
            fullWidth
          >
            <InputLabel id="market-label">مارکت</InputLabel>
            <Select
              labelId="market-label"
              value={market}
              onChange={handleChange}
            >
              {MARKETS_CHOICES.map((marketEnum) => (
                <MenuItem value={marketEnum.id}>{marketEnum.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <MuTextField
            id="filled-basic"
            label="SKU"
            style={{ marginTop: "1em" }}
            fullWidth
            value={sku}
            onChange={(e) => setSku(e.target.value)}
          />
          <SendPopUpBtn handleSubmit={_handleSubmit} />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
export default SendUserPopUp;
