import React from "react";
import {
  ReferenceInput,
  Datagrid,
  DateField,
  List,
  NumberField,
  NumberInput,
  SimpleShowLayout,
  TextField, 
  TextInput,
  required, 
  BooleanField,
  BooleanInput ,
  SelectInput,
  ArrayInput,
  SimpleFormIterator
} from "react-admin";

import EditView from "../../Components/EditView";
import { MaterialDateInput } from "../../Components/RaMaterialDatePickers";
import ShowView from "../../Components/ShowView";
import CreateView from "../../Components/CreateView";

const info =  {
  prefix : ""
}

export const ShopBulkDiscountList = (props) => (
  <List {...props}>
    <Datagrid rowClick="show">
      <TextField source="prefix" />
      <NumberField source="defaultPercent" textAlign="center" />
      <BooleanField source="isActive" />
      <DateField locales="fa-IR" source="expirationTime" />
      <NumberField source="percentPerPlan[0].percent" label="درصد (پلن)" textAlign="center" />
      <TextField source="percentPerPlan[0].planId" label="شناسه پلن" /> 
      <TextField source="number" />
      <TextField source="percent" />
        <TextField source="usedCount" />
      <TextField source="id" />
    </Datagrid>
  </List>
);


export const ShopBulkDiscountEdit = (props) => (
  <EditView{...props} transform={(data) => {
      let e = new Date(data.expirationTime).getTime();
      return { ...data, expirationTime: e };
    }}>
    <TextInput source="prefix" helperText={"پیشوند باید در تمامی کدهای تخفیف یکتا باشد"}  defaultValue={info.prefix} />
    <MaterialDateInput source="expirationTime"    options={{ format: "DD/MM/YYYY" }}  validate={required()} />
    <NumberInput source="number"   validate={required()}   defaultValue={info.number}/>
    <NumberInput source="defaultPercent" />
    <BooleanInput source="isActive" />
    <ArrayInput source="percentPerPlan" label="درصد تخفیف بر پلن">
      <SimpleFormIterator>
        <ReferenceInput source="planId" label="پلن" reference="plan">
          <SelectInput optionText={(record)=> `${record.subscriptionType}/${record.name}`}  />
        </ReferenceInput>
        <NumberInput source="percent" />
      </SimpleFormIterator>
    </ArrayInput> 
  </EditView>
);

export const ShopBulkDiscountCreate = (props) => {
  return (
    <CreateView
      {...props}
      transform={(data) => {
        let e = new Date(data.expirationTime).getTime();
        return { ...data, expirationTime: e };
      }}>
      <TextInput source="prefix" helperText={"پیشوند باید در تمامی کدهای تخفیف یکتا باشد"}  defaultValue={info.prefix} />
      <MaterialDateInput source="expirationTime"    options={{ format: "DD/MM/YYYY" }}  validate={required()} />
      <NumberInput source="number"   validate={required()}   defaultValue={info.number}/>
      <NumberInput source="defaultPercent" />
      <BooleanInput source="isActive"  defaultValue={true} />
      <ArrayInput source="percentPerPlan" label="درصد تخفیف بر پلن">
        <SimpleFormIterator>
          <ReferenceInput source="planId" label="پلن" reference="plan">
            <SelectInput optionText={(record)=> `${record.subscriptionType}/${record.name}`}  />
          </ReferenceInput>
          <NumberInput source="percent" />
        </SimpleFormIterator>
      </ArrayInput>
    </CreateView>
  );
};

export const ShopBulkDiscountShow = (props) => {
  return (
    <ShowView {...props}>
      <SimpleShowLayout>
      <TextField source="prefix" />
      <NumberField source="defaultPercent" textAlign="center" />
      <BooleanField source="isActive" />
      <DateField locales="fa-IR" source="expirationTime" />
      <NumberField source="percentPerPlan[0].percent" label="درصد (پلن)" textAlign="center" />
      <TextField source="percentPerPlan[0].planId" label="شناسه پلن" /> 
      <TextField source="number" />
      <TextField source="percent" />
      <TextField source="usedCount" />
      <TextField source="id" />
      </SimpleShowLayout>
    </ShowView>
  );
};
