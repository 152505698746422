export const common = {
  name: "نام",
  title: "عنوان",
  isDeleted: "حذف شده",
  updatedAt: "بروز شده",
  id: "شناسه",
  image: "تصویر",
  type: "نوع",
};
export default {
  ra: {
    action: {
      add_filter: "اضافه‌کردن فیلتر",
      add: "اضافه",
      back: "بازگشت",
      bulk_actions:
        "۱ آیتم انتخاب شد |||| %{smart_count} عدد از آیتم‌ها انتخاب شدند",
      cancel: "انصراف",
      clear_input_value: "پاک‌کردن مقدار",
      clone: "شبیه‌سازی",
      confirm: "تایید",
      create: "ایجاد",
      delete: "حذف",
      edit: "ویرایش",
      export: "دریافت خروجی",
      list: "لیست",
      refresh: "بروز‌رسانی",
      remove_filter: "حذف این فیلتر",
      remove: "حذف",
      save: "ذخیره",
      save_and_show: "ذخیره و نمایش",
      save_and_add: "ذخیره و افزودن",
      back_to_list: "بازگشت به لیست",
      restore: "بازگردانی",
      search: "جست‌وجو",
      show: "نمایش",
      sort: "مرتب‌سازی",
      undo: "لغو",
      unselect: "عدم انتخاب",
      expand: "باز کن",
      close: "ببند",
      close_menu: "بستن منو",
      open_menu: "باز کردن منو",
    },
    boolean: {
      true: "بله",
      false: "خیر",
      null: "",
    },
    page: {
      create: "ایجاد %{name}",
      dashboard: "داشبورد",
      edit: "%{name} #%{id}",
      error: "مشکلی ایجاد شد",
      list: "لیست %{name}",
      loading: "در حال بارگذاری",
      not_found: "پیدا نشد",
      show: "%{name} #%{id}",
      empty: "هنوز سطری از %{name} وجود ندارد.",
      invite: "آیا میخواهید یک مورد اضافه کنید؟",
    },
    input: {
      file: {
        upload_several:
          "تعدادی فایل برای آپلود دراپ کنید، یا برای انتخاب آن‌ها کلیک کنید.",
        upload_single:
          "فایلی را برای آپلود دراپ کنید، یا برای انتخاب آن کلیک کنید",
      },
      image: {
        upload_several:
          "تعدادی عکس برای آپلود دراپ کنید، یا برای انتخاب آن‌ها کلیک کنید.",
        upload_single:
          "عکسی را برای آپلود دراپ کنید، یا برای انتخاب آن کلیک کنید",
      },
      references: {
        all_missing: "امکان پیدا کردن اطلاعات ارجاعی وجود ندارد.",
        many_missing: "حداقل یکی از مراجع در دسترس نیست.",
        single_missing: "مرجع مورد نظر در دسترس نیست.",
      },
      password: {
        toggle_visible: "پنهان کردن رمز عبور",
        toggle_hidden: "نمایش رمز عبور",
      },
    },
    message: {
      about: "درباره",
      are_you_sure: "آیا اطمینان دارید ؟",
      bulk_delete_content:
        "آیا از حذف  %{name} اطمینان دارید؟ |||| آیا از حدف %{smart_count} عدد از آیتم‌ها اطمینان دارید؟",
      bulk_delete_title:
        "حذف %{name} |||| حذف %{smart_count} عدد از آیتم‌های %{name}",
      delete_content: "آیا از حذف این آیتم اطمینان دارید؟",
      delete_title: "حذف %{name} #%{id}",
      details: "جزییات",
      error: "خطایی در مرورگر رخ داد. درخواست شما کامل نشد",
      invalid_form: "فرم درست پر نشده است. لطفا خطاها را بررسی کنید",
      loading: "صفحه در حال بارگذاری است، چند لحظه صبر کنید",
      no: "خیر",
      not_found:
        "شما یک نشانی اینترنتی اشتباه تایپ کردید یا پیغام بدی را دنبال کردید.",
      yes: "بله",
      unsaved_changes:
        "تغییرات شما ذخیره نشده اند. آیا مطمئن هستید که می خواهید از آنها چشم پوشی کنید؟",
    },
    navigation: {
      no_results: "نتیجه‌ای پیدا نشد",
      no_more_results:
        "شماره صفحه‌ی %{page} خارج از محدوده مجاز است. صفحه قبل را امتحان کنید.",
      page_out_of_boundaries: "شماره صفحه %{page} خارج از محدوده است",
      page_out_from_end: "نمی‌توان به بعد از صفحه آخر رفت",
      page_out_from_begin: "نمی‌توان به قبل از صفحه اول رفت",
      page_range_info: "%{offsetBegin}-%{offsetEnd} (کل: %{total})",
      page_rows_per_page: "تعداد ردیف‌ها در صفحه:",
      next: "بعدی",
      prev: "قبلی",
      skip_nav: "skip_nav"
    },
    auth: {
      auth_check_error: "لطفا برای ادامه وارد شوید",
      user_menu: "پروفایل",
      username: "نام‌کاربری",
      password: "رمز عبور",
      sign_in: "ورود",
      sign_in_error: "شناسایی با شکست مواجه شد، دوباره تلاش کنید",
      logout: "خروج",
    },
    notification: {
      updated: "المان بروز‌رسانی شد",
      created: "المان ایجاد شد",
      deleted: "المان حذف شد",
      bad_item: "المان اشتباه",
      item_doesnt_exist: "المان پیدا نشد",
      http_error: "خطا در برقراری ارتباط با سرور",
      data_provider_error: "خطا در دریافت اطلاعات",
      canceled: "لغو شد",
      logged_out: "نشست کاربری شما به پایان زسیده‌است، لطفا دوباره وصل شوید.",
    },
    validation: {
      required: "اجباری",
      minLength: "حداقل باید %{min} کارکتر باشد",
      maxLength: "باید %{max} کارکتر یا کمتر باشد",
      minValue: "حداقل باید %{min} باشد",
      maxValue: "باید %{max} یا کمتر باشد",
      number: "باید یک عدد باشد",
      email: "باید یک آدرس ایمیل صحیح باشد",
      oneOf: "باید انتخابی از این گزینه‌ها باشد: %{options}",
      regex: "باید با فرمت خاصی هماهنگ باشد (regexp): %{pattern}",
    },
  },
  karafs: {
    general: {
      generic_err: "خطایی رخ داد",
      invalid_phone_number: "شماره‌تلفن صحیح نمی‌باشد",
      send_code: "کد تاییده به شماره‌ی شما پیامک شد",
    },
    menu: {
      food: "غذا",
      exercise: "فعالیت",
      exerciseClass: "کلاس ورزش ",
      marketing: "مارکتینگ",
      users: "کاربران",
      misc: "سایر",
      "a/b": "A/B",
      FAQ: "FAQ",
      downloadLink: "لینک های دانلود",
      price: "قیمت",
      shop : "فروشگاه",
      Apies: "API های سفارشی ",
      clinic:'کلینیک'
    },
    order: {
      success: "ترتیب تغییر یافت",
      failure: "خطایی در تغییر ترتیب رخ داد!",
    },
  },
  red: {
    user: { HIGH: "oio" },
  },
  resources: {
    exerciseInstructionCategory: {
      name: "دسته‌بندی تمرین‌ها",
      fields: {
        name: "نام",
        title: "عنوان",
        isDeleted: "حذف شده",
        foodCategories: "دسته‌بندی",
        updatedAt: "بروز شده",
        isPremium: "پرمیوم",
        id: "شناسه",
        needsEquipment: "تجهیزات",
        image: "تصویر",
      },
    },
    tracker:{
      name:'ترکر',
      fields:{
        trackerLink:'لینک ترکر',
        identifier:'شناسه',
        baseURL:'دامین',
        description:'توضیحات',
        url:'لینک',
        deleted:'حذف شده',
        createdAt:'تاریخ ایجاد',
        updatedAt:'تاریخ بروزرسانی',
      }
    },
    plan : {
      name : "پلن",
      fields: {
        name: "نام",
        subscription : "اشتراک",
        id: "شناسه",
      },
    },
    promotion : {
      name : "پروموشن",
      fields: {
        name: "نام",
        discountPercentage : "درصد تخفیف",
        expiredAt : "تاریخ انقضا ",
        startedAt : "تاریخ شروع ",
        id: "شناسه",
        isActive : "فعال",
      },
    },
    plansku : {
      name : "SKU",
      fields: {
        name: "نام",
        price : "قیمت",
        currency : "واحد"
      },
    },
    "shop-package" : {
      name : "پکیج فروشگاه",
      fields: {
        name: "نام",
        order : "اولویت",
        plan : "پلن",
        canApplyDiscount : "قابلیت اعمال تخفیف",
        isPopUp : "پاپ آپ",
        markets : "مارکت ها  ",
      },
    },
    userPackeges : {
      name : "پکیج های یک کاربر",
    },
    adminUsers: {
      name: "مدیران",
      fields: {
        name: "نام",
        phoneNumber: "شماره",
        role: "دسترسی",
      },
    },
    foodUnit: {
      name: "واحد غذاها",
      fields: {
        name: "نام",
        isDeleted: "حذف شده",
        foodCategories: "دسته‌بندی",
        updatedAt: "بروز شده",
        id: "شناسه",
      },
    },
    foodCategory: {
      name: "دسته‌بندی غذاها",
      fields: {
        name: "نام",
        isDeleted: "حذف شده",
        foodCategories: "دسته‌بندی",
        updatedAt: "بروز شده",
        id: "شناسه",
      },
    },
    food: {
      name: "غذاها",
      fields: {
        name: "نام",
        isDeleted: "حذف شده",
        foodCategories: "دسته‌بندی",
        updatedAt: "بروز شده",
        id: "شناسه",
        hasCategory : "بدون دسته بندی"
      },
    },
    foodFact: {
      name: "اطلاعات غذایی",
      fields: {
        name: "نام",
        isDeleted: "حذف شده",
        foodCategories: "دسته‌بندی",
        updatedAt: "بروز شده",
        id: "شناسه",
        foodId: "غذا",
        foodUnitId: "واحد غذا",
        calorie: "کالری",
        protein: "پروتئين (g)",
        carbohydrate: "کربوهیدارت (g)",
        cholesterol: "کلسترول (mg)",
        fat: "چربی (g)",
        sugar: "شکر (g)",
        sodium: "سدیم (mg)",
        calcium: "کلسیم (mg)",
        iron: "آهن (mg)",
        transFat: "اسید چرب ترانس (g)",
        fiber: "فیبر (g)",
        potassium: "پتاسیم (mg)",
        phosphorus: "فسفر (mg)",
        monounsaturatedFat: "اسید چرب تک غیر اشباع - MUFA (g)",
        polyunsaturatedFat: " اسید چرب چند غیر اشباع - PUFA (g)",
        saturatedFat: "اسید چرب اشباع شده (g)",
        magnesium: "منیزیم (mg)",
        summary: "جزییات",
        foodUnitGramSize: "چند گرم؟",
      },
    },
    exercise: {
      name: "فعالیت ها",
      fields: {
        name: "نام",
        isDeleted: "حذف شده",
        exerciseCategories: "دسته‌بندی",
        updatedAt: "بروز شده",
        id: "شناسه",
        description : "توضیحات"
      },
    },
    exerciseCategory: {
      name: "دسته‌بندی فعالیت ها",
      fields: {
        name: "نام",
        isDeleted: "حذف شده",
        updatedAt: "بروز شده",
        id: "شناسه",
      },
    },
    exerciseFact: {
      name: "اطلاعات فعالیت ها",
      fields: {
        name: "نام",
        isDeleted: "حذف شده",
        updatedAt: "بروز شده",
        id: "شناسه",
        met: "مت",
        exerciseId: "تمرین",
        exerciseUnitId: "واحد تمرین",
      },
    },
    exerciseInstruction: {
      name: " تمرین‌ها",
      fields: {
        title: "نام",
        isDeleted: "حذف شده",
        updatedAt: "بروز شده",
        id: "شناسه",
        met: "مت",
        exerciseId: "تمرین",
        isPremium: "پرمیوم",
        exerciseUnitId: "واحد تمرین",
        categoryId: "دسته",
        description: "توضیحات",
        level  : "درجه سختی",
        time  : "مدت زمان"
      },
    },
    exerciseUnit: {
      name: "واحد فعالیت ها",
      fields: {
        name: "نام",
        isDeleted: "حذف شده",
        updatedAt: "بروز شده",
        id: "شناسه",
      },
    },
    foodRecipeCategory: {
      name: "دسته‌بندی دستورغذا",
      fields: {
        name: "نام",
        title: "نام",
        isDeleted: "حذف شده",
        updatedAt: "بروز شده",
        createdAt: "ایجاد شده",
        imageId: "تصویر",
        imageUrl: "تصویر",
        isPremium: "پرمیوم",
        id: "شناسه",
      },
    },
    foodRecipe: {
      name: "دستورغذا",
      fields: {
        name: "نام",
        title: "نام",
        duration: "مدت",
        note: "یادداشت",
        direction: "دیرکشن",
        personCount: "تعداد نفر",
        description: "توضیحات",
        categoryId: "دسته",
        ingredients: "مواد لازم",
        amount: "مقدار",
        isDeleted: "حذف شده",
        isPremium: "پرمیوم",
        updatedAt: "بروز شده",
        createdAt: "ایجاد شده",
        imageId: "تصویر",
        id: "شناسه",
      },
    },
    "food-barcode": {
      name: "بارکد غذاها",
      fields: {
        name: "نام",
        title: "نام",
        barcode: "بارکد",
        isDeleted: "حذف شده",
        updatedAt: "بروز شده",
        createdAt: "ایجاد شده",
        foodId: "غذا",
        id: "شناسه",
      },
    },
    challengeCategory: {
      name: "دسته‌ی چالش",
      fields: {
        name: "نام",
        title: "نام",
        barcode: "بارکد",
        isDeleted: "حذف شده",
        updatedAt: "بروز شده",
        createdAt: "ایجاد شده",
        foodId: "غذا",
        id: "شناسه",
        isPremium: "پرمیموم",
        markets:"مارکت"
      },
    },
    challenge: {
      name: "چالش",
      fields: {
        name: "نام",
        title: "نام",
        duration: "مدت",
        note: "یادداشت",
        direction: "دیرکشن",
        personCount: "تعداد نفر",
        description: "توضیحات",
        categoryId: "دسته",
        ingredients: "مواد لازم",
        amount: "مقدار",
        isDeleted: "حذف شده",
        isPremium: "پرمیوم",
        updatedAt: "بروز شده",
        createdAt: "ایجاد شده",
        imageId: "تصویر",
        id: "شناسه",
        challengeCategories: "دسته‌ی چالش",
        startDate: "تاریخ شروع",
        endDate: "تاریخ پایان",
        isFasting: "فستینگ",
        fastingHour: "میزان فستینگ (ساعت)",
        type: "نوع",
        users: "کاربران",
      },
    },
    advice: {
      name: "توصیه ها",
      fields: {
        name: "نام",
        type: "نوع",
        personCount: "تعداد نفر",
        content: "محتوا",
        isDeleted: "حذف شده",
        isActionable: "قابل اجرا",
        updatedAt: "بروز شده",
        createdAt: "ایجاد شده",
        id: "شناسه",
      },
    },
    package: {
      name: "پکیج",
      fields: {
        name: "نام",
        type: "نوع",
        pricingOptions: "گزینه‌های قیمت",
        subscriptionAmount: "میزان اشتراک",
        isDeleted: "حذف شده",
        updatedAt: "بروز شده",
        createdAt: "ایجاد شده",
        month:"تعداد ماه",
        id: "شناسه",
      },
    },
    banner: {
      name: "بنر",
      fields: {
        name: "نام",
        title: "نام",
        duration: "مدت",
        note: "یادداشت",
        direction: "دیرکشن",
        personCount: "تعداد نفر",
        description: "توضیحات",
        categoryId: "دسته",
        ingredients: "مواد لازم",
        amount: "مقدار",
        isDeleted: "حذف شده",
        isPremium: "پرمیوم",
        updatedAt: "بروز شده",
        createdAt: "ایجاد شده",
        imageId: "تصویر",
        id: "شناسه",
        objectId: "شناسه",
        challengeId: "چالش",
        markets: "مارکت"
      },
    },
    getToKnow: {
      name: "آشنایی با اپ",
    },
    regularDiscountCodes: {
      name: "کد تخفیف",
      fields: {
        code : "پیشوند",
        defaultPercent : "درصد پیشفرض",
        limit : "تعداد",
        expirationTime : "تاریخ انقضا ",
        number : "تعداد",
        percent : "درصد ",
        usedCount : "تعداد استفاده شده ",
        id : "شناسه ",
        percentPerPlan : {
          percent : "درصد (پلن)",
          planId : " پلن",
        } 
      },
    },
    bulkDiscountCodes: {
      name: "کد تخفیف گروهی ",
      fields: {
        prefix : "پیشوند",
        defaultPercent : "درصد پیشفرض",
        isActive : "فعال",
        expirationTime : "تاریخ انقضا ",
        number : "تعداد",
        percent : "درصد ",
        usedCount : "تعداد استفاده شده ",
        id : "شناسه ",
        percentPerPlan : {
          percent : "درصد (پلن)",
          planId : " پلن",
        } 
      },
    },
    campaignCard: {
      name: "کمپین",
      fields: {
        startDate: " تاریخ شروع",
        expirationDate: "تاریخ پایان",
        updatedAt: "بروز رسانی",
        title:"عنوان",
        subtitle: "زیرعنوان",
        image : "تصویر",
        objectId: "شناسه",
        isActive: "فعال",
        page : "صفحه",
        action: "عملکرد",
        url: "لینک"
      },
    },
    popUpData: {
      name: "پاپ آپ",
      fields: {
        name: "نام",
        title: "نام",
        duration: "مدت",
        note: "یادداشت",
        direction: "دیرکشن",
        personCount: "تعداد نفر",
        description: "توضیحات",
        categoryId: "دسته",
        ingredients: "مواد لازم",
        amount: "مقدار",
        isDeleted: "حذف شده",
        isPremium: "پرمیوم",
        updatedAt: "بروز شده",
        createdAt: "ایجاد شده",
        imageId: "تصویر",
        id: "شناسه",
        objectId: "شناسه",
        sku: "sku",
        headerImageUrl: "آدرس عکس هدر",
        mainHeader: "هدر اصلی",
        mainHeaderSubtitle: "زیرعنوان هدر اصلی",
        footerTitle: "عنوان فوتر",
        footerSubtitle: "زیرعنوان فوتر اصلی",
        callToAction: "CTA",
        outOfBox: "outOfBox",
        discountLabel: "عنوان تخفیف",
        dismissButton: "دکمه رد کردن",
        shopButton: "دکمه فروشگاه",
        type: "نوع",
        priority: "اولویت",
        discountCode: "کد تخفیف",
      },
    },
    updatePopUpData: {
      name: "آپدیت اجباری",
      fields: {
        ...common,
        mainHeader: "هدر اصلی",
        mainHeaderSubtitle: "زیرعنوان هدراصلی",
        footerTitle: "عنوان فوتر",
        footerSubtitle: "زیر عنوان فوتر",
        discountLabel: "متن تخفیف",
        shopButton: "دکمه خرید",
        dismissButton: "دکمه خروج",
        priority: "اولویت",
        androidVersionCode: "حداقل ورژن اندروید",
        active: "فعال",
      },
    },
    packagePricing: {
      name: "قیمت پکیج‌ها",
      fields: {
        name: "نام",
        title: "عنوان",
        duration: "مدت",
        note: "یادداشت",
        direction: "دیرکشن",
        personCount: "تعداد نفر",
        description: "توضیحات",
        categoryId: "دسته",
        ingredients: "مواد لازم",
        amount: "مقدار",
        isDeleted: "حذف شده",
        isPremium: "پرمیوم",
        updatedAt: "بروز شده",
        createdAt: "ایجاد شده",
        imageId: "تصویر",
        id: "شناسه",
        objectId: "شناسه",
        subtitle: "زیرعنوان",
        eachMonth: "هرماه",
        discountCode: "کد تخفیف",
        market: "مارکت",
        image: "تصویر",
        priority: "اولویت",
        packageId: "پکیج",
        type: "نوع",
        price: " قیمت",
        currency:"واحد پول",
        planAmount:"مقدار برنامه",
        discountPercent:"درصد تخفیف",
        packagePricingTagId: "تگ",
        isAvailable: "فعال",
        from: "از تاریخ",
        to: "تا تاریخ",
        nonDiscountPrice: "قیمت بدون تخفیف",
        timeExtendedPrice: "قیمت بدون تخفیف برای هرماه",
      },
    },
    sku:{
      name:"لیست SKU",
      fields:{
        id:"شناسه ",
        price:"قیمت ",
      }
    },
    campaign: {
      name: "کمپین",
      fields: {
        name: "نام",
        title: "نام",
        isDeleted: "حذف شده",
        updatedAt: "بروز شده",
        createdAt: "ایجاد شده",
        id: "شناسه",
        objectId: "شناسه",
        from: "از",
        to: "تا",
      },
    },
    downloadLink: {
      name: "لینک‌های دانلود",
      fields: {
        source: "مبدا",
        medium: "مدیوم",
        targetLink: "لینک مقصد",
        isDeleted: "حذف شده",
        updatedAt: "بروز شده",
        createdAt: "ایجاد شده",
        id: "شناسه",
        objectId: "شناسه",
      },
    },
    user: {
      name: "کاربر",
      fields: {
        name: "نام",
        title: "نام",
        duration: "مدت",
        note: "یادداشت",
        direction: "دیرکشن",
        personCount: "تعداد نفر",
        description: "توضیحات",
        categoryId: "دسته",
        ingredients: "مواد لازم",
        amount: "مقدار",
        isDeleted: "حذف شده",
        isPremium: "پرمیوم",
        updatedAt: "بروز شده",
        createdAt: "ایجاد شده",
        imageId: "تصویر",
        id: "شناسه",
        objectId: "شناسه",
        "user.activityLevel": "سطح فعالیت",
        very_high: "خیلی زیاد",
        high: "زیاد",
        moderate: "متوسط",
        low: "کم",
        very_low: "خیلی کم",
        inactive: "غیرفعال",
        active: "فعال",
        milk_only: "شیر",
        milk_and_food: "شیر و غذا",
        "user.weight": "وزن",
        "user.height": "قد",
        "user.name": "نام",
        "user.phoneNumber": "شماره تلفن",
        "user.birthdate": "تاریخ تولد",
        "user.registeredDate": "تاریخ ثبت نام",
        "user.breastFeedingState": "وضعیت شیردهی",
        "user.sex": "جنسیت",
        "user.fcmToken": "توکن",
        "user.versionName": "نام ورژن",
        "user.versionCode": "کد ورژن",
        "user.isRamadan": "ماه رمضان",
        tag: "تگ",
        content: "محتوا",
        editLogs: "تاریخچه کاربر",
      },
      subscription: {
        name: "اشتراک",
        fields: {
          "status fetched": "اطلاعات مالی دریافت شد",
        },
      },
    },
    getInvalidFoodUnits: {
      name: "غذا با واحد نادرست",
      fields: {
        name: "نام",
        title: "نام",
        duration: "مدت",
        note: "یادداشت",
        direction: "دیرکشن",
        personCount: "تعداد نفر",
        description: "توضیحات",
        categoryId: "دسته",
        ingredients: "مواد لازم",
        amount: "مقدار",
        isDeleted: "حذف شده",
        isPremium: "پرمیوم",
        updatedAt: "بروز شده",
        createdAt: "ایجاد شده",
        imageId: "تصویر",
        _id: "شناسه",
        foodCategories: "دسته‌بندی",
        "foodFacts.foodUnits": "واحد غذایی",
      },
    },
    getInvalidFoodFacts: {
      name: "غذا با اطلاعات نادرست",
      fields: {
        name: "نام",
        title: "نام",
        duration: "مدت",
        note: "یادداشت",
        direction: "دیرکشن",
        personCount: "تعداد نفر",
        description: "توضیحات",
        categoryId: "دسته",
        ingredients: "مواد لازم",
        amount: "مقدار",
        isDeleted: "حذف شده",
        isPremium: "پرمیوم",
        updatedAt: "بروز شده",
        createdAt: "ایجاد شده",
        imageId: "تصویر",
        _id: "شناسه",
        foodCategories: "دسته‌بندی",
        "foodFacts.foodUnits": "اطلاعات غذایی",
      },
    },
    downloadLinks: {
      name: "لینک‌های دانلود",
      fields: {
        name: "نام",
        title: "نام",
        src: "سورس",
        medium: "مدیوم",
        link: "لینک ماتریکس",
        url: "لینک نهایی",
        description: "توضیحات",
        id: "شناسه",
      },
    },
    notification: {
      name: "اعلانات",
      fields: {
        title: "نام",
        body: "محتوا",
        page: "صفحه",
        testPhoneNumber: "شماره موبایل تست",
        isDeleted: "حذف شده",
        updatedAt: "بروز شده",
        createdAt: "ایجاد شده",
        id: "شناسه",
        currentUsersCount: "تعداد کاربران دریافت‌کننده اعلان",
        lastSentUsersCount: "تعداد کاربران دریافت کننده اعلان قبلی",
        lastSentUsersDate: "تاریخ آخرین ارسال",
        clickedCount: "تعداد کلیک‌ها",
        minAndroidVersionCode: "حداقل ورژن",
        sentUserIdsCount: "تعداد کاربران دریافتی",
        url: "لینک (نشانی اینترنتی)",
        imageUrl: "تصویر اعلان",
        navigateToChallengeId: " ورود به چالش خاص",
        successSentCount:"ارسال موفق",
        failedSentCount:"ارسال ناموفق",
        clickRateCount:"نرخ کلیک",
        data: {
          campaign: "کمپین",
          goal: "هدف",
          maxAppOpenedCount: "حداکثر تعداد باز کردن اپ",
          lastAppOpenedDatePerHour: "مدت زمان باز نشدن اپ (برحسب ساعت)",
          subscription: "اشتراک",
          markets: "مارکت",
          minAge: "حداقل سن",
          maxAge: "حداکثر سن",
          sex: "جنسیت"
        },
      },
    },
    bulkDiscountCode: {
      name: "کدتخفیف گروهی",
      fields: {
        name: "نام",
        prefix: "پیشوند",
        expirationTime: "تاریخ انقضا",
        skus: "SKU",
        number: "تعداد",
        percent: "درصد",
        id: "شناسه",
        codes: "کدهای تخفیف",
        usedCount: "تعداد خرید شده",
      },
    },
    regularDiscountCode: {
      name: "کدتخفیف",
      fields: {
        name: "نام",
        code: "کد",
        expirationTime: "تاریخ انقضا",
        skus: "SKU",
        limit: "تعداد",
        percent: "درصد",
        id: "شناسه",
        usedCount: "تعداد استفاد شده",
        pricings: "قیمت "
      },
    },
    errorMessage: {
      name: "ارور مسیج",
      fields: {
        name: "نام",
      },
    },
    config: {
      name: "کانفیگ",
      fields: {
        name: "نام",
        title: "نام",
        src: "سورس",
        medium: "مدیوم",
        link: "لینک ماتریکس",
        url: "لینک نهایی",
        description: "توضیحات",
        id: "شناسه",
        type: "نوع",
        value: "مقدار",
        updatedAt: "بروز شده",
      },
    },
    sibApp: {
      name: "سیب اپ",
      fields: {
        target :{
          name:"نام",
          description : "توضیحات",
          price: "قیمت"
        },
        user :{
          phone_number:"شماره کاربر",

        },

        consumed :"مصرف شده",
        create_time: "ایجاد شده",
        id: "شناسه",
      },
    },
    CountryCode: {
      name: "کد کشور",
      fields: {
        name: " نام کشور",
        prefixCode:"کد پیشوند",
        icon:"آیکون",
        id: "شناسه",
        pattern: "الگو",
        markets: "مارکت",

      },
    },
    "non-paid-invoices": {
      name: "بررسی سرور پیمنت",
      fields: {
        name: "نام",
        title: "نام",
        src: "سورس",
        medium: "مدیوم",
        link: "لینک ماتریکس",
        url: "لینک نهایی",
        description: "توضیحات",
        id: "شناسه",
        type: "نوع",
        value: "مقدار",
        updatedAt: "بروز شده",
      },
    },
    packagePricingTag: {
      name: "تگ قیمت پکیج",
      fields: {
        ...common,
        weight: "وزن",
        isAvailable: "در دسترس",
        markets: "مارکت",
        nonDiscountPrice: "قیمت بدون تخفیف",
        planAmount:"مقدار برنامه ",
        discountPercent:"درصد تخفیف",
      },
    },
    PackageUserTag: {
      name: "  اختصاص تگ به کاربر",
      fields: {
        ...common,
      },
    },
    pageDataType: {
      name: "نوع دیتا صفحه",
      fields: {
        ...common,
        page: "صفحه",
        weight: "وزن",
        minAndroidVersionCode: "کد حداقل ورژن اندروید",
      },
    },
    abtest: {
      name: "A/B تست ",
      fields: {
        ...common,
        description: "توضیحات",
        weight: "وزن",
        androidMinVersion: " حداقل ورژن اندروید",
        iosMinVersion: " حداقل ورژن IOS",
        active: "فعال",
      },
    },
    remoteConfig: {
      name: "ریموت کانفیگ",
      fields: {
        ...common,
        page: "صفحه",
        objectData : "دیتا",
        key :"کلید",
        value : "مقدار",
        isDefault : "پیشفرض",

      },
    },
    pagesData: {
      name: "نوع صفحات تولید شده",
      fields: {
        ...common,
        minAndroidVersionCode: "حداقل ورژن اندروید",
        weight: "وزن",
        pagesDataTypes: "نوع دیتا صفحه",
        "pagesDataTypes.page": "صفحه",
        "pagesDataTypes.type": "نوع",
        page:"صفحه"
      },
    },
    userPageData: {
      name: "دیتا صفحه کاربر",
      fields: {
        ...common,
        userId: "شناسه کاربر",
        pagesDataId: "دیتای صفحه",
      },
    },
    UserScenario: {
      name: " اختصاص سناریو به کاربر",
      fields: {
        ...common,
        userId: "شناسه کاربر",
        scenarioId: "سناریو",
      },
    },

    // Custom API 

    reportOldUser: {
      name: "گزارش کاربران قدیمی",
    },
    OldNonRegisterKarafs: {
      name: "کاربران قدیمی ثبت نام نکرده",
    },
    KarafsUserLog: {
      name: "تعداد لاگ وارد شده کاربران",
    },
    NonPaidUser: {
      name: "کاربران بدون خرید ",
    },
    getUsersByExpirationDate: {
      name: "اشتراک های در حال اتمام ",
    },
    SalesReport: {
      name: "گزارش فروش",
    },
    "report/users/old/": {
      name: "گزارش کاربران قدیمی",
    },
    SignUpReport: {
      name: "گزارش ثبت نام ",
    },
    old_non_registered_karafs_users: {
      name: "دریافت شماره تلفن کاربران قدیمی که هنوز ثبت‌نام نکردند",
    },
    BarcodeLog: {
      name: "تعداد لاگ وارد شده بارکد ",
    },
    addGroupSubscription: {
      name: "افزایش اشتراک گروهی",
    },
    RandomFood: {
      name: "غذای تصادفی",
    },
    FoodUnitUsage: {
      name: "تعداد واحدهای غذایی اضافه شده ",
    },
    UsersByExpirationDate: {
      name: "کاربران بدون اشتراک ",
    },
    getUsersTransaction: {
      name: "کاربران خرید کرده ",
    },
    GetMigratedUsers: {
      name: "کاربران مایگریت  ",
    },
    scoreboardReport: {
      name: "امتیاز ریفرال  ",
    },
    scoreboardVoteReport: {
      name: "امتیاز برای رای گیری ریفرال  ",
    },

    faqCategory: {
      name: "دسته‌بندی FAQ",
      fields: {
        ...common,
        order: "ترتیب",
      },
    },
    faqSubcategory: {
      name: "زیر دسته‌بندی FAQ",
      fields: {
        ...common,
        order: "ترتیب",
        categoryId: "دسته بندی",
      },
    },
    faqQuestion: {
      name: "سوالات FAQ",
      fields: {
        ...common,
        order: "ترتیب",
        category: "دسته بندی",
        body: "محتوا",
        subject: "عنوان",
        subcategoryId: "زیردسته",
        views: "بازدید",
        likeCount: "تعداد لایک",
        dislikeCount: "تعداد دیسلایک",
        _id : "شناسه"
      },
    },
    faqQuestionHistory: {
      name: "تاریخچه سوالات FAQ ",
      fields: {
        ...common,
        order: "ترتیب",
        category: "دسته بندی",
        body: "محتوا",
        subject: "عنوان",
        subcategoryId: "زیردسته",
        views: "بازدید",
        likedBy: "تعداد لایک",
        dislikedBy: "تعداد دیسلایک",
      },
    },
    faqUserQuestionAnswer: {
      name: "پاسخ سوالات کاربران",
    },
    fix_duplicate_client_data: {
      name: "حذف اطلاعات تکراری",
    },

    faqUserQuestion: {
      name: " سوالات کاربران ",
      fields: {
        ...common,
        questionId: "شناسه سوال",
        text: "متن سوال کاربر ",
        createdAt: "تاریخ ایجاد",
        userId: " شناسه کاربر ",
        answered: " پاسخ  ",
        adminName : "ادمین پاسخ دهنده",
        imageUrl : "تصویر"    
      },
    },
    faqTag: {
      name: "تگ ",
      fields: {
        ...common,
      },
    },
    clinicUser: {
      name:'کاربران کلینیک',
      fields:{
        ...common,
        code:'کد',
        phoneNumber:"شماره تماس",
        hatedFoods:'غذا‌های حساسیت‌زا',
        birthDate:"تاریخ تولد",
        weight:"وزن",
        height:"قد",
        abdomenSize:"دور باسن",
        waistSize:"دور کمر",
        wristSize:"دور مچ",
        diseases:"بیماری‌ها",
        activityLevel:"سطح فعالیت",
        cycle:"دوره",
        sex:"جنسیت",
        laboredPast:"سابقه زایمان",
      }
    }
  },
};
