import React from "react";
import {
  ArrayField,
  BooleanField,
  ChipField,
  Datagrid,
  DateField,
  List,
  ReferenceArrayField,
  ReferenceField,
  SingleFieldList,
  TextField,
} from "react-admin";
InvalidFoodsList.propTypes = {};
function InvalidFoodsList(props) {
  return (
    <List {...props}>
      <Datagrid rowClick="show">
        <ReferenceField
          reference="food"
          source="_id"
          label={"نام"}
          linkType={"show"}
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceArrayField reference="foodCategory" source="foodCategories">
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <TextField source="_id" />
        <BooleanField source="isDeleted" />
        <DateField locales={"fa-IR"} source="updatedAt" />
      </Datagrid>
    </List>
  );
}
export default InvalidFoodsList;

export function InvalidFoodsFactList(props) {
  return (
    <List {...props}>
      <Datagrid rowClick="show">
        <ReferenceField
          reference="food"
          source="_id"
          label={"نام"}
          link={"show"}
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceArrayField reference="foodCategory" source="foodCategories">
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <ArrayField source="similarNames" label={"نام‌های مشابه"}>
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ArrayField>
        <TextField source="_id" />
        <DateField locales={"fa-IR"} source="updatedAt" />
        <BooleanField source="isDeleted" />
      </Datagrid>
    </List>
  );
}