import React, { useState } from "react";
import { Confirm, useDataProvider, useNotify } from "react-admin";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";

export function SubscriptionCancellationDialog({
  transaction,
  record,
  refreshSubDate,
}) {
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [cancellation, setCancellation] = useState("");
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const handleCancellationClick = () => setIsConfirmOpen(true);
  const handleCancellationDialogClose = () => setIsConfirmOpen(false);
  const handleConfirm = () => {
    dataProvider
      .create("transaction", {
        id: transaction.transactionId,
        data: { reason: cancellation },
      })
      .then((res) => {
      
        notify(`خطا در لغو اشتراک: ${res.message}`, "warning");

      })
      .catch((err) => {
        notify("اشتراک لغو شد");
        refreshSubDate();
      });
    setIsConfirmOpen(false);
  };
  const _renderContent = () => {
    return (
      <FormControl fullWidth={true}>
        <InputLabel>دلیل لغو</InputLabel>
        <Select
          value={cancellation}
          onChange={(e) => setCancellation(e.target.value)}
        >
          <MenuItem value={"bazar"}>بازارپی</MenuItem>
          <MenuItem value={"bento"}>انتقال به بنتو</MenuItem>
          <MenuItem value={"SNAP_PAY_CANCEL"}>اسنپ پی</MenuItem>
        </Select>
        <TextField
          id="outlined-multiline-flexible"
          style={{ marginTop: "2em" }}
          label="دلیل لغو"
          multiline
          rowsMax={4}
          value={cancellation}
          onChange={(e) => setCancellation(e.target.value)}
          variant="outlined"
        />
      </FormControl>
    );
  };
  return (
    <>
      <Button onClick={handleCancellationClick} color="primary">
        لغو
      </Button>
      <Confirm
        isOpen={isConfirmOpen}
        // loading={loading}
        title="لغو اشتراک"
        content={_renderContent()}
        onConfirm={handleConfirm}
        onClose={handleCancellationDialogClose}
        onCancel={handleCancellationDialogClose}
      />
    </>
  );
}
