import CategoryIcon from "@material-ui/icons/Bookmark";
import {
  ExerciseCreate,
  ExerciseEdit,
  ExerciseList,
  ExerciseShow,
} from "./Exercise";
import {
  ExerciseFactCreate,
  ExerciseFactEdit,
  ExerciseFactList,
  ExerciseFactShow,
} from "./ExerciseFact";
import {
  ExerciseUnitCreate,
  ExerciseUnitEdit,
  ExerciseUnitList,
  ExerciseUnitShow,
} from "./ExerciseUnit";
import {
  ExerciseCategoryCreate,
  ExerciseCategoryEdit,
  ExerciseCategoryList,
  ExerciseCategoryShow,
} from "./ExerciseCategory";
import {
  ExerciseInstructionCreate,
  ExerciseInstructionEdit,
  ExerciseInstructionList,
  ExerciseInstructionShow,
} from "./ExerciseInstruction";
import {
  ExerciseInstructionCategoryCreate,
  ExerciseInstructionCategoryEdit,
  ExerciseInstructionCategoryList,
  ExerciseInstructionCategoryShow,
} from "./ExerciseInstructionCategory";
import FitnessCenterIcon from "@material-ui/icons/FitnessCenter";
import InfoIcon from "@material-ui/icons/Info";
import ScoreIcon from "@material-ui/icons/Score";
import ReceiptIcon from "@material-ui/icons/Receipt";

export const exercise = {
  name: "exercise",
  list: ExerciseList,
  edit: ExerciseEdit,
  show: ExerciseShow,
  create: ExerciseCreate,
  icon: FitnessCenterIcon,
  options: { cat: "exercise" },
};

export const exerciseFact = {
  name: "exerciseFact",
  list: ExerciseFactList,
  edit: ExerciseFactEdit,
  show: ExerciseFactShow,
  create: ExerciseFactCreate,
  icon: InfoIcon,
  options: { cat: "exercise" },
};
export const exerciseUnit = {
  name: "exerciseUnit",
  list: ExerciseUnitList,
  edit: ExerciseUnitEdit,
  show: ExerciseUnitShow,
  create: ExerciseUnitCreate,
  icon: ScoreIcon,
  options: { cat: "exercise" },
};
export const exerciseCategory = {
  name: "exerciseCategory",
  list: ExerciseCategoryList,
  edit: ExerciseCategoryEdit,
  show: ExerciseCategoryShow,
  create: ExerciseCategoryCreate,
  icon: CategoryIcon,
  options: { cat: "exercise" },
};
export const exerciseInstruction = {
  name: "exerciseInstruction",
  list: ExerciseInstructionList,
  edit: ExerciseInstructionEdit,
  show: ExerciseInstructionShow,
  create: ExerciseInstructionCreate,
  icon: ReceiptIcon,
  options: { cat: "exerciseClass" },
};
export const exerciseInstructionCategory = {
  name: "exerciseInstructionCategory",
  list: ExerciseInstructionCategoryList,
  edit: ExerciseInstructionCategoryEdit,
  show: ExerciseInstructionCategoryShow,
  create: ExerciseInstructionCategoryCreate,
  icon: CategoryIcon,
  options: { cat: "exerciseClass", type: "teaching" },
};
