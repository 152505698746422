import React from "react";
import {
  Datagrid,
  ImageField,
  ImageInput,
  List,
  SimpleShowLayout,
  TextField,
  TextInput,
  required,
} from "react-admin";
import EditView from "../../Components/EditView";
import ShowView from "../../Components/ShowView";
import CreateView from "../../Components/CreateView";
export const GetToKnowList = (props) => (
  <List {...props}>
    <Datagrid rowClick="show">
      <TextField source="name" />    
      <TextField source="id" />      
    </Datagrid>
  </List>
);
export const GetToKnowShow = (props) => (
  <ShowView {...props}>
    <SimpleShowLayout>
    <TextField source="name" />    
    <ImageField source="icon"/>
    </SimpleShowLayout>
  </ShowView>
);

export const  GetToKnowEdit = (props) => (
  <EditView {...props}>
    <TextInput source="name" />
    <ImageInput source="pictures" label="تصویر مربوطه" accept="image/*">
      <ImageField source="src" />
    </ImageInput>
  </EditView>
);

export const GetToKnowCreate = (props) => (
  <CreateView {...props}>
    <TextInput source="name" validate={required()} />
    <ImageInput source="icon" label="تصویر مربوطه" accept="image/*">
      <ImageField source="src" />
    </ImageInput>
    <ImageField source="icon" />
  </CreateView>
);
