import React from "react";
import {
  Datagrid,
  List,
  SimpleShowLayout,
  TextField,
  TextInput,
  NumberInput,
  NumberField,DateField, BooleanField, BooleanInput
} from "react-admin";
import ShowView from "../../Components/ShowView";
import EditView from "../../Components/EditView";
import CreateView from "../../Components/CreateView";
import {MaterialDateInput} from "../../Components/RaMaterialDatePickers";

export const PlanPromotionList = (props) => (
  <List {...props} >
    <Datagrid rowClick="show">
      <TextField source="name" />
      <NumberField source="discountPercentage" textAlign="center"/>
      <DateField locales="fa-IR" source="startedAt" />
      <DateField locales="fa-IR" source="expiredAt" />
      <BooleanField source="isActive"  />
    </Datagrid>
  </List>
);

export const PlanPromotionEdit = (props) => {
  const transform = (data) => {
    let expiredAt =  new Date(Date.parse(data.expiredAt)).setHours(23, 59, 0);
    let startedAt =  new Date(Date.parse(data.startedAt)).setHours(0, 0, 0);
    return { ...data, expiredAt,startedAt};
  };
  return(
  <EditView {...props} transform={transform}>
    <TextInput source="name"/>
    <NumberInput source="discountPercentage"/>
    <BooleanInput source="isActive"/>
    <MaterialDateInput source={"startedAt"}/>
    <MaterialDateInput source={"expiredAt"}/>
  </EditView>
)};

export function PlanPromotionCreate (props) {
  const transform = (data) => {
    let expiredAt =  new Date(Date.parse(data.expiredAt)).setHours(23, 59, 0);
    let startedAt =  new Date(Date.parse(data.startedAt)).setHours(0, 0, 0);
    return { ...data, expiredAt,startedAt}
  };
  return(
  <CreateView {...props} transform={transform}>
    <TextInput source="name"/>
    <NumberInput source="discountPercentage"/>
    <BooleanInput source="isActive" defaultValue={true}/>
    <MaterialDateInput source={"startedAt"}/>
    <MaterialDateInput source={"expiredAt"}/>
  </CreateView>
)}

export const PlanPromotionShow = (props) =>{

 return(
  <ShowView {...props}>
    <SimpleShowLayout>
      <TextField source="name"/>
      <NumberField source="discountPercentage"/>
      <BooleanField source="isActive" />
      <DateField locales="fa-IR" source="startedAt" />
      <DateField locales="fa-IR" source="expiredAt" />
    </SimpleShowLayout>
  </ShowView>
)};
