import React from "react";
import {
  Datagrid,
  DateField,
  List,
  NumberField,
  NumberInput,
  SimpleShowLayout, 
  TextField,
  TextInput,
  useRedirect,
  BooleanInput,
  ReferenceInput,
  SelectInput,
  BooleanField,
  SimpleFormIterator,
  ArrayInput
} from "react-admin";
import { MaterialDateInput } from "../../Components/RaMaterialDatePickers";
import EditView from "../../Components/EditView";
import ShowView from "../../Components/ShowView";
import CreateView from "../../Components/CreateView";
import {Button} from "@material-ui/core";
import FileCopyIcon from '@material-ui/icons/FileCopy';

const info={
  code: "",
  limit: ""
}
export const ShopRegularDiscountList = (props) => (
  <List {...props}>
    <Datagrid rowClick="show">
      <TextField source="code" /> 
      <NumberField source="limit" textAlign="center" />
      <NumberField source="defaultPercent" textAlign="center" />
      <BooleanField source="isActive" />
      <TextField source="usedCount" />
      <DateField locales="fa-IR" source="expirationTime" />
      <NumberField source="percentPerPlan[0].percent" label="درصد (پلن)" textAlign="center" />
      <TextField source="percentPerPlan[0].planId" label="شناسه پلن" /> 
    </Datagrid>
  </List>
);

export const ShopRegularDiscountEdit = (props) => (
  <EditView
    {...props}
    transform={(data) => {
      let e = new Date(data.expirationTime).getTime();
      return { ...data, expirationTime: e };
    }}>
    <NumberInput source="limit" />
    <MaterialDateInput source="expirationTime" options={{ format: "DD/MM/YYYY" }}/>
    <NumberInput source="defaultPercent" />
    <BooleanInput source="isActive" />
    <ArrayInput source="percentPerPlan" label="درصد تخفیف بر پلن">
      <SimpleFormIterator>
        <ReferenceInput source="planId" label="پلن" reference="plan">
          <SelectInput optionText={(record)=> `${record.subscriptionType}/${record.name}`}  />
        </ReferenceInput>
        <NumberInput source="percent" />
      </SimpleFormIterator>
    </ArrayInput>
  </EditView>
);

export const ShopRegularDiscountCreate = (props) => (
  <CreateView {...props}
    transform={(data) => {
      let e = new Date(data.expirationTime).getTime();
      return { ...data, expirationTime: e };
    }}>
    <TextInput source="code"    defaultValue={info.code}/>
    <NumberInput source="limit"    defaultValue={info.limit} />
    <MaterialDateInput source="expirationTime" options={{ format: "DD/MM/YYYY" }}/>
    <NumberInput source="defaultPercent" />
    <BooleanInput source="isActive" defaultValue={true}  />
    <ArrayInput source="percentPerPlan" label="درصد تخفیف بر پلن">
      <SimpleFormIterator>
        <ReferenceInput source="planId" label="پلن" reference="plan">
          <SelectInput optionText={(record)=> `${record.subscriptionType}/${record.name}`}  />
        </ReferenceInput>
        <NumberInput source="percent" />
      </SimpleFormIterator>
    </ArrayInput>
  </CreateView>
);
const DuplicateBtn = (props) => {
  let redirect = useRedirect()
  function OpenDuplicate() {
    redirect("/regularDiscountCodes/create")
    info.code = props.record.code
    info.expirationTime =Date.parse(props.record.expirationTime)
    info.limit = props.record.limit
  }
  return(
    <Button color="primary" variant="contained" onClick={OpenDuplicate}  endIcon={<FileCopyIcon/>} style={{marginTop:"15px"}}>
      دوپلیکت
    </Button>
    )
  }

export const ShopRegularDiscountShow = (props) => (
  <ShowView {...props}>
    <SimpleShowLayout>
      <TextField source="code" /> 
      <NumberField source="limit" textAlign="center" />
      <NumberField source="defaultPercent" textAlign="center" />
      <BooleanField source="isActive" />
      <TextField source="usedCount" />
      <DateField locales="fa-IR" source="expirationTime" />
      <NumberField source="percentPerPlan[0].percent" label="درصد (پلن)" textAlign="center" />
      <TextField source="percentPerPlan[0].planId" label="شناسه پلن" /> 
      <TextField source="id" />
      <DuplicateBtn/>
    </SimpleShowLayout>
  </ShowView>
);
