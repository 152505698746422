import React, { useState } from "react";
import { useNotify } from "react-admin";
import {
  Button,
  Card,
  CardContent,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { Search as SearchIcon } from "@material-ui/icons";
import { PersianDatePicker } from "../../Components/PersianDatePickers";
import { axiosFetcher } from "../../utils/axiosFetcher";

export const PaymentServerTroubleshootList = () => {
  const notify = useNotify();
  const [error, setError] = useState("");
  const [result, setResult] = useState([]);
  const [fromDate, setFromDate] = useState(
    new Date(Date.now() - (604800000).toString())
  );
  const [toDate, setToDate] = useState(new Date());
  const onSubmit = async (data) => {
    try {
      setError("");
      data.toDate = new Date(Date.parse(data.toDate)).setHours(0, 0, 0);
      data.fromDate = new Date(Date.parse(data.fromDate)).setHours(0, 0, 0);
      if (data.case === "complete") {
        const url = `non-paid-invoices/complete`;
        const result = await axiosFetcher.post(url, {
          from: JSON.stringify(data.fromDate),
          to: JSON.stringify(data.toDate),
        });
        setResult(result.data);
      } else {
        const url = `non-paid-invoices`;
        const result = await axiosFetcher.post(url, {
          from: JSON.stringify(data.fromDate),
          to: JSON.stringify(data.toDate),
        });
        setResult(result.data);
      }
    } catch (e) {
      setError("${e}");
      notify("خطا");
    }
  };

  return (
    <Card>
      <CardContent>
        <div>
          <Paper
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <PersianDatePicker
                name="from"
                value={fromDate}
                onChange={setFromDate}
                label={"از"}
              />
              <span style={{ margin: "0 1em" }} />
              <PersianDatePicker
                name="from"
                value={toDate}
                onChange={setToDate}
                label={"تا"}
              />
            </div>
            <div>
              <Button
                onClick={() => onSubmit({ toDate, fromDate })}
                type="submit"
                variant="contained"
                color="primary"
                style={{ height: "inherit" }}
              >
                جست‌وجو
                <SearchIcon />
              </Button>
              <span style={{ margin: "0 0.5em" }} />
              <Button
                onClick={() => onSubmit({ toDate, fromDate, case: "complete" })}
                type="submit"
                variant="contained"
                color="primary"
                style={{ height: "inherit" }}
              >
                Complete
              </Button>
              <span style={{ margin: "0 0.5em" }} />
            </div>
          </Paper>
        </div>
      </CardContent>
      <>
        <Typography style={{ padding: "0.5em" }}>
          {result.length > 0 ? `مجموع: ${result.length} ` : null}
        </Typography>
        {error ? (
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>خطایی پیش آمد</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  خطایی پیش آمد
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        ) : (
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                {result.length > 0 &&
                  result.map((row) => {
                    let rowHeaders = Object.keys(row);
                    return rowHeaders.map((header) => (
                      <TableCell align="center">{header}</TableCell>
                    ));
                  })}
              </TableRow>
            </TableHead>
            <TableBody>
              {result.length > 0 ? (
                result.map((element) => {
                  let rowBody = Object.values(element);
                  return rowBody.map((value) => {
                    if (typeof value === "string" && value.includes("http")) {
                      return (
                        <TableCell align="center" scope="row">
                          <a href={value}>LINK</a>
                        </TableCell>
                      );
                    } else if (typeof value === "string" && Date.parse(value)) {
                      return (
                        <TableCell align="center" scope="row">
                          {new Date(value).toLocaleDateString("fa-IR")}
                        </TableCell>
                      );
                    } else if (typeof value === "object") {
                      return (
                        <TableCell align="center" scope="row">
                          ----
                        </TableCell>
                      );
                    } else {
                      return (
                        <TableCell align="center" scope="row">
                          {value}
                        </TableCell>
                      );
                    }
                  });
                })
              ) : (
                <Typography style={{ padding: "0.5em" }}>
                  موردی یافت نشد
                </Typography>
              )}
            </TableBody>
          </Table>
        )}
      </>
    </Card>
  );
};
