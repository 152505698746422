// NOTE: delete btn works

import React from "react";
import {
    BooleanField,
    Datagrid,
    DateField,
    Filter,
    List,
    NumberField,
    SimpleShowLayout,
    TextField,
    TextInput,
    required
} from "react-admin";
import EditView from "../../Components/EditView";
import ShowView from "../../Components/ShowView";
import CreateView from "../../Components/CreateView";

export const FoodunitFilter = (props) => (
  <Filter {...props}>
    <TextInput label="جست‌وجو" source="name" alwaysOn />
  </Filter>
);
export const FoodunitList = (props) => (
  <List {...props} filters={<FoodunitFilter />} perPage={15}>
    <Datagrid rowClick="show">
      <TextField source="name" />
      <TextField source="id" />
      <DateField locales="fa-IR" source="updatedAt" />
      <BooleanField source="isDeleted" />
    </Datagrid>
  </List>
);

export const FoodunitEdit = (props) => (
  <EditView {...props}>
    <TextInput source="name"  validate={required()}/>
    <DateField locales="fa-IR" source="updatedAt" />
    <TextField source="id" />
  </EditView>
);

export const FoodunitShow = (props) => (
  <ShowView {...props}>
    <SimpleShowLayout>
      <BooleanField source="isDeleted" />
      <TextField source="name" />
      <NumberField source="updatedAt" />
      <TextField source="id" />
    </SimpleShowLayout>
  </ShowView>
);

export const FoodunitCreate = (props) => {
  return (
    <CreateView {...props}>
      <TextInput source="name" validate={required()} />
    </CreateView>
  );
};
