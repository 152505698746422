import React, {useState} from "react";
import Picker from "emoji-picker-react";
import {Button} from "@material-ui/core";
import EmojiEmotionsIcon from "@material-ui/icons/EmojiEmotions";
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';


const Emoji = (props) => {
    const [chosenEmoji, setChosenEmoji] = useState("");
    if(props.record.title===undefined){
        props.record.title =""
    }
    const [text, setText] = useState(props.record.title);
    let result=  text + chosenEmoji
    props.record.title = result
    function textChange(e) {
        setText(e.target.value)
        setChosenEmoji("")
    }
    const onEmojiClick = (event, emojiObject) => {
        setChosenEmoji(chosenEmoji +emojiObject.emoji);
    };
    return (
        <div style={{marginBottom:"30px"}}>
            <TextField id="textInputTitle" label="نام" variant="filled" onChange={textChange} value={result}  style={{height:"48.5px"}} />
            <Button
              id="openPickerTitle"
              style=
                {{
                background: "rgba(0,0,0,0.09)",
                color: "silver",
                width:"15px" ,
                height:48,
                borderRadius:"3px 0 0 3px",
                borderBottom:"1px solid rgba(0, 0, 0, 0.42)",
                display:"inline-flex"
                }}
              onClick={() =>  {
                  document.getElementById("emojiPickerTitle").style.display="block"
                  document.getElementById("openPickerTitle").style.display="none"
                  document.getElementById("closePickerTitle").style.display="inline-flex"
              }}
              startIcon={<EmojiEmotionsIcon/>}
            >
            </Button>
            <Button id="closePickerTitle" style={{
                background: "rgba(0,0,0,0.09)",
                color: "silver",
                width:"15px" ,
                height:48,
                borderRadius:"3px 0 0 3px",
                borderBottom:"1px solid rgba(0, 0, 0, 0.42)",
                display:"none"
            }}
              onClick={() =>  {
                  document.getElementById("emojiPickerTitle").style.display="none"
                  document.getElementById("openPickerTitle").style.display="inline-flex"
                  document.getElementById("closePickerTitle").style.display="none"
              }}
              startIcon={<CloseIcon/>}
            >
            </Button>
            <div id="emojiPickerTitle" style={{display:"none"}} >
            <Picker onEmojiClick={onEmojiClick} disableAutoFocus={false}   />
            </div>
        </div>
    );
};
export default Emoji;