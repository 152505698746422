import React from "react";
import {
  BooleanField,
  BooleanInput,
  ChipField,
  DateField,
  Edit,
  ImageField,
  ImageInput,
  List,
  ReferenceArrayField,
  ReferenceArrayInput,
  SelectArrayInput,
  SimpleForm,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
  TextInput,
  useRedirect,
  required,
  NumberInput,
  NumberField,
} from "react-admin";
import { sortableElement, sortableHandle } from "react-sortable-hoc";
import Emoji from "./Components/EmojiChallenge";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { MaterialDateInput } from "../../Components/RaMaterialDatePickers";
import ArrayLengthField from "../../Components/ArrayLengthField";
import ShowView from "../../Components/ShowView";
import CreateView from "../../Components/CreateView";
import SortableView from "./Components/SortableView";

const DragHandle = sortableHandle(() => (
  <span style={{ fontSize: "24px", cursor: "grab" }}>::</span>
));
const SortableItem = sortableElement((props) => {
  const red = useRedirect();
  return (
    <TableRow
      key={props.id}
      onClick={() => red(`/challenge/${props.id}/show`)}
      style={{ cursor: "pointer" }}
    >
      {props.children}
    </TableRow>
  );
});

const RenderedChildren = (props) => {
  return (
    <Table aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell align="center"> </TableCell>
          <TableCell align="center">نام</TableCell>
          <TableCell align="center">پرمیوم</TableCell>
          <TableCell align="center">نوع</TableCell>
          <TableCell align="center">تاریخ شروع</TableCell>
          <TableCell align="center">تاریخ اتمام</TableCell>
          <TableCell align="center">دسته ی چالش</TableCell>
          <TableCell align="center">تصویر</TableCell>
          <TableCell align="center">بروزشده</TableCell>
          <TableCell align="center">فستینگ</TableCell>
          <TableCell align="center">میزان فستینگ (ساعت) </TableCell>
          <TableCell align="center">شناسه</TableCell>
          <TableCell align="center">حذف شده</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {props.items.map((id, index) => (
          <SortableItem
            key={`item-${id}`}
            index={index}
            id={id}
            resource={props.resource}
          >
            <TableCell align="center" component="th" scope="row">
              <DragHandle />
            </TableCell>

            <TableCell align="center" component="th" scope="row">
              <TextField record={props.data[id]} source="name" />
            </TableCell>

            <TableCell align="center" component="th" scope="row">
              <BooleanField record={props.data[id]} source="isPremium" />
            </TableCell>
            <TableCell align="center" component="th" scope="row">
              <TextField record={props.data[id]} source="type" />
            </TableCell>
            <TableCell align="center" component="th" scope="row">
              <DateField
                record={props.data[id]}
                locales="fa-IR"
                source="startDate"
              />
            </TableCell>
            <TableCell align="center" component="th" scope="row">
              <DateField
                record={props.data[id]}
                locales="fa-IR"
                source="endDate"
              />
            </TableCell>
            <TableCell align="center" component="th" scope="row">
              <ReferenceArrayField
                source="challengeCategories"
                reference="challengeCategory"
                linkType="show"
                record={props.data[id]}
                basePath={props.basePath}
              >
                <SingleFieldList>
                  <ChipField source="name" />
                </SingleFieldList>
              </ReferenceArrayField>
            </TableCell>
            <TableCell align="center" component="th" scope="row">
              <ImageField record={props.data[id]} source="imageId" />
            </TableCell>

            <TableCell align="center" component="th" scope="row">
              <DateField
                record={props.data[id]}
                locales="fa-IR"
                source="updatedAt"
              />
            </TableCell>
            <TableCell align="center" component="th" scope="row">
              <BooleanField record={props.data[id]} source="isFasting" />
            </TableCell>
            <TableCell align="center" component="th" scope="row">
              <TextField record={props.data[id]} source="fastingHour" />
            </TableCell>
            <TableCell align="center" component="th" scope="row">
              <TextField record={props.data[id]} source="id" />
            </TableCell>
            <TableCell align="center" component="th" scope="row">
              <BooleanField record={props.data[id]} source="isDeleted" />
            </TableCell>
          </SortableItem>
        ))}
      </TableBody>
    </Table>
  );
};
export const ChallengeList = (props) => (
  <List {...props} sort={{ field: "order", order: "ASC" }}>
    <SortableView>
      <RenderedChildren />
    </SortableView>
  </List>
);

export const ChallengeEdit = (props) => (
  <Edit
    undoable={false}
    {...props}
    transform={(data) => {
      let s = new Date(Date.parse(data.startDate)).setHours(0, 0);
      let e = new Date(Date.parse(data.endDate)).setHours(0, 0);
      return { ...data, startDate: s, endDate: e };
    }}
  >
    <SimpleForm>
      <BooleanInput source="isPremium" />
      <BooleanInput source="isDeleted" />
      <TextInput source="name" validate={required()} />
      <TextInput source="type" validate={required()} />
      <MaterialDateInput
        source="startDate"
        options={{ format: "x" }}
        validate={required()}
      />
      <MaterialDateInput
        source="endDate"
        options={{ format: "DD/YYYY/MM" }}
        validate={required()}
      />
      <ReferenceArrayInput
        source="challengeCategories"
        reference="challengeCategory"
        validate={required()}
      >
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>
      <BooleanInput source="isFasting" defaultValue={false} />
      <NumberInput source="fastingHour" />
      <Emoji />
      <ImageInput source="pictures" label="تصویر مربوطه" accept="image/*">
        <ImageField source="src" title="title" />
      </ImageInput>
      <ImageField source="imageId" />
    </SimpleForm>
  </Edit>
);

export const ChallengeShow = (props) => (
  <ShowView {...props}>
    <SimpleShowLayout>
      <BooleanField source="isDeleted" />
      <BooleanField source="isPremium" />
      <BooleanField source="isFasting" />
      <NumberField source="fastingHour" />
      <DateField locales="fa-IR" source="updatedAt" />
      <TextField source="name" />
      <TextField source="type" />
      <DateField locales="fa-IR" source="startDate" />
      <DateField locales="fa-IR" source="endDate" />
      <ArrayLengthField source="users" />
      <TextField source="description" />
      <ReferenceArrayField
        source="challengeCategories"
        reference="challengeCategory"
        linkType="show"
      >
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <ImageField source="imageId" />
      <TextField source="id" />
    </SimpleShowLayout>
  </ShowView>
);

export const ChallengeCreate = (props) => (
  <CreateView
    {...props}
    transform={(data) => {
      let s = new Date(Date.parse(data.startDate)).setHours(0, 0);
      let e = new Date(Date.parse(data.endDate)).setHours(0, 0);
      return { ...data, startDate: s, endDate: e };
    }}
  >
    <BooleanInput source="isPremium" />
    <BooleanInput source="isDeleted" />
    <TextInput source="name" validate={required()} />
    <TextInput source="type" validate={required()} />
    <MaterialDateInput
      source="startDate"
      options={{ format: "DD/MM/YYYY" }}
      validate={required()}
    />
    <MaterialDateInput
      source="endDate"
      options={{ format: "DD/MM/YYYY" }}
      validate={required()}
    />
    <ReferenceArrayInput
      source="challengeCategories"
      reference="challengeCategory"
      validate={required()}
    >
      <SelectArrayInput optionText="name" />
    </ReferenceArrayInput>
    <BooleanInput source="isFasting" />
    <NumberInput source="fastingHour" />
    <TextInput multiline source="description" validate={required()} />
    <Emoji />
    <ImageInput
      source="pictures"
      label="تصویر مربوطه"
      accept="image/*"
      validate={required()}
    >
      <ImageField source="src" title="title" />
    </ImageInput>
  </CreateView>
);
