import React from "react";
import {
  BooleanField,
  BooleanInput,
  Datagrid,
  ImageField,
  ImageInput,
  List,
  NumberField,
  NumberInput,
  SelectInput,
  Show,
  SimpleShowLayout,
  TextField,
  TextInput,
  required,
} from "react-admin";
import EditView from "../../Components/EditView";
import ShowView from "../../Components/ShowView";
import CreateView from "../../Components/CreateView";
import { POPUP_DATA_CHOICES } from "../../Constants/choices";

const ExpandPopUpDataList = (props) => (
  <Show {...props} className="showShit" title=" ">
    <SimpleShowLayout className="pop-expand">
      <ImageField source="headerImageUrl" />
      <TextField source="mainHeader" />
      <TextField source="mainHeaderSubtitle" />
      <BooleanField source="shopButton" />
      <TextField source="footerTitle" />
      <TextField source="footerSubtitle" />
      <TextField source="discountLabel" />
      <TextField source="dismissButton" />
      <TextField source="callToAction" />
      <TextField source="outOfBox" />
      <TextField source="type" />
      <NumberField source="priority" />
    </SimpleShowLayout>
  </Show>
);

export const PopupdataList = (props) => (
  <List {...props} expand={<ExpandPopUpDataList />}>
    <Datagrid rowClick="show" >
      <TextField source="name" />
      <TextField source="sku" />
      <TextField source="type" />
      <NumberField source="id" />
    </Datagrid>
  </List>
);

export const PopupdataEdit = (props) => (
  <EditView {...props}>
    <BooleanInput source="isDeleted" />
    <TextInput source="sku" />
    <TextInput source="name"  />
    <TextInput source="imagetype" defaultValue="general"/>
    <ImageInput source="pictures" label="تصویر مربوطه" accept="image/*">
      <ImageField source="src" />
    </ImageInput>
    <ImageField source="headerImageUrl" />
    <TextInput source="mainHeader" />
    <TextInput source="mainHeaderSubtitle" />
    <BooleanInput source="shopButton" />
    <TextInput source="footerTitle" />
    <TextInput source="footerSubtitle" />
    <TextInput source="discountLabel" />
    <TextInput source="dismissButton" />
    <TextInput source="callToAction" />
    <TextInput source="outOfBox" />
    <SelectInput source="type" choices={POPUP_DATA_CHOICES} />
    <NumberInput source="priority" />
  </EditView>
);

export const PopupdataShow = (props) => (
    <ShowView {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="sku" />
            <TextField source="name" />
            <ImageField source="headerImageUrl" />
            <TextField source="mainHeader" />
            <TextField source="mainHeaderSubtitle" />
            <BooleanField source="shopButton" />
            <TextField source="footerTitle" />
            <TextField source="footerSubtitle" />
            <TextField source="discountLabel" />
            <TextField source="dismissButton" />
            <TextField source="callToAction" />
            <TextField source="outOfBox" />
            <TextField source="type" />
            <NumberField source="priority" />
        </SimpleShowLayout>
    </ShowView>
);

export const PopupdataCreate = (props) => (
  <CreateView {...props}>
    <TextInput source="name" validate={required()} />
    <TextInput source="sku" />
    <ImageInput source="pictures" label="تصویر مربوطه" accept="image/*">
      <ImageField source="src" />
    </ImageInput>
    <ImageField source="headerImageUrl" />
    <TextInput source="mainHeader" />
    <TextInput source="mainHeaderSubtitle" />
    <BooleanInput defaultValue={false} source="shopButton" />
    <TextInput source="footerTitle" />
    <TextInput source="footerSubtitle" />
    <TextInput source="discountLabel" />
    <TextInput source="dismissButton" />
    <TextInput source="callToAction" />
    <TextInput source="outOfBox" />
    <SelectInput source="type" choices={POPUP_DATA_CHOICES} />
    <NumberInput source="priority" />
  </CreateView>
);
