import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import {
  NotificationCreate,
  NotificationEdit,
  NotificationList,
  NotificationShow,
} from "./Notification";

export const notification = {
  name: "notification",
  list: NotificationList,
  show: NotificationShow,
  edit: NotificationEdit,
  create: NotificationCreate,
  icon: NotificationsActiveIcon,
  options: { cat: "marketing" },
};



