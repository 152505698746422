import React from "react";
import {
  Datagrid,
  List,
  SelectField,
  SimpleShowLayout,
  TextField,
  SelectInput,
  TextInput,
  NumberInput, 
  NumberField,
  Pagination
} from "react-admin";

import ShowView from "../../Components/ShowView";
import CreateView from "../../Components/CreateView";
import { SHOP_SELECT_MARKET } from "../../Constants/choices";


const PostPagination = props => <Pagination rowsPerPageOptions={[10, 50, 100,200]} {...props} />;
export const PlanSKUList = (props) => (
  <List {...props} pagination={<PostPagination />} >
    <Datagrid rowClick="show">
      <TextField source="name" />
      <TextField source="skuId" />
      <NumberField source="price" textAlign="center" />  
      <SelectField source="currency" choices={SHOP_SELECT_MARKET} />
    </Datagrid>
  </List>
);


export const PlanSKUCreate = (props) => (
  <CreateView {...props}>
    <TextInput source="name" />
    <TextInput source="skuId" />
    <NumberInput source="price"/>  
    <SelectInput source="currency" choices={SHOP_SELECT_MARKET} />
  </CreateView>
);

export const PlanSKUShow = (props) => (
  <ShowView {...props} EditView>
    <SimpleShowLayout>
      <TextField source="name" />
      <TextField source="skuId" />
      <NumberField source="price"/>  
      <SelectField source="currency" choices={SHOP_SELECT_MARKET} />
    </SimpleShowLayout>
  </ShowView>
);
