import moment from "moment";
import jMoment from "moment-jalaali";
import React, { useState } from "react";
import JalaliUtils from "@date-io/jalaali";
import {
  DatePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
  TimePicker,
} from "@material-ui/pickers";

jMoment.loadPersian({ dialect: "persian-modern", usePersianDigits: true });

export function PersianTimePicker() {
  const [selectedDate, handleDateChange] = useState(moment());

  return (
    <MuiPickersUtilsProvider utils={JalaliUtils} locale="fa">
      <TimePicker
        clearable
        okLabel="تأیید"
        cancelLabel="لغو"
        clearLabel="پاک کردن"
        labelFunc={(date) => (date ? date.format("hh:mm A") : "")}
        value={selectedDate}
        onChange={handleDateChange}
      />
    </MuiPickersUtilsProvider>
  );
}

export function PersianDatePicker(props) {
  return (
    <MuiPickersUtilsProvider utils={JalaliUtils} locale="fa">
      <DatePicker
        label={props.label || ""}
        okLabel="تأیید"
        cancelLabel="لغو"
        clearLabel="پاک کردن"
        labelFunc={(date) => (date ? date.format("jYYYY/jMM/jDD") : "")}
        value={props.value}
        onChange={props.onChange}
        inputVariant="filled"
          {...props}
      />
    </MuiPickersUtilsProvider>
  );
}

export function PersianDateTimePicker() {
  const [selectedDate, handleDateChange] = useState(moment());

  return (
    <MuiPickersUtilsProvider utils={JalaliUtils} locale="fa">
      <DateTimePicker
        okLabel="تأیید"
        cancelLabel="لغو"
        labelFunc={(date) => (date ? date.format("jYYYY/jMM/jDD hh:mm A") : "")}
        value={selectedDate}
        onChange={handleDateChange}
      />
    </MuiPickersUtilsProvider>
  );
}
