import React from "react";
import {
  Datagrid,
  List,
  BooleanField ,
  BooleanInput,
  NumberField,
  NumberInput,
  SimpleShowLayout,
  TextField,
  TextInput,
  SelectArrayInput,
} from "react-admin";
import EditView from "../../Components/EditView";
import CreateView from "../../Components/CreateView";
import ShowView from "../../Components/ShowView";
import { ArrayTextField } from "../../Components/ArrayTextField";
import { MARKETS_CHOICES } from "../../Constants/choices";

export const PackagePricingTagList = (props) => (
  <List {...props}>
    <Datagrid rowClick="show">
      <TextField source="name" />
      <NumberField  source="weight" />
      <BooleanField  source="isAvailable" valueLabelTrue="در دسترس است"  valueLabelFalse="در دسترس نیست" />
      <NumberField source="planAmount" />
      <NumberField source="discountPercent" />
      <ArrayTextField source={"markets"} />
      <TextField source="nonDiscountPrice" />
      <TextField source="id" />
    </Datagrid>
  </List>
);

export const PackagePricingTagEdit = (props) => (
  <EditView {...props} >
    <TextInput source="name" />
    <NumberInput source="weight"/>
    <BooleanInput source="isAvailable" label="در دسترس" />
    <NumberInput source="planAmount" />
    <NumberInput source="discountPercent" />
    <SelectArrayInput source="markets" choices={MARKETS_CHOICES} />
    <TextInput source="nonDiscountPrice" />
  </EditView>
);

export const PackagePricingTagCreate = (props) => (
  <CreateView {...props}>
    <TextInput source="name" />
    <NumberInput source="weight"/>
    <BooleanInput source="isAvailable" label="در دسترس" />
    <NumberInput source="planAmount" />
    <NumberInput source="discountPercent" />
    <SelectArrayInput source="markets" choices={MARKETS_CHOICES} />
    <TextInput source="nonDiscountPrice" />
  </CreateView>
);

export const PackagePricingTagShow = (props) => (
  <ShowView {...props}>
    <SimpleShowLayout>
      <TextField source="name" />
      <NumberField  source="weight"/>
      <BooleanField  source="isAvailable" valueLabelTrue="در دسترس است"  valueLabelFalse="در دسترس نیست"/>
      <NumberField source="planAmount" />
      <NumberField source="discountPercent" />
      <ArrayTextField source="markets" />
      <TextField source="nonDiscountPrice" />
      <TextField source="id" />
    </SimpleShowLayout>
  </ShowView>
);
