import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  AppBar,
  Dialog,
  IconButton,
  Paper,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

TransactionsDialog.propTypes = {
  transactions: PropTypes.array,
  title: PropTypes.string.isRequired,
};

function TransactionsDialog(props) {
  const { transactions = [], title, record, refreshSubDate } = props;
  const [open, setOpen] = useState(false);
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const renderChildren = () => {
    return React.Children.map(props.children, (child) => {
      return React.cloneElement(child, {
        onClick: handleClick,
        fullWidth: true,
      });
    });
  };
  return (
    <>
      {renderChildren()}
      <Dialog
        open={open}
        onClose={handleDialogClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        fullScreen
        TransitionComponent={Transition}
      >
        <AppBar style={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleDialogClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="body1" gutterTop style={{ margin: "0.5em" }}>
              {title}
            </Typography>
          </Toolbar>
        </AppBar>
        <Paper>
          {transactions && transactions.length === 0 ? (
            <Typography
              variant="body1"
              gutterBottom
              style={{ margin: "0.5em" }}
            >
              تراکنشی وجود ندارد
            </Typography>
          ) : (
            <>
              <Table aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell align="right">تاریخ شروع</TableCell>
                    <TableCell align="right">تاریخ اتمام</TableCell>
                    <TableCell align="right">نام پکیج</TableCell>
                    <TableCell align="right">عنوان قیمت</TableCell>
                    <TableCell align="right">sku</TableCell>
                    <TableCell align="right">مارکت</TableCell>
                    <TableCell align="right">آدرس پگیری پرداخت</TableCell>
                    <TableCell align="right">دلیل لغو</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <>
                    {transactions &&
                      transactions.map((transaction, index) => (
                        <TableRow key={index}>
                          <TableCell align="center">
                            {new Date(
                              transaction?.transaction?.startedAt ||
                                transaction?.startedAt ||
                                "-"
                            ).toLocaleDateString("fa-IR")}
                          </TableCell>
                          <TableCell align="center">
                            {new Date(
                              transaction?.transaction?.completedAt ||
                                transaction?.completedAt ||
                                "-"
                            ).toLocaleDateString("fa-IR")}
                          </TableCell>
                          <TableCell align="center">
                            {transaction?.transaction?.packageName ||
                              transaction?.packageName ||
                              "-"}
                          </TableCell>
                          <TableCell align="center">
                            {transaction?.transaction?.pricingTitle || "-"}
                          </TableCell>
                          <TableCell align="center">
                            {transaction?.transaction?.sku ||
                              transaction?.sku ||
                              "-"}
                          </TableCell>
                          <TableCell align="center">
                            {transaction?.transaction?.market ||
                              transaction?.market ||
                              "-"}
                          </TableCell>
                          <TableCell align="center">
                            <a
                              target={"_blank"}
                              rel="noopener noreferrer"
                              href={
                                transaction?.transaction?.checkPaymentUrl ||
                                transaction?.checkPaymentUrl ||
                                "#"
                              }
                            >
                              پیگیری
                            </a>
                          </TableCell>
                        </TableRow>
                      ))}
                  </>
                </TableBody>
              </Table>
            </>
          )}
        </Paper>
      </Dialog>
    </>
  );
}

export default TransactionsDialog;

