import React, { useEffect, useState } from "react";
import { useNotify, useRedirect } from "react-admin";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import arrayMove from "array-move";
import { TableRow } from "@material-ui/core";
import { axiosFetcher } from "../../../utils/axiosFetcher";

export const DragHandle = sortableHandle(() => (
  <span style={{ fontSize: "24px", cursor: "grab" }}>::</span>
));
export const SortableItem = sortableElement((props) => {
  const red = useRedirect();
  return (
    <TableRow
      key={props.id}
      onClick={() => red(`/${props.resource}/${props.id}/show`)}
      style={{ cursor: "pointer" }}
    >
      {props.children}
    </TableRow>
  );
});

const SortableContainer = sortableContainer(({ children }) => {
  return <>{children}</>;
});
export default function (props) {
  const { ids, resource } = props;
  const [items, setItems] = useState([]);
  const notify = useNotify();
  useEffect(() => {
    setItems(ids);
  }, [ids]);
  const onSortEnd = ({ oldIndex, newIndex }) => {
    setItems(() => arrayMove(items, oldIndex, newIndex));
    let na = arrayMove(items, oldIndex, newIndex);
    let fin = [];
    na.forEach((n, index) => {
      fin.push({ id: n, order: index });
    });
    axiosFetcher
      .put(`/${resource}`, fin)
      .then((res) => {
        if (res.status < 200 || res.status >= 300) {
          throw new Error(res.statusText);
        } else {
          notify("karafs.order.success", "success");
        }
      })
      .catch((er) => {
        notify(`karafs.order.failure: ${er.message}`, "warning");
      });
  };
  return (
    <SortableContainer
      onSortEnd={onSortEnd}
      helperClass="sortHelper"
      useDragHandle
    >
      {React.Children.map(props.children, (child) =>
        React.cloneElement(child, {
          ...props,
          items,
        })
      )}
    </SortableContainer>
  );
}
