import {
  updatePopupdataCreate,
  updatePopupdataEdit,
  updatePopupdataList,
  updatePopupdataShow,
} from "./UpdatePopUpData";
import FastfoodIcon from "@material-ui/icons/Fastfood";

export const updatePopUpData = {
  name: "updatePopUpData",
  list: updatePopupdataList,
  edit: updatePopupdataEdit,
  show: updatePopupdataShow,
  create: updatePopupdataCreate,
  icon: FastfoodIcon,
  options: { cat: "marketing" },
};
