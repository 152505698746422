import React from "react";
import {
  BooleanField,
  BooleanInput,
  ChipField,
  Datagrid,
  DateField,
  List,
  NumberField,
  ReferenceArrayField,
  ReferenceArrayInput,
  ReferenceField,
  ReferenceManyField,
  SelectArrayInput,
  SingleFieldList,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
  required,
  Filter,
} from "react-admin";
import EditView from "../../Components/EditView";
import CreateView from "../../Components/CreateView";
import ShowView from "../../Components/ShowView";

const ExerciseFilter = (props) => (
    <Filter {...props}>
      <TextInput label="جست‌وجو" source="name" alwaysOn />
    </Filter>
);

export const ExerciseList = (props) => (
  <List {...props} filters={<ExerciseFilter />} perPage={15}>
    <Datagrid rowClick="show">
      <TextField source="name" />
      <ReferenceArrayField
        source="exerciseCategories"
        reference="exerciseCategory"
        linkType="show"
        >
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <DateField locales="fa-IR" source="updatedAt" />
      <BooleanField source="isDeleted" />
    </Datagrid>
  </List>
);

export const ExerciseCreate = (props) => (
  <CreateView {...props}>
    <BooleanInput source="isDeleted" />
    <TextInput source="name" validate={required()} />
    <ReferenceArrayInput
      source="exerciseCategories"
      reference="exerciseCategory"
      label="Exercise Category"
      validate={required()}
      >
      <SelectArrayInput>
        <ChipField source="name" />
      </SelectArrayInput>
    </ReferenceArrayInput>
  </CreateView>
);

export const ExerciseEdit = (props) => (
  <EditView {...props}>
    <BooleanInput source="isDeleted" />
    <DateField locales="fa-IR" source="updatedAt" />
    <TextInput source="name" validate={required()} />
    <ReferenceArrayInput
      source="exerciseCategories"
      reference="exerciseCategory"
      label="Exercise Category"
    >
      <SelectArrayInput validate={required()}>
        <ChipField source="name" />
      </SelectArrayInput>
    </ReferenceArrayInput>
  </EditView>
);

export const ExerciseShow = (props) => (
  <ShowView {...props}>
    <TabbedShowLayout>
      <Tab label="توضیحات">
        <BooleanField source="isDeleted" />
        <DateField locales="fa-IR" source="updatedAt" />
        <TextField source="name" />
        <ReferenceArrayField
          source="exerciseCategories"
          reference="exerciseCategory"
        >
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
      </Tab>
      <Tab label="اطلاعات تمرین" path="body">
        <ReferenceManyField
          target="exerciseId"
          reference="exerciseFact"
          label=""
        >
          <Datagrid rowClick="show">
            <ReferenceField source="exerciseId" reference="exercise">
              <TextField source="name" />
            </ReferenceField>
            <ReferenceField
              source="exerciseUnitId"
              reference="exerciseUnit"
              linkType="show"
            >
              <TextField source="name" />
            </ReferenceField>
            <NumberField source="met" />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </ShowView>
);
