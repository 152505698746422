import React from "react";
import {DeleteButton, ShowButton, TopToolbar} from "react-admin";
import PropTypes from "prop-types";

EditActions.propTypes = {
  noDel: PropTypes.bool,
};

function EditActions({ basePath, data, resource, noDel = false }) {
  return (
    <TopToolbar>
      <ShowButton basePath={basePath} record={data} />
      {data?.isDeleted ? (
        <DeleteButton
          label={"ra.action.restore"}
          basePath={basePath}
          record={data}
          resource={resource}
          style={{ color: "orange" }}
        />
      ) : noDel ? null : (
        <DeleteButton basePath={basePath} record={data} resource={resource} />
      )}
    </TopToolbar>
  );
}
export default EditActions;
