export const MARKET_ENUMS = {
  NONE: "NONE",
  GOOGLE_PLAY_BETA: "GOOGLE_PLAY_BETA",
  GOOGLE_PLAY: "GOOGLE_PLAY",
  CAFE_BAZAAR: "CAFE_BAZAAR",
  MYKET: "MYKET",
  IOS_TESTFLIGHT : "IOS_TESTFLIGHT",
  IOS_SIBAPP: "IOS_SIBAPP",
  IOS_APP_STORE:"IOS_APP_STORE",
  APP_STORE:"APP_STORE",
  SAAM : "SAAM",
  PWA : "PWA",
  SNAP_PAY: "SNAP_PAY",

};

export const PRICING_ENUMS = {
  REGULAR_PAID: "REGULAR_PAID",
  FIRST_PAY_SALE: "FIRST_PAY_SALE",
  FIRST_PAY_SALE_TIME_LIMITED: "FIRST_PAY_SALE_TIME_LIMITED",
  SECOND_PAY_SALE_TIME_LIMITED: "SECOND_PAY_SALE_TIME_LIMITED",
  GIFT: "GIFT",
  CAMPAIGN: "CAMPAIGN",
  TRIAL: "TRIAL",
};

export const CURRENCY_ENUMS = {
  DOLLAR :"DOLLAR",
  EURO :"EURO",
  TOMAN :"TOMAN",
};

export const DATA_PAGE_NAMES_ENUMS = {
  ADVICE: "ADVICE",
  SHOP: "SHOP",
  SHOP_CARD_SALE  :"SHOP_CARD_SALE",
  SHOP_DESIGN  :"SHOP_DESIGN",
  GOAL_SCENARIO_TYPE  :"GOAL_SCENARIO_TYPE",
  INNER_NOTIFICATION_TYPE :"INNER_NOTIFICATION_TYPE",
  AFTER_REGISTER_TEXT_TYPE:"AFTER_REGISTER_TEXT_TYPE",
};

export const PAGE_ACTIONS_NAMES_ENUMS = {
  GOAL_POPUP: "GOAL_POPUP",
  NONE: "NONE",
  TUTORIAL: "TUTORIAL",
  SHOP_FEATURES_DETAILS_IN_POP_UP: "SHOP_FEATURES_DETAILS_IN_POP_UP",
  SHOP_FEATURES_STATIC_WITHOUT_SCROLL_DETAILS: "SHOP_FEATURES_STATIC_WITHOUT_SCROLL_DETAILS",
  SHOP_FEATURES_STATIC_WITH_SCROLL_DETAILS: "SHOP_FEATURES_STATIC_WITH_SCROLL_DETAILS",
  SHOP_CARD_SALE_DETAILS: "SHOP_CARD_SALE_DETAILS",
  SHOP_CARD_ACTION_WITH_BUTTON: "SHOP_CARD_ACTION_WITH_BUTTON",
  SHOP_CARD_ACTION_WITHOUT_BUTTON: "SHOP_CARD_ACTION_WITHOUT_BUTTON",
  SHOP_CARD_ADVICE_WEIGHT_CHANGE_DETAILS: "SHOP_CARD_ADVICE_WEIGHT_CHANGE_DETAILS",
  SHOP_CARD_ADVICE_EMPTY: "SHOP_CARD_ADVICE_EMPTY",
  SHOP_CARD_ADVICE_WEIGHT_CHANGE_BANNER: "SHOP_CARD_ADVICE_WEIGHT_CHANGE_BANNER",
  SHOP_CARD_POPULAR_CARD: "SHOP_CARD_POPULAR_CARD",
};

export const PAGE_DATA_TYPES_ENUMS = {
  SHOP_PREMIUM_WITH_BUTTON: "SHOP_PREMIUM_WITH_BUTTON",
  SHOP_PREMIUM_WITH_SCROLL_CONTENT: "SHOP_PREMIUM_WITH_SCROLL_CONTENT",
  SHOP_PREMIUM_WITH_SCROLL_PAGE: "SHOP_PREMIUM_WITH_SCROLL_PAGE",
  ADVICE_ACTIONABLE: "ADVICE_ACTIONABLE",
  ADVICE_NON_ACTIONABLE: "ADVICE_NON_ACTIONABLE",
  AFTER_REGISTER_TEXT_BMI:"AFTER_REGISTER_TEXT_BMI",
  AFTER_REGISTER_TEXT_DEFAULT:"AFTER_REGISTER_TEXT_DEFAULT",
  TYPE_F:"TYPE_F",
  TYPE_G:"TYPE_G",
  TYPE_H:"TYPE_H",
  GOAL_SCENARIO_TYPE_A:"GOAL_SCENARIO_TYPE_A",
  GOAL_SCENARIO_TYPE_B:"GOAL_SCENARIO_TYPE_B",
  GOAL_SCENARIO_TYPE_C:"GOAL_SCENARIO_TYPE_C",
  GOAL_SCENARIO_TYPE_D:"GOAL_SCENARIO_TYPE_D",
  NOTIFICATION_FREQ_0:"NOTIFICATION_FREQ_0",
  NOTIFICATION_FREQ_0_1:"NOTIFICATION_FREQ_0_1",
  NOTIFICATION_FREQ_0_3:"NOTIFICATION_FREQ_0_3",
  NOTIFICATION_FREQ_0_5:"NOTIFICATION_FREQ_0_5",
  NOTIFICATION_FREQ_0_7:"NOTIFICATION_FREQ_0_7",
  NOTIFICATION_FREQ_0_9:"NOTIFICATION_FREQ_0_9",
};

export const POPUP_DATA_ENUMS = {
  FIRST_PAY_SALE: "FIRST_PAY_SALE",
  OFFER: "OFFER",
  CAMPAIGN: "CAMPAIGN",
  GIFT: "GIFT",
  TRIAL: "TRIAL",
};

export const USER_ACTIVITY_LEVEL_ENUMS = {
  VERY_LOW: "VERY_LOW",
  LOW: "LOW",
  MODERATE: "MODERATE",
  HIGH: "HIGH",
  VERY_HIGH: "VERY_HIGH",
};

export const USER_BREAST_FEEDING_STATE_ENUMS = {
  INACTIVE: "INACTIVE",
  MILK_ONLY: "MILK_ONLY",
  MILK_AND_FOOD: "MILK_AND_FOOD",
};
export const NEW_USER_BREAST_FEEDING_STATE_ENUMS = {
  BREASTFEEDING: "BREASTFEEDING",
  PREGNANCY: "PREGNANCY",
};

export const USER_GENDER_ENUMS = {
  FEMALE: "FEMALE",
  MALE: "MALE",
};

export const PAGE_NAMES_ENUM = {
  SHOP: "SHOP",
  GOAL: "GOAL",
  CHALLENGE: "CHALLENGE",
  HOME: "HOME",
  AFTER_REGISTRATION: "AFTER_REGISTRATION",
  OFFER_REGISTRATION: "OFFER_REGISTRATION",
  GOAL_POPUP: "GOAL_POPUP",
  TRIAL_POPUP: "TRIAL_POPUP",
};

export const Notification_Data_Goal_Enums = {
  ALL: "ALL",
  HAS_GOAL: "HAS_GOAL",
  NO_GOAL: "NO_GOAL",
};

export const UpdatePopUpData_Type_Enums ={
  FORCE_UPDATE :"FORCE_UPDATE",
  VOLUNTARY_UPDATE:"VOLUNTARY_UPDATE"
}

export const RemoteConfigPageNames_Enums  = {
  SHOP : 'SHOP',
  AFTER_REGISTRATION : 'AFTER_REGISTRATION',
  INNER_NOTIFICATION_TYPE : 'INNER_NOTIFICATION_TYPE',
  GOAL_SCENARIO_TYPE : 'GOAL_SCENARIO_TYPE',
  INVITE_FRIEND_TEXT  : 'INVITE_FRIEND_TEXT' ,
  INVITE_FRIEND_FULL_TEXT  : 'INVITE_FRIEND_FULL_TEXT' ,
  INVITE_FRIEND_POPUP_NO_CODE_TEXT  : 'INVITE_FRIEND_POPUP_NO_CODE_TEXT ' ,
  INVITE_FRIEND_POPUP_TEXT : 'INVITE_FRIEND_POPUP_TEXT' ,
  INVITE_FRIEND_SHARE_TEXT : 'INVITE_FRIEND_SHARE_TEXT' ,
}

export const RemoteConfigPageTypes_Enums  = {
  TYPE_F: 'TYPE_F',
  TYPE_G: 'TYPE_G',
  TYPE_H: 'TYPE_H',
  AFTER_REGISTER_TEXT_DEFAULT: 'AFTER_REGISTER_TEXT_DEFAULT',
  AFTER_REGISTER_TEXT_BMI: 'AFTER_REGISTER_TEXT_BMI',
  NOTIFICATION_FREQ_0: 'NOTIFICATION_FREQ_0',
  NOTIFICATION_FREQ_0_1: 'NOTIFICATION_FREQ_0_1',
  NOTIFICATION_FREQ_0_3: 'NOTIFICATION_FREQ_0_3',
  NOTIFICATION_FREQ_0_5: 'NOTIFICATION_FREQ_0_5',
  NOTIFICATION_FREQ_0_7: 'NOTIFICATION_FREQ_0_7',
  NOTIFICATION_FREQ_0_9: 'NOTIFICATION_FREQ_0_9',
  GOAL_SCENARIO_TYPE_A: 'GOAL_SCENARIO_TYPE_A',
  GOAL_SCENARIO_TYPE_B: 'GOAL_SCENARIO_TYPE_B',
  GOAL_SCENARIO_TYPE_C: 'GOAL_SCENARIO_TYPE_C',
  GOAL_SCENARIO_TYPE_D: 'GOAL_SCENARIO_TYPE_D',
}

export const Advice_Enums = {
  WATER : "WATER",
  FOOD : "FOOD",
  WEIGHT : "WEIGHT",
  ACTIVITY :"ACTIVITY",
  CALORIE :"CALORIE",
  STEP :"STEP",
  Actionable : "Actionable",
  NonActionable : "Non-Actionable",
}

