import React from 'react';

const TrackerUrlField = (props) => {

    return <span>{props.record.baseURL}/?trackerId={props.record.identifier}</span>;
};

TrackerUrlField.defaultProps = {
    addLabel:true
}
export default TrackerUrlField;