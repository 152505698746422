import React from "react";
import {Create, SimpleForm} from "react-admin";
import {CreateFormActions} from "./FormActions";

CreateView.propTypes = {};

function CreateView(props) {

  return (
    <Create {...props}>
      <SimpleForm toolbar={<CreateFormActions />}>{props.children}</SimpleForm>
    </Create>
  );
}

export default CreateView;
