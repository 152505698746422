export const SERVER =
  process.env.REACT_APP_API_ENDPOINT || "https://v2.karafsapp.com/admin";
export const SERVER_DOMAIN =
  process.env.REACT_APP_SERVER_ENDPOINT || "https://v2.karafsapp.com";
export const ROLES = {
  SUPER_ADMIN: "SUPER_ADMIN",
  FOOD_ADMIN: "FOOD_ADMIN",
  USERS_ADMIN: "USERS_ADMIN",
  MARKETING_ADMIN: "MARKETING_ADMIN",
  DOCTOR: "DOCTOR",
};
export const KARAFS_WEBSITE = 'karafsapp.com'
// environment variable
// .env