import React from "react";
import PropTypes from "prop-types";
import {Edit, SimpleForm} from "react-admin";
import EditActions from "./EditActions";
import {EditFormActions} from "./FormActions";

EditView.propTypes = {
  noDel: PropTypes.bool,
};

function EditView(props) {
  const { noDel = false } = props;
  return (
    <Edit mutationMode="pessimistic" {...props} actions={<EditActions noDel={noDel}  />} undoable={false}>
      <SimpleForm   toolbar={<EditFormActions />}>{props.children}</SimpleForm>
    </Edit>
  );
}

export default EditView;
