import React, { useState } from "react";
import {
  Datagrid,
  Labeled,
  List,
  SimpleShowLayout,
  TextField,
  TextInput,
} from "react-admin";
import { Chip as MuiTextField, Tooltip } from "@material-ui/core";
import EditView from "../../Components/EditView";
import ShowView from "../../Components/ShowView";
import CreateView from "../../Components/CreateView";

export const DownloadlinkList = (props) => (
  <List {...props}>
    <Datagrid rowClick="show">
      <TextField source="source" />
      <TextField source="medium" />
      <TextField source="targetLink" />
    </Datagrid>
  </List>
);

const UrlField = (props) => {
  const [tooltip, setTooltip] = useState("برای کپی کلیک کنید");
  const { record } = props;
  let url = `https://landing.karafsapp.com/?source=${record?.source}&medium=${record?.medium}`;
  function copyToClipboard() {
    setTooltip("کپی شد");
    navigator.clipboard.writeText(url);
  }
  return (
    <Labeled label={"آدرس نهایی"}>
      <Tooltip title={tooltip} aria-label="add">
        <MuiTextField label={url} onClick={copyToClipboard} />
      </Tooltip>
    </Labeled>
  );
};
export const DownloadlinkEdit = (props) => (
  <EditView {...props}>
    <TextField source="id" />
    <TextInput source="source" />
    <TextInput source="medium" />
    <TextInput source="targetLink" />
    <UrlField />
  </EditView>
);
export const DownloadlinkCreate = (props) => (
  <CreateView {...props}>
    <TextInput source="source" />
    <TextInput source="medium" />
    <TextInput source="targetLink" />
  </CreateView>
);
export const DownloadlinkShow = (props) => (
  <ShowView {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="source" />
      <TextField source="medium" />
      <TextField source="targetLink" />
      <UrlField />
    </SimpleShowLayout>
  </ShowView>
);
