import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { FieldTitle, useInput } from "ra-core";
import {
  DatePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
  TimePicker,
} from "@material-ui/pickers";
import jMoment from "moment-jalaali";
import JalaliUtils from "@date-io/jalaali";

jMoment.loadPersian({ dialect: "persian-modern", usePersianDigits: true });

const Picker = ({ PickerComponent, ...fieldProps }) => {

  const {
    options,
    label,
    source,
    resource,
    className,
    isRequired,
    providerOptions,
  } = fieldProps;

  const { input, meta } = useInput({ source });

  const { touched, error } = meta;

  const handleChange = useCallback((value) => {
    Date.parse(value) ? input.onChange(value) : input.onChange(null);
  }, []);

  return (
    <div className="picker">
      <MuiPickersUtilsProvider {...providerOptions}>
        <PickerComponent
          {...options}
          label={
            <FieldTitle
              label={label}
              source={source}
              resource={resource}
              isRequired={isRequired}
            />
          }
          okLabel="تأیید"
          showTodayButton = "true"
          todayLabel = "برو به امروز"
          cancelLabel="لغو"
          clearLabel="پاک کردن"
          labelFunc={(date) => (date ? date.format("jYYYY/jMM/jDD") : "")}
          margin="normal"
          inputVariant="filled"
          error={!!(touched && error)}
          helperText={touched && error}
          className={className}
          value={input.value ? new Date(input.value) : null}
          onChange={(date) => handleChange(date)}
          onBlur={() =>
            input.onBlur(input.value ? new Date(input.value) : null)
          }
        />

      </MuiPickersUtilsProvider>

    </div>
  );
};

Picker.propTypes = {
  input: PropTypes.object,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  meta: PropTypes.object,
  options: PropTypes.object,
  resource: PropTypes.string,
  source: PropTypes.string,
  labelTime: PropTypes.string,
  className: PropTypes.string,
  providerOptions: PropTypes.shape({
    utils: PropTypes.func,
    locale: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
};

Picker.defaultProps = {
  input: {},
  isRequired: false,
  meta: { touched: false, error: false },
  options: {},
  resource: "",
  source: "",
  labelTime: "",
  className: "",
  providerOptions: {
    utils: JalaliUtils,
    locale: "fa",
  },
};

export const MaterialDateInput = (props) => (
  <Picker PickerComponent={DatePicker} {...props} />
);
export const MaterialTimeInput = (props) => (
  <Picker PickerComponent={TimePicker} {...props} />
);
export const MaterialDateTimeInput = (props) => (
  <Picker PickerComponent={DateTimePicker} {...props} />
);
