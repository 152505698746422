import FastfoodIcon from "@material-ui/icons/Fastfood";
import {PaymentServerTroubleshootList} from "./PaymentServerTroubleshoot";

export const paymentServerTroubleshoot = {
  name: "non-paid-invoices",
  list: PaymentServerTroubleshootList,
  // edit: PackagepricingEdit,
  // show: PackagepricingShow,
  // create: PackagepricingCreate,
  icon: FastfoodIcon,
  options: { cat: "misc" },
};
