import React, { useState } from "react";
import {
  AppBar,
  Dialog,
  IconButton,
  Paper,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { axiosFetcher } from "../../../utils/axiosFetcher";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const GenericDialog = (props) => {
  console.log(props, "genDialog");
  const [open, setOpen] = useState(false);
  const [his, setHis] = useState([{ name: "aaa", dislikeCount: 4, id: 56 }]);
  function getHistory() {
    axiosFetcher
      .get(`/faqQuestionHistory/byQuestionId/${props.record.id}`)
      .then((res) => {
        console.log(res, "res");
        setHis(res.data);
      })
      .catch((e) => {
        console.log(e, "error");
      });
  }
  const handleClick = () => {
    setOpen(true);
    getHistory();
  };
  const handleDialogClose = () => setOpen(false);
  const renderChildren = () => {
    return React.Children.map(props.children, (child) => {
      return React.cloneElement(child, {
        onClick: handleClick,
        fullWidth: false,
      });
    });
  };
  return (
    <>
      {renderChildren()}
      <Dialog
        open={open}
        onClose={handleDialogClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        fullScreen
        TransitionComponent={Transition}
      >
        <AppBar style={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleDialogClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="body1" gutterTop style={{ margin: "0.5em" }}>
              تاریخچه سوالات
            </Typography>
          </Toolbar>
        </AppBar>
        {/* TODO: Transform data to table => look at TransactionDialog Table Example ## look at materialUi Table Api*/}
        <Paper>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">شناسه</TableCell>
                <TableCell align="center">شناسه سوال</TableCell>
                <TableCell align="center"> تعداد لایک</TableCell>
                <TableCell align="center"> تعداد دیسلایک</TableCell>
                <TableCell align="center"> تاریخ ایجاد</TableCell>
                <TableCell align="center"> شناسه ادمین</TableCell>
                <TableCell align="center"> شناسه دسته بندی</TableCell>
                <TableCell align="center"> شناسه زیر دسته بندی</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {his.map((e) => (
                <TableRow>
                  <TableCell align="center">{e?.id}</TableCell>
                  <TableCell align="center">{e?.questionId} </TableCell>
                  <TableCell align="center">{e?.likeCount} </TableCell>
                  <TableCell align="center">{e?.dislikeCount} </TableCell>
                  <TableCell align="center">{e?.createdAt}</TableCell>
                  <TableCell align="center">{e?.adminUsername} </TableCell>
                  <TableCell align="center">{e?.categoryId} </TableCell>
                  <TableCell align="center">{e?.subcategoryId} </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </Dialog>
    </>
  );
};

export default GenericDialog;
