// NOTE: delete btn works

import React from "react";
import {
    AutocompleteInput,
    BooleanField,
    Datagrid,
    DateField,
    Filter,
    List,
    NumberField,
    NumberInput,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    Show,
    SimpleShowLayout,
    TextField,
    required,
    BooleanInput,
} from "react-admin";
import { withStyles } from "@material-ui/styles";
import EditView from "../../Components/EditView";
import ShowView from "../../Components/ShowView";
import CreateView from "../../Components/CreateView";

export const FoodfactFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput
      label="جست‌وجو"
      source="foodId"
      reference="food"
      alwaysOn
      filterToQuery={(searchText) => ({ name: searchText })}
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <NumberInput source="protein" />
    <NumberInput source="calorie" />
    <BooleanInput source="hasCategory"  alwaysOn label="دارای دسته بندی"  />
  </Filter>
);

const styles = {
  inlineBlock: {
    display: "inline-flex",
    marginRight: "1rem",
    right: "0 !important",
  },
};

const ExpandFoodfactList = (props) => (
  <Show {...props} className="showShit" title=" ">
    <SimpleShowLayout className={"expand-foodFact"}>
      <NumberField source="carbohydrate" className="shit" />
      <NumberField source="cholesterol" className="shit" />
      <NumberField source="sugar" className="shit" />
      <NumberField source="sodium" className="shit" />
      <NumberField source="calcium" className="shit" />
      <NumberField source="iron" className="shit" />
      <NumberField source="transFat" className="shit" />
      <NumberField source="fiber" className="shit" />
      <NumberField source="potassium" className="shit" />
      <NumberField source="phosphorus" className="shit" />
      <NumberField source="monounsaturatedFat" className="shit" />
      <NumberField source="polyunsaturatedFat" className="shit" />
      <NumberField source="saturatedFat" className="shit" />
      <NumberField source="magnesium" className="shit" />
    </SimpleShowLayout>
  </Show>
);

export const FoodfactList = (props) => (
  <List {...props} perPage={15} filters={<FoodfactFilter />}>
    <Datagrid
      rowClick="show"
      expand={<ExpandFoodfactList />}
    >
      <ReferenceField source="foodId" reference="food">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="foodUnitId" reference="foodUnit">
        <TextField source="name" />
      </ReferenceField>
      <NumberField source="fat" />
      <NumberField source="calorie" />
      <NumberField source="protein" />
      <TextField source="id" />
      <DateField locales="fa-IR" source="updatedAt" />
      <BooleanField source="isDeleted" />
    </Datagrid>
  </List>
);
export const FoodfactEdit = (props) => (
  <EditView {...props}>
    <BooleanField source="isDeleted" />
    <DateField locales="fa-IR" source="updatedAt" />
    <ReferenceField source="foodId" reference="food">
      <TextField source="name" disav />
    </ReferenceField>
    <ReferenceInput source="foodUnitId" reference="foodUnit">
      <SelectInput optionText="name" />
    </ReferenceInput>
    <NumberInput source="foodUnitGramSize" />
    <NumberInput source="calorie" />
    <NumberInput source="protein" />
    <NumberInput source="carbohydrate" />
    <NumberInput source="cholesterol" />
    <NumberInput source="fat" />
    <NumberInput source="sugar" />
    <NumberInput source="sodium" />
    <NumberInput source="calcium" />
    <NumberInput source="iron" />
    <NumberInput source="transFat" />
    <NumberInput source="fiber" />
    <NumberInput source="potassium" />
    <NumberInput source="phosphorus" />
    <NumberInput source="monounsaturatedFat" />
    <NumberInput source="polyunsaturatedFat" />
    <NumberInput source="saturatedFat" />
    <NumberInput source="magnesium" />
    <TextField source="id" />
  </EditView>
);

export const FoodfactShow = (props) => (
  <ShowView {...props}>
    <SimpleShowLayout className="showShit">
      <BooleanField source="isDeleted" className="shit" />
      <DateField locales="fa-IR" source="updatedAt" className="shit" />
      <ReferenceField source="foodId" reference="food">
        <TextField source="name" className="shit" />
      </ReferenceField>
      <ReferenceField source="foodUnitId" reference="foodUnit">
        <TextField source="name" className="shit" />
      </ReferenceField>
      <NumberField source="foodUnitGramSize" />
      <NumberField source="calorie" className="shit" />
      <NumberField source="protein" className="shit" />
      <NumberField source="carbohydrate" className="shit" />
      <NumberField source="cholesterol" className="shit" />
      <NumberField source="fat" className="shit" />
      <NumberField source="sugar" className="shit" />
      <NumberField source="sodium" className="shit" />
      <NumberField source="calcium" className="shit" />
      <NumberField source="iron" className="shit" />
      <NumberField source="transFat" className="shit" />
      <NumberField source="fiber" className="shit" />
      <NumberField source="potassium" className="shit" />
      <NumberField source="phosphorus" className="shit" />
      <NumberField source="monounsaturatedFat" className="shit" />
      <NumberField source="polyunsaturatedFat" className="shit" />
      <NumberField source="saturatedFat" className="shit" />
      <NumberField source="magnesium" className="shit" />
      <TextField source="id" className="shit" />
    </SimpleShowLayout>
  </ShowView>
);

export const FoodfactCreate = withStyles(styles)(({ classes, ...props }) => (
  <CreateView {...props}>
    <ReferenceInput
      source="foodId"
      reference="food"
      filterToQuery={(searchText) => ({ name: searchText })}
      validate={required()}
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput
      source="foodUnitId"
      reference="foodUnit"
      filterToQuery={(searchText) => ({ name: searchText })}
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <NumberInput source="foodUnitGramSize" />
    <NumberInput
      source="calorie"
      formClassName={classes.inlineBlock}
      className="createShit"
    />
    <NumberInput
      source="protein"
      formClassName={classes.inlineBlock}
      className="createShit"
    />
    <NumberInput
      source="carbohydrate"
      formClassName={classes.inlineBlock}
      className="createShit"
    />
    <NumberInput
      source="cholesterol"
      formClassName={classes.inlineBlock}
      className="createShit"
    />
    <NumberInput
      source="fat"
      formClassName={classes.inlineBlock}
      className="createShit"
    />
    <NumberInput
      source="sugar"
      formClassName={classes.inlineBlock}
      className="createShit"
    />
    <NumberInput
      source="sodium"
      formClassName={classes.inlineBlock}
      className="createShit"
    />
    <NumberInput
      source="calcium"
      formClassName={classes.inlineBlock}
      className="createShit"
    />
    <NumberInput
      source="iron"
      formClassName={classes.inlineBlock}
      className="createShit"
    />
    <NumberInput
      source="transFat"
      formClassName={classes.inlineBlock}
      className="createShit"
    />
    <NumberInput
      source="fiber"
      formClassName={classes.inlineBlock}
      className="createShit"
    />
    <NumberInput
      source="potassium"
      formClassName={classes.inlineBlock}
      className="createShit"
    />
    <NumberInput
      source="phosphorus"
      formClassName={classes.inlineBlock}
      className="createShit"
    />
    <NumberInput
      source="monounsaturatedFat"
      formClassName={classes.inlineBlock}
      className="createShit"
    />
    <NumberInput
      source="polyunsaturatedFat"
      formClassName={classes.inlineBlock}
      className="createShit"
    />
    <NumberInput
      source="saturatedFat"
      formClassName={classes.inlineBlock}
      className="createShit"
    />
    <NumberInput
      source="magnesium"
      formClassName={classes.inlineBlock}
      className="createShit"
    />
  </CreateView>
));
