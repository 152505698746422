import React from "react";
import {
    ArrayField,
    ArrayInput,
    AutocompleteInput,
    BooleanField,
    BooleanInput,
    ChipField,
    Datagrid,
    DateField,
    ImageField,
    ImageInput,
    List,
    NumberField,
    NumberInput,
    ReferenceArrayField,
    ReferenceArrayInput,
    ReferenceField,
    ReferenceInput,
    RichTextField,
    SelectArrayInput,
    SelectInput,
    SimpleFormIterator,
    SimpleShowLayout,
    SingleFieldList,
    TextField,
    TextInput,
    required
} from "react-admin";
import { withStyles } from "@material-ui/styles";
import ShowView from "../../Components/ShowView";
import EditView from "../../Components/EditView";
import CreateView from "../../Components/CreateView";

const styles = {
  inlineBlock: {
    display: "inline-flex",
    marginRight: "1rem",
    right: "0 !important",
  },
};

const postRowStyle = () => ({
  textAlign: "right",
});

const ExpandRecipeList = (props) => (
  <ShowView {...props} className="showShit" title=" ">
    <SimpleShowLayout>
      <ReferenceField source="foodFactId" reference="foodFact" className="shit">
        <TextField source="id" />
      </ReferenceField>
      <TextField source="description" className="shit" />
      <RichTextField source="direction" />
      <RichTextField source="note" className="shit" />
      <br />
      <ArrayField source="ingredients">
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
      <br />
      <ImageField source="imageUrl" className="shit" />
    </SimpleShowLayout>
  </ShowView>
);

export const FoodRecipeList = (props) => (
  <List {...props} perPage={30}>
    <Datagrid
      rowClick="show"
      rowStyle={postRowStyle}
      expand={<ExpandRecipeList />}
    >
      <TextField source="title" />
      <ReferenceArrayField source="categoryIds" reference="foodRecipeCategory">
        <SingleFieldList>
          <ChipField source="title" />
        </SingleFieldList>
      </ReferenceArrayField>
      <ImageField source="imageUrl" />
      <NumberField source="personCount" />
      <NumberField source="duration" />
      <BooleanField source="isPremium" />
      <NumberField source="id" />
      <DateField locales="fa-IR" source="updatedAt" />
      <BooleanField source="isDeleted" />
    </Datagrid>
  </List>
);
export const FoodRecipeEdit = (props) => (
  <EditView {...props} title={<FoodTitle />}>
    <NumberInput fullWidth source="duration" />
    <NumberInput fullWidth source="personCount" />
    <TextInput fullWidth source="title" validate={required()} />
    <ReferenceArrayInput
      source="categoryIds"
      reference="foodRecipeCategory"
      fullWidth={true}
      label={"دسته بندی"}
      validate={required()}
    >
      <SelectArrayInput optionText="title" />
    </ReferenceArrayInput>
    <BooleanInput source="isPremium" />
    <ArrayInput source="ingredients">
      <SimpleFormIterator>
        <TextInput source="name" />
        <TextInput source="amount" />
      </SimpleFormIterator>
    </ArrayInput>
    <TextInput multiline fullWidth source="description" />
    <TextInput fullWidth multiline source="direction" />
    <TextInput fullWidth multiline source="note" />
    <ImageInput source="pictures" label="تصویر مربوطه" accept="image/*">
      <ImageField source="src" title="imageUrl" />
    </ImageInput>
    <ReferenceInput source="foodFactId" reference="foodFact">
      <ReferenceInput source="foodId" reference="food">
        <SelectInput optionText="id" />
      </ReferenceInput>
    </ReferenceInput>
  </EditView>
);
const FoodTitle = ({ record }) => {
  return <span> غذای {record ? `"${record.name}"` : ""}</span>;
};
export const FoodRecipeShow = withStyles(styles)(({ classes, ...props }) => (
  <ShowView {...props}>
    <SimpleShowLayout>
      <TextField source="title" className="showShit" />
      <BooleanField source="isPremium" className="showShit" />
      <NumberField source="duration" className="showShit" />
      <NumberField source="personCount" className="showShit" />
      <TextField source="description" className="showShit" />
      <br />
      <DateField locales="fa-IR" source="updatedAt" className="showShit" />
      <ReferenceArrayField source="categoryIds" reference="foodRecipeCategory">
        <SingleFieldList>
          <ChipField source="title" />
        </SingleFieldList>
      </ReferenceArrayField>
      <TextField source="direction" className="showShit" />
      <TextField source="note" className="showShit" />
      <TextField source="foodFactId" className="showShit" />
      <TextField source="id" className="showShit" />
      <ImageField source="imageUrl" className="showShit" />
      <ArrayField source="ingredients" className="">
        <Datagrid>
          <TextField source="name" />
          <TextField source="amount" className="showShit" />
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  </ShowView>
));

export const FoodRecipeCreate = withStyles(styles)(({ classes, ...props }) => (
  <CreateView {...props}>
    <BooleanInput source="isPremium" defaultValue={false} />
    <NumberInput
      source="duration"
      formClassName={classes.inlineBlock}
      className="createShit"
    />
    <NumberInput
      source="personCount"
      formClassName={classes.inlineBlock}
      className="createShit"
    />
    <TextInput
      source="title"
      formClassName={classes.inlineBlock}
      className="createShit"
      validate={required()}
    />
    <ReferenceArrayInput
      source="categoryIds"
      reference="foodRecipeCategory"
      fullWidth={true}
      label={"دسته بندی"}
      validate={required()}
    >
      <SelectArrayInput optionText="title" />
    </ReferenceArrayInput>
    <TextInput fullWidth multiline source="description" />
    <ArrayInput source="ingredients" className="createShit">
      <SimpleFormIterator>
        <TextInput source="name" />
        <TextInput source="amount" />
      </SimpleFormIterator>
    </ArrayInput>
    <TextInput fullWidth multiline source="direction" />
    <TextInput fullWidth multiline source="note" />

    {/*<TextInput*/}
    {/*  source="imageId"*/}
    {/*  formClassName={classes.inlineBlock}*/}
    {/*  className="createShit"*/}
    {/*/>*/}
    {/*<ReferenceInput*/}
    {/*  label={"اطلاعات غذایی"}*/}
    {/*  source="foodFactId"*/}
    {/*  reference="foodFact"*/}
    {/*>*/}
    {/*  <SelectInput optionText="id" />*/}
    {/*</ReferenceInput>*/}

    {/*<TextInput*/}
    {/*  source="foodFactId"*/}
    {/*  formClassName={classes.inlineBlock}*/}
    {/*  className="createShit"*/}
    {/*/>*/}

    <ReferenceInput
      source="foodFactId"
      reference="foodFact"
      label={"اطلاعات غذایی"}
    >
      <ReferenceInput
        source="foodId"
        reference="food"
        filterToQuery={(searchText) => ({ name: searchText })}
      >
        <AutocompleteInput optionText="name" source="name" />
      </ReferenceInput>
    </ReferenceInput>
    <ImageInput source="pictures" label="تصویر مربوطه" accept="image/*">
      <ImageField source="src" title="imageUrl" />
    </ImageInput>
  </CreateView>
));
