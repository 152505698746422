import { SERVER, SERVER_DOMAIN } from "../Constants";
import { httpClient } from "../utils/request";

const apiUrl = SERVER;
const serverUrl = SERVER_DOMAIN;
export function getOne(resource, params) {
  console.log("res", resource);
  switch (resource) {
    case "food": {
      return httpClient(`${apiUrl}/${resource}/${params.id}`).then(
        ({ json }) => {
          let similarNames = [];
          json.similarNames &&
            json.similarNames.forEach((item) => {
              similarNames.push({ name: item });
            });
          return {
            data: { ...json, similarNames },
          };
        }
      );
    }
    case "personalfood": {
      return httpClient(`${apiUrl}/${resource}/dashboard`).then(
        ({ json }) => {
          console.log("pr ",  json.find(it => it) );
          return {
            data:  json.find(it => it) ,
          };
        }
      );
    }
    case "user":
      return httpClient(`${apiUrl}/${resource}/${params.id}`).then(
        ({ json }) => {
          let detail = json.user.phoneNumber;
          if (json.user.email !== undefined) {
            detail = json.user.email;
          }
          return {
            data: { ...json, id: detail },
          };
        }
      );
    case "adminUsers":
      return httpClient(`${apiUrl}/${resource}/${params.id}`).then(
        ({ json }) => ({
          data: { ...json, id: json.phoneNumber },
        })
      );
    case "campaign":
    case "getToKnow":
    case "faqQuestion":
    case "bulkDiscountCode":
      return httpClient(
        `${apiUrl}/${resource}/${params.id}`
      ).then(({ json }) => ({ data: { ...json, id: json._id } }));
    case "campaignCard":
      return httpClient(`${apiUrl}/${resource}/${params.id}`).then(
        ({ json }) => ({
          data: { ...json, id: json.objectId },
        })
      );

    // $$$ ↓↓↓ SHOP ↓↓↓ $$$
    case "plan":
      return httpClient(`${apiUrl}/shop/v2/plan/${params.id}`).then(
        ({ json }) => ({
          data: { ...json.result.plan, id: json.result.plan.id },
        })
      );

    case "plansku":
      return httpClient(`${apiUrl}/shop/v2/sku/${params.id}`).then(
        ({ json }) => ({
          data: { ...json.result.sku, id: json.result.sku.id },
        })
      );

    case "promotion":
      return httpClient(`${apiUrl}/shop/v2/${resource}/${params.id}`).then(
        ({ json }) => ({
          data: { ...json.result.promotion, id: json.result.promotion.id },
        })
      );

    case "shop-package":
      return httpClient(`${apiUrl}/shop/v2/${resource}/${params.id}`).then(
        ({ json }) => ({
          data: { ...json.result.shopPackage, id: json.result.shopPackage.id },
        })
      );

    case "bulkDiscountCodes":
      return httpClient(
        `${apiUrl}/shop/v2/bulkDiscountCode/${params.id}`
      ).then(({ json }) => ({ data: { ...json, id: json._id } }));

    case "regularDiscountCodes":
      return httpClient(
        `${apiUrl}/shop/v2/regularDiscountCode/${params.id}`
      ).then(({ json }) => ({ data: { ...json, id: json.id } }));

    case "packagePricing":
      return httpClient(`${apiUrl}/${resource}/${params.id}`).then(
        ({ json }) => ({
          data: { ...json },
        })
      );
    case "subscription":
      return httpClient(`${apiUrl}/user/${params.id}/${resource}`).then(
        ({ json }) => ({
          data: { ...json, id: json.expirationTime.toString() },
        })
      );
    case "notification": {
      if (params.reqType === "refreshUserToken") {
        return httpClient(
          `${apiUrl}/${resource}/recalculate_users/${params.record.id}`
        ).then(({ json }) => {
          return {
            data: { ...params.record, currentUserCount: json.count },
          };
        });
      }
      return httpClient(`${apiUrl}/${resource}/${params.id}`).then(
        ({ json }) => ({
          data: json,
        })
      );
    }
    case "tracker":
      return httpClient(
        `${serverUrl}/api/${resource}/${params.id}`
      ).then(({ json }) => ({ data: { ...json.data, id: json.data._id } }));
    default:
      return httpClient(`${apiUrl}/${resource}/${params.id}`).then(
        ({ json }) => ({
          data: { ...json, id: json.id },
        })
      );
  }
}
